import React from 'react';
import ReactApexChart from 'react-apexcharts';
// Function to generate random data
const generateData = (count, yrange) => {
  let series = [];
  for (let i = 0; i < count; i++) {
    let x = `w${i + 1}`;
    let y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push({ x, y });
  }
  return series;
};

const CampaignCard = ({graphData}) => {
  const bestModelNameObject = graphData.find(item => item.hasOwnProperty("Best Model Name"));
  console.log(bestModelNameObject)
  const bestModelName = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;
  // Step 2: Find the object containing the best model using the best model name
  const bestModelContainer = bestModelName ? graphData.find(obj => obj.hasOwnProperty(bestModelName)) : null;

  // Step 3: Extract the best model from the container
  const bestModel = bestModelContainer ? bestModelContainer[bestModelName] : null;

  // Step 4: Extract HeatMap data from the best model
  const heatmapData = bestModel?.HeatMap;


  // If heatmapData exists, map it to the series format required by the chart
  const series = heatmapData
    ? heatmapData.columns.map((column, colIndex) => {
        return {
          name: column,
          data: heatmapData.data[colIndex].map((value, rowIndex) => {
            return {
              x: heatmapData.index[rowIndex],
              y: value
            };
          })
        };
      })
    : [];

  const options = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#008FFB'],
    title: {
      text: 'HeatMap Chart (Single color)',
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="heatmap" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default CampaignCard;
