import { Card, CardContent, Grid, TextField } from '@mui/material';

const YourComponent = () => {
  return (
    <Card sx={{ background: 'linear-gradient(180deg, rgba(80, 164, 219, 0.70) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1) 100%)', border: '1px solid #AFAFAF', width: '80%', height: '227px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <CardContent>
        <Grid item container xs={12} md={12} sx={{ color: '#000', fontSize: '16px' }}>
          <p>Lorem ipsum dolor</p>
        </Grid>
        <Grid item container xs={12} md={12} sx={{ color: '#000', fontSize: '16px' }}>
          <p>
            Value: <TextField id="standard-basic" variant="standard" />
          </p>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default YourComponent;
