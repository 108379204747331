import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card3 from '../Card3/Card3';
import image from '../../Images/icon-park-outline_sales-report.jpg';
import image1 from '../../Images/ri_survey-line.jpg';
import image2 from '../../Images/mdi_canvas.jpg';
import image3 from '../../Images/humbleicons_dashboard.jpg';
import image4 from '../../Images/solar_graph-up-linear.jpg';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import Grid3x3OutlinedIcon from '@mui/icons-material/Grid3x3Outlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function SimpleContainer5() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" >
        <Box >
          <Grid  sx={{
              
              color: '#000',
              fontSize: '30px',
              fontWeight: '500',
              // textAlign: 'left',
              lineHeight: 'normal',
              paddingRight: {md: '20%'}
            }}>
          <p>
            Lorem ipsum dolor sit amet consectetur. Senectus ornare tempus interdum nibh.
          </p>
          </Grid>
          <Grid sx={{             
              // color: '#AFAFAF',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: 'normal',
            }}>
          <p>
            Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
          </p>
          </Grid>
          <Grid container item  direction="row" justifyContent="center" alignItems="center" style={{ marginTop: '3%', textAlign: 'center' }}>
            <Grid item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
              <Card3 icon={<AssessmentOutlinedIcon sx={{fontSize: '40px'}}/>} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
              <Card3 icon={<SummarizeOutlinedIcon sx={{fontSize: '40px'}}/>} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
              <Card3 icon={<Grid3x3OutlinedIcon sx={{fontSize: '40px'}}/>} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
              <Card3 icon={<DashboardOutlinedIcon sx={{fontSize: '40px'}}/>} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
              <Card3 icon={<TrendingUpIcon sx={{fontSize: '40px'}}/>} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
