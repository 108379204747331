import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { FaRegEdit } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function TeamData({memberId}) {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  

  const handleClickOpen =async () => {

    
    setOpen(true);
  };
const navigate = useNavigate()

  const handleClosed = async() => {
    setOpen(false);
  };
  const handleDelete =async ()=>{
    try{

      const response = await axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/delete/team-member/${memberId}`);
 toast.success(response.data.message)
          window.location.reload()
          navigate('/#/team')
      setOpen(false);
      }catch(error){
        console.error(error)
        setOpen(false)
      }

  }

  const [anchorEl, setAnchorEl] = useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (route) => {
    setAnchorEl(null);
    window.location.href = route; // Use window.location to navigate to the selected page
  };

  return (
    <React.Fragment>      
      <div>
        <IconButton
            aria-controls="dropdown-menu"
            aria-haspopup="true"
            onClick={handleClick}
        >
        <MoreVertIcon  />
        </IconButton>
        <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: '300px', // Set your desired max height
                width: '120px', // Set your desired width
              },
            }}
        >
        <MenuItem sx={{fontSize: '18px'}}>
      <Link to={`/team-view/${memberId}`} >
      <ListItemIcon>
      <GrView/>
    </ListItemIcon>

    View
    </Link>
    </MenuItem>

    <MenuItem onClick={() => handleRedirect(`/#/team-edit/${memberId}`)} sx={{fontSize: '18px'}}> <ListItemIcon>
         
      <FaRegEdit/>
     </ListItemIcon>
       Edit
    
    </MenuItem>

        <MenuItem onClick={handleClickOpen} sx={{fontSize: '18px'}}>
          <ListItemIcon>
      <MdDeleteOutline/>
    </ListItemIcon>
    Delete
    </MenuItem>
        </Menu>
      </div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth= "md"
      >
        <DialogTitle id="responsive-dialog-title">
       
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                    <DeleteIcon sx={{fontSize: '30px'}}/>
                </Grid>
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" sx={{textAlign: 'center', fontSize: '20px', marginTop: '1%'}}>
                    <p >Are you sure you want to delete this member ?</p>
                </Grid>
        
            
          </DialogContentText>
        </DialogContent>
        <Grid  item direction="row" container justifyContent="center" textAlign="center" spacing={2} sx={{paddingBottom: '2%'}}>
        <Grid item  xs={5} md={6} container justifyContent="center" alignItems="center" >
          <Button autoFocus onClick={handleClosed} sx={{          
            border: '1px solid #50A4DB ',
            borderRadius: '8px',
            color: '#50A4DB ',
            fontSize: '18px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px'
            
          }}>
            Go Back
          </Button>
        </Grid>
        <Grid item xs={5} md={6} container justifyContent="center" alignItems="center">
          <Button onClick={handleDelete} autoFocus sx={{            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px',
            "&:hover": {
              backgroundColor: "#50A4DB ", // Set to "inherit" or any other color you want on hover
            },
          }}>
            Delete
          </Button>
        </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
