import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Link, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";


function PlanPayment() {
  const { plan, amount, nameOfPlan } = useParams();



  const [expanded, setExpanded] = useState(null);
  const navigate = useNavigate();
  const handleToggle = (index) => {
    setExpanded((prev) => (prev === index ? null : index));
  };

  const [cardDetails, setCardDetails] = useState({
    cardHolderName: "",
    cardNumber: "",
    expiration: "",
    email: "",
    cvv: "",
  });
  const handleInputChange = (name, value) => {
    //handles all the card's input fields
    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const items = [
    {
      label: (
        <Grid item xs={10} md={10}>
          <p style={{ color: "#000", fontSize: "24px", fontWeight: "400" }}>
            Credit/Debit Card
          </p>
        </Grid>
      ),
      content: (
        <Grid container direction="row" spacing={2} sx={{ marginBottom: "3%" }}>
          <Grid item xs={12} md={10}>
            <p
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingLeft: "20px",
              }}
            >
              Card Holder's Name
            </p>
            <TextField
              id="filled-basic"
              variant="filled"
              InputProps={{ disableUnderline: "true" }}
              sx={{ width: "100%", paddingLeft: "20px", paddingRight: "10px" }}
              value={cardDetails.cardHolderName}
              onChange={(e) =>
                handleInputChange("cardHolderName", e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <p
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingLeft: "20px",
              }}
            >
              Card Number
            </p>
            <TextField
              id="filled-basic"
              variant="filled"
              InputProps={{ disableUnderline: "true" }}
              sx={{ width: "100%", paddingLeft: "20px", paddingRight: "10px" }}
              value={cardDetails.cardNumber}
              onChange={(e) => handleInputChange("cardNumber", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <p
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingLeft: "20px",
              }}
            >
              Expiration
            </p>
            <TextField
              id="filled-basic"
              variant="filled"
              InputProps={{ disableUnderline: "true" }}
              sx={{ width: "100%", paddingLeft: "20px", paddingRight: "10px" }}
              value={cardDetails.expiration}
              onChange={(e) => handleInputChange("expiration", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <p
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingLeft: "20px",
              }}
            >
              Email
            </p>
            <TextField
              id="filled-basic"
              variant="filled"
              InputProps={{ disableUnderline: "true" }}
              sx={{ width: "100%", paddingLeft: "20px", paddingRight: "10px" }}
              value={cardDetails.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <p
              style={{
                color: "#000",
                fontSize: "20px",
                fontWeight: "400",
                paddingLeft: "20px",
              }}
            >
              CVV
            </p>
            <TextField
              id="filled-basic"
              variant="filled"
              InputProps={{ disableUnderline: "true" }}
              sx={{ width: "100%", paddingLeft: "20px", paddingRight: "10px" }}
              value={cardDetails.cvv}
              onChange={(e) => handleInputChange("cvv", e.target.value)}
            />
          </Grid>
        </Grid>
      ),
    },

    {
      label: (
        <p
          style={{
            color: "#000",
            fontSize: "24px",
            fontWeight: "400",
            marginLeft: "10%",
          }}
        >
          PayPal
        </p>
      ),
      content: (
        <Grid item xs={10} md={10} sx={{ marginLeft: "5%" }}>
          <p style={{ color: "#000", fontSize: "20px", fontWeight: "400" }}>
            Lorem ipsum dolor sit amet consectetur
          </p>
        </Grid>
      ),
    },

    {
      label: (
        <p
          style={{
            color: "#000",
            fontSize: "24px",
            fontWeight: "400",
            marginLeft: "10%",
          }}
        >
          ApplePay
        </p>
      ),
      content: (
        <Grid item xs={12} md={12} sx={{ marginLeft: "5%" }}>
          <p style={{ color: "#000", fontSize: "20px", fontWeight: "400" }}>
            Lorem ipsum dolor sit amet consectetur
          </p>
        </Grid>
      ),
    },
  ];
  const currentDate = new Date();

  const handlePaymentProceed = async (e) => {
    e.preventDefault();
    const isCardDetailsFilled = Object.values(cardDetails).every(
      (value) => value !== ""
    );
    if (isCardDetailsFilled) {
      // Execute further query or proceed with payment
    //   const stripe = await loadStripe(process.env.PUBLISHED_KEY_loadStripe);
      console.log(cardDetails)

      //   navigate("/payment-confirm");
    } else {
      // Handle the case where not all fields are filled
      toast.error("Please fill in all card details");
      console.log("Please fill in all card details");
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          marginTop: {
            xs: "20%",
            sm: "15%",
            md: "5%",
          },
        }}
      >
        <Grid item xs={2} md={1} container>
          <a
            href="/#/pricing"
            style={{ textDecoration: "none", color: "#000", marginLeft: "40%" }}
          >
            <ArrowBackIcon sx={{ fontSize: "25px" }} />
          </a>
        </Grid>
        <Grid item xs={10} md={4} container alignItems="center">
          <h1 style={{ color: "#000", fontSize: "32px", fontWeight: "400" }}>
            Plan Selected - {nameOfPlan} Service ({plan})
          </h1>
        </Grid>
      </Grid>

      <Grid
        item
        xs={10}
        md={4}
        container
        alignItems="center"
        style={{ marginTop: "2%" }}
      >
        <h1
          style={{
            color: "#000",
            fontSize: "32px",
            fontWeight: "400",
            marginLeft: "25%",
          }}
        >
          Make the payment
        </h1>
      </Grid>

      <Grid
        item
        container
        direction="row"
        spacing={1}
        sx={{
          border: "1px solid #FFF",
          width: "97%",
          height: "auto",
          marginTop: "5%",
        }}
      >
        <Grid item xs={12} md={9} justifyContent="center" alignItems="center">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginLeft: "99px",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                border: "1px solid rgba(175, 175, 175, 1)",
                width: "96%",
                height: "auto",
              }}
            >
              <input
                type="radio"
                id="radio0"
                name="radioGroup"
                checked={expanded === 0}
                onChange={() => handleToggle(0)}
                style={{
                  marginLeft: "20px",
                  marginTop: "30px",
                  marginBottom: "50px",
                }}
              />

              <label htmlFor="radio0">{items[0].label}</label>

              {expanded === 0 && <div>{items[0].content}</div>}
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                border: "1px solid rgba(175, 175, 175, 1)",
                width: "96%",
                height: "auto",
              }}
            >
              <input
                type="radio"
                id="radio2"
                name="radioGroup"
                checked={expanded === 1}
                onChange={() => handleToggle(1)}
                style={{
                  marginLeft: "20px",
                  marginTop: "30px",
                  marginBottom: "50px",
                }}
              />
              <label htmlFor="radio2">{items[1].label}</label>
              {expanded === 1 && <div>{items[1].content}</div>}
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                border: "1px solid rgba(175, 175, 175, 1)",
                width: "96%",
                height: "auto",
                marginBottom: "10%",
              }}
            >
              <input
                type="radio"
                id="radio3"
                name="radioGroup"
                checked={expanded === 2}
                onChange={() => handleToggle(2)}
                style={{
                  marginLeft: "20px",
                  marginTop: "30px",
                  marginBottom: "50px",
                }}
              />
              <label htmlFor="radio3">{items[2].label}</label>
              {expanded === 2 && <div>{items[2].content}</div>}
            </Grid>
          </div>
        </Grid>
        {/* //2nd */}
        <Grid item xs={12} md={3}>
          <div
            style={{
              border: "0.5px solid #F5F5F5",
              width: "100%",
              height: "100%",
              backgroundColor: "#F5F5F5",
            }}
          >
            <div style={{ marginLeft: "8px" }}>
              <Grid item xs={12} md={12}>
                <p
                  style={{ color: "#000", fontSize: "20px", fontWeight: "400" }}
                >
                  Payment Details
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <hr style={{ color: "#AFAFAF", width: "100%" }}></hr>
              </Grid>
              <Grid item xs={12} md={12}>
                <p
                  style={{ color: "#000", fontSize: "20px", fontWeight: "400" }}
                >
                  {plan}
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#000",
                    lineHeight: "normal",
                  }}
                >
                  {nameOfPlan} Service
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <hr style={{ color: "#AFAFAF", width: "100%" }}></hr>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ fontSize: "18px", fontWeight: "400" }}
              >
                <Grid item xs={6} md={6}>
                  <p>Transaction Date</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{currentDate.toLocaleDateString()}</p>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ fontSize: "18px", fontWeight: "400" }}
              >
                <Grid item xs={6} md={6}>
                  <p>Transaction Time</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{currentDate.toLocaleTimeString()}</p>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <hr style={{ color: "#AFAFAF", width: "100%" }}></hr>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ fontSize: "18px", fontWeight: "400" }}
              >
                <Grid item xs={6} md={6}>
                  <p>GST</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>$00(0%)</p>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ fontSize: "18px", fontWeight: "400" }}
              >
                <Grid item xs={6} md={6}>
                  <p>Subtotal</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>$00.00</p>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <hr style={{ color: "#AFAFAF", width: "100%" }}></hr>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                <Grid item xs={6} md={6}>
                  <p>Total</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{amount}</p>
                </Grid>
              </Grid>
            </div>
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Link
                sx={{
                  border: "1px solid rgba(80, 164, 219, 1)",
                  color: "#FFF",
                  textDecoration: "none",
                  backgroundColor: "rgba(80, 164, 219, 1)",
                  fontWeight: "600",
                  fontSize: "24px",
                  marginTop: "3%",
                  marginBottom: "3%",
                  padding: "5px 15px 5px 15px",
                  cursor: "pointer",
                }}
                onClick={(e) => handlePaymentProceed(e)}
              >
                Proceed
              </Link>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <div style={{ marginTop: "2%" }}>
        <Footer />
      </div>
    </div>
  );
}

export default PlanPayment;
