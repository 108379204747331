import { useEffect, useState } from "react";
import DefaultHome from "../../components/HomePage/DefaultHome";
import Sidebar from "../../components/HomePage/Sidebar";
import { useDispatch } from "react-redux";
import { userProfile } from "../../Reducers/userReducers";

function HomePage() {
    const [userList,setUserList]=useState({})


    const dispatch = useDispatch();  // Using useDispatch to get the dispatch function

    useEffect(() => {
      if (localStorage.getItem("access-token")) {
        // Call the userProfile action using dispatch
        dispatch(userProfile()).then((res) => {
          localStorage.setItem("user-profile", JSON.stringify(res.payload));
          // Update the userList state with the received user profile data
      
        });
      }
    }, [dispatch]); // Include dispatch in the dependency array to satisfy the ESLint dependency warning
  
    return(
        <div>
        <Sidebar refreshProjectList={setUserList} Component={<DefaultHome  userList={userList} />}/>
        
        </div>
    );
}

export default HomePage;