import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Reducers/userReducers';
import user from './Reducers/userReducers';
import project from './Reducers/memberReducer';
import canvas from './Reducers/CanvasReducer';
// import auth from './Reducers/authReducer';



export const store = configureStore({
  reducer: {
    user,
    project,
    canvas
  },
  
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
      serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== "production",
  
});

export default store;