import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';
import { Grid, Link } from '@mui/material';
import './CancelPlan.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function CancelPlan({customerId}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
const navigate =useNavigate()
  const handleClickOpen = async () => {
    try {
      console.log(customerId)
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/billing-portal-link`,
        { customerId: customerId }
      );
     
      window.location.href = response?.data?.url

      // Check if the server responded with a redirect flag
      if (response?.data?.redirect) {
        // Redirect to the home page
        window.location.href = 'http://localhost:3000/#/home-page';
      }
      // Handle the response here
    } catch (error) {
      console.error("AxiosError:", error);
      // Handle the error here
    }
  };
  

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
       <Button className='cancel_size'  onClick={handleClickOpen} sx={{
              // width: '80%',
              // height: '60px',
              borderRadius: '4px', 
              color: '#CF6F64',
              border: '1px solid #CF6F64',
              fontSize: '22px',
              fontWeight: '400',
              textTransform: 'none',
              textDecoration: 'none',
            padding : '3px 8px',
              // marginLeft: '5%',
              marginTop: '10%'
            }}>
        Cancel Plan
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
       
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                    <CancelIcon sx={{fontSize: '30px', color: 'rgba(229, 138, 123, 1)'}}/>
                </Grid>
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" sx={{textAlign: 'center', fontSize: '20px', marginTop: '1%'}}>
                    <p >Are you sure you want to cancel your plan ?</p>
                </Grid>
        
            
          </DialogContentText>
        </DialogContent>


        {/* //dialog box open when cancel */}
        <DialogActions >
        <Grid item xs={6} md={6} container justifyContent="center" alignItems="center">
          <Button autoFocus onClick={handleClose} sx={{          
            border: '1px solid #50A4DB ',
            borderRadius: '8px',
            color: '#50A4DB ',
            fontSize: '20px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '10px 35px 10px 35px'
          }}>
            Back
          </Button>
        </Grid>
        <Grid item xs={6} md={6} container justifyContent="center" alignItems="center">
          <Link href="/#/payment-cancel"  sx={{            
            backgroundColor: '#50A4DB ',
            border: '1px solid #50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '10px 35px 10px 35px',
            textDecoration: 'none'
          }}>
            Cancel
          </Link>
        </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
