import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Container2.css';
import imgSrc from '../../Images/Container_2.png'
import { Box } from '@mui/material';

export default function SimpleContainer2() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ marginTop: '3%' }}>
        <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
          <h5 id="heading2">The Impact we create</h5>
        </Grid>
        <Grid item container direction="row" sx={{marginTop: '3%'}}>
          <Grid item xs={12} md={6}>
            <Box sx={{ width: '100%', textAlign: 'center', marginTop: '3%' }}>
              <img src={imgSrc} alt='' style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="container2_text">
            <h6 id="heading3">Lorem ipsum dolor sit amet consectetur. Senectus ornare interdum nibh.</h6>
            <p id="para_text2">
              Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
            </p>
            <h6 id="heading3">Lorem ipsum dolor sit amet consectetur. Senectus ornare interdum nibh.</h6>
            <p id="para_text2">
              Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
            </p>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
