import HomeProfile from "../../components/HomePage/HomeProfile";
import Sidebar from "../../components/HomePage/Sidebar";

function Profile() {
    return(
        <div>
            <Sidebar Component={<HomeProfile/>}/>
        </div>
    )
}

export default Profile;