
import { useEffect } from "react";
import VerticalBarChart from "./BarChart";
import ActionAreaCard from "./Card";
import DoughnutChart from "./DoughnutChart";
import MultiAxisLineChart from "./LineChart";
import ActionAreaCard4 from "./ProjectCard";
import ActionAreaCard2 from "./RecentCard";
// import ActionAreaCard3 from "./TeamCard";


import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Grid, Link } from "@mui/material";
import { useState } from "react";
import axios from "axios";
// import DoughnutChart from "./DoughnutChart";

function HomeDashboard(){

  const [projectCount, setProjectCount] = useState(0);

  useEffect(() => {
    // Fetch project count from your API endpoint
       // Retrieve the token from localStorage
       const accessToken = JSON.parse(localStorage.getItem('access-token')); // Replace 'yourTokenKey' with the actual key you use to store the token

       // Set the token in the headers
       const headers = {
         Authorization: `Bearer ${accessToken}`,
       };
    axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projectCount`,{headers})
      .then(response => {
        setProjectCount(response.data.count);
      })
      .catch(error => {
        console.error('Error fetching project count:', error);
      });
  }, []); // Empty dependency array ensures the effect runs once on mount


  const chartData = {
    datasets: [
      {
        label: 'Dataset 1',
        data: [10, 20, 15, 25, 30],
        borderColor: '#7D36C8',
        
      },
      {
        label: 'Dataset 2',
        data: [5, 10, 8, 15, 20],
        borderColor: '#50A4DB',
      },
      {
        label: 'Dataset 3',
        data: [8, 6, 18, 10, 12],
        borderColor: '#38B27F',
      },
    ],
    labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5'],
    yAxes: [
      {
        id: 'y-axis-1',
        type: 'linear',
        position: 'left',
        display: 'true'
      },
      // You can add more yAxes configurations here if needed
    ],
  };
    return (
        <div>
         <Grid sx={{border: '1px solid #FFF', marginTop: {
                    xs: '16%',
                    sm: '10%',
                    md: '7%',
                    lg: '5%'
                },
                // marginBottom: '2%',
                
                paddingTop: '2%'}}>
            <Grid container direction="row" spacing={2} justifyContent="end">
            <Grid item xs={12} md={12} container justifyContent="end" textAlign="end" sx={{paddingBottom: '2%'}}>
          <Link href="/#/home-page" style={{ textTransform: 'none', textDecoration: 'none', border: '1px solid #FFF', boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px', color: '#000', padding: '5px 0 5px 10px', marginRight: '2%', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
      Projects
      <HomeOutlinedIcon sx={{ border: '1px solid #50A4DB ', backgroundColor: '#50A4DB ', boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '50%', width: '30px', height: '28px', color: '#FFF', padding: '5px', marginRight: '10px', marginLeft: '5px' }} />
    </Link> 
         
          </Grid>
            </Grid>
            </Grid>
            
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={12} >
                <h1 style={{fontSize: '32px', fontWeight: '500'}}>Dashboard</h1>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{marginTop: '2%'}}>
            <Grid item xs={12} md={4} container justifyContent="center" alignItems="center">
              <ActionAreaCard chart={<DoughnutChart />} text1={<h1>Total Projects</h1>} projectCount={projectCount} />
            </Grid>
            <Grid item xs={12} md={8} container justifyContent="center" alignItems="center">
            <MultiAxisLineChart chartData={chartData} />
            </Grid>
          </Grid>

            
          <Grid container direction="row" spacing={2} sx={{marginTop: '2%'}}>
            <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
               <VerticalBarChart/>
            </Grid>
            <Grid item xs={12} md={6} >
                  <ActionAreaCard2 />
            </Grid>
            {/* <Grid item xs={12} md={3} >
                  <ActionAreaCard3/>
            </Grid> */}
          </Grid>
            
          <Grid container direction="row" spacing={2} sx={{marginTop: '2%'}}>
            <Grid item xs={12} md={12} >
                  <ActionAreaCard4 />
            </Grid>
          </Grid>
        
            
        </div>
    );
}

export default HomeDashboard;