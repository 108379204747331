import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Grid, Link, MenuItem, TextField } from '@mui/material';
import Select from 'react-select';
import axios from 'axios';
import { useState } from 'react';
import { Input, Label } from 'reactstrap';
import toast from 'react-hot-toast';

export default function CreateProject({project}) {
  console.log(project,"edit prject")
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [goalSubType,setGoalSubType] = useState("")
const [categoryCount,setCategoryCount] = useState(0)
const [categories,setCategories] = useState([])
const [levelCount,setLevelCount] = useState(0)
const [levels,setLevels] = useState([])

const handleAddLevel = () => {
  if (levelCount < 5) {
    setLevelCount(prevCount => prevCount + 1);
  setLevels([...levels,{levelName : '',levelValue : '',levelMinRange : '',levelMaxRange : ''}])

  } else {
    // Optionally, you can display a message or prevent further addition when the limit is reached
   toast.error("Maximum level count reached!");
  }
};
const handleFieldChange = (index, field, value) => {
  const updatedLevels = [...levels];///if anyone updates the value of previous level
  updatedLevels[index][field] = value;
  setLevels(updatedLevels);

  setProjectDetails(prevDetails => ({
    ...prevDetails,
    goalType: {
      ...prevDetails.goalType,
      levels : updatedLevels
    }
  }));
};
  const handleAddCategory = () => {
    if (categoryCount < 5) {
      setCategoryCount(prevCatCount => prevCatCount + 1);
      setCategories(prevCategories => [...prevCategories, { categoryName: '', categoryValue: '' }]);
    } else {
      toast.error("Maximum category count reached!");
    }
  };


  const handleCategoryChange = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = value;
    setCategories(updatedCategories);

    setProjectDetails(prevDetails => ({
      ...prevDetails,
      goalType: {
        ...prevDetails.goalType,
        category: updatedCategories
      }
    }));
  };
  //Initially inside this form this data is presents

  const [projectDetails, setProjectDetails] = React.useState({
    projectName: project?.projectName || '', // default values or empty strings
    projectDetails: project?.projectDetails || '',
    industry: project?.industry || '', // default industry
    specializedUnit: project?.specializedUnit || '', // default specialized unit
    startDate: project?.startDate || '', // default start date
    endDate: project?.endDate || '', // default end date
    goalName: project?.goalName || '', // default goal name'
    // goalType: project?.goalType || '', // default goal type
    status: project?.status || '', // default status
    // goalMetric : project?.goalMetric?.goalMetricName || '', // default
    // goalMetricValue : project?.goalMetric.goalMetricValue || '', // default
    goalType: {
      typeofGoal : project?.goalType?.typeofGoal,
      name : project?.goalType?.name,
      value : project?.goalType?.value,
      selectedCategory  :   project?.goalType?.selectedCategory || '',
      category : [],

      levels :  []

    },
    goalMetric: {
      goalMetricName: project?.goalMetric?.goalMetricName || '', 
      goalMetricValue: project?.goalMetric?.goalMetricValue || '',

    },
    thresholdMetric :  {
      thresholdMetricValue : project?.thresholdMetric?.thresholdMetricValue || 0
    }

    // ... add more fields as needed
  });

const currentUrl = window.location.href;
const parts = currentUrl.split('/');
const projectId = parts[parts.length - 1];

  // Function to fetch project details from the API
  const updateProjectDetails = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/updateProjects/${projectId}`, projectDetails); // Replace with your API endpoint
      // console.log(response.data); // Log the response data for debugging purposes
      
      setProjectDetails(response.data);
    } catch (error) {
      console.error('Error fetching project details:', error);
    }
  };

  React.useEffect(() => {
    if (project) 
    setProjectDetails((prevDetails) => ({
  ...prevDetails,
   projectName: project?.projectName || '', // default values or empty strings
    projectDetails: project?.projectDetails || '',
    industry: project?.industry || '', // default industry
    specializedUnit: project?.specializedUnit || '', // default specialized unit
    startDate: project?.startDate || '', // default start date
    endDate: project?.endDate || '', // default end date
    goalName: project?.goalName || '', // default goal name'
   
    status: project?.status || '', // default status
    
      // goalMetric : project?.goalMetric?.goalMetricName || '', // default
      // goalMetricValue : project?.goalMetric.goalMetricValue || '', // default
      goalType: {
        typeofGoal: project.goalType?.typeofGoal || '',
        name: project.goalType?.name || '',
        value: project.goalType?.value || '',
        selectedCategory: project.goalType?.selectedCategory || '',
        category: project.goalType?.category || [],
        levels: project.goalType?.levels || []
      },
      goalMetric: {
        goalMetricName: project?.goalMetric?.goalMetricName || '', 
        goalMetricValue: project?.goalMetric?.goalMetricValue || ''
      },
      thresholdMetric :  {
        thresholdMetricValue : project?.thresholdMetric?.thresholdMetricValue || 0
      }
    }));
    if (project?.goalType?.category) {
      setCategories(project.goalType.category);
      setCategoryCount(project.goalType.category.length);
    }
    if (project?.goalType?.levels) {
      setLevels(project.goalType.levels);
      setLevelCount(project.goalType.levels.length);
    }
  }, [project]);

  

  const setNestedProperty = (obj, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const target = keys.reduce((acc, key) => {
        if (!acc[key]) acc[key] = {};
        return acc[key];
    }, obj);
    target[lastKey] = value;
    return obj;
};
const handleMenuItemClick = (value) => {
    
  setGoalSubType(value);

  setCategoryCount(0)
  setLevelCount(0)
  setCategories([]);
  setLevels([])
 
};

const goalsType = [
  {
    value: "Qualitative",
    subOptions: ["Nominal", "Categorical", "Ordinal"],
    levels : ["level 1","level 2","level 3","level 4"]
  },
  {
    value: "Quantitative",
    subOptions: ["Discrete", "Continous"]
  }
];

// Handler for form field changes
const handleFormFieldChange1 = (field, value) => {
  setProjectDetails((prevDetails) => {
      const newDetails = { ...prevDetails };
      if (goalSubType === 'Discrete' && field === 'goalType.value') {
        setNestedProperty(newDetails, 'goalType.name', '');
      }
      setNestedProperty(newDetails, field, value);
      return newDetails;
  });
};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    // console.log("Hello")
    // Update project details via API
    await updateProjectDetails();
    
    setOpen(false);
  };

  // Handler for form field changes
  const handleFormFieldChange = (field, value) => {
  
    setProjectDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const industries = [
    {
      value: 'mining',
      label: 'mining'
    },
    {
      value: 'FMCG',
      label: 'FMCG'
    },
    {
      value: 'Tech',
      label: 'Tech'
    },
    {
      value: 'Pharma',
      label: 'Pharma'
    },
    {
      value: 'Health',
      label: 'Health'
    },
  ];
  const specializedUnits = [
    {
      value: 'Marketing',
      label: 'Marketing'
    },
    {
      value: 'Sales',
      label: 'Sales'
    },
    {
      value: 'Finance',
      label: 'Finance'
    },
    {
      value: 'losgistics',
      label: 'losgistics'
    },
    {
      value: 'Procurement',
      label: 'Procurement'
    },
    {
      value: 'market access',
      label: 'market access'
    },
    {
      value: 'Clin ops',
      label: 'Clin ops'
    },
  ];
  const options = [
    { value: 'Ongoing', label: 'Ongoing' },
    { value: 'Completed', label: 'Completed' },
  ];
  const users = JSON.parse(localStorage.getItem('users'));
  const isDisabled = users?.designation === 'teamMember' || users?.designation === 'teamManager'
  return (
    <React.Fragment>
      <Button
      disabled = { isDisabled }
            sx={{
              // width: '10%',
              // height: '60px',
              backgroundColor: '#50A4DB ',
              borderRadius: '8px',
              color: '#FFF',
              fontSize: '24px',
              fontWeight: '600',
              textTransform: 'none',
              padding: '0px 50px 0px 50px'
              // marginLeft: '5%',
              // marginTop: '1%'
            }}
            color="primary"
            variant="contained"
            // endIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            Edit
          </Button>
          <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        PaperProps={{
          sx: {
            
            maxWidth: 'md', // You can use 'sm', 'md', 'lg', 'xl', or a specific pixel value
            position: 'relative',
            // overflowY: 'scroll',
            
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Grid container direction="row" spacing={6}>
                <Grid item xs={1} md={1} >
                  
                      <Button sx={{textDecoration:'none', color: '#000'}} autoFocus onClick={handleClose}>
                          <ArrowBackIcon sx={{fontSize: '30px'}}/>
                      </Button>
                  
                </Grid>
                <Grid item xs={9} md={4}>
                      <h1 style={{color: '#000', fontSize: '32px', fontWeight: '400'}}>Edit Project</h1>
                </Grid>
              </Grid>
              
              <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
              <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Project Name</h3>
              <TextField
    id="outlined-helperText"
    value={projectDetails.projectName}
    onChange={(e) => {handleFormFieldChange('projectName', e.target.value)}}
    sx={{ width: '100%',marginTop : '0.4%' }} // Increased fontSize to 32px
    inputProps = {{style : {
      fontSize  :'18px'
    }}}
/>
              </Grid>

              <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
              <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Project Details</h3>
              <TextField
                  id="outlined-helperText"
                  
                  value={projectDetails.projectDetails}
                  onChange={(e) => {handleFormFieldChange('projectDetails', e.target.value)}}
                  sx={{width: '100%',marginTop : '0.4%'}}
                  inputProps = {{style : {
                    fontSize  :'18px'
                  }}}
              />
              </Grid>    
              <Grid container direction="row" sx={{marginTop: '2%'}} >
                <Grid item xs={5} sm={4} md={2}>
                  <h5 style={{color: '#000', fontSize: '24px', fontWeight: '400'}}>Define Goals</h5>
                </Grid>
                {/* <Grid item xs={1} md={1} container justifyContent="center" alignItems="center">
                  <AddCircleOutlineIcon sx={{}}/>
                </Grid> */}
              </Grid>
            
              <Grid container direction="row" spacing={2} >
                <Grid item xs={12} md={6}>                
                    <p style={{color: '#000', fontSize: '16px', fontWeight: '400px'}}>Goal Name</p>
                    <TextField
                      id="outlined-helperText"
                      inputProps = {{style : {
                        fontSize  :'18px'
                      }}}
                      value={projectDetails?.goalName}
                  onChange={(e) => {handleFormFieldChange('goalName', e.target.value)}}
                      sx={{width: '100%'}}
                    />                
                </Grid>

                
                <Grid item xs={12} md={6}>                
                <p style={{color: '#000', fontSize: '16px', fontWeight: '400px'}}>Type of Goal</p>
                    <Select
                    placeholder="Select Goal"
                    options = {
                      [
                        {label : "Quantitative",value : "Quantitative"},
                        {label : "Qualitative",value : "Qualitative"}
                      ]
                    }
                      id="outlined-helperText"
                      inputProps = {{style : {
                        fontSize  :'18px'
                      }}}
                      value={{label : projectDetails?.goalType?.typeofGoal , value:projectDetails?.goalType?.typeofGoal}}
                  onChange={(selectedOption) => {handleFormFieldChange1('goalType.typeofGoal',selectedOption.value)}}
                      // sx={{width: '100%'}}
                      sx={{ width: '100%' ,height: '50px'}}
                      // error={!!formErrors.goalMetric}
                      // helperText={formErrors.goalMetric}
                     
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          fontSize: '18px', // Set font size for the input field
                          // borderWidth: '2px', // Increase border size
                          padding: '4px 0px 6px 0px' // Add more padding
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: '18px' // Set font size for the selected value
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: '18px' // Set font size for the options in the dropdown
                        })
                      }}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        neutral10: 'grey',
                                        primary: '#50a4db',
                                      },
                                    })}
                    />                
                </Grid>
              </Grid>              
            


              <Grid container direction="row" spacing={2} style={{marginTop:  '1%'}}>

<Grid item xs={12} md={4} >
                <h5  style={{color: '#000',  fontSize: '22px', fontWeight: '400' }}>{projectDetails?.goalType?.typeofGoal}</h5>
 {
projectDetails?.goalType?.typeofGoal && (
  <>
  {  console.log(projectDetails?.goalType?.typeofGoal === "Qualitative" ? projectDetails?.goalType?.typeofGoal?.selectedCategory : projectDetails?.goalType?.typeofGoal?.selectedCategory)}
  
  <Select
  options={(projectDetails?.goalType?.typeofGoal === 'Qualitative'
    ? goalsType.find((option) => option.value === projectDetails?.goalType?.typeofGoal)?.subOptions

    : ['Discrete', 'Continuous'])?.map((option) => ({ value: option, label: option }))
  }
  // value={{ value: selectCategory, label: selectCategory }}
  // onChange={(selectedOption) => setGoalSubType(selectedOption?.value)}
  onChange={(selectedOption) => handleMenuItemClick(selectedOption?.value)}
  placeholder='Select Subtype'
  isClearable={true}
  // error={!!formErrors.selectCategory}
  // helperText={formErrors.selectCategory}
  sx={{ width: '100%' }}
  inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
  styles={{
    control: (provided) => ({
      ...provided,
      fontSize: '18px', // Set font size for the input field
      // borderWidth: '2px', // Increase border size
      padding: '4px 0px 6px 0px' // Add more padding
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set font size for the selected value
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '18px' // Set font size for the options in the dropdown
    })
  }}
  theme={(theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral10: 'grey',
      primary: '#50a4db',
    },
  })}
/>
</>

)

}




</Grid>    

 {goalSubType === 'Nominal' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{goalSubType}</h5>
    <TextField
      placeholder="Name"
      value={projectDetails?.goalType?.name}
      onChange={(e)=>handleFormFieldChange1('goalType.name',e.target.value)}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '18px', height: '18px' } }} // Adjust height here
    />
    <TextField
      placeholder="Value"
       type="number"
      value={projectDetails.goalType?.value}
      sx={{ width: '100%', height: '64%' }}
      onChange={(e)=>handleFormFieldChange1('goalType.value',e.target.value)}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)}

{goalSubType === 'Discrete' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{goalSubType}</h5>
    <TextField
      label="Value"
      type="number" // Only allow integer inputs
      value={projectDetails.goalType?.value}
      onChange={(e)=>handleFormFieldChange1('goalType.value',e.target.value)}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
      inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)}

{goalSubType === 'Continuous' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{goalSubType}</h5>
    <TextField
      label="Value"
      type="number" // Allow float inputs as well
      value={projectDetails.goalType?.value}
      onChange={(e)=>handleFormFieldChange1('goalType.value',e.target.value)}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)}


{
goalSubType === 'Categorical' && 

<>

<Grid xs={12} md={4} item>
<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400' }}>{`Categorical Name`}</h5>
            <Input
              type="text"
              id='categorical name'
             value={projectDetails.goalType?.selectedCategory}
             style={{ fontSize: '18px',height:'48px' }}
              onChange={(e) => handleFormFieldChange1('goalType.selectedCategory',e.target.value)}
            />

         </Grid>

<Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  {
    projectDetails.goalType?.selectedCategory &&
    <Button
      style={{
        backgroundColor: '#50A4DB',
        width: '100%', // Full width on extra small screens (xs)
        maxWidth: '54%', // Maximum half width on medium screens (md)
        height: '39px',
        fontSize: '14px',
        color : '#FFFFFF',
        marginTop : '10%'
      }}
      onClick={handleAddCategory}
    >
      Add Category <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
    </Button>

    
  }
    
  </Grid>

 
 


</>
}



{
goalSubType === 'Ordinal' && 
<>

<Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Button
      style={{
        backgroundColor: '#50A4DB',
        width: '100%', // Full width on extra small screens (xs)
        maxWidth: '50%', // Maximum half width on medium screens (md)
        height: '38px',
        marginTop : '7%',
        fontSize: '16px',
        color : 'white'
      }}
      onClick={handleAddLevel}
    >
      Add Level <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
    </Button>
  </Grid>

</>
}

<Grid container spacing={4} style={{ marginTop: '1%' }}>
    {projectDetails?.goalType?.typeofGoal=== "Qualitative" && goalSubType == "Ordinal" && [...Array(levelCount)].map((_, index) => (
      <Grid item xs={12} md={6} key={index} style={{ paddingLeft: '6%' }}>
        <Label htmlFor={`levelName${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Name of level ${index + 1}`}</Label>
        <Input
          type="text"
          id={`levelName${index}`}
          placeholder={`level ${index + 1}`}
          // value={levelNames[index]}
          style={{ fontSize: '18px',height: '50px'}}
          onChange={(e) => handleFieldChange(index, 'levelName', e.target.value)}
         
        />
        <div style={{ marginTop: '3%' }}>
          <Label htmlFor={`levelValue${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Value of level ${index + 1}`}</Label>
          <Input
            type="text"
            id={`levelValue${index}`}
            placeholder={`level ${index + 1}`}
            // value={levelValues[index]}
            style={{ fontSize: '18px',height: '50px'}}
            onChange={(e) => handleFieldChange(index, 'levelValue', e.target.value)}
          />
        </div>

        <Grid container spacing={4} style={{ marginTop: '0.5%' }}>
          <Grid item xs={6} md={6}>
            <Label htmlFor={`rangeLevel${index}`} style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Select Range of Level ${index + 1}`}</Label>
            <Input
              type="number"
              id={`rangeLevel${index}`}
              placeholder={`level ${index + 1}`}
              // value={levelValMinRanges[index]}
              style={{ fontSize: '18px',height: '50px'}}
              onChange={(e) => handleFieldChange(index, 'levelMinRange', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <div>
              <Input
                type="number"
                id={`levelRange${index}`}
                placeholder={`Level ${index + 1}`}
                // value={levelValMaxRanges[index]}
                style={{ fontSize: '18px',height: '50px'}}
                onChange={(e) => handleFieldChange(index, 'levelMaxRange', e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    ))}

</Grid> 

{/* //categorical  */}

<Grid container spacing={4} style={{marginTop : '2%'}}>

{projectDetails?.goalType?.typeofGoal === 'Qualitative'  && goalSubType == "Categorical" && [...Array(categoryCount)].map((_, index) => (

<Grid item xs={12} md={6} key={index} >
<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400' }}>{`Category Name ${index + 1}`}</h5>

<Input
 type="text"
 id={`categoryName${index}`}
 placeholder={`Category Name ${index + 1}`}
//  value={categoryName[index]}
value={categories[index]?.categoryName || ''}
 style={{ fontSize: '18px',height:'50px' }}
 onChange={(e) => handleCategoryChange(index,'categoryName', e.target.value)}
 
/>




<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400', marginTop: '3%'}}>{`Category Value ${index + 1}`}</h5>

<Input
 type="text"
 id={`categoryValue${index}`}
 placeholder={`Category value ${index + 1}`}
//  value={categoryValue[index]}
value={categories[index]?.categoryValue || ''}
 style={{ fontSize: '18px',height:'54px' }}
 onChange={(e) => handleCategoryChange(index,'categoryValue', e.target.value)}
/>


</Grid>

))}
</Grid>


</Grid>
















            
                        
              <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
                <h5 style={{color: '#000', fontSize: '24px', fontWeight: '400px'}}>Industry</h5>
              </Grid>
               
               
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>                      
                          <Select
                          options ={industries}
                            id="outlined-helperText"
                            inputProps = {{style : {
                              fontSize  :'18px'
                            }}}
                            value={{value : projectDetails.industry , label : projectDetails.industry}}
                  onChange={(selectedOption) => {handleFormFieldChange('industry',  selectedOption.value)}}
                  sx={{ width: '100%' ,height: '50px'}}
                  // error={!!formErrors.goalMetric}
                  // helperText={formErrors.goalMetric}
                 
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '18px', // Set font size for the input field
                      // borderWidth: '2px', // Increase border size
                      padding: '4px 0px 6px 0px' // Add more padding
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the selected value
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the options in the dropdown
                    })
                  }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    neutral10: 'grey',
                                    primary: '#50a4db',
                                  },
                                })}
                      />
        




                </Grid>
                <Grid item xs={12} md={6}>                      
                      <Select
                      options={specializedUnits}
                        id="outlined-helperText"                            
                        value={{value : projectDetails?.specializedUnit , label :  projectDetails?.specializedUnit}}
                  onChange={(selectedOption) => {handleFormFieldChange('specializedUnit', selectedOption?.value)}}
                  sx={{ width: '100%' ,height: '50px'}}
                  // error={!!formErrors.goalMetric}
                  // helperText={formErrors.goalMetric}
                 
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '18px', // Set font size for the input field
                      // borderWidth: '2px', // Increase border size
                      padding: '4px 0px 6px 0px' // Add more padding
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the selected value
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the options in the dropdown
                    })
                  }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    neutral10: 'grey',
                                    primary: '#50a4db',
                                  },
                                })}
                      />
              
                       
                </Grid>
              </Grid>               
                
                
              <Grid container direction="row" spacing={2} sx={{marginTop: '1%'}}>
                <Grid item xs={12} md={6}>        
                <p style={{color: '#000', fontSize: '16px', fontWeight: '400'}}>Start Date</p>              
                      <TextField
                      type='date'
                        id="outlined-helperText"                            
                        value={projectDetails.startDate}
                        onChange={(e) => {handleFormFieldChange('startDate', e.target.value)}}
                        sx={{width: '100%'}}
                        inputProps = {{style : {
                          fontSize  :'15px'
                        }}}
                      />                      
                </Grid>
                <Grid item xs={12} md={6}>    
                <p style={{color: '#000', fontSize: '16px', fontWeight: '400'}}>End Date</p>                  
                      <TextField
                      type='date'
                        id="outlined-helperText"                          
                        value={projectDetails.endDate}
                  onChange={(e) => {handleFormFieldChange('endDate', e.target.value)}}
                        sx={{width: '100%'}}
                        inputProps = {{style : {
                          fontSize  :'15px'
                        }}}
                      />                     
                </Grid>
              </Grid>



              <Grid container direction="row" spacing={2} sx={{marginTop: '1%'}}>

              <Grid item xs={12} md={6}>
             <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Goal Metric</h5>
              
          <Select 
          options={[
            { label: "Percentage", value: "Percentage" },
            { label: "Whole", value: "Whole" },
            { label: "Decimal", value: "Decimal" }
          ]}
          value={{value : projectDetails?.goalMetric.goalMetricName  , label :projectDetails?.goalMetric.goalMetricName }}
            id="outlined-helperText"
            // options={mathValue}
            // onChange={(selectedOption) => {setGoalMetric(selectedOption?.value) }}
            onChange={(selectedOption) => handleFormFieldChange1('goalMetric.goalMetricName',selectedOption?.value)}
            placeholder='Select Value'
            sx={{ width: '100%' ,height: '50px'}}
            // error={!!formErrors.goalMetric}
            // helperText={formErrors.goalMetric}
           
            styles={{
              control: (provided) => ({
                ...provided,
                fontSize: '18px', // Set font size for the input field
                // borderWidth: '2px', // Increase border size
                padding: '4px 0px 6px 0px' // Add more padding
              }),
              singleValue: (provided) => ({
                ...provided,
                fontSize: '18px' // Set font size for the selected value
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '18px' // Set font size for the options in the dropdown
              })
            }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              neutral10: 'grey',
                              primary: '#50a4db',
                            },
                          })}
         
         />
          
                 </Grid>
                 {projectDetails?.goalMetric?.goalMetricName === 'Percentage' && (
      <Grid item xs={12} md={6}>
        <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Percentage value</h5>
        <TextField
          type="number"
          id="percentage"
          placeholder="Enter Percentage"
          value={projectDetails?.goalMetric?.goalMetricValue}
          inputProps={{ style: { fontSize: '15px', height: '16px' } }}
          onChange={(e) => {
            // Extract numeric part (removes decimals)
            const number = e.target.value.replace(/\D/g, '');
            // Parse as integer, clamp between 0 and 100
            const percentage = Math.max(0, Math.min(100, parseInt(number, 10)));
            // Update the input value (controlled component approach)
            handleFormFieldChange1('goalMetric.goalMetricValue', percentage);
          }}
        />
      </Grid>
    )}

    {projectDetails?.goalMetric.goalMetricName === 'Whole' && (
         <Grid item xs={12} md={6}>

         <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Whole value</h5>
             
    <TextField
      type="number"
      id="whole"
      // error={!!formErrors.metricValue}
      // helperText={formErrors.metricValue}
      placeholder="Enter Whole Number"
      value={projectDetails?.goalMetric?.goalMetricValue}
      inputProps={{ style: { fontSize: '15px', height: '16px' } }} // Adjust height
    //  style={{ fontSize: '18px',width:'100%' }}
     onChange={(e) => {
      // Extract the numeric part (removes decimals)
      const wholeNumber = e.target.value.replace(/\D/g, '');
      // Update the input value (controlled component approach)
      // setMetricValue(wholeNumber);
      handleFormFieldChange1('goalMetric.goalMetricValue', wholeNumber);
    }}
 
    />
 
     </Grid>
    )}

    {projectDetails?.goalMetric.goalMetricName === 'Decimal' && (
          <Grid item xs={12} md={6}>
          <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Decimal Value</h5>
              
     <TextField
       type="number"
      //  error={!!formErrors.metricValue}
      //  helperText={formErrors.metricValue}
       id="decimal"
     value={projectDetails?.goalMetric?.goalMetricValue }
       inputProps={{ style: { fontSize: '15px', height: '16px' } }}
       placeholder="Enter Decimal Value"
      
      //  value={Percentage} 
      // style={{ fontSize: '18px',width:'100%' }}

      //  onChange={(e) => setWidgetName(e.target.val
       onChange={(e) =>  handleFormFieldChange1('goalMetric.goalMetricValue', e.target.value)}
     />
   
      </Grid>
    )}

                 </Grid>


                 <Grid item xs={12} md={6} style={{marginTop : '3%'}}>
      <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Threshold Metric</h5>
                 <Box sx={{ width: 300, ml: 5, mt: 2 }}>
                 <Slider
      aria-label="Discrete values"
      sx={{
        '& .MuiSlider-valueLabel': { fontSize: '24px' },
        '& .MuiSlider-markLabel': { fontSize: '16px' }
      }}
      defaultValue={projectDetails?.thresholdMetric?.thresholdMetricValue}
      step={0.1}
      valueLabelDisplay="auto"
      onChange={(e, value) => handleFormFieldChange1('thresholdMetric.thresholdMetricValue', value)}
      min={0}
      max={0.5}
      marks={[
        { value: 0, label: '0' },
        { value: 0.1, label: '0.1' },
        { value: 0.2, label: '0.2' },
        { value: 0.3, label: '0.3' },
        { value: 0.4, label: '0.4' },
        { value: 0.5, label: '0.5' },
      ]}
    />
</Box>
    </Grid>


              <Grid container direction="row" spacing={2} sx={{marginTop: '1%'}}>
                {/* <Grid item xs={12} md={6}>        
                <p style={{color: '#000', fontSize: '16px', fontWeight: '400'}}>Goal Name</p>              
                      <TextField
                      
                        id="outlined-helperText"                            
                        defaultValue="Lorem ispum"
                        sx={{width: '100%'}}
                      />                      
                </Grid> */}
               <Grid item xs={12} md={6}>
      <p style={{ color: '#000', fontSize: '16px', fontWeight: '400' }}>Status</p>
      <Select
  options={options}
  value={{ value: projectDetails.status, label: projectDetails.status }}
  onChange={(selectedOption) => handleFormFieldChange('status', selectedOption.value)}
  styles={{
    control: (provided) => ({
      ...provided,
      fontSize: '15px', // Set font size for the input field
      // borderWidth: '2px', // Increase border size
      padding: '4px 0px 6px 0px' // Add more padding
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '15px' // Set font size for the selected value
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '15px' // Set font size for the options in the dropdown
    })
  }}
  theme={(theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral10: 'grey',
      primary: '#50a4db',
    },
  })}
/>

    </Grid>
              </Grid>
               
            
            
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
          <Button variant='contained' onClick={handleClose} autoFocus sx={{
            width: '20%',
            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '600',
            textTransform: 'none',     
            marginRight: '5%'
            
          }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
