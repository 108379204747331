import React, { useState } from 'react';
import { Typography, Grid, List, ListItem, Button } from '@mui/material';
import BasicCard3 from '../../components/PricingCard/PricingCard';
import Footer from '../../components/Footer/Footer';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import './Pricing.css';
import { Table } from 'reactstrap';
import Qualitative from '../../components/PricingCard/Qualitative';

function Pricing() {

  const [activeButton, setActiveButton] = useState(2);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const cellStyle = {
    border: '1px solid #FFF',
    textAlign: 'left',
    // padding: '8px',
  };

  const firstCellStyle = {
    ...cellStyle,
    borderLeft: 'none', // Remove left border
    
    
  };
  const secondCellStyle = {
    borderLeft: 'none'
  }

  const lastCellStyle ={
    borderLeft: 'none',
   
  }

  const colLastCellStyle = {
    borderRight: 'none'
  }
  
 //qualitativeData Pricing
const qualitativeData = [
  {
  planName : 'Simple',
  amount : '$ 12',
  priceId : "price_1OlRPTSGW1EyGI68j0Q8wSOS"
},
  {
  planName : 'Moderate' ,
   amount : '$ 20',
  priceId  : "price_1OlRTxSGW1EyGI6845fez4k6"
  },
  {
  planName : 'Advanced' ,
  amount : '$ 36',
  priceId : "price_1OlRVLSGW1EyGI68yuqpDxWm"
}
]
 //quantitativeData Pricing
const quantitativeData = [
  {planName : 'Simple' , amount : '$ 5'},
  {planName : 'Moderate' , amount : '$ 7'},
  {planName : 'Advanced' , amount : '$ 9'}
]
  

  
  return (
    <div>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} style={{marginTop:'0.1px'}}>
        <Grid item xs={12} mt={4}  sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                marginTop : '8%'
            }}>
            <p
            >
            Plans and Prices
            </p>
            <Grid item xs={12} md={6} mt={4} sx={{
                color: '#AFAFAF',
                textAlign: 'center',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
               
                margin: '0 auto',
             
            }}>
            <p>
            Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor
            pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis
            ac sollicitudin nibh. In quam vitae nisl diam purus.
            </p>
            </Grid>
        </Grid>
        </Grid>
       
        <Grid
  container
  justifyContent="center"
  alignItems="center"
  sx={{
    position: 'absolute',
    // top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #FFF',
    width: {lg: '40%',md: '60%', sm: '70%', xs: '90%'},
    boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
    marginTop: '4%',
  }}
>
<Grid item container xs={12} md={12} justifyContent="center" sx={{ paddingTop: '1%', paddingBottom: '1%' }}>
<Button
    className='button-size'
    onClick={() => handleButtonClick(2)}
    sx={{
      border: activeButton === 2 ? '1px solid #50A4DB' : 'none',
      backgroundColor: activeButton === 2 ? '#50A4DB' : 'transparent',
      color: activeButton === 2 ? '#FFF' : '#000',
      textTransform: 'none',
      borderRadius: '15px',
      fontSize: '20px',
      fontWeight: activeButton === 2 ? '600' : '400',
      padding: '5px 20px',
      '&:hover': {
        backgroundColor: activeButton === 2 ? '#50A4DB' : 'rgba(80, 164, 219, 0.2)',
        color: activeButton === 2 ? '#FFF' : '#000',
        border: activeButton === 2 ? '1px solid #50A4DB' : 'none',
      },
    }}
  >
    Qualitative Service
  </Button>
  <Button
    className='button-size'
    onClick={() => handleButtonClick(1)}
    sx={{
      border: activeButton === 1 ? '1px solid #50A4DB' : 'none',
      backgroundColor: activeButton === 1 ? '#50A4DB' : 'transparent',
      color: activeButton === 1 ? '#FFF' : '#000',
      textTransform: 'none',
      borderRadius: '15px',
      fontSize: '20px',
      fontWeight: activeButton === 1 ? '600' : '400',
      padding: '5px 20px',
      '&:hover': {
        backgroundColor: activeButton === 1 ? '#50A4DB' : 'rgba(80, 164, 219, 0.2)',
        color: activeButton === 1 ? '#FFF' : '#000',
        border: activeButton === 1 ? '1px solid #50A4DB' : 'none',
      },
    }}
  >
    Quantitative Service
  </Button>
 
</Grid>

</Grid>



        {activeButton === 1 && (
          <>
        <Grid container item justifyContent="center" direction="row" spacing={2} sx={{marginTop: '8%'}}>
           
            {quantitativeData.map((item) => (
            <Grid key={item} item xs={12} sm={6} md={3} lg={3}>
                <BasicCard3  planName={item.planName} amount={item.amount}/>
            </Grid>
            ))}



        </Grid>
        <Grid item container xs={12} md={12} lg={12} mt={5}>
            <Typography
            variant="h5"
            sx={{
                color: '#000',
                fontSize: '32px',
                fontWeight: '500',
                paddingLeft: '12%'
            }}
            >
            Compare the Services
            </Typography>
            

            <Grid container item xs={12}  alignItems="left" mt={2} >
            <Typography
              variant="body1"
              style={{
                color: '#797979',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: 'normal',
                paddingLeft: '12%',
                paddingRight: '8%'
              }}
            >
          Lorem ipsum dolor sit amet consectetur. Adipiscing diam urna eros dis fermentum egestas. Facilisi congue sed sed quis est metus. Non at justo nibh dictum nunc dignissim. Feugiat viverra id orci sagittis risus pulvinar id. Volutpat in nisl velit laoreet quis nunc. Sed leo bibendum urna non in amet auctor arcu. Maecenas vestibulum vitae sed sit integer elit magnis consequat facilisis amet ut sed volutpat. Enim scelerisque tincidunt duis laoreet ultrices.
          </Typography>
            </Grid>
            
  
            
          </Grid>
          <Table bordered responsive style={{    
              marginTop: '4%',
              // border: '1px solid #000',
              // boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
              justifyContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '70%',
              borderCollapse: 'collapse',            
            }}
          >
        
            <tbody>
              <tr className='tableSize' style={{color: '#000', fontSize: '20px', fontWeight: '400', textDecoration: 'none', height: '50px'}}>
              
                <td style={{borderLeft: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  Features
                </td>
                <td style={{ border: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  QuantitativeService
                </td>
                <td style={{ border: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  QualitativeService
                </td>
              </tr>           
              <tr style={{height: '60px'}}>
                
                <td  id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{borderBottom: 'none', height: '60px'}}>
                
                <td id='tabContent' style={{...lastCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
            </tbody>
          </Table>
         
        </>
         )}

      {activeButton === 2 && (
        <>
        <Grid container item justifyContent="center" direction="row" spacing={2} sx={{marginTop: '8%'}}>
            {qualitativeData.map((item) => (
            <Grid key={item} item xs={12} sm={6} md={3} lg={3}>
                <Qualitative planName = {item.planName} amount={item.amount} priceId = {item.priceId}/>
            </Grid>
            ))}

        </Grid>
        <Grid item container xs={12} md={12} lg={12} mt={5}>
            <Typography
            variant="h5"
            sx={{
                color: '#000',
                fontSize: '32px',
                fontWeight: '500',
                paddingLeft: '12%'
            }}
            >
            Compare the Services
            </Typography>
            

            <Grid container item xs={12}  alignItems="left" mt={2} >
            <Typography
              variant="body1"
              style={{
                color: '#797979',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: 'normal',
                paddingLeft: '12%',
                paddingRight: '8%'
              }}
            >
          Lorem ipsum dolor sit amet consectetur. Adipiscing diam urna eros dis fermentum egestas. Facilisi congue sed sed quis est metus. Non at justo nibh dictum nunc dignissim. Feugiat viverra id orci sagittis risus pulvinar id. Volutpat in nisl velit laoreet quis nunc. Sed leo bibendum urna non in amet auctor arcu. Maecenas vestibulum vitae sed sit integer elit magnis consequat facilisis amet ut sed volutpat. Enim scelerisque tincidunt duis laoreet ultrices.
          </Typography>
            </Grid>
            
  
            
          </Grid>
          <Table bordered responsive style={{    
              marginTop: '4%',
              // border: '1px solid #000',
              // boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
              justifyContent: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '70%',
              borderCollapse: 'collapse',            
            }}
          >
        
            <tbody>
              <tr className='tableSize' style={{color: '#000', fontSize: '20px', fontWeight: '400', textDecoration: 'none', height: '50px'}}>
              
                <td style={{borderLeft: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  Features
                </td>
                <td style={{ border: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  QuantitativeService
                </td>
                <td style={{ border: 'none', ...firstCellStyle, backgroundColor: '#50A4DB ', color: '#FFF', borderRadius: '8px'}}>
                  QualitativeService
                </td>
              </tr>           
              <tr style={{height: '60px'}}>
                
                <td  id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{height: '60px'}}>
                
                <td id='tabContent' style={{...secondCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
              <tr style={{borderBottom: 'none', height: '60px'}}>
                
                <td id='tabContent' style={{...lastCellStyle, fontSize: '24px'}}>
                Lorem ipsum dolor sit amet consectetur
                </td>
                <td style={{textAlign: 'center'}}>
                {<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
                <td style={{textAlign: 'center', ...colLastCellStyle}}>
                {<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}
                </td>
              </tr>
            </tbody>
          </Table>
        </>

        
      )}

        
        <Grid item xs={12} md={4} lg={4} mt={5}>
            <Typography
            variant="h5"
            sx={{
                color: '#000',
                fontSize: '32px',
                fontWeight: '700',
                paddingLeft: '12%'
            }}
            >
            Terms and Condition
            </Typography>
            <Grid container item direction="column" alignItems="left" spacing={1} mt={2} sx={{ paddingLeft: '15%' }}>
  <List style={{ listStyleType: 'disc' }}>
    {[1, 2, 3].map((item, index) => (
      <ListItem  key={item}>
        <Typography
          variant="body1"
          style={{
            color: '#AFAFAF',
            fontSize: '20px',
            fontWeight: '400',
            lineHeight: 'normal',
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare
          tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis.
        </Typography>
      </ListItem>
    ))}
  </List>
</Grid>
        </Grid>

        
        
        <div style={{marginTop: '2%'}}>
            <Footer/>
        </div>
    </div>
  );
}

export default Pricing;
