import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import "../index.css"
import { Col, Row } from 'reactstrap';

import CustomDropdown from './Templates';
import { Link, useNavigate } from 'react-router-dom';

function LeftSidebar({ onTemplateSelect, onDashboardSelect, onSimulationSelect, onCanvasSelect }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const sidebarContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center', 
  };
  const iconContainerStyle = (item) => ({
    padding: '10px', 
    borderRadius: '5px', 
    backgroundColor: selectedItem === item ? '#50A4DB ' : 'transparent', 
    color: selectedItem === item ? 'white' : 'black', 
    cursor: 'pointer', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }); 
  const textStyle = (item) => ({
    marginTop: '10px',
    fontSize : '14px',
    
    color: selectedItem === item ? '#50A4DB ' : 'inherit', 
    textAlign: 'center'
  });

  const dropdownRef = useRef(); 

  useEffect(() => {
   
  function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); 
      }
    }
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]); 
  
const navigate = useNavigate()

  const handleItemClick = (item, event) => {
    setSelectedItem(item);
    if (item === 'canvas') {
      onCanvasSelect();
      const rect = event.currentTarget.getBoundingClientRect();
      setDropdownPosition({ 
        top: rect.top + window.scrollY, 
        left: rect.right + window.scrollX 
      });
      setIsDropdownOpen(!isDropdownOpen);
    } else if (item === 'dashboard') {
      onDashboardSelect();
    } else if (item === 'simulation') {
      onSimulationSelect(); 
    }
//     else if(item === 'logout'){
// navigate("/#/home-page")

//     }
  };
  
  
  return (
    <div style={sidebarContainerStyle}>
      <Row>
      
        <Col md="12" sm="12" xs="12" xl="12" lg="12"  onClick={(e) => handleItemClick('canvas', e)} className='mt-3'>
          <div style={iconContainerStyle('canvas')}>
            <Icon icon="mdi:canvas" width="22" />
          </div>
          <div style={textStyle('canvas')}>Canvas</div>
        </Col>
     
        <Col md="12" sm="12" xs="12" xl="12" lg="12" onClick={() => handleItemClick('dashboard')} className='mt-3'>
          <div style={iconContainerStyle('dashboard')}>
            <Icon icon="carbon:report-data" width="22" />
          </div>
          <div style={textStyle('dashboard')}>Dashboard</div>
        </Col>
        <Col md="12" sm="12" xs="12" xl="12" lg="12" onClick={() => handleItemClick('simulation')}className='mt-3'>
          <div style={iconContainerStyle('simulation')}>
            <Icon icon="icon-park-outline:connection-point-two" width="22" />
          </div>
          <div style={textStyle('simulation')}>Simulation</div>
        </Col>
        <Col md="12" sm="12" xs="12" xl="12" lg="12" onClick={() => handleItemClick('logout')}className='mt-3'>
          <Link to="/home-page">
          <div style={iconContainerStyle('logout')}>
            <Icon icon="solar:logout-outline" width="30" />
          </div>
          <div  style={{marginTop: '10px',
    fontSize : '14px',
    textAlign : 'center',
    color : 'black'
    }}>Go back</div>
          </Link>
        </Col>
      </Row>
      {isDropdownOpen && (
        <div ref={dropdownRef}> 
          <CustomDropdown
            onClose={() => setIsDropdownOpen(false)}
            onTemplateSelect={onTemplateSelect}
            position={dropdownPosition}
          />
        </div>
      )}

    </div>
  );
}

export default LeftSidebar;
