import { Button, Grid,Pagination as MuiPagination } from "@mui/material";
import { useEffect, useState } from "react";
import './Client.css'
import axios from "axios";

function Client() {

    const fetchClientData =async ()=>{
        try{
const accessToken = JSON.parse(localStorage.getItem('access-token'));
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/admin/client`,{
            headers : {
                Authorization : `Bearer ${accessToken}`
            }
        }
            )
            console.log(response.data.clientData)
            setPaymentData(response?.data?.clientData)
          
        }catch(error){
            console.error(error)
        }
    }
const [paymentData,setPaymentData] = useState([])
   
useEffect(()=>{
        fetchClientData()
    },[])

    const itemsPerPage = 10; // Set the number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);

    // const paymentData = [
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     { project: 'Lorem ispum', status: 'Ongoing', client: 'Lorem ispum', industry: 'Lorem', date: '13/07/23', button: 'View Details' },
    //     // ... other data
    //   ];

       // Calculate the total number of pages
  const pageCount = Math.ceil(paymentData.length / itemsPerPage);

  // Get the current page data
  const currentData = paymentData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
    return(
        <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',marginTop: {
            xs: '20%',
            sm: '12%',
            md: '10%',
            lg: '7%'
        },}}>
            {/* Header */}
            <Grid container direction="row"  alignItems="center" sx={{fontSize: {md: '22px', xs: '10px'}, fontWeight: '600', paddingTop: '1%', backgroundColor: '#e0e0e0'}}>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Project</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Project Status</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Client</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Industry</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Start Date</p>
                        </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', position: 'relative', bottom: '10px'}} />
            </Grid>

            {/* Data rows */}

            {currentData.map((item, index) => (
                <div key={index} >
                    <Grid container direction="row" className='client-row'    alignItems="center" sx={{fontSize: {md: '20px', xs: '8px'}, fontWeight: '400', paddingTop: '1%', color: '#000'}}>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item?.projectName}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p >{item?.status}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item?.createdBy[0]?.userName}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item?.industry}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item?.startDate}</p>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <Button className='detail_button' style={{
                                color: '#AFAFAF',
                                fontSize: '20px',
                                fontWeight: '400',                 
                                textTransform: 'none',
                            }}
                           >
                                View Details
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)'}} />
                    </Grid>
                </div>
            ))}

            {/* Pagination component */}
            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
                <MuiPagination
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    //   shape="rounded"
                    variant="outlined"
                />
            
            </Grid>
        </Grid>
    )
}

export default Client;