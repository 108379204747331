import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export default function BasicCard3({planName , amount,priceId}) {
const navigate = useNavigate()

  const handleSubscribe = (plan,amount)=>{
   
    // navigate(`/signup`);
    navigate(`/signup/${planName}/${priceId}`)
   
    toast.success("Firstlly Signup and then takes subscription")
}

  return (
    <Grid xs={12} md={12} item
    sx={{
      width: '100%',
      height: '500px',
      borderRadius: '8px',
      backgroundColor: '#FFF',
      boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
      marginTop: '5%',
      border: '1px solid #FFF'
    }}
  >

      <Grid xs={12} md={12} item container
        sx={{
          width: '100%',
          height: '20px',
          borderRadius: '8px',
          backgroundColor: '#38B27F',
          position: 'relative',
          // right: '14px',
          bottom: '16px',
          
        }}
      ></Grid>
      <Grid xs={12} md={12} item container justifyContent="center" alignItems="center">
      <Typography
        sx={{
          textAlign: 'center',
          color: '#000',
          fontSize: '24px',
          fontWeight: '400',
        }}
      >
     {planName}
      </Typography>
      </Grid>
      <Grid xs={12} md={12} item container justifyContent="center" alignItems="center">
      <Typography
        sx={{
          textAlign: 'center',
          color: '#000',
          fontSize: '32px',
          fontWeight: '600',
          marginTop: '10%',
        }}
      >
  {amount}
      </Typography>
      </Grid>

      <Grid container justifyContent="center" mt={4}>
        <Grid item>
          <Link
            sx={{
              textTransform: 'none',
              // width: '245px',
              // height: '61px',
              borderRadius: '8px',
              backgroundColor: '#50A4DB ',
              color: '#FFF',
              fontSize: '20px',
              fontWeight: '600',
              textAlign: 'center',
              marginTop: '10%',
              padding: '15px 70px 15px 70px',
              textDecoration: 'none'
            }}
            onClick={()=>handleSubscribe(planName,amount)}
          >
            Subscribes
          </Link>
        </Grid>
      </Grid>

      <Grid container direction="column" alignItems="center" mt={2} spacing={2}>
        {[1, 2, 3, 4, 5].map((item, index) => (
          <Grid
            key={item}
            item
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '40px',
              alignItems: 'center',
              marginTop: index > 0 ? '-15px' : '20px',
            }}
          >
            <CheckIcon style={{ marginTop: '10px' , fontSize: '30px', color: '#797979', fontWeight: '800'}} />
            <Typography
              style={{
                color: '#AFAFAF',
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              Lorem ipsum dolor
            </Typography>
          </Grid>
        ))}
      </Grid>
  
  </Grid>
  );
}
