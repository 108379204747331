import { Grid, Link } from '@mui/material';
import imgSrc from '../../Images/payment.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PaymentConfirm(){
    return(
        <div>
            <Grid item xs={12} md={12} sx={{
                marginTop: {
                    xs: '20%',
                    sm: '10%',
                    md: '7%',
                  }
            }}>
                <a href='/#/upgrade-subscription' style={{ textDecoration:'none', color: '#000'}}>
                    <ArrowBackIcon sx={{fontSize:'30px'}}/>
                </a>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <img src={imgSrc} alt="" style={{width: '30%', height: '20%'}}/>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <h4 style={{color: '#000', fontSize: '34px', fontWeight: '600'}}>Congratulations</h4>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <p style={{color: '#000', fontSize: '20px', fontWeight: '400'}}>The payment was successful</p>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <Link href="/#/upgrade-subscription" sx={{border: '1px solid rgba(80, 164, 219, 1)', backgroundColor: 'rgba(80, 164, 219, 1)', color: '#FFF', fontSize: '22px', fontWeight: '600', textDecoration: 'none', padding: '10px 60px 10px 60px'}} >Continue</Link>
            </Grid>
        </div>
    )
}

export default PaymentConfirm;   