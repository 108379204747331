import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardActionArea, Grid,Pagination as MuiPagination } from '@mui/material';
import imgSrc from '../../Images/avatar.jpg'
import { AvatarGroup } from '@mui/material';

import './ProjectCard.css'
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';




export default function ActionAreaCard4({}) {

    const [projects, setProjects] = useState([]);
    const accessToken = JSON.parse(localStorage.getItem('access-token'))

        useEffect(() => {
            // Fetch projects when the component mounts
            axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects`,{
                headers : {
                    Authorization : `Bearer ${accessToken}`
                }
            }
            )
              .then(response => {
                setProjects(response.data);
                
              })
              .catch(error => {
                console.error('Error fetching projects:', error);
              });
          }, []); // Empty dependency array ensures the effect runs once on mount

    

      const itemsPerPage = 10; // Set the number of items to display per page
        const [currentPage, setCurrentPage] = React.useState(1);

        // Calculate the total number of pages
  const pageCount = Math.ceil(projects.length / itemsPerPage);

  // Get the current page data
  const currentData = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  
  const AvatarAsync = ({ memberId }) => {
    const [memberDetails, setMemberDetails] = useState(null);

    useEffect(() => {
        const fetchMemberDetails = async () => {
            try {
                // Fetch member details using memberId
                const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/teamMembers/${memberId}`);
                setMemberDetails(response.data);
            } catch (error) {
                console.error('Error fetching member details:', error);
            }
        };

        fetchMemberDetails();

        // Cleanup function
        return () => {
            // Cancel any pending requests or cleanup if needed
        };
    }, [memberId]);

    if (memberDetails) {
        return <Avatar alt={memberDetails.name} src={memberDetails.image} />;
    } else {
        return null;
    }
};
  return (
    
            <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}>
           

                {/* Header */}

                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ fontSize: {md: '24px', xs: '10px'}, fontWeight: '600', color: '#FFF', backgroundColor: '#50A4DB ', paddingTop: '1%', borderRadius: '8px' }}>
                    <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                        <p>Project</p>              
                    </Grid>

                    <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                        <p>Project Status</p>         
                    </Grid>

                    <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                       <p>Team</p>              
                    </Grid>

                    <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                        <p>Start Date</p>          
                    </Grid>  

                    <Grid item xs={2} md={2}>

                    </Grid>
                   
                </Grid>
                <Grid item xs={12} md={12}>
                    <hr style={{border: '1px solid rgba(175, 175, 175, 1)', width: '100%', height: '1px', position: 'relative', bottom: '10px'}}></hr>
                </Grid>

                {/* Data rows */}
                
                {
             currentData.length > 0 ?    currentData.map((item, index) => (
                <div key={index} >

                    <Grid container item justifyContent="center" alignItems="center"  direction="row" sx={{ fontSize: {md: '20px', xs: '10px'}, fontWeight: '400', color: '#000', cursor: 'pointer', transition: 'background-color 0.3s', backgroundColor: index % 2 === 0 ? 'rgba(80, 164, 219, 0.20)' : 'rgba(80, 164, 219, 0.05)', borderRadius: '8px' }}>
                            <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                                <p>{item?.projectName}</p>       
                            </Grid>

                            <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                                {item?.status}                
                            </Grid>

                            <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <div style={{ position: 'relative' }}>
                                    <AvatarGroup max={3} 
                                     sx={{
                                        '& .MuiAvatar-root': { width: 33, height: 33, fontSize: 15 },
                                      }}
                                    >
              {/* {item.teamMember.map((memberId, index) => (
                <div style={{ position: 'relative', marginLeft: '-12px' }}>
                <Avatar key={index} alt="Member" src={imgSrc} style={{ position: 'relative', zIndex: 2, width: 32, height: 32 }} />
                </div>
              ))} */}

{item.teamMember.map((memberId, index) => (
                    <AvatarAsync key={index} memberId={memberId} />
                ))}
            </AvatarGroup>
                                        {/* <Avatar alt="Remy Sharp" src={imgSrc} style={{ position: 'relative', zIndex: 1, width: 24, height: 24 }} /> */}
                                    </div>

                                    {/* <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Travis Howard" src={imgSrc} style={{ position: 'relative', zIndex: 2, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 3, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 4, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 5, width: 24, height: 24 }} />
                                    </div> */}
                                </div>

                            </Grid>

                            <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                                <p>{item.startDate}</p>          
                            </Grid>                                            
                                
                            <Grid item xs={3} md={2} container justifyContent="center" alignItems="center" >                                    
                                <Button className='detail_button'
                                    component={Link}
                                    to={`/project-det/${item._id}`} 
                                    style={{
                                    color: '#000',
                                    fontSize: '20px',
                                    fontWeight: '400',                    
                                    
                                    textTransform: 'none',
                                }}
                                >
                                    View Details
                                </Button>              
                            </Grid>                                                     

                    </Grid>
                    <Grid item xs={12} md={12}>
                            <Divider/>
                    </Grid>                  
                                          
                </div>


                ))
                : 
                <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                <h3 style={{ marginRight: '3%' }}>No Projects present now!</h3>
            </Grid>
            
            }

                    {/* Pagination component */}
                <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
                <MuiPagination
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    //   shape="rounded"
                    variant="outlined"
                    />
                
                </Grid>
                    
            </div>        
                            
    
  );
}



function Divider() {
    return(
        <hr style={{border: '1px solid rgba(175, 175, 175, 1)', width: '100%', height: '1px'}}></hr>
    );
}