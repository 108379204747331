import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Link, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addMemberInProject } from '../../Reducers/memberReducer';
import axios from 'axios';
import toast from 'react-hot-toast';

function AddMember2() {

    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();

    // const urlParams = new URLSearchParams(location.search);

// console.log(companyName)
    // const projectName = location.state?.projectName;
    
const [memberData, setMemberData] = useState({
    memberName:'',
    memberId: '',
    email: '',
    password: '',
    projectName: '',
    designation: '',
    country : '',
    address : '',
    company :''
  });
  
  const [projectOptions, setProjectOptions] = useState([]);

  const handleInputChange = (field) => (event) => {
    console.log(event.target.value);
    setMemberData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  
  };

//   useEffect(()=>{
//     if(urlParams?.get('name')){
//         setMemberData({...memberData,'projectName':urlParams?.get('name')})
//     }
//   },[urlParams?.get('name')])
useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = JSON.parse(localStorage.getItem('access-token'));
  
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/user/project`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  console.log(response.data)
        setProjectOptions(response.data);
      } catch (error) {
        console.error('Error fetching project options:', error);
      }
    };  
  
    fetchData();
  }, []);
  
const [projectId, setProjectId] = useState([])
  const handleClick = (projectKiId)=>{
   
    setProjectId([projectKiId])

  }

  const handleAddMember = async () => {
    try {

      const accessToken = JSON.parse(localStorage.getItem('access-token'));
  
      const payload = {
        formData: memberData,
        projectId: projectId[0] // Assuming projectId is a single value
      };
  // console.log(payload,"payload")
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/addMemberInTeam`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      
      navigate(`/team`);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    }
  };


    return(
        <div >
                    <Grid container direction="row" spacing={2} alignItems="center" sx={{
                        marginTop: {
                            xs: '20%',
                            sm: '15%',
                            md: '5%',
                        },
                        marginBottom: '2%'
                    }}>
                        <Grid item xs={2} md={1} >
                            <a href={`/#/team`} style={{ textDecoration:'none', color: '#000'}}>
                                <ArrowBackIcon sx={{fontSize: '30px'}}/>
                            </a>
                        </Grid>

                        <Grid item xs={8} md={4} container alignItems="center">
                            <h1 style={{color: '#000', fontSize: '32px', fontWeight: '400'}}>Add Member</h1>
                        </Grid>

                    </Grid>
                    

                    <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}>    
                        <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Member Name</h3>
                                <TextField
                                    id="outlined-helperText"                
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        style: {
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }
                                    }}
                                    value={memberData?.memberName}
                                       onChange={handleInputChange('memberName')}

                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Member User Id</h3>
                                <TextField
                                    id="outlined-helperText"          
                                    value={memberData.memberId}
                                    onChange={handleInputChange('memberId')}
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        style: {
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }
                                    }}
                                   
                                />
                            </Grid>
                        </Grid>

                        <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >E-mail</h3>
                                <TextField
                                    id="outlined-helperText"  
                                    value={memberData.email}
                                    onChange={handleInputChange('email')}              
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        style: {
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }
                                    }}  
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Password</h3>
                                <TextField
                                    id="outlined-helperText"          
                                    value={memberData.password}
                                    onChange={handleInputChange('password')}
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        style: {
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                           
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Project Name</h3>
                                {/* <Select
                                    id="outlined-helperText" 
                                    styles={customStyles}
                                    // value="N/A"
                                    // disabled 
                                    onChange={(selectedOption) => {
                                        setSearch({ ...search, availability: selectedOption?.value })
                                      }}
                                      value={search.availability ? { value: search.availability, label: search.availability } : null}
                
                                    // onChange={handleInputChange('projectName')}              
                                    sx={{width: '100%'}}
                                   

                                /> */}
                            <TextField
                                    id="outlined-helperText"          
                                    select
  value={memberData.projectName}
  onChange={handleInputChange('projectName')}
  sx={{ width: '80%' }}
  inputProps={{
  
    style: {
      fontSize: '18px',
      fontWeight: '500'
    }
  }}
>
  {/* <MenuItem value="" disabled>
    Select Project
  </MenuItem> */}
  {projectOptions && projectOptions?.map((option) => (

    <MenuItem style={{ fontSize: '16px'}} key={option?.id} value={option?.projectName} onClick={()=>handleClick({projectKiId : option._id})}>
       {option?.projectName || 'Unknown'}
    </MenuItem>
  ))


}
</TextField>
                            </Grid>
                           
                            <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                                <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Designation</h3>
                                <TextField
                                    id="outlined-helperText"          
                                    select
                                    value={memberData.designation}
                                    onChange={handleInputChange('designation')}     
                                    sx={{width: '80%'}}
                                    InputProps={{
                                        style: {
                                            fontSize: '18px',
                                            fontWeight: '500'
                                        }
                                    }}
                                >
                                <MenuItem style={{ fontSize: '16px'}} value="teamMember">Team Member</MenuItem>
                               <MenuItem style={{ fontSize: '16px'}} value="teamManager">Team Manager</MenuItem>

                               </TextField>
                            </Grid>


                        </Grid>



{/* ///start */}
<Grid item container direction="row"  sx={{marginTop: '2%'}} >
                           
                           <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                               <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Country</h3>
                               <TextField
                                   id="outlined-helperText"  
                                   value={memberData?.country}
                                  
                                   onChange={handleInputChange('country')}              
                                   sx={{width: '80%'}}
                                   InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                               />
                           </Grid>
                          
                           <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                               <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Address</h3>
                               <TextField
                                   id="outlined-helperText"  
                                   value={memberData?.address}
                                
                                   onChange={handleInputChange('address')}              
                                   sx={{width: '80%'}}
                                   InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                               />
                           </Grid>


                       </Grid>



{/* ///end */}
                        <Grid item xs={12} md={12} container justifyContent={{ xs: 'center', md: 'end' }} alignItems="end" sx={{marginTop: '4%', marginBottom: '2%', paddingRight: '9%'}} >
                            <Button variant='contained' sx={{
                                width: '200px',
                                height: '50px',
                                backgroundColor: '#50A4DB ',
                                borderRadius: '8px',
                                color: '#FFF',
                                fontSize: '24px',
                                fontWeight: '200',
                                textTransform: 'none',  
                                // marginTop: '40px',
                                // paddingLeft: '30px',
                                textDecoration: 'none',
                                // paddingTop: '8px'
                            }}
                            onClick={handleAddMember}
                            >
                                Add Member
                            </Button>
                        </Grid>
                    </div>
        </div>
    )
}        

export default AddMember2;