import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import imgSrc from "../../Images/profilePic.jpg";
import { useDispatch, useSelector } from "react-redux";
import { editTeamDetails, editUserDetails } from "../../Reducers/userReducers";
import { useNavigate } from "react-router-dom";

function HomeProEdit() {
  // const [selectedFile, setSelectedFile] = useState(null);

  const { userData } = useSelector((state) => state.user);

  const dispatch = useDispatch()
 const navigate = useNavigate() 
 // Retrieve user data from localStorage
 const userDataFromLocalStorage = JSON.parse(localStorage.getItem('users'));
// console.log(userDataFromLocalStorage)
  // Initialize formData with userDataFromLocalStorage or empty values
  const [formData, setFormData] = useState({
    userName: userDataFromLocalStorage?.userName || '',
    email: userDataFromLocalStorage?.email || '',
    companyName: userDataFromLocalStorage?.companyName || '',
    address: userDataFromLocalStorage?.address || '',
    country: userDataFromLocalStorage?.country || '',
    description: userDataFromLocalStorage?.description || '',
    image: userDataFromLocalStorage?.image || '',
  });

  // Function to handle file selection
  const [file,setFile] = useState(null)
  const [frontShow,setFrontShow] = useState()

  const handleFileSelect = async (e) => {
    const file = e.target.files[0];
  
    // // Check file size before proceeding
    // if (file.size > 1024 * 1024 * 5) { // 5 MB limit (adjust as needed)
    //   alert('File size limit exceeds 5MB. Please choose a smaller file.');
    //   return;
    // }

    // const cloudinary = window.cloudinary; // Assuming Cloudinary widget is loaded globally
  
    // if (cloudinary) {
      const uploadOptions = {
        cloud_name: "djhpptodq",
  api_key: "177433826987685",
  api_secret: "Ptvt0KXA1_7RErTdhmXbmkxSFNs",
      };
  

        // Upload the entire file for smaller files
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', "user-profile");
        formData.append('api_key', uploadOptions.api_key);
        formData.append("cloud_name",uploadOptions.cloud_name)
  
        const response = await fetch('https://api.cloudinary.com/v1_1/' + uploadOptions.cloud_name + '/image/upload', {
          method: 'POST',
          body: formData,
        });
  
        const data = await response.json();
      
        setFile(data.secure_url); // Update state with uploaded image URL
        setFrontShow(data.secure_url); // Assuming this is for displaying the image (optional)
    
    
  };

  const handleTextFieldChange = (fieldName, value) => {
     
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  
  };

  const users = JSON.parse(localStorage.getItem('users'))
  //calls edit api
 
const handleSubmit = async (userId)=>{
 

try{
// Dispatch the action 
if(users?.designation === 'teamManager' || users?.designation === 'teamMember'){
 
dispatch(editTeamDetails({formValue : formData,userId : userData?._id,data : file}))///memberId and formData
navigate("/home-profile")
}else{


dispatch(editUserDetails({ formValue : formData, userId,data:file}));
navigate("/home-profile")
window.location.reload()
}
}catch(error){
    console.error('Error updating profile:', error);
}
}

// const postDetails = ()=>{
//   const data =  new FormData()
//   data.append("file",selectedFile)
//   data.append("upload_preset","UserProfile")
//   data.append("cloud_name","djhpptodq")
// }

  // const labelStyle = {
  //   color: "#FFF", // Text color
  //   border: "1px solid #FFF", // Border
  //   padding: "8px 12px", // Adjust padding as needed
  //   cursor: "pointer", // Change cursor on hover
  //   position: "relative",

  //   top: "100px",
  // };
  const labelStyle = {
    position: "absolute",
    bottom: "20px", // Adjust the vertical position as needed
    left: "50%",
    transform: "translateX(-50%)",
    color: "black",
    display: "flex",
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
    border: "1px solid black",
    padding : "7px",
    borderRadius: "4px",
    cursor: "pointer",
    zIndex: 100, // Ensure the label is on top of the background image
  };
  
  

  return (
    <div>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          marginTop: {
            xs: "18%",
            sm: "10%",
            md: "5%",
          },
        }}
      >
        <Grid
          item
          xs={2}
          md={1}
          container
          justifyContent="center"
          alignItems="center"
        >
          <a
            href="/#/home-profile"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <ArrowBackIcon sx={{ fontSize: "30px" }} />
          </a>
        </Grid>
        <Grid item xs={10} md={3}>
          <h3
            style={{
              color: "#000",
              fontSize: "32px",
              fontWeight: "400",
            }}
          >
            Your Profile
          </h3>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={8}>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "400",
              color: "#AFAFAF",
              marginTop: "3%",
            }}
          >
            User Name
          </h3>
          <TextField
            id="outlined-helperText"
            defaultValue={formData?.userName || formData?.userName}
            sx={{
              width: "100%",
              marginTop: "2%",
              "& #outlined-helperText": {
                fontSize: "20px",
                // Add other styles as needed
              },
            }}
            onChange={(e) => handleTextFieldChange("userName", e.target.value)}
          />
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "400",
              color: "#AFAFAF",
              marginTop: "3%",
            }}
          >
            E-mail
          </h3>
          <TextField
            id="outlined-helperText"
            defaultValue={formData.email}
            sx={{
              width: "100%",
              marginTop: "2%",
              "& #outlined-helperText": {
                fontSize: "20px",
                // Add other styles as needed
              },
            }}
            onChange={(e) => handleTextFieldChange("email", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={3} container justifyContent="center">
  {frontShow ? (
   <div
   style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center",
     width: "100%",
     height: "100%",
     backgroundImage: `url(${frontShow})`,
     backgroundSize: "contain",
     backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     borderRadius: "4px",
     position: "relative",
     cursor: "pointer",
     paddingBottom: "80%",
     opacity: 1.2, // Lower opacity of the background image
   }}
 >
<label htmlFor="fileInput" style={{ ...labelStyle }}>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => handleFileSelect(e)}
    style={{ display: "none" }}
    id="fileInput"
  />
  <span style={{ color: "#FFF", fontSize: "16px", textAlign: 'center' }}>Update Profile</span>
</label>


    </div>
  ) : (
    <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
     // Adjust the height as needed
     width : '100%',
     height : '100%',
      backgroundImage: `url(${formData?.image})`,
      backgroundSize: "contain",
      backgroundPosition: "center", // Center the background image
      backgroundRepeat: "no-repeat",
      borderRadius: "4px",
      position: "relative",
      cursor: "pointer",
      paddingBottom: "80%", // Set a padding-bottom to maintain aspect ratio
    }}
  >
    <label htmlFor="fileInput" style={labelStyle}>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleFileSelect(e)}
        style={{ display: "none" }}
        id="fileInput"
      />
    
      <span style={{ color: "black", fontSize: "16px",textAlign:'center' }}>
        Change Profile
      </span>
    </label>
  </div>
  )}
</Grid>



      </Grid>

      <Grid item xs={12} md={11}>
        <h3 style={{ fontSize: "20px", fontWeight: "400", color: "#AFAFAF" }}>
          Company Name
        </h3>
        <TextField
          id="outlined-helperText"
          defaultValue={formData?.companyName}
          sx={{
            width: "100%",
            marginTop: "1%",
            "& #outlined-helperText": {
              fontSize: "20px",
              // Add other styles as needed
            },
          }}
          onChange={(e) => handleTextFieldChange("companyName", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={11}>
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "400",
            color: "#AFAFAF",
            marginTop: "2%",
          }}
        >
          Address
        </h3>
        <TextField
          id="outlined-helperText"
          defaultValue={formData?.address}
          sx={{
            width: "100%",
            marginTop: "1%",
            "& #outlined-helperText": {
              fontSize: "20px",
              // Add other styles as needed
            },
          }}
          onChange={(e) => handleTextFieldChange("address", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={11}>
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "400",
            color: "#AFAFAF",
            marginTop: "3%",
          }}
        >
          Country
        </h3>
        <TextField
          id="outlined-helperText"
          defaultValue={formData?.country}
          sx={{
            width: "100%",
            marginTop: "1%",
            "& #outlined-helperText": {
              fontSize: "20px",
              // Add other styles as needed
            },
          }}
          onChange={(e) => handleTextFieldChange("country", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={11}>
        <h3
          style={{
            fontSize: "20px",
            fontWeight: "400",
            color: "#AFAFAF",
            marginTop: "3%",
          }}
        >
          Description
        </h3>
        <TextField
          id="outlined-helperText"
          defaultValue={formData?.description ? formData?.description : "..."}
          sx={{
            width: "100%",
            marginTop: "1%",
            "& #outlined-helperText": {
              fontSize: "20px",
              // Add other styles as needed
            },
          }}
          onChange={(e) => handleTextFieldChange("description", e.target.value)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={11}
        container
        justifyContent="end"
        alignItems="end"
      >
  <Button
  sx={{
    textTransform: "none",
    border: "1px solid #50A4DB ",
    borderRadius: "4px",
    width: "30%",
    color: "#FFF",
    background: "#50A4DB ",
    marginTop: "3%",
    fontSize: "20px",
  }}
  variant="contained"
  onClick={(e) => {
    e.preventDefault();
    handleSubmit(userData?._id);
  }}
>
  Confirm
</Button>

      </Grid>
    </div>
  );
}

export default HomeProEdit;
