import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid, CardActionArea } from '@mui/material';

export default function ActionAreaCard({ rightImage, image }) {
  return (
    <Card sx={{ maxWidth: 1073}}>
      <CardActionArea>
        <Grid container spacing={2} direction={rightImage ? 'row-reverse' : 'row'} alignItems="center">
          <Grid item xs={12} md={6}>
            <CardMedia component="img" width="100%" height="auto" image={image} alt="" />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography
                sx={{
                  color: '#38B27F',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  textAlign: 'left',
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Senectus ornare interdum nibh.
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: '#797979',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  textAlign: 'left',
                  marginTop: '30px',
                }}
              >
                Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
