import { Grid,MenuItem,Pagination as MuiPagination, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ChangePlan from "./ChangePlan";
import AddClient from "./AddClient";
import './AdminSubscription.css'
import ViewClient from "./ViewClient";
import axios from "axios";
import Select from 'react-select';
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";

function AdminSubscription() {
//All clients details

    const itemsPerPage = 5; // Set the number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOption, setSelectedOption] = useState("Subscription");
    const [subscriptionData,setSubscriptionData] = useState([])

const fetchAllSubscription = async () => {
  try {
      const token = JSON.parse(localStorage.getItem("access-token"));
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/admin/subscription-list`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      // Update priceId to string based on conditions
      const updatedSubscriptionData = response.data.map(subscription => {
          let plan = '';
          let priceIdLength = subscription.priceId.length;

          switch (subscription.priceId[0]) {

            case 'price_1OlRPTSGW1EyGI68j0Q8wSOS':
            case 'price_1OiDo4SGW1EyGI68P1y5vHR2':
            case 'price_1OiDobSGW1EyGI68bTJttjHo':
            case 'price_1OiDp0SGW1EyGI683THx9Iri':
            case 'price_1OiDpTSGW1EyGI68mAhLYoHb':
            case 'price_1Oiwn9SGW1EyGI68PmguaKcQ':
            case  'price_1OiwnUSGW1EyGI680ki1SPn7':
            case 'price_1Oiwo2SGW1EyGI68h6Edac2D':
            case 'price_1OiwoNSGW1EyGI68EJN6vbXq':
                plan = 'Simple';
                break;
                
            case 'price_1OlRTxSGW1EyGI6845fez4k6':
            case 'price_1Obkd8SGW1EyGI68XIbrGJXa':
            case 'price_1Oiu2uSGW1EyGI68SY92P7Fn':
             case 'price_1Oiu3ESGW1EyGI68Y61Uu3to':
             case 'price_1Oiu3VSGW1EyGI68mdmXRd7Y':
             case 'price_1Oiu3tSGW1EyGI68K6amlBow':
             case 'price_1Oiwp7SGW1EyGI68zboluETF':
             case 'price_1OiwpSSGW1EyGI68iaTbHSiy':
             case 'price_1OiwpoSGW1EyGI68WksFlnmk':
             case 'price_1OiwqESGW1EyGI68SNfUQTgt':
                plan = 'Moderate';
                break;
            case 'price_1OlRVLSGW1EyGI68yuqpDxWm':
            case 'price_1OivesSGW1EyGI68lwEjcpgZ':
            case 'price_1OivfiSGW1EyGI68EdRX8E3r':
            case 'price_1Oivg4SGW1EyGI6808fyUZm1':
            case  'price_1OivgdSGW1EyGI68VINqqBAW':
            case 'price_1OiwqxSGW1EyGI68QSB2ULYt':
            case 'price_1OiwrJSGW1EyGI68RToh8B5K':
              case 'price_1OiwrqSGW1EyGI68le8rNoKF':
                case 'price_1OiwsCSGW1EyGI68AVeGUU1M': 
                plan = 'Advance';
                break;
            default:
                plan = 'Unknown Subscription';
           }
        
  // If there are 2 or more items in priceId, append the length to the plan
          if (priceIdLength >= 2) {
            plan = `${plan} + ${priceIdLength} features`; 
          }
          return { ...subscription, plan };
      });
console.log(updatedSubscriptionData)
      setSubscriptionData(updatedSubscriptionData);
  } catch (error) {
      console.error('Error fetching subscription data:', error);
  }
};
useEffect(()=>{
  fetchAllSubscription()
},[])


const [clientDatas,setClientDatas] = useState([])

const fetchAllClients =async ()=>{

  try{

    const token = JSON.parse(localStorage.getItem("access-token"));
    const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/admin/subscribed-client`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    setClientDatas(response?.data?.subscribedClient)
  }catch(error){
    console.error(error)
    
  }

}
useEffect(()=>{
  fetchAllClients()
},[])
    // const paymentData = [
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
    //     { plan: 'Lorem ispum dolor', charges: 'Ongoing', provider: 'Lorem ispum', date: '13/07/23', button: 'Change Plan' },
        
    //   ];

      // const clientData = [
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
      //   { planClient: 'Lorem ispum dolor', pricing: '$00', planHolder: 'Lorem ispum', createdDate: '13/07/23', buttonClient: 'View Details' },
        
      // ];

       // Calculate the total number of pages
  
            const pageCount = Math.ceil(subscriptionData?.length / itemsPerPage);
            const pageCountClient = Math.ceil(clientDatas?.length / itemsPerPage);

  // Get the current page data
  const currentData = subscriptionData && subscriptionData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  const currentDataClient =clientDatas &&  clientDatas.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const currencies = [
    {
      value: 'Client',
     label : 'Client'
    },
    {
      value: 'Subscription',
      label : 'Subscription'
    },
    
  ];
  
  // const [selectedOption, setSelectedOption] = React.useState('');
  const [isMenuOpen, setIsMenuOpen] =useState(false);

  const handleClickOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChange = (value) => {
    // console.log(value)
    setSelectedOption(value?.value);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };
    return(
       <div>
        <Grid item direction="row" container sx={{marginTop: {lg: '7%', md: '10%', sm: '12%', xs: '20%'}, border: '1px solid #FFF', paddingTop: {xs: '3%', md: '1%'}}}>
                
        <Grid item xs={6} md={6} sx={{ paddingLeft: '5%'}}>
        <Select
    id="subscription"
    options={currencies}
    value={{value : selectedOption , label : selectedOption}}
    placeholder="Select subscription"
    onChange={(selectedOption) => handleChange(selectedOption)}
    onClick={handleClickOpen}
    styles={{
        control: (provided) => ({
            ...provided,
            fontSize: '15px', // Set font size for the input field
            padding: '4px 0px 6px 0px', // Add more padding
            minHeight: '30px', // Adjust the height
            width: '50%', // Adjust the width
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '15px', // Set font size for the selected value
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '15px', // Set font size for the options in the dropdown
            
        }),
        menu: (provided) => ({
            ...provided,
            width: '50%', // Set the width of the dropdown menu to match the control
        }),
    }}
    theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            neutral10: 'grey',
            primary: '#50a4db',
        },
    })}
/>

    </Grid>

                <Grid item container xs={6} md={6} justifyContent="end" >
                    <AddClient/>
                </Grid>

        </Grid>

        <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', marginTop: {md: '2%', xs: '4% '}}}>
        {/* Header */}
        {selectedOption === "Subscription" ? (
        <Grid container direction="row"  alignItems="center" sx={{fontSize: {md: '22px', xs: '10px'}, fontWeight: '600', paddingTop: '1%', backgroundColor: '#e0e0e0'}}>
                        <Grid item xs={4} md={4} container justifyContent="center">
                            <p>Plan</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Recent Charges</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Provider</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Last Updated</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            
                        </Grid>
        </Grid>
         ) : (
            <Grid container direction="row"  alignItems="center" sx={{fontSize: {md: '22px', xs: '10px'}, fontWeight: '600', paddingTop: '1%', backgroundColor: '#e0e0e0'}}>
                        <Grid item xs={4} md={4} container justifyContent="center">
                            <p>Plan</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Pricing</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Plan Holder</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>Created On</p>
                        </Grid>             
                        <Grid item xs={2} md={2} container justifyContent="center">
                            
                        </Grid>
        </Grid>
        )}
        <Grid item xs={12} md={12}>
                <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', position: 'relative', bottom: '10px'}} />
        </Grid>
        

        {/* Data rows */}
        {selectedOption === "Subscription" ? (

currentData &&  currentData.map((item, index) => (
            <div key={index}>
              <Grid
                container
                direction="row"
                className="client-row"
                alignItems="center"
                sx={{
                  fontSize: { md: "20px", xs: "8px" },
                  fontWeight: "400",
                  paddingTop: "1%",
                  color: "#000",
                }}
              >
                <Grid item xs={4} md={4} container justifyContent="center">
                            <p>{item?.plan }</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p >{item.amount /100} $</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item.customerName}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                        <p>{item.planCreated}</p> 
                        {/* <p>{new Date(item?.planCreated * 1000).toLocaleDateString()}</p> */}
                        {/* <p>{item?.planCreated ? new Date(item.planCreated).toLocaleDateString() : ""}</p> */}

    
                         </Grid>
                        
                        <Grid item xs={2} md={2}>
                           <ChangePlan currentCharge = {item?.amount} customerId = {item?.customerId} currentPriceId = {item.priceId} currentSubscription = {item.id}/>
                        </Grid> 


                </Grid>
              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    border: "1px solid rgba(175, 175, 175, 0.9)",
                  }}
                />
              </Grid>
            </div>
          ))
        ) : (
          /* Your data rows content for Client */
          currentDataClient && currentDataClient.map((item, index) => (
            <div key={index}>
              <Grid
                container
                direction="row"
                className="client-row"
                alignItems="center"
                sx={{
                  fontSize: { md: "20px", xs: "8px" },
                  fontWeight: "400",
                  paddingTop: "1%",
                  color: "#000",
                }}
              >
                <Grid item xs={4} md={4} container justifyContent="center">
                            <p>{item?.clientData?.type}</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p >{item?.clientData?.amount} $</p>
                        </Grid>
                        <Grid item xs={2} md={2} container justifyContent="center">
                            <p>{item?.userName}</p>
                        </Grid>
                        {/* <Grid item xs={2} md={2} container justifyContent="center">
    <p>{new Intl.DateTimeFormat('en-US').format(new Date(item?.clientData?.date))}</p>
</Grid> */}
                        
                        <Grid item xs={2} md={2}>
                           <ViewClient clientData = {item} />
                        </Grid> 
              </Grid>
              <Grid item xs={12} md={12}>
                <hr
                  style={{
                    border: "1px solid rgba(175, 175, 175, 0.9)",
                  }}
                />
              </Grid>
            </div>
          ))
        )}    

            {/* Pagination component */}
            {selectedOption === "Subscription" ? (
            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
                <MuiPagination
                    count={pageCount}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    //   shape="rounded"
                    variant="outlined"
                />
            
            </Grid>
            ) : (
            <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
                <MuiPagination
                    count={pageCountClient}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    //   shape="rounded"
                    variant="outlined"
                />
            
            </Grid>
            )}
        </Grid>
       </div>
    );
}

export default AdminSubscription;