import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export default function InputWithIcon({label,icon, onChange, type, required, helperText, error,disabled,defaultValue}) {  
  return (
    <Box sx={{
      width: 500,
      maxWidth: '100%',
    }}>
     
      <TextField
      fullWidth
        id="input-with-icon-textfield"
        placeholder={label}
        onChange={onChange} // add onClick prop
        type={type}
        required={required}
        helperText={helperText}
        error={error}
        disabled={disabled} // Set disabled prop
        defaultValue={defaultValue} // Set defaultValue prop
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {icon}
            </InputAdornment>
          ),
          inputProps: {
            style: {
              fontSize: '20px', // Adjust the font size for desktop and phone views
            },
          },
           sx: {
            '& fieldset': {
              borderColor: '#000', // Set your desired border color
              borderRadius: 24, // Set your desired border radius
              // paddingRight:  isDesktop ? '300px' : '100px',
            },
            
          
          },
        
        }}
       
        variant="outlined"
        
        
        
      />
   
    </Box>
  );
}