import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Container1.css';
import img from '../../Images/container1.png';

export default function SimpleContainer1() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ marginTop: '1.5%' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} sm={6}>
            <h4 id="heading1">
              "Unlock the Power of Clarity: Chart Your Path to Success with Our Funnel Mapping Software. Visualize, Optimize, and Convert like Never Before."
            </h4>
            <p id="para_text1">
              Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
            </p>
            <button id="button1">Start Now</button>
          </Grid>
          <Grid item xs={12} md={6} sm={6} container justifyContent="center" alignItems="center">
            <img id="img" src={img} alt="" style={{ width: '70%', height: 'auto' }} />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
