import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import imgSrc from '../../Images/Member.jpg'
import { Button, CardActionArea, Grid, IconButton } from '@mui/material';
import ProjectRemove from './ProjectRemove';

import DeleteIcon from '@mui/icons-material/Delete';

export default function MemberCard({teamMember}) {

  return (
    <Card sx={{ width: '100%' ,  background: 'rgba(245, 245, 245, 1)' }}>
      
      {/* <IconButton 
    sx={{ 
      // position: 'absolute', 
      // top: '5px', 
      marginLeft:'140px', 
      // zIndex: 1,
      color: 'red', // Make the icon red
      fontSize: '50px', // Increase the icon size
    }} 
    // onClick={handleDelete} // Handle delete functionality
  >
    <DeleteIcon />
  </IconButton> */}
 
      <CardActionArea>
        <CardMedia
          component="img"
          sx={{padding: '8px'
        }}

          image={teamMember?.image?.url}          
          alt=""

        />
        
        
  <CardContent >
    <Grid container direction="row" spacing={0.5}>
      <Grid item xs={12} md={12}>
        <Typography variant="body2" color="text.secondary" sx={{
          // color: '#AFAFAF',
          fontSize: '16px',
          fontWeight: '600'
        }}>
          {teamMember?.userName}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="body2" color="text.secondary" sx={{
          // color: '#AFAFAF',
          fontSize: '13px'
        }}>
          {teamMember?.designation}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {/* <Typography variant="body2" color="text.secondary" sx={{
          color: '#AFAFAF',
          fontSize: '13px'
        }}>
          Other Details - {teamMember?.projectName}
        </Typography> */}
      </Grid>
      <Grid item xs={12} md={12}>
        <ProjectRemove teamMember={teamMember}/>
      </Grid>
    </Grid>
  </CardContent>
      </CardActionArea>
    </Card>
  );
}
