import React, { useState, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend);

const Utils = {
  namedColor: (index) => {
    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
    return colors[index % colors?.length];
  },
  transparentize: (color, opacity) => {
    const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');
    return `${color}${alpha}`;
  },
};

const CustomerCard2 = ({ aucGraphData }) => {
  const chartRef = useRef(null);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });

  useEffect(() => {
    // Filter out the "Best Model Name" object from aucGraphData
    const filteredData = aucGraphData.filter(item => !item["Best Model Name"]);
  
    if (filteredData.length > 0) {
      // Assuming the first model contains the `fpr` array for labels
      const firstModelKey = Object.keys(filteredData[0])[0];
      const labels = filteredData[0][firstModelKey].fpr.map(value => parseFloat(value).toFixed(4));
  
      const datasets = filteredData.map((model, index) => {
        const modelKey = Object.keys(model)[0];
        return {
          label: modelKey,
          data: model[modelKey].tpr,
          borderColor: Utils.namedColor(index),
          backgroundColor: Utils.transparentize(Utils.namedColor(index), 0.5),
          fill: false,
        };
      });
  
      setChartData({
        labels,
        datasets
      });
    }
  }, [aucGraphData]);
  

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'TPR vs FPR'
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'False Positive Rate (FPR)'
        }
      },
      y: {
        title: {
          display: true,
          text: 'True Positive Rate (TPR)'
        }
      },
    },
  };

  return (
    <div>
      <h2>TPR vs FPR</h2>
      <Line ref={chartRef} data={chartData} options={options}   />
    </div>
  );
};

export default CustomerCard2;
