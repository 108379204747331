import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { removeMember } from '../../Reducers/memberReducer';

export default function ProjectRemove({teamMember}) {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const params = useParams()
const dispatch = useDispatch()

  const handleClose = () => {
  
    setOpen(false);
  };
  const handleDelete = ()=>{
    dispatch(removeMember({projectId : params.id, memberIdToRemove : teamMember._id}))
  }

  React.useEffect(()=>{
console.log("projectMember")
  },[dispatch])
  
const users = JSON.parse(localStorage.getItem('users'));
const isDisabled = users?.designation === 'teamMember' 
  return (
    <React.Fragment>

      <Button disabled={isDisabled} variant="outlined" color="error" onClick={handleClickOpen} sx={{
        // marginTop: '30px',
        // marginLeft: '40px',
        // background: '#e02d2df5',
        borderRadius: '4px',
        fontSize: '10px',
        fontWeight: '300'
        // color: '#FFF',
        // textAlign: 'center'
      }}>
        Remove
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
       
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                    <CancelIcon sx={{fontSize: '30px', color:  'rgba(229, 138, 123, 1)'}}/>
                </Grid>
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" sx={{textAlign: 'center',fontSize: '20px', marginTop: '1%'}}>
                    <p >Are you sure you want to remove lorem ispum from the team?</p>
                </Grid>
        
            
          </DialogContentText>
        </DialogContent>
        <Grid  item direction="row" container justifyContent="center" textAlign="center" spacing={2} sx={{paddingBottom: '2%'}}>
        <Grid item  xs={5} md={6} container justifyContent="center" alignItems="center" >
          <Button autoFocus onClick={handleClose} sx={{          
            border: '1px solid #50A4DB ',
            borderRadius: '8px',
            color: '#50A4DB ',
            fontSize: '18px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px'
            
          }}>
            Go Back
          </Button>
        </Grid>
        <Grid item xs={5} md={6} container justifyContent="center" alignItems="center" onClick={handleDelete}>
          <Button onClick={handleClose} autoFocus sx={{            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px',
            "&:hover": {
              backgroundColor: "#50A4DB ", // Set to "inherit" or any other color you want on hover
            },
          }}
      
          >
            Delete
          </Button>
        </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
