import React, { useState } from "react";
import "./ResponsiveAppBar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink, useLocation } from "react-router-dom";

const ResponsiveAppBar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const location = useLocation();

  const closeMobileMenu = () => {
    setShowMediaIcons(false);
  };

  return (
    <>
      <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          
            <img src="/assets/image4.jpg" alt="" height="80px" width="100px" />
          
        </div>
        {/* 2nd menu part  */}
        <div
          className={showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"}
        >
          <ul >

           
            <li>
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                Home
                {/* Render the arrow icon only if the link is active */}
                {location.pathname === "/" && (
                  <span className="arrow-icon active">&#9660;</span>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tutorial"
                className="nav-link"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                Tutorial
                {/* Arrow icon for Tutorial */}
                {location.pathname === "/tutorial" && (
                  <span className="arrow-icon active">&#9660;</span>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/pricing"
                className="nav-link"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                Pricing
                {/* Arrow icon for Pricing */}
                {location.pathname === "/pricing" && (
                  <span className="arrow-icon active">&#9660;</span>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/work"
                className="nav-link"
                activeClassName="active"
                onClick={closeMobileMenu}
              >
                How We Work
                {/* Arrow icon for How We Work */}
                {location.pathname === "/work" && (
                  <span className="arrow-icon active">&#9660;</span>
                )}
              </NavLink>
            </li>
            
            <li>
              {/* <a style={{color: '#000'}} href="/#/login">Login</a> */}

              <NavLink
              to="/login"
              className="nav-link"
              activeClassName="active"
              onClick={closeMobileMenu}
              >
Login

{location.pathname === "/login" &&(
  <span className="arrow-icon active">&#9660; </span>
)}                </NavLink>
            </li> 

            <li>
              
              <a href="/#/signUp" className="signUp">
                Register
              </a>
            </li>
          
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          <ul className="social-media-desktop">
           
          </ul>

          {/* hamburger menu start */}
          <div className="hamburger-menu">
            <a onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ResponsiveAppBar;
