import { useState } from "react";
import Sidebar from "../../components/HomePage/Sidebar";
import TeamPage from "../../components/HomePage/Team";


function Team() {
    // const [teamList,setTeamList] = useState({})
    return(
        <Sidebar Component={<TeamPage />} />
    )
}

export default Team;