import AdminSidebar from "../../components/AdminPages/AdminSidebar";
import Client from "../../components/AdminPages/Client";



function AdminClient() {
    return(
        <AdminSidebar Component={<Client/>} />
    );
}

export default AdminClient;


