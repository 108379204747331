import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from "@mui/material";
import './Variable.css'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import VariableCard from './VariableCard';

function Variable() {
    return(
        <div>
            <Grid item direction="row" container sx={{marginTop: {lg: '7%', md: '10%', sm: '12%', xs: '20%'}, border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', paddingTop: {xs: '3%', md: '1%'}}}>
                <Grid item container xs={6} md={6} sx={{fontSize: {xs: '24px', md: '32px'}, fontWeight: '600', color: '#000', paddingLeft: '5%'}}>
                    <p>Variable</p>
                </Grid>
                <Grid item container xs={6} md={6} justifyContent="end" >
                    <Button className='button-size' variant="contained" endIcon={<AddIcon />} sx={{backgroundColor: 'rgba(80, 164, 219, 1)', color: '#FFF', textTransform: 'none', padding: '10px', height: '70%', fontSize: '16px', marginRight: '10%'}}>Create Variable</Button>
                </Grid>
            </Grid>
            <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', backgroundColor: 'rgba(248, 248, 248, 1)', marginTop: {xs: '4%', md: '2%'}, paddingBottom: '3%'}}>
            <Grid item direction="row"  container>
                <Grid item container  xs={6} sm={4} md={3}  sx={{color: '#000', fontWeight: '600', fontSize: {md: '24px', xs: '20px'},paddingTop: {md: '0.5%', sm: '0.3%', xs: '2%'}, paddingLeft: '5%'}}>
                    <p>Industry Name</p>
                </Grid>
                <Grid item container xs={6} sm={1} md={1} alignItems="center">
                    <AddCircleOutline sx={{color: 'rgba(80, 164, 219, 1)', fontSize: '24px'}}/>
                </Grid>

            </Grid>
            <Grid item container spacing={2} justifyContent="center" alignItems="center"   direction="row">
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
            </Grid>
            </Grid>
            <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', marginTop: {xs: '4%', md: '2%'}, paddingBottom: '3%'}}>
            <Grid item direction="row"  container>
                <Grid item container xs={6} sm={4} md={3} sx={{color: '#000', fontWeight: '600', fontSize: {md: '24px', xs: '20px'},paddingTop: {md: '0.5%', sm: '0.3%', xs: '2%'}, paddingLeft: '5%'}}>
                    <p>Industry Name</p>
                </Grid>
                <Grid item container xs={6} sm={1} md={1} alignItems="center">
                    <AddCircleOutline sx={{color: 'rgba(80, 164, 219, 1)', fontSize: '24px'}}/>
                </Grid>

            </Grid>
            <Grid item container spacing={2}  direction="row">
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                
            </Grid>
            </Grid>
            <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', backgroundColor: 'rgba(248, 248, 248, 1)', marginTop: {xs: '4%', md: '2%'}, paddingBottom: '3%'}}>
            <Grid item direction="row"  container>
                <Grid item container  xs={6} sm={4} md={3} sx={{color: '#000', fontWeight: '600', fontSize: {md: '24px', xs: '20px'},paddingTop: {md: '0.5%', sm: '0.3%', xs: '2%'}, paddingLeft: '5%'}}>
                    <p>Industry Name</p>
                </Grid>
                <Grid item container xs={6} sm={1} md={1} alignItems="center">
                    <AddCircleOutline sx={{color: 'rgba(80, 164, 219, 1)', fontSize: '24px'}}/>
                </Grid>

            </Grid>
            <Grid item container spacing={2} justifyContent="center" alignItems="center"   direction="row">
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={12} sm={6} md={3}>
                    <VariableCard/>
                </Grid>
            </Grid>
            </Grid>
        </div>

    );
}

export default Variable;