
import HomeGrid from "../../components/HomePage/HomeGrid";
import Sidebar from "../../components/HomePage/Sidebar";

function Grid() {
    return(
        <div>
        <Sidebar Component={<HomeGrid/>}/>
        
        </div>
    );
}

export default Grid;