import { Box, Button, Grid, Typography } from "@mui/material";
import imgSrc from '../../Images/loginImage.png'
import InputWithIcon from "../../components/InputField/InputField"; 
import { FaRegUser } from "react-icons/fa";
import imgSrc2 from '../../Images/LoginPoly.png';
import imgSrc3 from '../../Images/LoginPoly3.png';
import imgSrc4 from '../../Images/LoginPoly4.png';
import imgSrc5 from '../../Images/LoginPoly2.png'
import { NavLink, useNavigate } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import './AdminLogin.css';
import { loginFailure, loginSuccess } from "../../Reducers/userReducers";
import { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";



function AdminLogin(){  
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [userNotFoundError, setUserNotFoundError] = useState(false);
const dispatch = useDispatch()
    const handleLogin = async () => {
console.log("admin ")
        try {
  
          // Reset error states when attempting login
          setEmailError(false);
          setPasswordError(false);
          setUserNotFoundError(false);
      
          if (!email || !password) {
            // Set error and helper text for empty fields
            if (!email) setEmailError(true);
            if (!password) setPasswordError(true);
      
            return;
          }
      
          const response = await fetch( `${process.env.REACT_APP_PUBLIC_BASE_URL}/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
          });
      
          if (response.ok ) {
            const data = await response.json();
            // Handle successful login, e.g., set user state or redirect
          //   console.log('Login successful:', data);
          
          
      
      if(data.user.role==="admin"){
        localStorage.setItem(
            "access-token",
            JSON.stringify(data.token)
          );
    localStorage.setItem("users",JSON.stringify(data.user))
    
        dispatch(loginSuccess(data.user));
        // Redirect to the desired URL upon successful login

        navigate('/admin-dashboard'); // Replace '/home-page' with your desired URL
        
      }else{
        toast.error("Please write correct credentials")
      }
     
          
      
          } else {
            // Handle login error
            const errorData = await response.json();
            console.error('Login failed:', errorData.message);
      
            if (errorData.message === 'User not found!') {
              // Set a specific error message for user not found
              setUserNotFoundError(true);
            } else {
              // Set a generic error message for other errors
              setErrorMessage('Incorrect username or password!');
            }
          }
        } catch (error) {
          console.error('Error during login:', error);
          dispatch(loginFailure(error.response.data.message));
          // Set a generic error message for other errors
          setErrorMessage('An error occurred during login');
        }
      };
    return(
        
        <Box sx={{height: 'auto'}} >
           
            
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} md={4}>
                    <div style={{position: 'relative', top : '25px', left: '8%'}}>
                    
                        <img src="/assets/image4.jpg" alt="" />
                  
                    </div>
                </Grid>

                <Grid item xs={4} md={4}>
               
                    <img style={{width: '60%'}} src={imgSrc3} alt="" />
                        
                </Grid>  

                <Grid item xs={4} md={4} container alignItems="end" justifyContent="end">

                    <img style={{width: '40%'}} src={imgSrc4} alt="" />

                </Grid>         
            </Grid>

            <Grid container direction="row" sx={{position: 'relative', bottom: {md: '150px'}}}>

                <Grid  item xs={12} md={6} justifyContent="center" textAlign="center" container >
                    <img className="hide-on-sm"  style={{height: '450px', width: '450px'}} src={imgSrc} alt="" />
                </Grid>

                <Grid item container xs={12} md={6} justifyContent="center" textAlign="center">

                    <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{marginTop: {xs: '12%'}, color: '#000', fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '700',}}>
                       <p> Welcome! </p> 
                    </Grid>

                    <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{ color: '#000', fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '700',}}>
                        <p> Login to your account </p>
                    </Grid>

                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '2%'}}>                        
                        <InputWithIcon label='User Id' icon={<FaRegUser size={25}/>} 
                         required
                         onBlur={() => setEmailError(email.trim() === '')}
                         helperText={userNotFoundError ? 'User not found!' : (emailError && email.trim() === '') ? 'This field is required !' : ''}
                         error={emailError || userNotFoundError}
                         type='email'
                         value={email}
                         onChange={(e) => {
                             setEmail(e.target.value);
                             setEmailError(false); // Reset the error when the user starts typing
                             setUserNotFoundError(false); // Reset user not found error
                         }}
                        />
                    </Grid>

                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '2%'}}>
                        <InputWithIcon label='Password' icon={<RiLockPasswordLine size={30}/>}
                          required
                          onBlur={() => setPasswordError(password.trim() === '')}
                          helperText={passwordError && password.trim() === '' ? 'Password is required' : ''}
                          type='password'
                          value={password}
                          error={passwordError}
                          onChange={(e) => {setPassword(e.target.value); setPasswordError(false);}}
                        />
                    </Grid>
                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{ color: 'red', marginTop: '2%', fontSize: '20px' }}>
                        <p>{errorMessage}</p>
                    </Grid>

                    <Grid item container xs={10} md={10} justifyContent="end" textAlign="end" sx={{paddingRight: {md: '55px', sm: '40px', xs: '15px'}, fontSize: {md: '24px', xs: '16px'}}}>
                        <a href='/#/admin-forgot-password' style={{display: 'flex',  textDecoration: 'none' , fontWeight: '400', color: '#000'}}>Forgot Password? </a>
                    </Grid>

                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center">   
                        <Button onClick={handleLogin} type="Submit" style={{fontSize: '32px', fontWeight: '500', borderRadius: '24px', border: '1px solid #FFF', background: 'rgba(80, 164, 219, 1)', color: "#FFF", textAlign: 'center',padding: '10px 0px 10px 0px', marginTop: '2%', width: '60%'}} variant="contained">Login</Button>
                    </Grid>
{/* 
                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{fontSize:{md: '24px', xs: '18px'}, fontWeight: '400', marginTop: '6%'}}>                     
                        <p>Do not have an account?<a href="/#/signUp" style={{color: '#50A4DB ', textDecoration: 'underline'}}>Create account</a></p>                
                    </Grid> */}

                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{fontSize:{md: '24px', xs: '20px'}, fontWeight: '400', marginTop: '1%'}}>                      
                        <p>Login as a <a href="#" style={{textDecoration: 'none', color: '#50A4DB '}}>Team Member</a></p>                   
                    </Grid> 

                </Grid>

            </Grid>

            <Grid container direction="row" >

                <Grid item xs={6} md={4}>                    
                    <img style={{width: '30%'}} src={imgSrc2} alt="" />
                </Grid>

                <Grid item xs={6} md={4} container justifyContent="end">
                    <img style={{width: '50%'}} src={imgSrc5} alt="" />                    
                </Grid>

            </Grid>                           

            
        </Box>
        
    
    );
}

export default AdminLogin;