
import AddMember2 from "../../components/HomePage/AddMember2";

import Sidebar from "../../components/HomePage/Sidebar";

function Member2() {
    return(
        <div>
        <Sidebar Component={<AddMember2/>}/>
        
        </div>
    );
}

export default Member2;