import { Box, Button, Card, Grid, Typography } from "@mui/material";
import imgSrc from '../../Images/loginImage.png'
import InputWithIcon from "../../components/InputField/InputField"; 
import { FaRegUser } from "react-icons/fa";
import imgSrc2 from '../../Images/LoginPoly.png';
import imgSrc3 from '../../Images/LoginPoly3.png';
import imgSrc4 from '../../Images/LoginPoly4.png';
import imgSrc5 from '../../Images/LoginPoly2.png'
import { Link, NavLink } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import './Login.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loginFailure, loginRequest, loginSuccess } from "../../Reducers/userReducers";
import toast from "react-hot-toast";



function Login(){  
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [userNotFoundError, setUserNotFoundError] = useState(false);

    // const { isAuthenticated } = useSelector(
    //     (state) => state.user
    //   );
const dispatch = useDispatch()

const baseUrl = window.location.hostname === "localhost"
? "http://localhost:3001/api"
: process.env.REACT_APP_PUBLIC_BASE_URL;


const handleLogin = async () => {
  try {
    dispatch(loginRequest());
    // Reset error states when attempting login
    setEmailError(false);
    setPasswordError(false);
    setUserNotFoundError(false);

    if (!email || !password) {
      // Set error and helper text for empty fields
      if (!email) setEmailError(true);
      if (!password) setPasswordError(true);

      return;
    }


    const response = await fetch( `${baseUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
   
   
    if (response.ok ) {
      const data = await response.json();
    //   codeblunt1010@gmail.com
     localStorage.setItem(
        "access-token",
        JSON.stringify(data.token)
      );
localStorage.setItem("users",JSON.stringify(data.user))


if (data.checkoutUrl) {//if its present then login with checkout after payed payment .otherwize simple login
 
    window.location.href = data.checkoutUrl; // Reload the page if sessionUrl exists
  }


if(data?.user?.role == 'user'){
    dispatch(loginSuccess(data.user));
    navigate('/#/home-page'); // Replace '/home-page' with your desired URL
  
  }else{
    toast.error("Please write correct credentials")
  }
     
      // Redirect to the desired URL upon successful logn  

    } else {
      // Handle login error
      const errorData = await response.json();
      console.error('Login failed:', errorData.message);

      if (errorData.message === 'User not found!') {
        // Set a specific error message for user not found
        setUserNotFoundError(true);
      } else {
        // Set a generic error message for other errors
        setErrorMessage('Incorrect username or password!');
      }
    }
  } catch (error) {
    console.error('Error during login:', error);
    dispatch(loginFailure(error.response.data.message));
    // Set a generic error message for other errors
    setErrorMessage('An error occurred during login');
  }
};
              



    return(
        
        <Box sx={{height: 'auto'}} >
           
            
            {/* <Grid container direction="row" spacing={2}> */}
             

                {/* <Grid item xs={4} md={4}>
               
                        <img style={{width: '60%'}} src={imgSrc3} alt="" />
                        
                </Grid>  

                <Grid item xs={4} md={4} container alignItems="end" justifyContent="end">
                    <img style={{width: '40%'}} src={imgSrc4} alt="" />

                </Grid>          */}



            {/* </Grid> */}
            <Typography
         
        >
         m
        </Typography>

          
                {/* <Grid  item xs={12} md={6} justifyContent="center" textAlign="center" container >
                    <img className="hide-on-sm"  style={{height: '450px', width: '450px'}} src={imgSrc} alt="" />
                </Grid> */}
              
          
              <Grid container direction="row"  sx={{marginTop: '4.5%'}}  >
             
                <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" >                
                <div
                  style={{
                    marginTop: '2%',
                    display: 'flex',
                    alignItems: 'center', // Center align vertically
                    flexDirection: 'column',
                  }}
                >
                    <Grid item container xs={12} md={10} justifyContent="center" textAlign="center" sx={{marginTop: {xs : '3%'}, color: '#000', fontSize:{md: '25px', sm: '27px', xs: '16px'},fontWeight: '700',}}>
                       <p >
                        Welcome! Please Login 
                        </p> 
                    </Grid>
                  
                    
                
                    <Grid item  xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '1.5%'}}>
                        
                        <InputWithIcon
                      
                            label='Email Id'
                            icon={<FaRegUser size={25} />}
                            required
                            onBlur={() => setEmailError(email.trim() === '')}
                            helperText={userNotFoundError ? 'User not found!' : (emailError && email.trim() === '') ? 'This field is required !' : ''}
                            error={emailError || userNotFoundError}
                            type='email'
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError(false); // Reset the error when the user starts typing
                                setUserNotFoundError(false); // Reset user not found error
                            }}
                        />

                    </Grid>

                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '2%'}}>
                        <InputWithIcon
                            label='Password'
                            icon={<RiLockPasswordLine size={30}/>}
                            required
                            onBlur={() => setPasswordError(password.trim() === '')}
                            helperText={passwordError && password.trim() === '' ? 'Password is required' : ''}
                            type='password'
                            value={password}
                            error={passwordError}
                            onChange={(e) => {setPassword(e.target.value); setPasswordError(false);}}
                            style={{ width: '93%' }} // Add this style to set the width
                        />
                    </Grid>
                    
                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{ color: 'red', marginTop: '2%', fontSize: '20px' }}>
                        <p>{errorMessage}</p>
                    </Grid>

                    <Grid item container xs={10} md={10} justifyContent="end" textAlign="end" sx={{paddingRight: {md: '20px', sm: '40px', xs: '15px'}, fontSize: {md: '20px', xs: '15px'}}}>
                        <a href='/#/forgot-password' style={{display: 'flex',  textDecoration: 'none' , fontWeight: '400', color: '#000'}}>Forgot Password? </a>
                    
                    </Grid>
                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center">   
                        <Button type="submit"  onClick={handleLogin}  style={{fontSize: '26px', fontWeight: '400', borderRadius: '24px', border: '1px solid #FFF', background: 'linear-gradient(to right, #50A4DB, #9E95F5) !important', color: "#FFF", textAlign: 'center',padding: '1px 0px 1px 0px', marginTop: '2%', width: '100%'}} variant="contained">Login</Button>
                    </Grid>

                    
                    <Grid item container xs={10} md={10}  justifyContent="center" textAlign="center" sx={{ fontSize: { md: '20px', xs: '18px' }, fontWeight: '400', marginTop: '1%' }}>
  <p style={{ display: 'inline'}}> Do not have an account?</p>
  <a href="/#/signUp" style={{ color: '#50A4DB', textDecoration: 'none', marginLeft: '2%' }}>Create account</a>
</Grid>

<Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{ fontSize: { md: '20px', xs: '18px' }, fontWeight: '400' }}>
  <p style={{ display: 'inline' }}>Login as a</p>
  <Link to="/team-login" style={{ textDecoration: 'none', color: '#50A4DB', marginLeft: '2%' }}> Team Member/Team Manager</Link>
</Grid>

                    </div>
                </Grid>

            </Grid>

            {/* <Grid container direction="row" >
                <Grid item xs={6} md={4}>
                    <img style={{width: '30%'}} src={imgSrc2} alt="" />

                </Grid>
                <Grid item xs={6} md={4} container justifyContent="end">
                    <img style={{width: '50%'}} src={imgSrc5} alt="" />
                    
                </Grid>
            </Grid> */}
                
            

            
        </Box>
        
    
    );
}

export default Login;