import * as React from 'react';
import imgSrc from '../../Images/image 6.jpg';
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  const content = (
    <div
      style={{
        color: '#FFF',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: 'normal',
        
      }}
    >
      <p>Lorem ipsum dolor</p>
      <p>Lorem ipsum</p>
      <p>Lorem ipsum</p>
    </div>
  );

  // Create an array of 4 elements and map the content
  const replicatedContent = Array(4)
    .fill(null)
    .map((_, index) => (
      <Grid item xs={12} sm={4} md={2} key={index}>
        {content}
      </Grid>
    ));

  return (
    <div style={{ width: '100%', backgroundColor: '#141824', marginTop: '5%' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <Grid item container justifyContent="center" spacing={2} sx={{marginTop: '2%'}}>
          
          <Grid item container justifyContent="center" spacing={2}>
          <img
            
              src={imgSrc}
           
              alt=""
              style={{
                position: 'relative',
                right: '5%'
              }}
            />
            {replicatedContent}
            <hr style={{width: '100%', color:'#FFF', marginTop: '5%' }}></hr>
          </Grid>
        </Grid>
       
      </Grid>
      <Grid
        container
        direction="row"
        item
        // justifyContent="space-between"
        // alignItems="center"
        sx={{paddingBottom: '1%'}}
        // mt={5}
      >
        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
          <h6
            style={{
              color: '#FFF',
              fontSize: '24px',
              fontWeight: '400',
              zIndex: '1',
              // textAlign: 'left',
              // marginLeft: '50px',
              // position: 'relative',
              // top: '30px'
            }}
          >
            2023 © All rights reserved
          </h6>
        </Grid>
        {/* <Grid item container justifyContent="flex-end" spacing={2} sx={{marginRight: '50px', position: 'relative', bottom: '20px'}}> */}
        <Grid item xs={4} md={2} container justifyContent="end" alignItems="end">
          <InstagramIcon sx={{color: '#FFF', fontSize: '30px'}} />
        </Grid>
        <Grid item xs={4} md={1} container justifyContent="center" alignItems="end">
          <FacebookOutlinedIcon sx={{color: '#FFF', fontSize: '30px'}} />
        </Grid>
        <Grid item xs={4} md={3} container  alignItems="end">
          <FaXTwitter style={{color: '#FFF', fontSize: '30px'}} />
        </Grid>
        </Grid>
      {/* </Grid> */}
    </div>
  );
}
