import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

function SimpleCard({ title, number }) {
   const cardStyle = {
    boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)',
  };

  return (
    <Card style={cardStyle}>
      <CardBody>
      <CardTitle className="mb-3  text-muted" style={{fontSize:"22px", fontWeight:"600"}}>{title}</CardTitle>

        <p style={{ color: '#50A4DB ' ,fontWeight:"600", fontSize:"32px"}}>{number}</p> 
      </CardBody>
    </Card>
  );
}

export default SimpleCard;
