import { Button, Grid, TextField } from "@mui/material";
import imgSrc from '../../Images/profilePic.jpg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import imgSrc2 from '../../Images/Rectangle 148.jpg'
import {  useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

function TeamView() {
//     const location = useLocation()
//     const { teamData } = location?.state
// console.log(teamData)
const params = useParams()
const [viewTeamMember,setViewTeamMember] =  useState({})
const [viewMemberProject,setMemberProject] = useState([])

useEffect(()=>{
    
const fetchTeamData = async ()=>{
    try{
      
        const accessToken = JSON.parse(localStorage.getItem('access-token'))
        if (!accessToken) {
            // Handle the case where the access token is not available
            return;
          }
    
    const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/view/team-member/${params.id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    );
    
    // console.log(response.data);
    setViewTeamMember(response?.data?.memeberData)



    setMemberProject(response.data?.memberProject)
    toast.success(response.data.message)
    }catch(error){
        console.error(error)
        toast.success(error.response.data.message)
    }
    }
    
fetchTeamData()
},[params])
// const [formData,setFormData] = useState({
//     userName:viewTeamMember.userName,
//     email  :viewTeamMember.email,
//     country :viewTeamMember.country,
//     companyName :viewTeamMember.companyName
// })
    return (
        <div>
            <Grid container direction="row" spacing={2} sx={{
                marginTop: {
                    xs: '25%',
                    sm: '15%',
                    md: '9%',
                  }
            }}>
            <Grid item xs={4} sm={8} lg={9}>
                <a href='/#/team' style={{ textDecoration:'none', color: '#000'}}>
                <ArrowBackIcon sx={{fontSize: '30px'}}/>
                </a>   
            </Grid>     
            <Grid item xs={8} sm={4} lg={3} justifyContent={{ xs: 'center', md: 'end' }} textAlign={{ xs: 'center', md: 'end' }}>        
                <Button variant="contained" sx={{border: '1px solid #50A4DB ', background: '#50A4DB ', borderRadius: '8px', width: '100%',color: '#FFF', textTransform:'none', fontSize: '20px', fontWeight: '600'}}>Add in project</Button>
            </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{marginTop: '3%'}}>
                <Grid item xs={12} md={4}>
                <img style={{
                    width: '70%',
                    height: '75%',
                    // marginLeft: '4%'
                }} src={viewTeamMember?.image} alt="" />
                
                </Grid>
                <Grid item xs={12} md={4}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '20px'}}>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >User Name</h3>
                   
                    {/* <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >{viewTeamMember.userName}</h3>
                    */}
                    <TextField
                        id="outlined-helperText"
                
                        value={viewTeamMember ? viewTeamMember.userName : ""}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          }}}
                    />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >E-mail</h3>
                    {/* <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >{viewTeamMember.email}</h3> */}
                    <TextField
                      id="outlined-helperText"

                        value={viewTeamMember ? viewTeamMember.email : ""}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Company Name</h3>
                    <TextField
                        id="outlined-helperText"
                        
                      
                        value={viewTeamMember ? viewTeamMember.companyName : ""}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Department</h3>
                    <TextField
                        id="outlined-helperText"
                  
                        value={viewTeamMember.designation && "N/A"}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    {/* <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Start Date</h3>
                    <TextField
                        id="outlined-helperText"
                    
                        value={viewTeamMember?.startDate && ""}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            lineHeight: '2.2rem'
                            // Add other styles as needed
                          },}}
                    />
                    </div> */}
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Address</h3>
                        <TextField
                            id="outlined-multiline-static"
                        
                            multiline
                            rows={6}
                            value={viewTeamMember?.address}
                            sx={{width: '100%', '& #outlined-multiline-static': {
                                fontSize: '20px',
                                lineHeight: '2.2rem'
                                // Add other styles as needed
                              },}}
                        />
                        </div>

                     
                    <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Country</h3>
                        <TextField
                            id="outlined-helperText"
                           
                        
                            value={viewTeamMember?.country}
                            sx={{width: '100%', '& #outlined-helperText': {
                                fontSize: '20px',
                                lineHeight: '2.2rem'
                                // Add other styles as needed
                              }}}
                        />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Designation</h3>
                    <TextField
                        id="outlined-helperText"
                     
                        value={viewTeamMember?.designation}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          }}}
                    />
                    </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <p style={{color: '#AFAFAF', fontSize: '20px', fontWeight: '400', marginTop: '5%'}}>Projects</p>
            </Grid>
            <Grid container direction="row" spacing={2} style={{marginBottom : '5%'}}>
            {viewMemberProject.map((project, index) => (
    <Grid key={index} item xs={12} sm={6} md={3} sx={{ marginTop: { xs: '2%' } }}>
        <img src={imgSrc2} alt="" style={{ width: '100%', height: '100%' }} />
        
        <p style={{ color: '#000', fontWeight: '500', fontSize: '18px' }}>{project.projectName}</p>
    </Grid>
))}

            </Grid>
            
        </div>
    )
}

export default TeamView;