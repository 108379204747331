import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import imgSrc from '../../Images/inverted-commas_2.jpg';


const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12.0003 17.27L16.1503 19.78C16.9103 20.24 17.8403 19.56 17.6403 18.7L16.5403 13.98L20.2103 10.8C20.8803 10.22 20.5203 9.12001 19.6403 9.05001L14.8103 8.64001L12.9203 4.18001C12.5803 3.37001 11.4203 3.37001 11.0803 4.18001L9.19032 8.63001L4.36032 9.04001C3.48032 9.11001 3.12032 10.21 3.79032 10.79L7.46032 13.97L6.36032 18.69C6.16032 19.55 7.09032 20.23 7.85032 19.77L12.0003 17.27Z" fill="#FFE600"/>
  </svg>
);

const BasicCard2 = ({ name, image }) => {
  return (
    <div>
      <div>
      <div style={{ position: 'relative', width: '100%', height: '100px', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ width: '77px', height: '77px', position: 'absolute', top: '70%',left: '10%'}} src={imgSrc} alt='' />
        <img src={image} alt="Image" style={{ position: 'absolute', top: '20%', zIndex: '2'}} />
        <img style={{ width: '77px', height: '77px', position: 'absolute', top: '70%',right:'10%', transform: 'rotate(180deg)'}} src={imgSrc} alt='' />

      </div>

     
    <Card sx={{ width: '100%',height:'auto', backgroundColor: '#FFF', position: 'relative', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}>
    
    
      <CardContent sx={{paddingTop: '130px'}}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center',marginTop: '2%'}}>
              {Array(5).fill().map((_, index) => (
                <StarIcon key={index} />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ textAlign: 'center', width: '100%', paddingTop: '20px' , color: '#797979', fontWeight: '400', fontSize: '1.9rem' ,color:'2f2f2f'}}>
              Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
            </Typography>
          
          
            <Typography sx={{ color: '#000', textAlign: 'center', fontSize: '36px', fontWeight: '400',marginTop:'1%'}}>
              {name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    </div>
    </div>
  );
};

export default BasicCard2;
