import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image, Rect, Text, Group, Circle, Line, Arrow } from 'react-konva';
import { Html } from 'react-konva-utils';
import { ScaleLoader, Text as SpinnerText} from 'react-spinners';
import useImage from 'use-image';
import { Icon } from '@iconify/react';
import { createBasicTemplate, createCampaignTemplate, createTemplate1, createTemplate10, createTemplate2, createTemplate3, 
  createTemplate4,
  createTemplate5,
  createTemplate8,
  createTemplate9} from './TemplateCreaters.js';
import TemplateModal from './CustomModal.js';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Button } from 'reactstrap';
import { handleClearClick, handleCopyClick,handleRedoClick, handleUndoClick,
  handleZoomInClick,handleZoomOutClick,handleMouseUp, handleMouseDown, handleMouseMove, handleDeleteClick,  
  handleMouseMove1} from './HandlerFunctions.js';
import "../index.css"
import { useDispatch, useSelector } from 'react-redux';
import { addTemplate, dropTemplateID, savedDatabaseTemplate ,getUploadedFiles, getUploadedFiles2, updateFileInfo, getSingleSelectedVariable, getAllSingleVariableWidgets, getAllGroupVariableWidgets, savedCurrentConnector, projectData, getConnectorLine, updateConnectorLines, updateLines, getAllSinglePresetVariableWidgets, getAllGroupPresetVariableWidgets, getRightsideVariables, lastEditedCanvas, setSelectedOption, setOpenRightBar, deleteConnectorLineAction} from '../../../Reducers/CanvasReducer.js';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FormulaModal from './FormulaModal.js';
import SingleVariableModal from './SingleVariableModal.js';
import GroupVariableWidget from './GroupVariableWidget.js';
import PresetVariableWidget from './PresetVariableWidget.js';
import PresetSingleVariableWidget from './PresetSingleVariableWidget.js';
import Select from 'react-select';
import { Dialog, DialogContent, DialogTitle,DialogActions } from '@mui/material';

function CanvasArea({selectedTemplate,toggleRightSidebarTrue, toggleRightSidebar, isSidebarVisible, toggleLeftSidebar,isSidebar2Visible}) {
  
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const openModal = () => setIsModalOpen1(true);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPop, setShowPopup] = useState(false);

  const [backgroundImage] = useImage('/assets/graph.png'); 
  const [dimensions, setDimensions] = useState({ width: 0, height: "100vh"});
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const [templates, setTemplates] = useState([]); 
  const [scale, setScale] = useState(0.60);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
  const [selectMode, setSelectMode] = useState(false);
  const [selectionRect, setSelectionRect] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [selectionBorder, setSelectionBorder] = useState(null);
  const [isSelectButtonActive, setIsSelectButtonActive] = useState(false);
  const [isClearActive, setIsClearActive] = useState(false);
  const [isCopyActive, setIsCopyActive] = useState(false);
  const [isSaveActive, setIsSaveActive] = useState(false);
  const [isUndoActive, setIsUndoActive] = useState(false);
  const [isRedoActive, setIsRedoActive] = useState(false);
  const [isZoomOutActive, setIsZoomOutActive] = useState(false);
  const [isZoomInActive, setIsZoomInActive] = useState(false);
  const [history, setHistory] = useState([{ templates: [], lines: [] }]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [lines, setLines] = useState([]);
  const [currentLine, setCurrentLine] = useState(null);
  const [selectedUniqueWidgetId, setSelectedUniqueWidgetId] = useState(null);
  const [selectedUniqueWidgetId1, setSelectedUniqueWidgetId1] = useState(null);
  const [templateData, setTemplateData] = useState({});
  const [isLineConnected, setIsLineConnected] = useState(false)
  const selectedVariable = useSelector((state) => state.canvas.selectedVariable);
  const params = useParams()
 // Add state to track the current template type being interacted with
// const [currentTemplateType,   ] = useState(null);
const [currentTemplateType1, setCurrentTemplateType1] = useState(null);
const {fileInfo,fileInfoLoading,files,selectedOption,filesLoading,singleSelectedVariable,singleSelectedVariableLoading, allsingleVariableWidgets,allGroupVariableWidgets,allSinglePresitVariableWidgets,allGroupPresitVariableWidgets,groupSelectedVariable} = useSelector(state=>state.canvas)
const rightSideVariables = useSelector(state => state.canvas.rightSideVariables);

console.log(rightSideVariables,"rightSideVariables")

 //call the api to gets uploaded file data  
 const {connectorLines}  = useSelector(state => state?.canvas || []);
 

 const allTemplates = useSelector(state => state?.canvas?.addingTemplate);

const dispatch = useDispatch()
const isLoading = useSelector((state) => state.canvas.isLoading)


 
  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (hasSeenPopup === null || hasSeenPopup === 'false') { // Check for null or "false" string
      setShowPopup(true);
      localStorage.setItem('hasSeenPopup', 'true');
    }
    return ()=>{
      localStorage.setItem('hasSeenPopup', 'false');
    }
  }, []);
  
  
 

  const callsFreezeCanvas = ()=>{
    const getFreezeValue = JSON.parse(localStorage.getItem('freeze'))
    dispatch(setSelectedOption(getFreezeValue))
  }

useEffect(()=>{ 
  
  toggleRightSidebarTrue()   
  // toggleRightSidebar()
  // setShowPopup(true);
  callsFreezeCanvas()
},[])


 

  
  // const uploadedFileData = async ()=>{
  //   try{
  //     const uploadedFileData = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/fileInfo/${params.id}`)
  //     // console.log(uploadedFileData)

  //   }catch(error){
  //     console.error(error);
  //   }
  // }
const [selctedTransaction , setSelectedTransaction] = useState(null)

  const dropdownOptions = [
    { value: '10%', label: '10%' },
    { value: '20%', label: '20%' },
    { value: '30%', label: '30%' },
    { value: '40', label: '40%' },
    { value: '50%', label: '50%' },
    { value: '60%', label: '60%' },
    { value: '70%', label: '70%' },
    { value: '80%', label: '80%' },
    { value: '90%', label: '90%' },
    { value: '100%', label: '100%' },
  ];


 

  useEffect(() => {
    const getSavedCanvas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-template-widget/${params.id}`);
      
        const getTemplateData = response.data?.canvasTemplates?.templateData
        // Extracting 'templateData' objects from each item and dispatching the action
        const templateDataArray = getTemplateData.map(item => item.templateData);
        // console.log(templateDataArray,"templatesss")
        // console.log(templateDataArray,"getting")
        // console.log("templateDataArray",templateDataArray)
        dispatch(savedDatabaseTemplate(templateDataArray));
      } catch (error) {
        console.error(error);
      }
    };

    getSavedCanvas(); // Call the function to fetch templates when component mounts
  }, [dispatch, params.id]); // Dependencies: dispatch and params.id

  useEffect(() => {
    const getConnectorLines = async () => {
      try {
        if (!params.id) return; // Ensure params.id is available
        // Make API call to fetch connector lines data
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-connector-lines/${params.id}`);
        const connectorLines = response.data?.connectorLines;
// console.log("connectorLines",connectorLines)

         dispatch(getConnectorLine(connectorLines))
          dispatch(savedCurrentConnector(connectorLines));

          setLines1(connectorLines)
        // Update local state with fetched connector lines
        // setLines1(connectorLines);
        setCurrentLine(response.data?.currentLine)
  
        // console.log('getting all connector lines:', connectorLines);
      } catch (error) {
        console.error('Error fetching connector lines:', error);
      
      }
    };
  
    getConnectorLines(); // Call getConnectorLines when params.id changes
  }, []);

const [outComeVariable,setOutcomeVariable] = useState("")
  const handleGetProjectData =async ()=>{
    try{     
       const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects/${params.id}`)
      //  console.log(response.data,"mohan")
       setOutcomeVariable(response.data?.goalName)
 dispatch(projectData(response.data))

}
catch(error){
console.log(error)
}
  }

  useEffect(() => {
    handleGetProjectData();
  }, [params.id, dispatch]);

  
  // useEffect(()=>{

  //   uploadedFileData()

  // },[])

  // const fileWithMatchingProjectId = files && files.find(file => {
  //   console.log('file metadata:', file.metadata);
  //   console.log('file projectId:', file.metadata.projectId);
  //   console.log('params.id:', params.id);
  //   return file.metadata && file.metadata.projectId === params.id;
  // });
  // console.log('fileWithMatchingProjectId:', fileWithMatchingProjectId);
 
  const [hasUploadedFilesDispatched, setHasUploadedFilesDispatched] = useState(false);

  useEffect(() => {
    if (files && params.id && hasUploadedFilesDispatched) {
      const fileWithMatchingProjectId = files.find(file => file.metadata && file.metadata.projectId === params.id);
  
      if (fileWithMatchingProjectId) {
        const filename = fileWithMatchingProjectId.filename;
        
        dispatch(getUploadedFiles(filename)); 
      
      }
    }
  }, [files, params.id, dispatch]);

  useEffect(() => {
    if (!hasUploadedFilesDispatched  ) {
      dispatch(getUploadedFiles2()); // Only dispatch getUploadedFiles2() after getUploadedFiles()
      setHasUploadedFilesDispatched(true); // Mark that the first dispatch has been done
    }
  }, [hasUploadedFilesDispatched, dispatch]);

  // useEffect(()=>{  
  //   dispatch(updateFileInfo())
  // },[params.id])

  ///firstlly files is comes in there




  // useEffect(()=>{
  //   dispatch(updateFileInfo())
  // },[params.id])

  const firstFileInfo = fileInfo && fileInfo.length > 0 ? fileInfo[0] : null;

  // Log the firstFileInfo
  // console.log('First File Info:',  firstFileInfo);

  let options = [];
  
  if (firstFileInfo) {
    options = Object.keys(firstFileInfo).map(key => ({
      value: firstFileInfo[key],
      label: key
    }))
  };
  const single = firstFileInfo && options?.length > 0 ? options[0].label : null

  // useEffect(()=>{

  //   getSavedCanvas()
  //   uploadedFileData()

  // },[])
  // console.log(params.id,"ID")
  // console.log(fileInfo,fileInfoLoading,files,filesLoading,"hii") 

  const [endLine1, setEndLine1] = useState({});
  const [lines1, setLines1] = useState([]);

 const startLine = (x, y, connectorType, templateIndex) => {
  // console.log("shanu",x,y,connectorType,templateIndex)
  setCurrentLine({ 
    points: [x, y, x, y], 
    startConnectorType: connectorType, 
    startTemplateIndex: templateIndex,
    dash: [4, 4] 
  });

};


const updateLine = (x, y) => {
  // setEndLine1({
  //   ...currentLine, 
  //   points: [currentLine.points[0], currentLine.points[1], x, y] 
  // });

 
  if (currentLine) {
    setCurrentLine({ 
      ...currentLine, 
      points: [currentLine.points[0], currentLine.points[1], x, y] 
    });

//  console.log("updateLine",currentLine)
    
   
  }
};                                                                                                   
  


const [line1,setLine1] = useState({})
const [selectedTemplate1 , setSelectedTemplate1] =useState({})

const endLine = () => {

  if (!currentLine || currentLine?.points?.length < 4 ) return;

  const lineEnd = { x: currentLine.points[2], y: currentLine.points[3] };
  let closestConnector = null;
  let minDistance = Infinity;
  let endConnectorIndex = -1; 
  let closestTemplateIndex = -1; 
  let connectedTemplate = null; // Variable to store the connected template object
 

  templates?.forEach((template, index) => {
    if (index !== currentLine.startTemplateIndex) { 
      template?.connectors?.forEach((connector, connectorIndex) => {
        if (isConnectorMatch(currentLine?.startConnectorType, connector?.type)) {
          const dx = lineEnd.x - (template.x + connector.x);
          const dy = lineEnd.y - (template.y + connector.y);
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < minDistance) {
            minDistance = distance;
            closestConnector = { x: template.x + connector.x, y: template.y + connector.y };
            endConnectorIndex = connectorIndex;
            closestTemplateIndex = index; 
            connectedTemplate = template; // Capture the connected template object
           
          }

        }
      });
    }
  });

  const SNAP_THRESHOLD = 10;
  if (minDistance <= SNAP_THRESHOLD && closestConnector) {
    const startConnectorIndex = templates[currentLine.startTemplateIndex].connectors.findIndex((c) => {
      return c.x === currentLine.points[0] - templates[currentLine.startTemplateIndex].x && 
             c.y === currentLine.points[1] - templates[currentLine.startTemplateIndex].y;
    });

    openModal(); // Open modal only when line connects successfully
    
 const updateLine = {
  ...currentLine,
  points: [currentLine.points[0], currentLine.points[1], closestConnector.x, closestConnector.y],
  startConnectorIndex,
  endConnectorIndex,
  startTemplateIndex: currentLine.startTemplateIndex,
  endTemplateIndex: closestTemplateIndex ,
  connectedTemplate: connectedTemplate,
  selectedValue: selctedTransaction,
  startTemplateId: templates[currentLine.startTemplateIndex].unique_widget_id, // Include the unique_widget_id of the starting template
}

// Call the API to save the line into the database
updateConnectorLines({ ...updateLine, uniqueConnectorId: uuidv4() })
  .then((res) => {
   
    // Dispatch action to indicate that the current connector is saved
    dispatch(savedCurrentConnector({ connectorLines: { ...updateLine } }));

    // Update the lines state array
    setLines(res?.data);

   
  })
  .catch((error) => {
    console.error('Error updating connector line:', error);
  });
    
 
    // setSelectedTransaction(null)

   
    
  }
 
 

 setSelectedTemplate1(connectedTemplate)//connected template
//  setSelectedTransaction(null);

      
       
      
      //  saveConnectorLineToDatabase(updateLine);                                                                                                                                                                                                                                                                                              
  setCurrentLine(null);


};



//Inside this when we connects any line then the value and selectedTemplate id is go
const handleMovesTransaction = async () => {
  try {
    // const { id } = params; // Assuming params contains the necessary data
    // const response = await axios.post(
    //   `${process.env.REACT_APP_PUBLIC_BASE_URL}/update-selectedValue-template/${id}`,
    //   {
    //     selectedValue: selctedTransaction,
    //     connectedTemplateId: selectedTemplate1?.unique_widget_id
    //   }
    // );

    // Check the response status and handle accordingly
    // if (response.status === 200) {
      // setSelectedTransaction(null); // Reset selectedTransaction after successful update
      setIsModalOpen1(false);
    // } else {
      // Handle error or display a message if the API call fails
    //   console.error('Failed to update selected value.');
    // }
  } catch (error) {
    console.error('Error updating selected value:', error.message);
    // Handle error or display a message if an exception occurs
  }
};

    // Example usage of handleMovesTransaction


    // const saveConnectorLine = async (line) => {
    //   try {
    //     const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-connector-line`, {
    //       ...line, 
    //       uniqueConnectorId: uuidv4()
    //     });
    //     dispatch(savedCurrentConnector({ connectorLines: response.data }));
    //     setLines1(response.data);
    //   } catch (error) {
    //     console.error('Error saving connector line:', error);
    //   }
    // };
//when any of template moves then this connector line array will be updated

// useEffect(() => {
  const updateConnectorLines = async (updateLine) => {
    try {
    //  console.log("updateLine",updateLine)

     
      // Make API call to update connector lines in the database
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/update-canvas-template/${params.id}`,
        {
            updateLine,
          //  currentLine: line
        }
      );
      

      // Dispatch action to save fetched connector lines to Redux store
      // const currentConnector = response?.data?.data;
      
      // dispatch(savedCurrentConnector(currentConnector));
      
      return response.data;
    } catch (error) {
      console.error('Error updating connector lines:', error.message);
    }
  };

  // updateConnectorLines(); // Call updateConnectorLines when params.id or lines change
// }, [dispatch, params.id, lines]);






  const isConnectorMatch = (startType, endType) => {

    // if (startType === 'left' && endType === 'right') return true;
    if (startType === 'right' && endType === 'left') return true;
    if (startType === 'top' && endType === 'bottom') return true;
    if (startType === 'bottom' && endType === 'top') return true;
    return false;
  };
 
  const handleDragEnd =async (templateIndex,uniqueWidgetId, event) => {
    const { x, y } = event.target.position();

   

    const updatedTemplate = { ...templates.find(template => template.unique_widget_id === uniqueWidgetId), x, y };

    setTemplates(prevTemplates => prevTemplates.map((template, index) => ({
      ...template,
      x: index === templateIndex ? x : template.x,
      y: index === templateIndex ? y : template.y
    })));
  
  //  console.log(templateIndex,lines)
    setLines(prevLines => prevLines.map(line => {
      let updatedLine = { ...line };//previous line
  
      if (line.startTemplateIndex === templateIndex) {
        const startConnector = templates[templateIndex].connectors[line.startConnectorIndex];
     
        updatedLine.points[0] = x + startConnector?.x;
        updatedLine.points[1] = y + startConnector?.y;
      }
      if (line.endTemplateIndex === templateIndex) {
        const endConnector = templates[templateIndex].connectors[line.endConnectorIndex];
     
        updatedLine.points[2] = x + endConnector?.x;
        updatedLine.points[3] = y + endConnector?.y;
      }
  
       //call the post api and puts lines into database
    
    // dispatch(savedCurrentConnector({ connectorLines : { ...updateLine} }));
      return updatedLine;
    }));
   
      // Update history
  const newTemplates = [...templates]; 
  const newLines = [...lines]; 
  updateHistory(newTemplates, newLines);

    try {

      // dispatch(savedDatabaseTemplate(updatedTemplate));//gets savedDatabaseTemplate
      await updateTemplateInDatabase(params.id, updatedTemplate);
      await updateConnectorLinesInDatabase(params.id,newLines)
      //  dispatch(savedDatabaseTemplate(templates))
    } catch (error) {
      console.error('Error updating template in database:', error.message);
    }
  };



  
  



  useEffect(() => {
    // Check if lines state is empty
    if (lines.length === 0) {
      setLines(lines1); // Initialize lines state with lines1 data
    }
  }, [lines1]);

  ///update connector lines when drag end any templates
  const updateConnectorLinesInDatabase = async (id, newLines) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/update-connector-lines/${id}`, { newLines });
      setLines(response?.data?.data || []);
      await getConnectorLines1();
    } catch (error) {
      console.error('Error updating connector lines in database:', error.message);
    }
  };
//that is also hits when component is refresh
  const getConnectorLines1 = async () => {
    try {
      if (!params.id) return; // Ensure params.id is available
      // Make API call to fetch connector lines data
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-connector-lines/${params.id}`);
      
      const connectorLines = response.data?.connectorLines;
      dispatch(savedCurrentConnector(connectorLines));
       dispatch(getConnectorLine(connectorLines))
       

        // setLines1(connectorLines)
      // Update local state with fetched connector lines
      // setLines1(connectorLines);
      setCurrentLine(response.data?.currentLine)

    } catch (error) {
      console.error('Error fetching connector lines:', error);
    
    }
  };

  useEffect(() => {
    const updateLinePositions = () => {
      setLines(prevLines => {
        if (!Array.isArray(prevLines)) return []; // Ensure prevLines is an array
  
        return prevLines.map(line => {
          const startTemplate = templates[line?.startTemplateIndex];
          const endTemplate = templates[line?.endTemplateIndex];
          if (!startTemplate || !endTemplate) return line; 
  
          const startConnector = startTemplate.connectors[line?.startConnectorIndex];
          const endConnector = endTemplate.connectors[line?.endConnectorIndex];
  
          const updatedLine = {
            ...line,
            points: [
              startTemplate?.x + startConnector?.x, 
              startTemplate?.y + startConnector?.y,
              endTemplate?.x + endConnector?.x, 
              endTemplate?.y + endConnector?.y
            ],
          };
  
          // Set the selected value
          updatedLine.selectedValue = selctedTransaction;
  
          return updatedLine;
        });
      });
    };
  
    updateLinePositions();
  }, [templates, selctedTransaction, setLines]);
  
  
  useEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    }

    updateSize(); 

    window.addEventListener('resize', updateSize); 
    return () => window.removeEventListener('resize', updateSize);
  }, [isSidebarVisible, isSidebar2Visible]); 




    
    
// Second useEffect for addCustomization


// 

  useEffect(() => {
    if (selectedTemplate ) {
      // console.log('selectedTemplate',selectedTemplate)
     
      let newTemplate;
      switch(selectedTemplate.type) {
        case 'singlePresetVariable':
          newTemplate = createBasicTemplate(selectedTemplate, 50, 50); 
          break;
        case 'groupPresetVariable':
          newTemplate = createTemplate1(selectedTemplate, 100, 100); 
          break;
        case 'mutuateVariable':
          newTemplate = createTemplate2(selectedTemplate, 100, 100); 
          break;  
        case 'formulaVariable':
          newTemplate = createTemplate3(selectedTemplate, 100, 100); 
          break;
          case 'uploadSingleVariable':
            newTemplate = createTemplate4(selectedTemplate, 100, 100);
             break;
          case 'uploadGroupVariable':
            newTemplate = createTemplate5(selectedTemplate, 100, 100);
             break;
          
        case 'campaign':
          newTemplate = createCampaignTemplate(selectedTemplate, 100, 100);
          break;    
        default:
          newTemplate = { ...selectedTemplate, x: 100, y: 100, width: 100, height: 50 };
      }
      setTemplates(currentTemplates => [...currentTemplates, newTemplate]);
      // Reset the addCustomizationChanged state variable
   
    }
  }, [selectedTemplate]);
  
  useEffect(()=>{
    dispatch(getAllSingleVariableWidgets())
  },[isModalOpen])
  useEffect(()=>{
    dispatch(getAllGroupVariableWidgets())
  },[isModalOpen])
  useEffect(()=>{
dispatch(getAllSinglePresetVariableWidgets())
  },[isModalOpen])
  useEffect(()=>{
dispatch(getAllGroupPresetVariableWidgets())
  },[isModalOpen])

   // Call this function whenever the templates state is updated
   const updateHistory = (newTemplates, newLines, isDeleteAction = false) => {
    const newHistory = history.slice(0, currentHistoryIndex + 1);
    newHistory.push({ templates: newTemplates, lines: newLines });
    setHistory(newHistory);
    setCurrentHistoryIndex(newHistory?.length - 1);
  
    // If it's a delete action, don't update the history to prevent replaying
    if (isDeleteAction) return;
  };
  
  const undo = () => {
    if (currentHistoryIndex > 0) {
      const newIndex = currentHistoryIndex - 1;
      const previousState = history[newIndex];
      setCurrentHistoryIndex(newIndex);
      setTemplates(previousState.templates);
      setLines(previousState.lines);
    }
  };
  
  const redo = () => {
    if (currentHistoryIndex < history?.length - 1) {
      const newIndex = currentHistoryIndex + 1;
      const nextState = history[newIndex];
      setCurrentHistoryIndex(newIndex);
      setTemplates(nextState.templates);
      setLines(nextState.lines);
    }
  };
  

    
//zoom in function
const handleZoomIn = () => {
  setScale(scale => Math.min(2, scale + 0.1)); 
};

const handleZoomOut = () => {
  // Adjust the minimum scale value as needed to prevent the image from getting too small
  setScale(scale => Math.max(0.4, scale - 0.1)); //  minimum scale set to 1
};



  // Toggle selection mode
  const toggleSelectMode = () => {

    const newSelectMode = !selectMode;
    setSelectMode(newSelectMode);
    setIsSelectButtonActive(newSelectMode); 
    if (!newSelectMode) {
      setSelectedTemplateIndex(null);
      setSelectionBorder(null);
    }
  };

  const saveTemplateToDatabase = async (template ) => {

    try {
  
      const templateWithProjectId = { ...template, projectId : params.id };
  
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-canvas-template`, 
       {
        templateWithProjectId, 
        projectId : params.id
       },
      );
     
  // dispatch(lastEditedCanvas(response?.data?.lastEdited));
      console.log('Template saved to database:', response.data);
    } catch (error) {
      console.error('Error saving template:', error.message);
    }
  };    
const [currentMutateId,setCurrentMutateId] = useState("")
const [currentFormulaId,setCurrentFormulaId] = useState("")

const handleSelectTemplate =async (index,e,template) => {

setCurrentTemplateType1(template.type); 

setSelectedUniqueWidgetId(template.unique_widget_id);
setSelectedUniqueWidgetId1(template.unique_widget_id);
dispatch(getSingleSelectedVariable(template.unique_widget_id));
dispatch(getAllSingleVariableWidgets())
dispatch(getAllGroupVariableWidgets())
dispatch(getAllSinglePresetVariableWidgets())
dispatch(getAllGroupPresetVariableWidgets())
setCurrentMutateId(template?.unique_widget_id)
setCurrentFormulaId(template?.unique_widget_id)
setCurrentId(template?.id)
   
if (selectMode) {
      setSelectedTemplateIndex(index);
    
    if (index !== null) {
      const template = templates[index];
      let borderProps;
  
      
      const circleShape = template.shapes.find(shape => shape.type === 'Circle');
      const borderPadding = 5;
  
      if (circleShape) {
      
        borderProps = {
          x: template.x + circleShape.x - circleShape.radius - borderPadding,
          y: template.y + circleShape.y - circleShape.radius - borderPadding,
          width: (circleShape.radius + borderPadding) * 2,
          height: (circleShape.radius + borderPadding) * 2,
          stroke: 'grey',
          strokeWidth: 2,
          dash: [4, 4],
          cornerRadius: (circleShape.radius + borderPadding), 
        };
      } 
      else {
       
        let minX = Infinity, minY = Infinity, maxX = 0, maxY = 0;
        template?.shapes?.forEach(shape => {
          if (shape.type === 'Rect') {
            minX = Math.min(minX, template.x + shape.x);
            minY = Math.min(minY, template.y + shape.y);
            maxX = Math.max(maxX, template.x + shape.x + shape.width);
            maxY = Math.max(maxY, template.y + shape.y + shape.height);
          }
        });
  
        borderProps = {
          x: minX - borderPadding,
          y: minY - borderPadding,
          width: maxX - minX + (borderPadding * 2),
          height: maxY - minY + (borderPadding * 2),
          stroke: 'grey',
          strokeWidth: 2,
          dash: [4, 4],
        };
      }
  
      setSelectionBorder(borderProps);

      ///saved also in database when we selected any of template
      //  const dispatchs = await dispatch(dropTemplateID({ newTemplate: { ...template, projectId: params.id } }));
    
      //  await saveTemplateToDatabase(template, lines);
      } else {
      setSelectionBorder(null);
    }
  }else {
    
    if (!e.target.attrs.isConnector) {
      setIsModalOpen(true);
    }
  }

  };  

  const containerRef = useRef(null);


  const stageRef = useRef(null);
  const [currentId,setCurrentId] = useState("")



 const handleSaveClick = () => {
  setIsSaveActive(true);
  setTimeout(() => setIsSaveActive(false), 500);
};

//when drag and drop then saves the specific template in the database
 

 
const updateTemplateInDatabase = async (projectId, templateData) => {
  try {
    // Make a PUT request to the update canvas template route
    const response = await axios.put(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/update-canvas/${projectId}`,
      { templateData }
    );  
   
    // Extracting only the templateData objects
const transformedData = response?.data?.data?.templateData.map(item => item.templateData);
// console.log("templateData",transformedData);
// console.log("transformedData",transformedData)

    dispatch(lastEditedCanvas(transformedData));
    return response.data;
  } catch (error) {
    console.error('Error updating template:', error.message);
    throw new Error('Failed to update template');
  }
};
const toggleModal1 = () => {
  setIsModalOpen1((prevState) => !prevState); // Toggle modal state
};
const [mutateId,setMutateId] = useState('')
const [formulaId,setFormulaId] = useState('')
// const [presetSingleOpen,setPresetSingleOpen] = useState("")

const onDrop = async (e) => {
  try {

    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData('application/reactflow'));

    if (stageRef.current) {
      const stage = stageRef.current.getStage();
    //  console.log("drop",stage.current)
      stage.setPointersPositions(e);
      const point = stage.getPointerPosition();

      if (!point) return;

      let newTemplate;

      switch (data.type) {
        case 'singlePresetVariable':
          newTemplate = createBasicTemplate(data, point.x, point.y);
          setCurrentTemplateType1('singlePresetVariable')
          break;
        case 'groupPresetVariable':
          newTemplate = createTemplate1(data, point.x, point.y);
          setCurrentTemplateType1('groupPresetVariable');
          break;
        case 'mutuateVariable':
          newTemplate = createTemplate2(data, point.x, point.y);
          setCurrentTemplateType1('mutuateVariable');
          setMutateId(newTemplate?.unique_widget_id)

          break;
        case 'formulaVariable':
          newTemplate = createTemplate3(data, point.x, point.y);
          setCurrentTemplateType1('formulaVariable');
          setFormulaId(newTemplate.unique_widget_id)
          break;
        case 'uploadSingleVariable':
          newTemplate = createTemplate4(data, point.x, point.y);
          setCurrentTemplateType1('uploadSingleVariable');
          break;
        case 'uploadGroupVariable':
          newTemplate = createTemplate5(data, point.x, point.y);
          setCurrentTemplateType1('uploadGroupVariable');
          break;
        default:
          newTemplate = { ...data, x: point.x, y: point.y };
      }

      const dispatchs = await dispatch(dropTemplateID({ newTemplate: { ...newTemplate, projectId: params.id } }));
      // console.log("drag drop template", dispatchs.payload);
      // dispatch(setOpenRightBar(true))
      await saveTemplateToDatabase(newTemplate, lines);
      // Open the right sidebar directly
      isSidebarVisible = true; // Set state directly for immediate response
      // toggleRightSidebar()
      toggleRightSidebarTrue(); // May trigger additional side effects (optional)
    }
  } catch (error) { 
    console.error('An error occurred in onDrop:', error);
    // Handle the error here, e.g., show an error message or take appropriate action
  }
};

  // useEffect to save lines state when it changes
  // useEffect(() => {
    
  //     // Lines state has changed, trigger API call to save lines
  //     saveTemplateToDatabase(allTemplates, lines);
    
  // }, [lines]);


// const allTemplates = useSelector(state => state?.canvas?.addingTemplate);

useEffect(() => {
  // Filter templates based on projectId
  const filteredTemplates =allTemplates && allTemplates?.filter(template => template?.projectId === params?.id);

  setTemplates(filteredTemplates);

}, [dispatch,allTemplates,params.id]);

const toggleModal = () => {
  setIsModalOpen((prevState) => !prevState); // Toggle modal state
};

 const deleteConnectorLine = async (projectId, lineIndex, lineDetails) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/delete-connector-line/${projectId}`, {
      data: lineDetails,
    });

    if (response.data.success) {
      // Dispatch the deleteConnectorLineAction only if deletion is successful on the server
      dispatch(deleteConnectorLineAction(lineIndex));
      console.log('Connector line deleted successfully!'); // Optional success message
    } else {
      console.error('Connector line deletion failed:', response.data.error || 'Unknown error'); // Log specific error details or generic message
      throw new Error('Failed to delete connector line'); // Re-throw error with more context
    }

    return response.data;
  } catch (error) {
    console.error('Error deleting connector line:', error.message);
    throw new Error('Failed to delete connector line'); // Re-throw error for further handling
  }
};

// const handleDeleteLine =async (lineIndex,uniqueId) => {
//   // const updatedLines = lines.filter((_, index) => index !== lineIndex);
//   // setLines(updatedLines);
//   // // Update history accordingly
//   // updateHistory(templates, updatedLines);
//   try {
//     // console.log(lineIndex)
//     const lineToDelete = connectorLines[lineIndex];
//     // console.log(lineToDelete)
//     const { points, startConnectorType, startTemplateIndex, endConnectorIndex, endTemplateIndex } = lineToDelete;
//     // Delete the connector line from the backend
  

//     // Remove the line from the local state
//     const updatedLines = connectorLines.filter((line) => {
//       return (
//         line.points !== points ||
//         line.startConnectorType !== startConnectorType ||
//         line.startTemplateIndex !== startTemplateIndex ||
//         line.endConnectorIndex !== endConnectorIndex ||
//         line.endTemplateIndex !== endTemplateIndex
//       );
//     });
//     setLines(updatedLines);
//     updateHistory(templates, updatedLines);

//       await deleteConnectorLine(params.id, {
//         uniqueId,
//       points,
//       startConnectorType,
//       startTemplateIndex,
//       endConnectorIndex,
//       endTemplateIndex
//     });
//   } catch (error) {
//     console.error('Error handling delete line:', error.message);
//   }
// };

const handleDeleteLine =async (lineIndex,uniqueId) => {
  dispatch(deleteConnectorLineAction(lineIndex));
  const updatedLines = lines.filter((_, index) => index !== lineIndex);
  setLines(updatedLines);

  updateHistory(templates, updatedLines);
  try {
    // console.log(lineIndex)
    const lineToDelete = connectorLines[lineIndex];
    // console.log(lineToDelete)
    const { points, startConnectorType, startTemplateIndex, endConnectorIndex, endTemplateIndex } = lineToDelete;
    // Delete the connector line from the backend
  

    // Remove the line from the local state
    const updatedLines = connectorLines.filter((line) => {
      return (
        line.points !== points ||
        line.startConnectorType !== startConnectorType ||
        line.startTemplateIndex !== startTemplateIndex ||
        line.endConnectorIndex !== endConnectorIndex ||
        line.endTemplateIndex !== endTemplateIndex
      );
    });
    setLines(updatedLines);
    // Update history only if it's not a delete action
    updateHistory(templates, connectorLines.filter((_, index) => index !== lineIndex), true);



      await deleteConnectorLine(params.id, {
        uniqueId,
      points,
      startConnectorType,
      startTemplateIndex,
      endConnectorIndex,
      endTemplateIndex
    });
  } catch (error) {
    console.error('Error handling delete line:', error.message);
  }
};

const getMidpoint = (linePoints) => {
  return {
    x: (linePoints[0] + linePoints[2]) / 2,
    y: (linePoints[1] + linePoints[3]) / 2
    
  };
};

const loaderContainer = {
 
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.25)' /* Optional: Add a semi-transparent background */
}
if (fileInfoLoading) {
  return (
    <div style={loaderContainer}>
      <ScaleLoader color="#50A4DB" size={50} loading={true} />
      <span style={{ marginLeft: '10px', fontSize: '16px' }}>getting variables...</span>
    </div>
  );
}

const freeze = JSON.parse(localStorage.getItem('freeze'));

const resetMutateId = () => {
  setMutateId('');
};
return (


 
  <div

  ref={containerRef} 
  style={{  position:"relative" ,width: '90vw', height: '100%',
  pointerEvents: freeze?.freeze && freeze?.projectId === params.id ? 'none' : 'auto',
  opacity: freeze?.freeze && freeze?.projectId === params.id ? 0.5 : 1,

   }}
 
  onDrop={onDrop}
  onDragOver={(e) => e.preventDefault()}
>
<Dialog
 open={showPop}
 onClose={() => setShowPopup(false)}
PaperProps={{
  // style: {
  //   width: '400px', // Adjust the width as needed
  //   height: '160px', // Adjust the height as needed
  // },
}}
>
<DialogTitle> 

 <h3>Outcome Variable</h3>
  </DialogTitle>
<DialogContent>

  <p style={{fontSize : '18px'}}>{`The last outcome variable is ${outComeVariable}`}</p>
  
         </DialogContent>
<DialogActions>
  <Button style={{fontSize : '16px'}} onClick={() => setShowPopup(false)}>Ok</Button>
     </DialogActions>
</Dialog>

  <Stage
  //  style={{cursor : 'move'}}
width={dimensions.width}
height={dimensions.height}
ref={stageRef}
onMouseDown={selectMode ? handleMouseDown : undefined}
onMouseMove={(e) => {
  if (currentLine) {
    
    const stage = e.currentTarget;

    const point = stage.getPointerPosition();
 
    updateLine(point.x, point.y);
  } else {
    handleMouseMove(e, selectMode, startPoint, setSelectionRect);
  }
}}
onMouseUp={(e) => {//when mouse click is released
  if (currentLine) {
    endLine();
  } else if (selectMode) {
    handleMouseUp(e, selectMode, startPoint, setSelectionRect, selectionRect, setStartPoint, handleSelectTemplate, templates);
  
  }
}}
>                                                                                                         

    <Layer scale={{ x: scale, y: scale }}>

  
      {selectionRect && selectionRect?.visible && (
        
        <Rect
          x={selectionRect.x}
          y={selectionRect.y}
          width={selectionRect.width}
          height={selectionRect.height}
          stroke="black"
          strokeWidth={1}
          dash={[4, 2]}
        />
      )}
      {selectionBorder && (
        <Rect {...selectionBorder} />
      )}
      {/* <Image 
image={backgroundImage} 
width={dimensions.width} 
height={dimensions.height} 
listening={false} 
/> */}
{/* {console.log(templates,"templates")} */}
{/* In Ui Which template we drops that is show with the helps of map */}
{templates && templates?.map((template, i) => (

<>
<Group
  key={i}
  x={template?.x}
  y={template?.y}
 
  draggable
  onClick={(e) => handleSelectTemplate(i, e,template)}
  onDragEnd={(e) => {
    
    handleDragEnd(i,template.unique_widget_id, e)
  }
  }
 onMouseMove={handleMouseMove1} 

>
     <Html
          divProps={{
            style: {
              position: 'absolute',
             fontSize:'15px',
              marginTop : '10px',
              marginLeft : '14px',
              zIndex : 10,
              color : '#ffff',
              userSelect : 'none'
            },
          }}
        >
       <p style={{fontSize : '17px'}}>{template?.text}</p>
       {/* {template.text === 'Uploaded Single Variable Widget' && selectedVariable && (
      <p style={{marginTop:'8%' , color:'black', fontSize:'23px', textAlign:'center', justifyContent: 'center', alignItems:'center' }}>{selectedVariable}</p>
    )} */}
{rightSideVariables &&
  rightSideVariables
    .filter(item => item.unique_widget_id === template.unique_widget_id)
    .map((sidebarItem, index) => {
      if (template.text === sidebarItem.type ) {
        // console.log(template.text === sidebarItem.type )
        switch (template.text) {
          case 'Uploaded Single Variable Widget':
          case 'Single Preset Variable Widget':
          case "Mutate Variable Widget":
            case "Formula Variable Widget":
            return (
              <p key={index} style={{ marginTop: '8%', color: 'black', fontSize: '23px', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
             {/* {console.log("sidebarItem",sidebarItem?.data)} */}
                {sidebarItem?.data}
              </p>
            );

          case 'Uploaded Group Variable Widget':
          case 'Group Preset Variable Widget':
            return (
              <div key={index}>
                {sidebarItem.data.map((matchedGroupVariable, idx) => (
                  <p key={idx} style={{ marginTop: '11%', color: 'black', fontSize: '23px', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    {matchedGroupVariable?.label || null}
                  </p>
                ))}
              </div>
            );

          default:
            return (
              <div key={index}>
                <p style={{ marginTop: '11%', color: 'black', fontSize: '23px', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
                  Select variable Please
                </p>
              </div>
            );
        }
      }
      return null; // No match found
    })}





    {/* {template.text === 'Uploaded Group Variable Widget' && allGroupVariableWidgets && options.length > 0 && (
      options.slice(0, 4).map((option, index) => (
        <p key={index} style={{marginTop:'11%' , color:'black', fontSize:'23px', textAlign:'center', justifyContent: 'center', alignItems:'center'}}>{option.label}</p>
      ))
    )} */}
   
        </Html>
        
  
  {template?.shapes?.map((shape, j) => {
    switch (shape.type) {
      case 'Rect':
        return <Rect key={j} {...shape} />;
      case 'Circle':
        return <Circle key={j} {...shape} />;
    }
  })}


  {template?.connectors?.map((connector, j) => (//the circle of template
<Circle
  key={j}
  {...connector}
  isConnector={true}
  onMouseDown={(e) => {
    e.cancelBubble = true;
    const { x, y } = e.target.position();
    const connectorType = e.target.attrs.type;
    startLine(x + template.x, y + template.y, connectorType, i); 
  }}
/>
))}




</Group>



</>
))}



  {currentLine && (//show the current line which we connects any of template

          <Arrow
            points={currentLine.points}
            stroke="#797979"
            pointerLength= {20}
            strokeWidth={2}
            dash={[4, 4]}
            fill="#797979"
            

          />

        )}

  {connectorLines &&
    connectorLines.map((line, index) => {
      const [startX, startY, endX, endY] = line.points;
      const crossSize = 17;
      const selectedValue = line.selectedValue;
  
      // Calculate midpoint coordinates
      const midX = (startX + endX) / 2;
      const midY = (startY + endY) / 2;
    
      // Calculate the coordinates to position the additional data to the right side of the endpoint
      const additionalDataX = endX + 10; // Adjust as necessary for spacing
      const additionalDataY = endY;

      return (
        <React.Fragment key={index}>
          <Arrow
            key={index}
            points={line.points}
            pointerLength={10}
            pointerWidth={10}
            fill="#797979"
            stroke="#797979"
            strokeWidth={2}
          />
          {/* Text at the start of the line */}
        <Text
          x={startX - crossSize / 2.5}
          y={startY - crossSize / 2.5}
          text="X"
          fontSize={crossSize}
          fill="red"
          onClick={() => handleDeleteLine(index,line?.uniqueConnectorId)}
        />
        {/* Text at the midpoint of the line */}
        <Text
          x={midX - crossSize / 1.1}
          y={midY - crossSize / 1.1}
          text={selectedValue}
          fontSize={crossSize}
          fill="#50a4db"
          // onClick={() => handleDeleteLine(index)}
        />
        {/* Text at the end of the line */}
        {/* <Text
          x={additionalDataX}
          y={additionalDataY}
          text={line.additionalData || ""} // Ensure line.additionalData is a string or can be represented as a string
          fontSize={crossSize}
          fill="red"
        /> */}
      </React.Fragment>
    );
  })}

     </Layer>
    </Stage>

{/* //left sidebar icon */}
<div style={{display:'flex',justifyContent:'center',alignItems : "center"}}>      
<div  className='icon-container'>
 
    <div onClick={toggleLeftSidebar} >
  
  {isSidebar2Visible ? 
    <Icon icon="noto-v1:left-arrow" width={35} />
     :
    <Icon icon="noto-v1:right-arrow" width={35} />
  }
</div>
    
  <Icon 
  icon="ant-design:clear-outlined" 
  width="24" 
  onClick={() => handleClearClick(setTemplates, setLines, updateHistory, setIsClearActive,dispatch,params.id)} 
  style={{ color: isClearActive ? '#50A4DB ' : 'black' }}
/>
<Icon 
icon="charm:cross"  
width="27"  
onClick={() => handleDeleteClick(selectedTemplateIndex, setIsDeleteActive, templates, setTemplates, updateHistory, setSelectedTemplateIndex,params.id)} 
style={{ color: isDeleteActive ? '#50A4DB ' : 'black' }}
/>
    
      <Icon icon="uil:copy" width="24" onClick={() => handleCopyClick(selectedTemplateIndex, templates, setTemplates, updateHistory, setIsCopyActive,selectMode,params,dispatch)} style={{ color: isCopyActive ? '#50A4DB ' : 'black' }}/> 
    
      <Icon  icon="mdi:select"  width="24"onClick={toggleSelectMode}  style={{ color: isSelectButtonActive ? '#50A4DB ' : 'black' }} />
     
      <Icon icon="basil:save-outline" width="24" onClick={handleSaveClick} style={{ color: isSaveActive ? '#50A4DB ' : 'black' }} />
      <Icon icon="material-symbols:undo" width="24" onClick={() => handleUndoClick(isUndoActive, setIsUndoActive, undo)} style={{ color: isUndoActive ? '#50A4DB ' : 'black' }} />
      <Icon  icon="material-symbols:redo" width="24" onClick={() => handleRedoClick(setIsRedoActive, redo)} style={{ color: isRedoActive ? '#50A4DB ' : 'black' }}/>        
      <Icon  icon="zondicons:minus-solid"  width="18"  onClick={() => handleZoomOutClick(setIsZoomOutActive, handleZoomOut)}  style={{ color: isZoomOutActive ? '#50A4DB ' : 'black' }}/>
      <Icon icon="jam:plus-circle-f"  width="21"  onClick={() => handleZoomInClick(setIsZoomInActive, handleZoomIn)} style={{ color: isZoomInActive ? '#50A4DB ' : 'black' }}/>
   
     <div onClick={toggleRightSidebar}>
     
        {isSidebarVisible ? 
          <Icon icon="noto-v1:right-arrow" width={34} /> :
          <Icon icon="noto-v1:left-arrow" width={34}  />
        }
      </div>
    
    </div>
    </div>

    {/* // Conditionally render TemplateModal */}

    {/* // Conditionally render TemplateModal */}
{/* Conditionally render TemplateModal */}
{/* Conditionally render TemplateModal */}


{/* //that is only popUp */}

{/* {currentTemplateType1 && (currentTemplateType1 === 'uploadSingleVariable')  && (
    <SingleVariableModal isOpen={isModalOpen} toggle={() => toggleModal()} currentId={currentId} firstFileInfo={firstFileInfo} uniqueWidgetId={selectedUniqueWidgetId} />
  )}
{currentTemplateType1 && (currentTemplateType1 === 'uploadGroupVariable')  && (
    <GroupVariableWidget isOpen={isModalOpen} toggle={() => toggleModal()} currentId={currentId} firstFileInfo={firstFileInfo} uniqueWidgetId={selectedUniqueWidgetId1}/>
  )}

  {currentTemplateType1 && currentTemplateType1 === 'groupPresetVariable' && (
      <PresetVariableWidget isOpen = {isModalOpen} toggle={() => toggleModal()} currentId = {currentId} firstFileInfo={firstFileInfo} uniqueWidgetId={selectedUniqueWidgetId1} />
    )
  }
  {currentTemplateType1 && currentTemplateType1 === 'singlePresetVariable' && (
      <PresetSingleVariableWidget isOpen = {isModalOpen} toggle={() => toggleModal()} currentId = {currentId} firstFileInfo={firstFileInfo} uniqueWidgetId={selectedUniqueWidgetId1} />
    )
  } */}
   <div>
    {/* Modal component which is opens a percentage modal in which we selects percentage*/}
    {/* <Modal isOpen={isModalOpen1} toggle={()=>toggleModal1()} centered style={{ maxWidth: '100%', width: '50%', margin: 'auto', overflow: 'auto' }}>
          <ModalHeader toggle={()=>toggleModal1()} style={{ fontSize: "20px", textAlign: 'center' }}>
              <p style={{ fontSize: "25px", fontWeight: '600' }}>Moves transformation</p>
          </ModalHeader>

          <ModalBody style={{ padding: "25px" }}>

          <Select
          options={dropdownOptions}
          placeholder="Select percentage difference"
          onChange={(selectedOption) => {
            setSelectedTransaction(selectedOption?.value)
            
          }}

        />
          </ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1%' }}>
  
    
    <Button onClick={handleMovesTransaction} style={{ width: '20%',padding : '1.3%',marginRight:'2%', backgroundColor: '#50a4db' }}>
      Next
    </Button>
  </div>
      </Modal> */}
    {/* Your existing component content */}
    {/* For example, buttons or other elements triggering endLine */}
    {currentTemplateType1 && (currentTemplateType1 === 'mutuateVariable' ) && (
    <TemplateModal isOpen={isModalOpen} toggle={() => toggleModal()} currentId={currentId} type =  "Mutate Variable Widget"
    unique_widget_id={currentMutateId}
    resetMutateId={resetMutateId}
     />
  )}
{currentTemplateType1 && (currentTemplateType1 === 'formulaVariable')  && (
    <FormulaModal isOpen={isModalOpen} toggle={() => toggleModal()} currentId={currentId} unique_widget_id={currentFormulaId} type =  "Formula Variable Widget" />
  )}
  </div>
  </div>
  

//   }
//  </div>

);
}
export default CanvasArea;
