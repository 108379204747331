import { Grid } from "@mui/material";
import DoughnutCard from "./DoughnutCard";
import AreaCard from "./AreaCard";
import BarCard from "./BarCard";

function AdminDashboard() {
    return(
        <div>
        <Grid item container xs={12} md={12} sx={{marginTop: {xs: '20%',sm: '10%', md: '7%'}, color: '#000', fontSize: {xs: '24px', md: '40px'}, fontWeight: '500'}}>
            <p>Hello, Hava a good day !</p>
        </Grid>
        <Grid item container direction="row" spacing={3}>
            <Grid item container xs={12} md={4} justifyContent="center" >
                <DoughnutCard/>
            </Grid>
            <Grid item container xs={12} md={8} justifyContent="center">
                <AreaCard/>
            </Grid>
            <Grid item container xs={12} md={12} justifyContent="center">
                <BarCard/>
            </Grid>

        </Grid>
        </div>
    );
}

export default AdminDashboard;