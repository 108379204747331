import { Button, Grid, TextField } from "@mui/material";
import imgSrc from '../../Images/profilePic.jpg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import imgSrc2 from '../../Images/Rectangle 148.jpg'
import Select from "react-select";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import axios from "axios";
function TeamEdit() {
    // const location = useLocation()
//     const { teamData } = location?.state
// console.log(teamData)
const params = useParams()
const navigate = useNavigate()
const [viewTeamMember,setViewTeamMember] =  useState({})
const [file,setFile] = useState(null)
useEffect(()=>{
    
    const fetchTeamData = async ()=>{
        try{
          
            const accessToken = JSON.parse(localStorage.getItem('access-token'))
            if (!accessToken) {
                // Handle the case where the access token is not available
                return;
              }
        
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/view/team-member/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
        );
        
        console.log("35",response.data);
        setViewTeamMember(response?.data?.memeberData)
    
        }catch(error){
            console.error(error)
           
        }
        }
        
    fetchTeamData()
    },[])

    const [formData, setFormData] = useState({
        userName: "",
        address: "",
        email: "",
        country: "",
        companyName: "",
        startDate: "",
        department: "",
        designation: "",
        image : ""
    });
    // Update formData when viewTeamMember changes
useEffect(() => {
    setFormData({
        userName: viewTeamMember?.userName || "",
        address: viewTeamMember?.address || "",
        email: viewTeamMember?.email || "",
        country: viewTeamMember?.country || "",
        companyName: viewTeamMember?.companyName || "",
        startDate: viewTeamMember?.startDate || "",
        department: viewTeamMember?.department || "",
        designation: viewTeamMember?.designation || "",
        image : viewTeamMember?.image || "",

    });
}, [viewTeamMember]);

const handleSubmit =async ()=>{
try{
    const accessToken = JSON.parse(localStorage.getItem('access-token'));
  

  
    const response = await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/edit-profile/${params.id}`,
  
  {
    updateData:  formData,
    imageData : file
},
     
    {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
 navigate("/team")



}catch(error){
    console.log(error);
}

}
const [frontShow,setFrontShow] = useState()
const handleFileSelect = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    const uploadOptions = {
  cloud_name: "djhpptodq",
  api_key: "177433826987685",
  api_secret: "Ptvt0KXA1_7RErTdhmXbmkxSFNs",
      };
  

        // Upload the entire file for smaller files
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', "member-profile");
        formData.append('api_key', uploadOptions.api_key);
        formData.append("cloud_name",uploadOptions.cloud_name)
  
        const response = await fetch('https://api.cloudinary.com/v1_1/' + uploadOptions.cloud_name + '/image/upload', {
          method: 'POST',
          body: formData,
        });
  
        const data = await response.json();
      
        setFile(data.url); // Update state with uploaded image URL
        setFrontShow(data.url); // Assuming this is for displaying the image (optional)
    
    
  
}
const labelStyle = {
    position: "absolute",
    bottom: "20px", // Adjust the vertical position as needed
    left: "50%",
    transform: "translateX(-50%)",
    color: "black",
    display: "flex",
    marginTop:  '10%',
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
    border: "1px solid black",
    padding : "7px",
    borderRadius: "4px",
    cursor: "pointer",
    zIndex: 100, // Ensure the label is on top of the background image
  };
  // Handler function for changing the select value
  const handleSelectChange = (selectedOption) => {

    setFormData({ ...formData, designation: selectedOption?.label });
};
    return (
        <div>
            <Grid container direction="row" spacing={2} sx={{
                marginTop: {
                    xs: '25%',
                    sm: '15%',
                    md: '9%',
                  }
            }}>
            <Grid item xs={4} sm={8} lg={9}>
                <a href='/#/team' style={{ textDecoration:'none', color: '#000'}}>
                <ArrowBackIcon sx={{fontSize: '30px'}}/>
                </a>   
            </Grid>     
            {/* <Grid item xs={8} sm={4} lg={3} justifyContent={{ xs: 'center', md: 'end' }} textAlign={{ xs: 'center', md: 'end' }}>        
                <Button variant="contained" sx={{border: '1px solid #50A4DB ', background: '#50A4DB ', borderRadius: '8px', width: '100%',color: '#FFF', textTransform:'none', fontSize: '20px', fontWeight: '600'}}>Add in project</Button>
            </Grid> */}
            </Grid>
            <Grid container direction="row" spacing={2} sx={{marginTop: '3%'}}>
                <Grid item xs={12} md={4}>

                {
                frontShow ? (
   <div
   style={{
     display: "flex",
     flexDirection: "column",
     alignItems: "center",
     justifyContent: "center",
     width: "80%",
     height: "80%",
     backgroundImage: `url(${frontShow})`,
     backgroundSize: "contain",
     backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     borderRadius: "4px",
     position: "relative",
     cursor: "pointer",
     paddingBottom: "80%",
     opacity: 1.2, // Lower opacity of the background image
   }}
 >
<label htmlFor="fileInput" style={{ ...labelStyle }}>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => handleFileSelect(e)}
    style={{ display: "none" }}
    id="fileInput"
  />
  <span style={{ color: "#FFF", fontSize: "16px", textAlign: 'center' }}>Update Profile</span>
</label>


    </div>
  )
  :
  (
                <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
     // Adjust the height as needed
     width : '80%',
     height : '80%',
      backgroundImage: `url(${formData?.image})`,
    //   backgroundImage: `url(${imgSrc})`,
      backgroundSize: "contain",
      backgroundPosition: "center", // Center the background image
      backgroundRepeat: "no-repeat",
      borderRadius: "4px",
      position: "relative",
      cursor: "pointer",
      paddingBottom: "80%", // Set a padding-bottom to maintain aspect ratio
    }}
  >
    <label htmlFor="fileInput" style={labelStyle}>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleFileSelect(e)}
        style={{ display: "none" }}
        id="fileInput"
      />
    
      <span style={{ color: "black", fontSize: "16px",textAlign:'center' }}>
        Change Profile
      </span>
    </label>
  </div>
)
}
            
                </Grid>
                <Grid item xs={12} md={4}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '20px'}}>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >User Name</h3>
                   
                    <TextField
    id="outlined-helperText"

    value={formData.userName}  // Fix the typo here
    name="userName"
    onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
    sx={{
        width: '100%',
        '& #outlined-helperText': {
            fontSize: '20px',
            // Add other styles as needed
        },
    }}
/>

                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >E-mail</h3>
                    <TextField
                        id="outlined-helperText"
                        value = {formData.email}
                        name="email"
                        onChange={(e)=>setFormData({...formData,email : e.target.value})}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Company Name</h3>
                    <TextField
                        id="outlined-helperText"
                        value = {formData.companyName}
                        name="companyName"
                        onChange={(e)=>setFormData({...formData,companyName : e.target.value})}
                        // defaultValue={teamData.companyName}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Department</h3>
                    <TextField
                        id="outlined-helperText"
                        value = {formData.designation}
                        name="designation"
                        onChange={(e)=>setFormData({...formData,designation : e.target.value})}
                        // defaultValue={teamData.designation}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div>
                    {/* <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Start Date</h3>
                    <TextField
                        id="outlined-helperText"
                        value = {formData.startDate}
                        name="startDate"
                        onChange={(e)=>setFormData({...formData,startDate : e.target.value})}
                        // defaultValue={teamData.userName && "N/A"}
                        sx={{width: '100%', '& #outlined-helperText': {
                            fontSize: '20px',
                            // Add other styles as needed
                          },}}
                    />
                    </div> */}
                </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Address</h3>
                        <TextField
                            id="outlined-multiline-static"
                            
                            multiline
                            rows={6}
                            defaultValue = {formData.address}
                        name="address"
                        onChange={(e)=>setFormData({...formData,address : e.target.value})}
                            // defaultValue={teamData?.address && "N/A"}
                            sx={{width: '100%', '& #outlined-multiline-static': {
                                fontSize: '20px',
                                lineHeight: '2.2rem'
                                // Add other styles as needed
                              },}}
                        />
                        </div>

                    
                    <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Country</h3>
                        <TextField
                            id="outlined-helperText"
                            value = {formData.country}
                        name="country"
                        onChange={(e)=>setFormData({...formData,country : e.target.value})}
                            // defaultValue={teamData.country}
                            sx={{width: '100%', '& #outlined-helperText': {
                                fontSize: '20px',
                                // Add other styles as needed
                              },}}
                        />
                    </div>
                    <div>
                    <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Designation</h3>
                    <Select
                    options={[
                        {
                            label : 'teamMember',
                            value : 'teamMember'
                        },
                        {
                            label : 'teamManager',
                            value : 'teamManager'
                        },
                    ]}
                        id="outlined-helperText"
                      
                        value={{ value: formData.designation, label: formData.designation }}
                        name="designation"
                        onChange={handleSelectChange}
                    
                                                sx={{width: '100%'}}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            fontSize: '20px', // Set font size for the input field
                            fontWeight: '400',
                            // borderWidth: '2px', // Increase border size
                            padding: '10px 0px 10px 0px' // Add more padding
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: '20px', // Set font size for the input field
                            fontWeight: '400',
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: '20px', // Set font size for the input field
                            fontWeight: '400',
                          })
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            neutral10: 'grey',
                            primary: '#50a4db',
                          },
                        })}
                    />
                    </div>
                    
                    </div>
                </Grid>
                <Grid
        item
        xs={12}
        md={12}
        container
        justifyContent="end"
        alignItems="end"
      >
  <Button
  sx={{
    textTransform: "none",
    border: "1px solid #50A4DB ",
    borderRadius: "4px",
    width: "25%",
    color: "#FFF",
    background: "#50A4DB ",
    marginTop: "3%",
  
    fontSize: "20px",
  }}
  variant="contained"
  onClick={(e) => {
    e.preventDefault();
    handleSubmit();
  }}
>
  Confirm
</Button>

      </Grid>
            </Grid>
            {/* <Grid item xs={12} md={12}>
                <p style={{color: '#AFAFAF', fontSize: '20px', fontWeight: '400', marginTop: '5%'}}>Projects</p>
            </Grid>
            <Grid container direction="row" spacing={2} >
                <Grid item xs={12} sm={6} md={3} sx={{marginTop: {xs: '2%'}}}>
                    <img src={imgSrc2} alt="" style={{width: '100%', height: '100%'}}/>
                    <p style={{color: '#000', fontWeight: '500', fontSize: '18px'}}>Project Name</p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{marginTop: {xs: '2%'}}}>
                    <img src={imgSrc2} alt="" style={{width: '100%', height: '100%'}}/>
                    <p style={{color: '#000', fontWeight: '500', fontSize: '18px'}}>Project Name</p>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{marginTop: {xs: '2%'}}}>
                    <img src={imgSrc2} alt="" style={{width: '100%', height: '100%'}}/>
                    <p style={{color: '#000', fontWeight: '500', fontSize: '18px'}}>Project Name</p>
                </Grid>
            </Grid> */}
            
        </div>
    )
}

export default TeamEdit;