// App.js
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container, Row, Col, Button } from 'reactstrap';
import CustomNavbar from './components/Navbar';
import RightSidebar from './components/RightSidebar';
import LeftSidebar from './components/LeftSidebar';
import CanvasArea from './components/Canvas';
import Dashboard from './components/Dashboard';
import "./index.css"
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from 'react-redux';
import ChatBot from './components/ChatBot';

function Canvas() {

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false); 
  const [showSimulationOptions, setShowSimulationOptions] = useState(false);
  // const [showRightSidebar, setShowRightSidebar] = useState(true); 
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const leftSidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);

// const {openRightSidebar} = useSelector(state => state.canvas)  
// console.log('openRightSidebar',openRightSidebar)
  // Set the initial state of showRightSidebar based on selectedOption
  const [showRightSidebar, setShowRightSidebar] = useState(true)

 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLeftSidebar && window.innerWidth <= 1333 && leftSidebarRef.current && !leftSidebarRef.current.contains(event.target)) {
        setShowLeftSidebar(true);
      }
      if (showRightSidebar && window.innerWidth <= 1333 && rightSidebarRef.current && !rightSidebarRef.current.contains(event.target)) {
        setShowRightSidebar(true);
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLeftSidebar, showRightSidebar]);
  
  useEffect(() => {
    // Call the toggleLeftSidebar function when the component mounts
   
    toggleLeftSidebar()
  }, []); 

  const handleResize = () => {
    
    if (window.innerWidth <= 1333) {
      setShowRightSidebar(true);
      setShowLeftSidebar(true);
    } else {
      setShowRightSidebar(true);
      setShowLeftSidebar(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleRightSidebar = () => {
    // setShowRightSidebar(!showRightSidebar); 
    setShowRightSidebar(!showRightSidebar); 
  };
  const toggleRightSidebarTrue = () => {
    // setShowRightSidebar(!showRightSidebar); 
    setShowRightSidebar(true); 
  };
  const toggleLeftSidebar = () => {
    setShowLeftSidebar(!showLeftSidebar);
  };
  const handleSimulationSelect = () => {
    setShowDashboard(false);
    setShowSimulationOptions(true);
  };
  const handleDashboardSelect = () => {
    setShowDashboard(true);
    setShowSimulationOptions(false);
  };

  const handleCanvasSelect = () => {
    setShowDashboard(false);
    setShowSimulationOptions(false);
  };
  const sidebarStyle1 = {//leftsidebar
    backgroundColor: '#ffffff',
    // boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.2)', 
    // height: '  vh' 
    height: '110vh',
    
  };
  
  const sidebarStyle2 = {
    backgroundColor: '#E7E7E7',
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.2)',
    height: 'auto', 
    
    // marginBottom: '5%',
    // right :'0', 
    // position : 'fixed',
    // overflowY : 'auto'
  };
                                                                                                                                                    
  const sidebarStyle3 = {
    backgroundColor: '#E7E7E7',
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.2)',
    height: 'auto', 
  // position: 'relative',
    // marginBottom: '2%',
    // right :'0',
    // position : 'fixed',
    // overflowY : 'auto'
  };
                                                                                                                                                    
                                                                                  
  

const leftSidebarClass = showLeftSidebar ? 'left-sidebar visible' : 'left-sidebar';
const rightSidebarClass = showRightSidebar ? 'right-sidebar visible' : 'right-sidebar';
const canvasAreaClass = showLeftSidebar || showRightSidebar ? 'canvas-area darkened' : 'canvas-area';
const dashboardClass = showLeftSidebar || showRightSidebar ? 'canvas-area darkened' : 'dashboard';



  const handleTemplateSelect = (template, event) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(template));
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
<div>


 <DndProvider backend={HTML5Backend}>
        <Container fluid  style={{ paddingTop: '72px' }}>
        
     <Row>
          <Col md="12" sm="12"  >
            <CustomNavbar  />
          </Col>
        </Row>
 
    
        <Row>
       
         {showLeftSidebar && (
          
    <Col md="2" sm="3" xs="3" xl="1" lg="2"  style={sidebarStyle1}  className={leftSidebarClass}>
    <div ref={leftSidebarRef}>
      <LeftSidebar
        onTemplateSelect={handleTemplateSelect}
        onDashboardSelect={handleDashboardSelect}
         onSimulationSelect={handleSimulationSelect}
         onCanvasSelect={handleCanvasSelect} 
       
      /></div> 
    </Col>
   
  )}
         
          {showDashboard ? (
            <Col xl = {showLeftSidebar ? "11"  : "12"}  className={dashboardClass}>
              <Dashboard  toggleLeftSidebar={toggleLeftSidebar} sidebarVisible={showLeftSidebar} />
            </Col>
          ) : (
            <>
              <Col md={showLeftSidebar ? (showRightSidebar ? "9" : "12") : (showRightSidebar ? "10" : "12")} 
               sm ={showLeftSidebar ? (showRightSidebar ? "9" : "12") : (showRightSidebar ? "10" : "12")} 
               lg ={showLeftSidebar ? (showRightSidebar ? "9" : "12") : (showRightSidebar ? "10" : "12")} 
               xl ={showLeftSidebar ? (showRightSidebar ? "9" : "11") : (showRightSidebar ? "10" : "12")} 
              style={{ paddingLeft: "0px" }} className={canvasAreaClass}>
                <CanvasArea
                  selectedTemplate={selectedTemplate}
                  toggleRightSidebar={toggleRightSidebar}
                  isSidebarVisible={showRightSidebar}
                  toggleRightSidebarTrue = {toggleRightSidebarTrue}
                  toggleLeftSidebar={toggleLeftSidebar}
                  isSidebar2Visible={showLeftSidebar}
                />
                
              </Col>
              
              {showRightSidebar && (
  
    <Col md="5" sm="6" xs="7" xl="2" lg="4" style={showSimulationOptions ? sidebarStyle3 : sidebarStyle3} className={rightSidebarClass}>
    <div ref={rightSidebarRef}>
     
      <RightSidebar
        showSimulationOptions={showSimulationOptions}
        isSidebarVisible={showRightSidebar}
      />
<div>
        

        </div>

      </div>
      
     
    </Col>
  
)}
<div style={{marginTop : '4%',backgroundColor:'#E7E7E7'}}>
                <ChatBot  />
                </div>

            </>
          )}     
        </Row>
      </Container>
    </DndProvider>
  
    

</div>
  );
}

export default Canvas;