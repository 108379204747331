import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Grid } from '@mui/material';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const data = {
  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: 'Data Set 1',
      data: [20, 40, 30, 50, 25, 35, 46],
      backgroundColor: [
        'rgba(80, 164, 219, 0.3)', // Light blue color
        'rgba(80, 164, 219, 0.3)',
        'rgba(80, 164, 219, 0.3)',
        'rgba(80, 164, 219, 0.3)', // Light blue color
        'rgba(80, 164, 219, 0.3)',
        'rgba(80, 164, 219, 0.3)',
        'rgba(80, 164, 219, 0.3)', // Light blue color
       
      ],
    },
  ],
};


  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
        legend: {
          display: false,
          
        },
      },
  };

  export default function VerticalBarChart() {
    return (
      <Grid item direction="row" container sx={{width: '100%', height: '270px', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)'}}>
        <div style={{
                  color: '#000',
                  fontSize: '24px',
                  fontWeight: '500',
                  textAlign: 'center',
                  marginLeft: '10px',
                  marginTop: '10px'
                }}>
          <p>Activity Report</p>
        </div>
        <Grid item xs={12} md={12} sx={{width: '70%', height: '80%', position: 'relative',marginTop:'2%', bottom: {xs: '15px', md: '12px'}}} container justifyContent="center" alignItems="center">
        <Bar data={data} options={options} />
        </Grid>
      </Grid>
    );
  }
  