import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Grid, TextField } from '@mui/material';

import './AddClient.css'

export default function ViewClient({clientData}) {
console.log(clientData)
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 const LoggedInAs = 'Team Manager' || '' || 'Team Member';
 // Function to format date string

 function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding leading zeros if necessary
  const day = String(date.getDate()).padStart(2, '0'); // Adding leading zeros if necessary

  return `${year}-${month}-${day}`;
   
      
 
}



   const [clientDatas,setClientDatas] = React.useState({
   holderName : clientData?.userName,
   holderEmail :clientData?.clientData?.email,
   timePeriod : clientData?.clientData?.interval,
   startDate: formatDate(clientData?.clientData?.date),
   endDate: formatDate(clientData?.clientData?.endDate)
  })



  return (
    <React.Fragment>
      <Button className='detail-button'
           sx={{
            color: '#AFAFAF',
            fontSize: '20px',
            fontWeight: '400',                 
            textTransform: 'none',
           }}
           
           
            onClick={handleClickOpen}
            disabled={LoggedInAs === ''}
          >
            View Details
          </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        PaperProps={{
          sx: {
            
            maxWidth: 'md', // You can use 'sm', 'md', 'lg', 'xl', or a specific pixel value
            position: 'relative',
            overflowY: 'scroll',
            
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              
                <Grid item xs={12} md={12} >                  
                      <Button sx={{textDecoration:'none', color: '#000'}} autoFocus onClick={handleClose}>
                          <ArrowBackIcon sx={{fontSize: '30px'}}/>
                      </Button>                  
                </Grid>                
           
                <Grid item justifyContent="center" container direction="row" spacing={4} sx={{marginTop: '1%'}} >
                    
                    <Grid item xs={12} md={5} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Subscription holder name</p>
                        <TextField
                        sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                        inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                        id="outlined-basic"  variant="outlined" fullWidth value = {clientDatas.holderName}/>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p>Subbscription holder E-mail Id</p>
                        <TextField 
                        sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                        inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                        id="outlined-basic" fullWidth variant="outlined" value = {clientDatas.holderEmail} />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Time Period for Subscription</p>
                        <TextField 
                        sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                        inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                        id="outlined-basic" fullWidth variant="outlined" value = {clientDatas.timePeriod}/>
                    </Grid>
                    <Grid item xs={12} md={2} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Start Date</p>
                        <TextField
                        sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                        // inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                        id="outlined-basic" type="date" fullWidth variant="outlined" 
                           inputProps={{ defaultValue: clientData.startDate }} // Example default date, format: yyyy-mm-dd
                           InputLabelProps={{
                               shrink: true,
                           }}
                        />                  
                    </Grid>

                    <Grid item xs={12} md={2} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >End Date</p>
                        <TextField
                        sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                        // inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here

                        id="outlined-basic" type="date" fullWidth variant="outlined" 
                          // inputProps={{ defaultValue: '2025-08-22' }} // Example default date, format: yyyy-mm-dd
                          InputLabelProps={{
                              shrink: true,
                          }}
                        />
                    </Grid>

                    
                </Grid>

                <Grid item xs={12} md={12} sx={{marginTop: '4%', color: '#000', fontSize: '24px', fontWeight: '500', paddingLeft: '8%'}}>
                  <p >Features</p>
                </Grid>
              
                
                
                <Grid item container direction="row" sx={{fontSize: {md: '20px', xs: '10px'}, fontWeight: '400', color: '#000'}}>
                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%',  }}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%'}}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%',}}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%'}}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                   <p>Lorem ipsum dolor sit amet consectetur</p>
                </Grid>
                </Grid>
                              
                          
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
          <Button className='button_size' variant='contained' onClick={handleClose} autoFocus sx={{
            width: '20%',
            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '600',
            textTransform: 'none',     
            marginRight: '5%'
            
          }}>
            Edit Plan
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
