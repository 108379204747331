// ChartComponent.js

import { Grid } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';


class AreaCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    
      series: [{
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100]
      }],
      options: {
        chart: {
          height: 300,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
    
    
    };
  }



  render() {
    return (
      

    <div id="chart" style={{border: '1px solid #D9D9D9', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)', width: '100%'}}>
      <Grid item container xs={12} md={12}  sx={{color: '#000', fontSize: '24px', fontWeight: '600', paddingLeft: '3%', paddingTop: '1%'}}>
            <p>Visitor Analysis</p>
        </Grid>
   
    
    <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={200} />
    
    </div>


    );
  }
}
export default AreaCard;
