import { Box, Button, Grid, Typography } from "@mui/material";
import imgSrc from '../../Images/loginImage.png'
import InputWithIcon from "../../components/InputField/InputField";
import imgSrc2 from '../../Images/LoginPoly.png';
import imgSrc3 from '../../Images/LoginPoly3.png';
import imgSrc4 from '../../Images/LoginPoly4.png';
import imgSrc5 from '../../Images/LoginPoly2.png';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import './ForgotPassword.css'
import { useState } from "react";

function ResetPassword() {
    const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  
  const currentUrl = window.location.href;

// Find the position of the token parameter in the URL
const tokenIndex = currentUrl.indexOf('token=');

// If the token parameter is found
let token = "";
if (tokenIndex !== -1) {
  // Extract the token value
  token = currentUrl.substring(tokenIndex + 6);

  // Log or use the token as needed
  console.log(token);
} else {
  console.error('Token parameter not found in the URL');
}
//   console.log(token);
//   console.log(urlSearchParams);
//   console.log(window.location);

 

  const handleSubmit = async (e) => {
    e.preventDefault();

     if (newPassword !== confirmPassword) {
    // Passwords don't match, handle accordingly (e.g., show an error message)
    console.error('Passwords do not match');
    return;
  }

    try {
      // Make a request to your backend to handle the password reset
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/resetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token, newPassword, confirmPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        // Handle success, e.g., show a success message to the user
        console.log('Password reset successful:', data.message);
        setNewPassword("")
        setConfirmPassword("")
        navigate('/home-page')
      } else {
        const errorData = await response.json();
        // Handle errors, e.g., display an error message to the user
        console.error('Password reset failed:', errorData.message);
      }
    } catch (error) {
      console.error('Error during password reset:', error);
    }
  };
    return (
        <Box >
            
        <Grid container direction="row" spacing={2}>
            <Grid item xs={4} md={4}>
                <div style={{position: 'relative', top : '25px',left: '8%'}}>
                <NavLink to="/">
                    <img src="/assets/image4.jpg" alt="" />
                </NavLink>
                </div>
            </Grid>

            <Grid item xs={4} md={4}>
                <div>
                    <img style={{width: '60%'}} src={imgSrc3} alt="" />
                </div>            
            </Grid>  

            <Grid item xs={4} md={4} container alignItems="end" justifyContent="end">
                <img style={{width: '40%'}} src={imgSrc4} alt="" />
            </Grid>         
        </Grid>

        <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{position: 'relative', bottom: {md: '100px'}}}>
            <Grid item xs={12} md={6} justifyContent="center" container alignItems="center">
                <img  className="hide-on-sm"  style={{height: '500px', width: '500px'}} src={imgSrc} alt="" />
            </Grid>

            <Grid item container xs={12} md={6} justifyContent="center" textAlign="center">                
                
            <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{marginTop: {xs: '5%'},color: '#000',fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '700',}}>
                <p>
                Create New Password
               </p>
            </Grid>
            <Grid item container xs={12} md={12} justifyContent="center" textAlign="center">
            <Typography sx={{
                color: '#000',
                fontSize: '20px',
                fontWeight: '500',
                paddingLeft: '15%',
                paddingRight: '15%',
                marginTop: '2%',
                lineHeight: 'normal'
            }}>
                Please create your new password
            </Typography>
            </Grid>
            <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%'}}>
                <InputWithIcon label='Password' type='password' icon={<RiLockPasswordLine size={30}/>} value={newPassword}
          onChange={(e) => {setNewPassword(e.target.value)}}/>
            </Grid>
            <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%'}}>
                <InputWithIcon label='Confirm Password' type='password' icon={<RiLockPasswordLine size={30}/>} value={confirmPassword}
          onChange={(e) => {setConfirmPassword(e.target.value)}}/>
            </Grid>
            <Grid item container xs={8} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%'}}>
                <Button type="submit" onClick={handleSubmit}  className="request-link-size" sx={{ fontSize: '30px', fontWeight: '500', borderRadius: '24px', textTransform: 'none', backgroundColor: '#50A4DB ', padding: '10px 0px 10px 0px', width: {md: '60%', xs: '90%'},}} variant="contained">Set New Password</Button>
            </Grid>
            <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%',fontSize:{md: '24px', xs: '18px'}, fontWeight: '400',  textDecoration: 'underline', color: '#50A4DB '}}>
               
                <a style={{color: '#50A4DB '}} href="/#/login">Back to Login</a>
            
            </Grid> 
            </Grid>

        </Grid>

        <Grid container direction="row">
            <Grid item xs={6} md={4}>
                <img style={{width: '30%'}} src={imgSrc2} alt="" />

            </Grid>
            <Grid item xs={6} md={4} container justifyContent="end">
                <img style={{width: '60%'}} src={imgSrc5} alt="" />
                
            </Grid>
        </Grid>
            
        

        
    </Box>       
    )

}

export default ResetPassword;