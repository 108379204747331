import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import videoUrl from '../../Images/geruvideo.mp4';
export default function ActionAreaCard2({image}) {
  return (
    <Card
     sx={{ width: '369px', height: '449px', borderRadius: '8px', backgroundColor: '#FFF', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', marginTop: '30px' }}>
      <CardActionArea>
        <CardMedia
          component="video"
          height="276"
          width="379"
          controls
          // autoPlay
          loop
          muted
          sx={{ borderRadius: '8px' }}
          src={videoUrl}
        />
        <CardContent>
          <Typography sx={{
            color: '#000',
            fontSize: '32px',
            fontStyle: 'normal',
            fontWeight: '400',
          }}>
            Title
          </Typography>
          <Typography sx={{
            color: '#000',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
          }} >
            Description
          </Typography>
          <Typography sx={{
            // color: '#AFAFAF',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            marginTop: '3%'
          }}>
          Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo 
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
