import React, { useEffect } from 'react';
import { Route, Routes, Outlet, HashRouter, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/LandingPage/Home';
import Tutorial from './pages/LandingPage/Tutorial';
import Pricing from './pages/LandingPage/Pricing';
import Work from './pages/LandingPage/Work';
import ResponsiveAppBar from './components/ResponsiveAppBar/ResponsiveAppBar';
import SignUp from './pages/LandingPage/SignUp';
import Login from './pages/LandingPage/Login';
import ForgotPassword from './pages/LandingPage/ForgotPassword';
import HomePage from './pages/HomePage/HomePage';
import ProjectDetail from './components/HomePage/ProjectDetail';
import Dashboard from './pages/HomePage/Dashboard';
import Grid from './pages/HomePage/Grid';
import Member from './pages/HomePage/Member';
import ProjectDet from './pages/HomePage/ProjectDet';
import Profile from './pages/HomePage/Profile';
import ProfileEdit from './pages/HomePage/ProfileEdit';
import Team from './pages/HomePage/Team';
import TeamEditView from './pages/HomePage/TeamEditView';
import TeamViews from './pages/HomePage/TeamViews';
import SubscriptionPage from './pages/HomePage/SubscriptionPage';
import UpgradeSubs from './pages/HomePage/UpgradeSubs';
import PaymentScreen from './pages/HomePage/PaymentScreen';
import PayConfirmPage from './pages/HomePage/PayConfirmPage';
import PayCancelPage from './pages/HomePage/PayCancelPage';
import Canvas from './pages/canvas';

import './index.css';
import TeamManagerLogin from './pages/TeamManager/TeamManagerLogin';
import TeamManagerLogin2 from './pages/TeamManager/TeamManagerLogin2';
import TeamManagerHome from './pages/TeamManager/TeamManagerHome';
import AdminLogin from './pages/Admin/AdminLogin';
import AdminForgotPassword from './pages/Admin/AdminForgotPassword';
import Ad_Dashboard from './pages/Admin/Ad_Dashboard';
import AdminClient from './pages/Admin/AdminClient';
import AdminVariable from './pages/Admin/AdminVariable';
import Ad_Subscription from './pages/Admin/Ad_Subscription';
import AdminClientPlan from './pages/Admin/AdminClientPlan';
import ResetPassword from './pages/LandingPage/ResetPassword';
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
import { loadUser } from './Actions/authAction';
import { clearAllErrors } from './Reducers/userReducers';
import NotFound from './pages/HomePage/NotFound';
import PlanPayment from './pages/LandingPage/PlanPayment';
import Member2 from './pages/HomePage/Member2';


const AuthProvider = ({ children }) => {
  const location = useLocation(); // Move inside the Router component
  const navigate = useNavigate();
  const { isAuthenticated, loading, user } = useSelector((state) => state.user);
  console.log("isAuthenticated", isAuthenticated)

  useEffect(() => {

    if (isAuthenticated) {

      if (
        location.pathname === '/' ||
        location.pathname === '/tutorial' ||
        location.pathname === '/pricing' ||
        location.pathname === '/work' ||
        location.pathname === '/login' ||
        location.pathname === '/signUp' ||
        location.pathname === '/forgot-password' ||
        location.pathname === '/reset-password' ||
        location.pathname === '/team-login'

      ) {

        navigate('/home-page');
        //  navigate('/plan-payment');


      }

      if (location.pathname === '/admin-login') {
        user.role === 'admin' && navigate('/admin-dashboard')
      }
    }

    else if (isAuthenticated === false) {

      if (

        location.pathname === '/home-page' ||
        location.pathname === '/project-detail' ||
        location.pathname === '/home-dashboard' ||
        location.pathname === '/home-grid' ||
        location.pathname === '/project-member' ||
        location.pathname === '/project-det' ||
        location.pathname === '/home-profile' ||
        location.pathname === '/home-profile-edit' ||
        location.pathname === '/team' ||
        location.pathname === '/team-edit' ||
        location.pathname === '/team-view' ||

        location.pathname === '/subscription' ||
        location.pathname === '/upgrade-subscription' ||
        location.pathname === '/payment' ||
        location.pathname === '/payment-comfirm' ||
        location.pathname === '/payment-cancel' ||
        location.pathname === '/canvas' ||
        location.pathname === '/admin-dashboard' ||
        location.pathname === '/admin-client' ||
        location.pathname === '/admin-variable' ||
        location.pathname === '/admin-subscription' ||
        location.pathname === '/admin-client-plan'

      ) {

        if (location.pathname.startsWith('/admin')) {

          navigate('/admin-login');
        } else {

          navigate('/login');

        }


      }

    }
  }, [isAuthenticated, location.pathname, navigate]);

  return <>{children}</>;
};



function App() {

  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(loadUser())
    dispatch(clearAllErrors())
  }, [dispatch])



  return (

    <HashRouter>

      <AuthProvider>
        <Toaster />


        <Routes>
          <Route
            path="/"
            element={
              <>
                <ResponsiveAppBar />
                <Outlet />
              </>
            }
          >
            <Route path="/" element={<Home />} />
          
            <Route path="/tutorial"  element={<Tutorial />} />
          
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/work" element={<Work />} />

            <Route path="/signUp" element={<SignUp />} />
          <Route path="/signUp/:planName/:id" element={<SignUp />} />

          <Route path="/login" element={<Login />} />
          </Route>

          <Route path='/plan-payment/' element={<PlanPayment />} />
{/* 
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signUp/:planName/:id" element={<SignUp />} />

          <Route path="/login" element={<Login />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />

          <Route path='/home-page' element={<HomePage />} />
          <Route path='/project-detail' element={<ProjectDetail />} />
          <Route path='/home-dashboard' element={<Dashboard />} />
          <Route path='/home-grid' element={<Grid />} />
          <Route path='/project-member/:id' element={< Member />} />
          <Route path='/add-member' element={< Member2 />} />

          <Route path='/project-det/:id' element={<ProjectDet />} />
          <Route path='/home-profile' element={<Profile />} />
          <Route path='/home-profile-edit' element={<ProfileEdit />} />
          <Route path='/team' element={<Team />} />
          <Route path='/team-view/:id' element={<TeamViews />} />
          <Route path='/team-edit/:id' element={<TeamEditView />} />

          <Route path='/subscription' element={<SubscriptionPage />} />
          <Route path='/upgrade-subscription' element={<UpgradeSubs />} />
          <Route path='/payment' element={<PaymentScreen />} />
          <Route path='/payment-confirm' element={<PayConfirmPage />} />
          <Route path='/payment-cancel' element={<PayCancelPage />} />




          <Route path='/canvas/:id' element={<Canvas />} />
          <Route path='/team-login' element={<TeamManagerLogin />} />
          <Route path='/team-manager-login2' element={<TeamManagerLogin2 />} />
          <Route path='/team-manager-home' element={<TeamManagerHome />} />
          {/* //Admin */}
          <Route path='/admin-login' element={<AdminLogin />} />
          <Route path='/admin-forgot-password' element={<AdminForgotPassword />} />
          <Route path='/admin-dashboard' element={<Ad_Dashboard />} />
          <Route path='/admin-client' element={<AdminClient />} />
          <Route path='/admin-variable' element={<AdminVariable />} />
          <Route path='/admin-subscription' element={<Ad_Subscription />} />
          <Route path='/admin-client-plan' element={<AdminClientPlan />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {/* {process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PUBLIC_BASE_URL : process.env.REACT_APP_PRO_MODE} */}
      </AuthProvider>
    </HashRouter>

  );
}

export default App;
