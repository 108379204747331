import React, { useState } from 'react';
import "../index.css"
import { FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

const VariableModal = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sexDropdownOpen, setSexDropdownOpen] = useState(false);
    const [databaseVarDropdownOpen, setDatabaseVarDropdownOpen] = useState(false);
  const [selectedDatabaseVar, setSelectedDatabaseVar] = useState('');
    const [selectedAge, setSelectedAge] = useState(""); 
    const [selectedSex, setSelectedSex] = useState('');
  const toggleSexDropdown = () => setSexDropdownOpen(prevState => !prevState);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleDatabaseVarDropdown = () => setDatabaseVarDropdownOpen(prevState => !prevState);

  const handleDatabaseVarSelect = (variable) => {
    setSelectedDatabaseVar(variable);
  }

  const handleSexSelect = (sex) => {
    setSelectedSex(sex);
  }
    const handleAgeSelect = (age) => {
      setSelectedAge(age); 
    }
  
  return (
    <div>
      <h6 style={{fontSize:"16px" ,fontWeight:"600", marginBottom:"20px"}}>Preset Variable</h6>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        {/* for sex*/}
    <FormGroup style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
    <Label check style={{ marginRight: '12px', fontSize: '16px'  ,  whiteSpace: 'nowrap'}}>
        <Input type="checkbox" style={{ marginRight: '7px' }} />
        Sex
    </Label>
    <Dropdown isOpen={sexDropdownOpen} toggle={toggleSexDropdown} className="custom-dropdown1">
        <DropdownToggle caret className="custom-dropdown-toggle" 
            style={{  
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '16px',
                width: '100px', 
                height: '31px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>
            {selectedSex || <span style={{ visibility: 'hidden' }}>&nbsp;</span>}
        </DropdownToggle>
        
        <DropdownMenu style={{ maxWidth: '100px !important' }}> 
            <DropdownItem onClick={() => handleSexSelect('Male')}>Male</DropdownItem>
            <DropdownItem onClick={() => handleSexSelect('Female')}>Female</DropdownItem>
        </DropdownMenu>
    </Dropdown>
</FormGroup>


      {/* Input for Male */}
<FormGroup style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
  <Label for="maleInput" className="mr-2" style={{ fontSize: "16px", marginBottom: '0' , marginRight: '10px' }}>Male</Label>
  <Input type="text" name="male" id="maleInput" placeholder=""  style={{ fontSize: '16px', width: '70px' ,height:"30px"}} />
</FormGroup>

{/* Input for Female */}
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label for="femaleInput" className="mr-2" 
  style={{ fontSize: "16px", marginBottom: '0' ,marginRight: '10px' }}>Female</Label>
  <Input type="text" name="female" id="femaleInput" placeholder="" 
  style={{ fontSize: '16px', width: '70px' ,height:"30px"}} />
</FormGroup>

      </div>
     {/* Age and its dropdown */}
     <FormGroup style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <Label check style={{ marginRight: '9px', fontSize: '16px' }}>
        <Input type="checkbox" style={{ marginRight: '7px' }} />
        Age
      </Label>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} className="custom-dropdown1">
      <DropdownToggle 
  caret 
  className="custom-dropdown-toggle" 
  style={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    fontSize: '16px', 
    width: '170px', 
    height: '33px', 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }}
>
  {selectedAge || <span style={{ visibility: 'hidden' }}>&nbsp;</span>}
</DropdownToggle>

          <DropdownMenu>
            <DropdownItem onClick={() => handleAgeSelect('10-12')}>10-12</DropdownItem>
            <DropdownItem onClick={() => handleAgeSelect('12-10')}>12-10</DropdownItem>
            <DropdownItem onClick={() => handleAgeSelect('20-30')}>20-30</DropdownItem>
          </DropdownMenu>
        </Dropdown>

    </FormGroup>

{/* Online Consumption and its input */}
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>
{/* database Variable */}
<FormGroup style={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginBottom: '20px' }}>
        <Label for="databaseVariable" className="mr-2"
         style={{ fontSize: "16px", fontWeight: "600", marginBottom: '0', marginRight: '43px' }}>
          Database Variable
        </Label>
        <Dropdown isOpen={databaseVarDropdownOpen} toggle={toggleDatabaseVarDropdown} className="custom-dropdown1">
          <DropdownToggle caret className="custom-dropdown-toggle" 
          style={{   display: 'flex', 
    justifyContent: 'space-between',  overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
    alignItems: 'center', fontSize: '16px', width: '170px', height: '31px' }}>
            {selectedDatabaseVar || <span style={{ visibility: 'hidden' }}>&nbsp;</span>}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => handleDatabaseVarSelect('10-12')}>10-12</DropdownItem>
            <DropdownItem onClick={() => handleDatabaseVarSelect('12-10')}>12-10</DropdownItem>
            <DropdownItem onClick={() => handleDatabaseVarSelect('20-30')}>20-30</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </FormGroup>
  <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>
{/* //createvariable */}
 <h6 style={{fontSize:"16px" ,fontWeight:"600", marginBottom:"20px"}}>Preset Variable</h6>
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>
<FormGroup style={{ display: 'flex', alignItems: 'center' }}>
  <Label check style={{ marginRight: '10px', fontSize: '16px', marginRight: '10px'  }}>
    <Input type="checkbox" style= {{marginRight: '7px' }}/>
    Online Consumption
  </Label>
  <Input 
  style={{ fontSize: '16px', width: '170px' ,height:"31px"}} 
    type="text" 
    name="consumption" 
    id="consumption" 
    placeholder="" 
  
  />
</FormGroup>

<Button color="primary" style={{ float: 'right'  ,height:" 40px" ,width:"170px" ,fontSize:"16px"}}>Confirm Variables</Button>
    </div>
  );
};

export default VariableModal;
