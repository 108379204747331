import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './Container4.css';
import BasicCard from '../Card2/Card2';

export default function SimpleContainer4() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{marginTop: '6%'}}>
        <Box>
          <h4 id='heading5'>How are we different</h4>
          <Grid container spacing={2} justifyContent="center" textAlign="center" sx={{position: 'relative', bottom: '120px'}}>
            <Grid item xs={12} sm={12} md={6} container justifyContent="center" textAlign="center">
              <BasicCard number='1' />
            </Grid>
            <Grid item xs={12} sm={12} md={6} container justifyContent="center" textAlign="center">
              <BasicCard number='2' />
            </Grid>
            <Grid item xs={12} sm={12} md={6} container justifyContent="center" textAlign="center" sx={{position: 'relative', bottom: '70px'}}>
              <BasicCard number='3' />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
