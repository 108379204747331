import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Link, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { addMemberInProject } from '../../Reducers/memberReducer';
import axios from 'axios';
import Select from 'react-select';

import toast from 'react-hot-toast';
function AddMember() {

    const params = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
   const projectID = params?.id
   const [selectedOption, setSelectedOption] = useState(null);
   const [teamData,setTeamData] = useState([])
   const [loading,setLoading] = useState(false)
   const [memberExist, setMemberExist] = useState(false);
  
   
    // Access the state object
const companyName = urlParams.get('company')
const projectName = location.state?.projectName;

const [memberData, setMemberData] = useState({
    memberName:'',
    memberId: '',
    email: '',
    password: '',
    projectName: '',
    designation: '',
    country : '',
    address : '',
    company :companyName
  });

  const handleInputChange = (field) => (event) => {
    setMemberData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  
  };

  useEffect(()=>{
    if(urlParams?.get('name')){
        setMemberData({...memberData,'projectName':urlParams?.get('name')})
    }
  },[urlParams?.get('name')])
  
const handleAddMember =async ()=>{
    try{
        setLoading(true)
        const accessToken = JSON.parse(localStorage.getItem('access-token'));

        if(!memberExist){
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/project/add-member/${params.id}`,
        memberData,
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }
        );
    }else {
        
        toast.error("It's Already added member! Please selects it in dropdown")
        setLoading(false)
        
        return;
    }
        // toast.success("Member added successfully", { id: toastId });
   
        // toast.success(response.data?.message)
        
        // return response.data?.teamMember?.teamMember
        navigate(`/project-det/${params.id}?status=submit`);
    }catch(error){
        setLoading(false)
      toast.error(error?.response?.data?.message)
        console.error(error);
    }finally{
        setLoading(false)
    }

// dispatch(addMemberInProject({projectId:params.id  ,formValue:memberData}))


}

const handleInputChange1 = (selectedValue) => {
  
    setSelectedOption(selectedValue);
  };
  
  const specializedUnits1 = teamData.map((teamMember) => ({

   value: {
     memberId: teamMember.memberId,
     _id: teamMember._id,
   },
   label: `${teamMember.memberId} - (${teamMember.designation})`,
 }));


useEffect(()=>{
  const showAllTeamData = async ()=>{
    try{
    const accessToken = JSON.parse(localStorage.getItem('access-token'))
  const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members`,{
    headers : {
      Authorization : `Bearer ${accessToken}`
    }
  }
  );
  console.log(response.data.teamMembers);
  const existingTeamMembers = response.data.teamMembers

//we used this filter method to exclides existing teamMembers inside this specific project
//   const filterTeamMember = existingTeamMembers.filter((teamMember) =>
//   teamMember.projects.every((projectId) => projectId !== projectID)
// );
// console.log(filterTeamMember)

  setTeamData(existingTeamMembers)
  
  }catch(error){
    console.error(error)
  }
  }
  
  showAllTeamData()
    },[projectID])
  //some css properties 
    
  const handleAddExistingMember = async ()=>{
    try{
       
    await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/add-existing-teamMember`,{
        projectId: projectID,
        teamMemberId : selectedOption?.value?._id
    })


    navigate(`/project-det/${params.id}?status=submit`);
}catch(error){
    
    toast.error(error.response?.data?.message)
    console.error(error);
}
  }

  const handleMemberIdChange = (memberId) => {
    // Update memberId immediately
  
    setMemberData(prevState => ({
      ...prevState,
      memberId: memberId,
    }));
  
    // Perform the API call after 2 seconds
    setTimeout(async () => {
      try {
        const accessToken = JSON.parse(localStorage.getItem('access-token'));
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members`, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        const teamMember = response.data.teamMembers;
  
        const memberExist = teamMember.some(member => member.memberId === memberId);
      
        setMemberExist(memberExist);
        if (memberExist) {
          setMemberData(prevState => ({
            ...prevState,
            password: '', // Clear password field if memberId exists
          }
          
          ))
        }
      } catch (error) {
        console.error('Error fetching team members:', error);
      }
    }, 1500);
  };
  
  

    return(
<>
            {loading ?      ( 
       
       (
         <Grid item container >
       
       <Grid xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
           <ClipLoader color="#1565C0" loading={loading} size={50} />
        </Grid>
        </Grid >
         ) 
             ) : (
    <div>
                <Grid container direction="row" spacing={2} alignItems="center" sx={{
                    marginTop: {
                        xs: '20%',
                        sm: '15%',
                        md: '5%',
                    },
                    marginBottom: '2%'
                }}>
                    <Grid item xs={2} md={1} >
                        <a href={`/#/project-det/${params.id}`} style={{ textDecoration:'none', color: '#000'}}>
                            <ArrowBackIcon sx={{fontSize: '30px'}}/>
                        </a>
                    </Grid>

                    <Grid item xs={8} md={4} container alignItems="center" >
                        <h1 style={{color: '#000', fontSize: '32px', fontWeight: '400'}}>Add Member</h1>
                    </Grid>

                </Grid>
                

                <div style={{ marginTop : "2%", border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}>    
                <Grid item xs={12} md={12} container alignItems="center" justifyContent="center">
                        <p style={{color: '#000', fontSize: '25px', fontWeight: '400'}}>Add Existing Team Member</p>
                    </Grid>

                <div style={{marginTop : '1%'}}>
<Grid item xs={9.3} md={10.3} style={{ marginLeft: '5%' }}>
  <Select
    id="outlined-helperText"
    options={specializedUnits1}
    isSearchable
    isClearable
    onChange={(selectedOption) => handleInputChange1(selectedOption)}
    value={selectedOption}
    styles={{
        control: (provided) => ({
          ...provided,
          fontSize: '18px', // Adjust the font size
          fontWeight: '500', // Adjust the font weight
        }),
      }}
    placeholder="Select Existing Team Members"
 
   
  />
</Grid>
</div>
<Grid item xs={12} md={12} container alignItems="center" justifyContent="center" style={{marginTop : '2%'}}>
                        <p style={{color: '#000', fontSize: '25px', fontWeight: '400'}}>Add Manually Team Member</p>
                    </Grid>
                <div style ={{ pointerEvents: selectedOption ? 'none' : 'auto', opacity: selectedOption ? 0.5 : 1,}}>
                    <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Member Name</h3>
                            <TextField
                                id="outlined-helperText"                
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                                value={memberData?.memberName}
                                   onChange={handleInputChange('memberName')}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Member User Id</h3>
                            <TextField
                                id="outlined-helperText"          
                                value={memberData.memberId}
                                onChange={(e)=>handleMemberIdChange(e.target.value)}
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                               
                            />
                        </Grid>
                    </Grid>

                    <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >E-mail</h3>
                            <TextField
                                id="outlined-helperText"  
                                value={memberData.email}
                                onChange={handleInputChange('email')}              
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}  
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Password</h3>
                            <TextField
                                id="outlined-helperText"          
                                value={memberData.password}
                                onChange={handleInputChange('password')}
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    },
                                    
                                }}
                                
                            /> 
                            {memberExist && <p style={{color : 'red' ,fontSize:'15px'}}>Member already exist please select it in dropdown!</p> }
                        </Grid>
                    </Grid>

                    <Grid item container direction="row"  sx={{marginTop: '2%'}} >
                       
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Project Name</h3>
                            <TextField
                                id="outlined-helperText"  
                                value={memberData?.projectName}
                                disabled 
                                onChange={handleInputChange('projectName')}              
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                            />
                        </Grid>
                       
                        <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                            <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Designation</h3>
                            <TextField
                                id="outlined-helperText"          
                                select
                                value={memberData.designation}
                                onChange={handleInputChange('designation')}     
                                sx={{width: '80%'}}
                                InputProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }
                                }}
                            >
                            <MenuItem style={{ fontSize: '16px'}} value="teamMember">Team Member</MenuItem>
                           <MenuItem style={{ fontSize: '16px'}} value="teamManager">Team Manager</MenuItem>

                           </TextField>
                        </Grid>


                    </Grid>



{/* ///start */}
<Grid item container direction="row"  sx={{marginTop: '2%'}} >
                       
                       <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                           <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Country</h3>
                           <TextField
                               id="outlined-helperText"  
                               value={memberData?.country}
                              
                               onChange={handleInputChange('country')}              
                               sx={{width: '80%'}}
                               InputProps={{
                                style: {
                                    fontSize: '18px',
                                    fontWeight: '500'
                                }
                            }}
                           />
                       </Grid>
                      
                       <Grid item xs={12} md={6} sx={{paddingLeft: '5%'}}>
                           <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Address</h3>
                           <TextField
                               id="outlined-helperText"  
                               value={memberData?.address}
                            
                               onChange={handleInputChange('address')}              
                               sx={{width: '80%'}}
                               InputProps={{
                                style: {
                                    fontSize: '18px',
                                    fontWeight: '500'
                                }
                            }}
                           />
                       </Grid>


                   </Grid>
                   </div>


{/* ///end */}
{selectedOption ?  <Grid item xs={12} md={12} container justifyContent={{ xs: 'center', md: 'end' }} alignItems="end" sx={{marginTop: '4%', marginBottom: '2%', paddingRight: '9%'}} >
                        <Button variant='contained' sx={{
                            width: '200px',
                            height: '50px',
                            backgroundColor: '#50A4DB ',
                            borderRadius: '8px',
                            color: '#FFF',
                            fontSize: '24px',
                            fontWeight: '200',
                            textTransform: 'none',  
                            // marginTop: '40px',
                            // paddingLeft: '30px',
                            textDecoration: 'none',
                            // paddingTop: '8px'
                        }}
                        onClick={handleAddExistingMember}
                        >
                            Add Member
                        </Button>
                    </Grid>
                    
                :
                <Grid item xs={12} md={12} container justifyContent={{ xs: 'center', md: 'end' }} alignItems="end" sx={{marginTop: '4%', marginBottom: '2%', paddingRight: '9%'}} >
                <Button variant='contained' sx={{
                    width: '200px',
                    height: '50px',
                    backgroundColor: '#50A4DB ',
                    borderRadius: '8px',
                    color: '#FFF',
                    fontSize: '24px',
                    fontWeight: '200',
                    textTransform: 'none',  
                    // marginTop: '40px',
                    // paddingLeft: '30px',
                    textDecoration: 'none',
                    // paddingTop: '8px'
                }}
                onClick={handleAddMember}
                >
                    Add Member
                </Button>
            </Grid>}
                    {/* <Grid item xs={12} md={12} container justifyContent={{ xs: 'center', md: 'end' }} alignItems="end" sx={{marginTop: '4%', marginBottom: '2%', paddingRight: '9%'}} >
                        <Button variant='contained' sx={{
                            width: '200px',
                            height: '50px',
                            backgroundColor: '#50A4DB ',
                            borderRadius: '8px',
                            color: '#FFF',
                            fontSize: '24px',
                            fontWeight: '200',
                            textTransform: 'none',  
                            // marginTop: '40px',
                            // paddingLeft: '30px',
                            textDecoration: 'none',
                            // paddingTop: '8px'
                        }}
                        onClick={handleAddMember}
                        >
                            Add Member
                        </Button>
                    </Grid> */}
               
                </div>

                
    </div>
            )

        }
        </>
     
    )
}        

export default AddMember;