import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Card, Grid, InputAdornment, Link, List, Menu, MenuItem, TextField, useMediaQuery } from '@mui/material';
import { MdOutlineSubscriptions } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { GoHome } from "react-icons/go";
import { Navigate, matchPath, useLocation, useNavigate } from 'react-router-dom';
import CreateProject from './CreateProject';
import { RiTeamLine } from "react-icons/ri";
import imgSrc from '../../Images/aidprofit.jpg'
import { GoProject } from "react-icons/go";
import { logoutUser } from '../../Actions/authAction';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import './Sidebar.css'

const drawerWidth = 251;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    ...(open && {
      marginLeft: drawerWidth,
    }),
  }),
);


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
  }),
}));

export default function  ({ Component, refreshProjectList }) {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(window.innerWidth >= 960); // Initially open for screens wider than or equal to 960px
  const dispatch = useDispatch()
  const users = JSON.parse(localStorage.getItem('users'))
  const [hasContent, setHasContent] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [isFocused, setIsFocused] = React.useState(false);
  // console.log(users)
  // Determine if the button should be disabled based on user designation  
  const isDisabled = users?.designation === 'teamMember' || users?.designation === 'teamManager';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    setOpen(window.innerWidth >= 960);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const [isDesktopView, setIsDesktopView] = useState(true);

  const location = useLocation();

  const LoggedInAs = 'Team Manager' || '' || 'Team Member';

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  const handleChange = (event) => {
    setHasContent(event.target.value !== '');
  };
  const handleMenuClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuItemClick = (pathname) => {
    // Perform any additional actions before navigating
    // For example, you can close the menu
    handleMenuClose();

    // Navigate to the specified path
    // You need to implement your navigation logic (useHistory, etc.)
    // Example: history.push(pathname);
  };

  const handleLogout = () => {

    dispatch(logoutUser());
  };


  // const location = useLocation();

  const isProjectRoute = [
    '/home-page',
    '/home-grid',
    '/project-det/'
  ].some(path => location.pathname.startsWith(path));

// Somewhere in your code or component

React.useEffect(() => {
  const getData = setTimeout(() => {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));
    axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/search-all-projects/?q=${searchQuery}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      setSearchResults(response?.data || []); // Initialize to empty array if response.data is undefined
    })
    .catch((error) => console.log('error', error));
  }, 1000);

  return () => clearTimeout(getData);
}, [searchQuery]);

// Filtering logic to include both projectName and specializedUnit
const filteredData1 = Array.isArray(searchResults) ? searchResults.filter((item) => (
  item?.projectName?.trim()?.toLowerCase()?.includes(searchQuery?.trim().toLowerCase()) ||
  item?.specializedUnit?.trim()?.toLowerCase()?.includes(searchQuery?.trim().toLowerCase()) || 
  item?.teamMember.some(member => member?.userName?.toLowerCase().includes(searchQuery?.trim().toLowerCase()))
)) : [];


const navigate = useNavigate()
const handleNavigate = (e,id)=>{  
  // console.log('navigate')
  e.stopPropagation();
  navigate(`/#/project-det${id}`)
}
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: '#FFF', boxShadow: 'none', borderBottom: '1px solid #000', height: 'auto', borderColor: '#AFAFAF', paddingBottom: '10px' }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, color: 'black' }}
          >
            {open ? <ChevronLeftIcon sx={{fontSize: '30px'}} /> : <MenuIcon  sx={{fontSize: '30px'}} />}
          </IconButton>
          <Grid container spacing={8} direction="row">
          
          <Grid item xs={6} sm={7} md={8} lg={9} sx={{ marginTop: '10px' }}> 
        
      <TextField
        // label="Search"
        value = {searchQuery}
        placeholder='Search any project by name, specialized unit or member'
      
        // focused
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{
          fontSize: '35px',
          fontWeight: '500',
          color : '#000',
          width: '100%',
          // fontSize : '50px',
          // border: '2px solid #AFAFAF', // Set border size to 2px
          // borderRadius: '20px', // Set border radius to 10px
          "& .MuiInputBase-input": {
            paddingRight: '40px' // Ensure space for the icon
          }
        }}

        onChange={
          (event) => setSearchQuery(event.target.value)
        }
        InputProps={{  style : {fontSize :  '16px'  ,  borderRadius : '30px'},endAdornment: <SearchIcon sx={{ color: '#AFAFAF', fontSize: { md: '30px', xs: '25px' }, position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} /> }}
      />

        </Grid>



            
            <Grid item xs={6} sm={5} md={4} lg={3} container justifyContent="end" sx={{ marginTop: { xs: '2.4%', sm: '1.5%', md: '1.5%' }, spacing: { xs: 1, md: 8 } }}>
              <CreateProject refreshProjectList={refreshProjectList} />
            </Grid>
        
    

      
          </Grid>

        </Toolbar>

        <Card className="card-container" >
  {searchQuery.trim() !== '' && filteredData1 ?
    (filteredData1.length > 0 ?
      filteredData1.map((result, index) => (
        <Grid item xs={12} sm={12} md={12} lg={12} key={result._id}>
          <a href={`/#/project-det/${result._id}`} onClick={(e) => handleNavigate(e, result._id)}>
            <div style={{ color: '#000', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <h3 style={{ marginLeft: '7%', marginTop: '1.5%', cursor: 'pointer' }}>
                {result?.projectName}
              </h3>
            </div>
          </a>
          {index !== filteredData1.length - 1 && <hr style={{ color: '#000' }} />}
        </Grid>
      )) :
      <Grid item xs={12} sm={12} md={12} lg={12} justifyContent='flex-start' alignItems='flex-start'>
        <div style={{ color: '#000', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <h3 style={{ marginLeft: '7%', marginTop: '1%' }}>No projects Found</h3>
        </div>
      </Grid>
    ) : null
  }
</Card>



      </AppBar>

     
     {/* //SideBar icon Button */}
      <Grid container direction="row" spacing={0}>

        <Grid item xs={12} md={2}>
          <Drawer
            sx={{
              width: drawerWidth,          
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
                // background: 'linear-gradient(180deg, #FFF 0%, #BFDEF2 40.52%, #7BBAE4 59.27%, rgba(80, 164, 219, 0.80) 73.33%, #BCDCF1 87.4%, #FFF 100%)'
                background : 'white'
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <List style={{overflow : 'hidden', paddingTop: '0px',}} >
              <ListItem >
                <Link href="/#/home-dashboard" sx={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}>
                  <img src={imgSrc} alt="" />
                </Link>
              </ListItem>

                      {/* <ListItem style={{paddingTop : '0px'}}>
                        <InputSearch label="Search for team or members" />
                      </ListItem> */}

              <ListItem>
                <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                    <LinkButton
                      pathname="/home-dashboard"
                      label="Dashboard"
                      active={location.pathname === '/home-dashboard'}
                      icon={<GoHome size={30} />}
                      disabled={LoggedInAs === '' || isDisabled}

                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* <div onClick={handleMenuClick} style={{ cursor: 'pointer' }}> */}

                    <LinkButton
                      pathname="/home-page"
                      label="Projects"
                      //active={location.pathname === '/home-page' || location.pathname === '/home-grid' || location.pathname === '/project-det/:id'}
                      active={isProjectRoute}
                      icon={<GoProject size={30} />}

                    />


                  </Grid>

              
                  <Grid item xs={12} md={12}>
                    <LinkButton
                      pathname="/team"
                      label="Your Team"
                      active={location.pathname === '/team' || location.pathname === '/team-edit' || location.pathname === '/team-view'}
                      icon={<RiTeamLine size={30} />}
                      disabled={LoggedInAs === '' || isDisabled}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <LinkButton
                      pathname="/subscription"
                      label="Subscription"
                      active={
                        location.pathname === '/subscription' ||
                        location.pathname === '/upgrade-subscription' ||
                        location.pathname === '/payment' ||
                        location.pathname === '/payment-confirm' ||
                        location.pathname === '/payment-cancel'
                      }
                      icon={<MdOutlineSubscriptions size={30} />}
                      disabled={LoggedInAs === '' || isDisabled}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <LinkButton
                      pathname="/home-profile"
                      label="Profile"
                      active={location.pathname === '/home-profile' || location.pathname === '/home-profile-edit'}
                      icon={<FaRegUser size={30} />}

                    />

                  </Grid>

                  <Grid item xs={12} md={12} 
                    onClick={() => {

                      handleLogout()

                    }}
                    sx={{ alignSelf: 'flex-end', cursor: 'pointer' }}
                  >
                    <LinkButton

                      pathname="/"
                      label="Logout"

                      icon={<IoMdLogOut size={30} />}
                     
                    />
                  </Grid>

                </Grid>
              </ListItem>
              
              <ListItem>

                {/* <Grid container spacing={0}>
                
                </Grid> */}
              </ListItem>
            </List>

          </Drawer>
        </Grid>

        <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
      
          <Main open={open}>
            <div>
              {Component}
            </div>
     
          </Main>
        </Grid>

      </Grid>

      
    </Box>
    
  );
}

function LinkButton({ pathname, label, active, icon, disabled }) {

  return (
    <ListItem
      disabled={disabled}
      sx={{
        background: active ? 'linear-gradient(to right, #50A4DB, #50A4DB) !important' : 'none !important',
        
        border: active ? '1px solid #FFF' : 'none',
        // width: active ? '190px' : 'none',
        borderRadius: active ? '12px' : '0',
        textAlign: '',
        position: 'relative',
        // left: active ? '20px' : '0'
        opacity: disabled ? 0.5 : 1, // Adjust the opacity when disabled
      }}
    >
      <ListItemIcon sx={{ color: active ? 'white' : '#000' }}>
        {icon}
      </ListItemIcon>
      <ListItemText>
        <Link href={`/#${pathname}`} sx={{
          textDecoration: 'none',
          fontSize: active ? '20px' :  '20px',
          lineHeight : active ? '24px' : 'none',
          color: active ? 'white' : '#000',
          position: 'relative',
          fontWeight: '500',
          pointerEvents: disabled ? 'none' : 'auto',
          // right: active ? '0px' : '20px'
        }}>
          {label}
        </Link>
      </ListItemText>
    </ListItem>
  );
}

function Input({ icon, label }) {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          border: '1px solid #000',
          width: '100%',
          padding: isSmallScreen ? '0px 0px 0px 17px' : '0px 0px 10px 20px ',
          gap: isSmallScreen ? '10px' : '20px',
          borderRadius: '24px',
          borderColor: '#AFAFAF'
        }}
      >

        {icon}

        <TextField
          sx={{
            width: '100%', '& label': {
              fontSize: isSmallScreen ? '16px' : '20px',
              color: '#AFAFAF',
            },
            position: 'relative',
            bottom: '10px'

          }} // Set the width to 100% to fill the container
          id="input-with-sx"
          label={label}
          variant="standard"
          InputProps={{ disableUnderline: true }} // Remove the default underline

        />
      </Box>

    </Box>
  )
}

function InputSearch({ label, icon }) {
  const [isFocused, setIsFocused] = React.useState(false);
  const [hasContent, setHasContent] = React.useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleChange = (event) => {
    setHasContent(event.target.value !== '');
  };
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          border: '1px solid #000',
          width: '100%',
          padding: '0px 35px 8px 30px',
          // gap: '20px',
          borderRadius: '24px',

        }}
      >

        {icon}

        <TextField
          sx={{
            width: '100%', '& label': {
              fontSize: '9px',
              display: (isFocused || hasContent) ? 'none' : 'block',
              color: '#000',


              // Adjust the font size as needed
            },

          }} // Set the width to 100% to fill the container
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          id="input-with-sx"
          label={label}
          variant="standard"
          InputProps={{ disableUnderline: true }} // Remove the default underline

        />
      </Box>

    </Box>
  )
}
