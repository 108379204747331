import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import BasicCard2 from '../Card4/Card4';
import imgSrc2 from '../../Images/Ellipse 4.jpg';
import imgSrc3 from '../../Images/Ellipse 5.jpg';
import imgsrc4 from '../../Images/Ellipse 4.jpg';

export default function SimpleContainer6() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{marginTop: '5%'}}>
        <Box>
          <h4 style={{
            color: '#000',
            textAlign: 'center',
            fontSize: '40px',
            fontWeight: '500',
          
          }}>
            Testimonial
          </h4>
          <Grid container spacing={2} justifyContent="center" sx={{marginTop: '2%'}}>
            <Grid item xs={12} md={6} lg={6}>
              <BasicCard2 name='Noha Ray' image={imgSrc2} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <BasicCard2 name='Aron Brick' image={imgSrc3} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <BasicCard2 name='Sonae Ray' image={imgsrc4} />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <BasicCard2 name='Blaon Brick' image={imgSrc3} />
            </Grid>
     
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
