import { v4 as uuidv4 } from 'uuid';
// export function createBasicTemplate(template, x, y) {
//   const connectorSize = 10;
//   const connectorOffset = connectorSize / 2;
  
//   return {
//     id : '0',
//     ...template,
//     x: x,
//     y: y,
//     width: 80,
//     height: 93,
//     shapes: [
//       { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: '#50A4DB ' ,cornerRadius : 10 },
//       { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, 
      
//     ],
//     connectors: [
//       {  id: 'connector1',x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'right', connections: [] },
//       { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'bottom' , connections: []},
//       {  id: 'connector3',x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'left', connections: [] },
//       { id: 'connector4', x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'top', connections: [] },
//     ],
    
    
//   };

// }


export function createBasicTemplate(template,x,y,selectedColor){
  const uniqueId = uuidv4();
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB";

  return {
    id: '3',
    ...template,
    x: x,
    unique_widget_id : uniqueId,
    y: y,
    width: 80,
    height: 300, // Adjusted total height to accommodate four rectangles
    shapes: [
      { type: 'Rect', x: 10, y: 1, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
     
      { type: 'Rect', x: 20, y: 42, width: 246, height: 50, fill: 'white' }, // First white rectangle
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 296 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 7 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  };

}
// export function createTemplate1(template, x, y, selectedColor) {
//   const fillColor = selectedColor || "#50A4DB"; // Default or selected fill color
//   const fontSize = 16;

//   // Define dimensions for the rectangles
//   const rectX = 20, rectY = 20, rectWidth = 246, rectHeight = 50;

//   // Starting position for the text should ideally be within the rectangle
//   // Adjusting textX to horizontally center might require measuring text width, which varies by environment
//   const textX = rectX + 5; // Slightly offset from the left for now
//   // Adjust textY for better vertical centering; consider the descent of letters if necessary
//   const textY = rectY + (rectHeight / 2) + (fontSize / 3); // Adjusted for typical font descent


//   return {
//     id: '3',
//     ...template,
//     x: x,
//     y: y,
//     width: 270, // Overall width
//     height: 270, // Overall height
//     shapes: [
//       { type: 'Rect', x: 10, y: 10, width: 270, height: 260, fill: fillColor, cornerRadius: 10 }, // Background
//       // First rectangle, now without the text to ensure text is not covered
//       { type: 'Rect', x: rectX, y: rectY, width: rectWidth, height: rectHeight, fill: 'white', stroke: 'black', strokeWidth: 1 },
//       // Text placed after the rectangle to ensure it's rendered on top
//       { type: 'Text', x: textX, y: textY, text: "vievk" || 'Your Text Here', fontSize: fontSize, fill: 'black', align: 'left' },
//       // Additional rectangles as needed...
//     ],
//     connectors: [
//       // Connectors if necessary...
//     ],
//   };
// }
export function createTemplate1(template, x, y,selectedColor) {
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB";
  const uniqueId = uuidv4();
  return {
    id: '3',
    ...template,
    unique_widget_id : uniqueId,
    x: x,
    y: y,
    width: 80,
    height: 300, // Adjusted total height to accommodate four rectangles
    shapes: [
      { type: 'Rect', x: 10, y: 10, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
      
      { type: 'Rect', x: 20, y: 54, width: 246, height: 50, fill: 'white' }, // First white rectangle
      { type: 'Rect', x: 20, y: 114, width: 246, height: 50, fill: 'white' }, // Second white rectangle
      { type: 'Rect', x: 20, y: 174, width: 246, height: 50, fill: 'white' }, // Third white rectangle
      { type: 'Rect', x: 20, y: 236, width: 246, height: 50, fill: 'white' }, // Fourth white rectangle
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 305 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 14 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  }
}

export function createTemplate2(template, x, y,selectedColor) {
  
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB";
  const uniqueId = uuidv4();
  return {
    id: '3',
    unique_widget_id : uniqueId,
    ...template,
    x: x,
    y: y,
    width: 80,
    height: 300, // Adjusted total height to accommodate four rectangles
    shapes: [
      { type: 'Rect', x: 10, y: 1, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
      
      { type: 'Rect', x: 20, y: 42, width: 246, height: 50, fill: 'white' }, // First white rectangle
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 295 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 7 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  };
}

  export function createTemplate3(template, x, y, selectedColor) {
    const connectorSize = 10;
    const connectorOffset = connectorSize / 2;
    const fillColor = selectedColor ? selectedColor : "#50A4DB";
    const uniqueId = uuidv4();
    return {
      id: '3',
      unique_widget_id :  uniqueId,
      ...template,
      x: x,
      y: y,
      width: 80,
      height: 300, // Adjusted total height to accommodate four rectangles
      shapes: [
      
          { type: 'Rect', x: 10, y: 1, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
          
          { type: 'Rect', x: 20, y: 42, width: 246, height: 50, fill: 'white' }, // First white rectangle
        
      ],
      connectors: [
        { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
        { id: 'connector2', x: 150 - connectorOffset, y: 296 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
        { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
        { id: 'connector4', x: 150 - connectorOffset, y: 8 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
      ]
    };
  }
export function createTemplate4(template, x, y,selectedColor) {
  
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB";
  const uniqueId = uuidv4();
  return {
    id: '3',
    unique_widget_id : uniqueId,
    ...template,
    x: x,
    y: y,
    width: 80,
    height: 300, // Adjusted total height to accommodate four rectangles
    shapes: [
    
        { type: 'Rect', x: 10, y: 1, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
        
        { type: 'Rect', x: 20, y: 42, width: 246, height: 50, fill: 'white' }, // First white rectangle
      
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 296 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 8 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  };
}

export function createTemplate5(template, x, y, selectedColor) {
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB";
  const uniqueId = uuidv4();
  return {
    id: '3',
    unique_widget_id : uniqueId,
    ...template,
    x: x,
    y: y,
    width: 80,
    height: 300, // Adjusted total height to accommodate four rectangles
    shapes: [
      { type: 'Rect', x: 10, y: 10, width: 270, height: 290, fill: fillColor, cornerRadius: 10 }, // Blue background
      
      { type: 'Rect', x: 20, y: 54, width: 246, height: 50, fill: 'white' }, // First white rectangle
      { type: 'Rect', x: 20, y: 114, width: 246, height: 50, fill: 'white' }, // Second white rectangle
      { type: 'Rect', x: 20, y: 174, width: 246, height: 50, fill: 'white' }, // Third white rectangle
      { type: 'Rect', x: 20, y: 236, width: 246, height: 50, fill: 'white' }, // Fourth white rectangle
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 305 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 16  - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  };
}

// export function createTemplate1(template, x, y,selectedColor) {
//   const connectorSize = 10;
//   const connectorOffset = connectorSize / 2;
//   return {
//     id : '1',
//     ...template,
//     x: x,
//     y: y,
//     width: 200,
//     height: 100,
//     shapes: [
//       { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: '#50A4DB ' ,cornerRadius : 10  }, // Blue background
//       { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, // Top   white rectangle
//       { type: 'Rect', x: 20, y: 91, width: 120, height: 195, fill: 'white' }, // Left vertical rectangle
//       { type: 'Rect', x: 146, y: 91, width: 120, height: 195, fill: 'white' }  // Right vertical rectangle
//     ],
//     connectors: [
//       { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'right' , connections: []},
//       { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'bottom', connections: [] },
//       { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'left', connections: [] },
//       { id: 'connector4', x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill: '#50A4DB ', type: 'top', connections: [] },
//     ],
//   };
// }        






// export function createTemplate3(template, x, y,selectedColor) {
//   const remainingHeight = 286 - 20 - 61; 
//   const newHeight = remainingHeight / 2; 
//   const connectorSize = 10;
//   const connectorOffset = connectorSize / 2;
//   const fillColor = selectedColor ? selectedColor : "#50A4DB"
//   return {
//     id : '3',
//     ...template,
//     x: x,
//     y: y,
//     width: 80,
//     height: 93,
//     shapes: [
//       { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: fillColor,cornerRadius : 10  }, // Blue background
//       { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, // Top white rectangle
//       { type: 'Rect', x: 20, y: 91, width: 246, height: 90, fill: 'white' }, // Second white rectangle
//       { type: 'Rect', x: 20, y: 91 + newHeight, width: 246, height: 90, fill: 'white' }  // Third white rectangle
//     ],
//     connectors: [
//       {  id: 'connector1',x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
//       { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom', connections: [] },
//       { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
//       {  id: 'connector4',x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
//     ],
//   };
// }

                                                                                                                                  
                                                                                              
                          
                                           
///makes this with testing
// export function createTemplate4(template, x, y,selectedColor) { 
//   const connectorSize = 10;
//   const connectorOffset = connectorSize / 2;
//   const fillColor = selectedColor ? selectedColor : "#50A4DB"
//   return {
//     id : '4',
//     ...template,
//     x: x,
//     y: y,
//     width: 80,
//     height: 93,
//     shapes: [
//       { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: fillColor ,cornerRadius : 10 }, // Blue background
//       { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white'}, // Top white rectangle
//       { type: 'Rect', x: 20, y: 91, width: 120, height: 90, fill: 'white' }, // Top-left square
//       { type: 'Rect', x: 146, y: 91, width: 120, height: 90, fill: 'white' }, // Top-right square
//       { type: 'Rect', x: 20, y: 191, width: 120, height: 90, fill: 'white' }, // Bottom-left square
//       { type: 'Rect', x: 146, y: 191, width: 120, height: 90, fill: 'white' }  // Bottom-right square
//     ],
//     connectors: [
//       { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
//       { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom' , connections: []},
//       { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'left', connections: [] },
//       { id: 'connector4', x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
//     ],
//   };
// }

//campaign greeen circles
export function createCampaignTemplate(template, x, y,selectedColor) {
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB"
  return {
    id : '5',
    ...template,
    x: x,
    y: y,
    width: 100,
    height: 100,
    shapes: [
      { type: 'Circle', x: 50, y: 50, radius: 71, fill: fillColor } 
    ],
    connectors: [
      { x: 125 - connectorOffset, y: 59 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'right', connections: [] },
      { x: 59 - connectorOffset, y: 125 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'bottom' , connections: []},
      { x: -16 - connectorOffset, y: 50 - connectorOffset, radius: connectorSize, fill:fillColor , type: 'left' , connections: []},
      { x: 50 - connectorOffset, y: -16 - connectorOffset, radius: connectorSize, fill: fillColor, type: 'top', connections: [] },
    ],
  };
}



export function createTemplate8(template, x, y) {
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  return {
    ...template,
    x: x,
    y: y,
    width: 80,
    height: 93,
    shapes: [
      { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: "#50A4DB" ,cornerRadius : 10 }, // Blue background
      { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, // Top white rectangle
      { type: 'Rect', x: 20, y: 91, width: 120, height: 90, fill: 'white' }, // Top-left square
      { type: 'Rect', x: 146, y: 91, width: 120, height: 90, fill: 'white' }, // Top-right square
      { type: 'Rect', x: 20, y: 191, width: 120, height: 90, fill: 'white' }, // Bottom-left square
      { type: 'Rect', x: 146, y: 191, width: 120, height: 90, fill: 'white' }  // Bottom-right square
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill:  template.themeColor, type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill:  template.themeColor, type: 'bottom' , connections: []},
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill:  template.themeColor, type: 'left', connections: [] },
      { id: 'connector4', x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill:  template.themeColor, type: 'top', connections: [] },
    ],
    // texts: [
    //   { 
    //     type: 'Text', 
    //     x: 133, 
    //     y: 50, 
    //     text: template.widgetName,
    //     fontSize: 16,
    //     fontFamily: 'Arial',
    //     fill: 'black',
    //   }
    // ]
  };
}
export function createTemplate9(template, x, y,selectedColor) {
  const remainingHeight = 286 - 20 - 61; 
  const newHeight = remainingHeight / 2; 
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  const fillColor = selectedColor ? selectedColor : "#50A4DB"
  return {
    ...template,
    x: x,
    y: y,
    width: 80,
    height: 93,
    shapes: [
      { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill:fillColor ,cornerRadius : 10  }, // Blue background
      { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, // Top white rectangle
      { type: 'Rect', x: 20, y: 91, width: 246, height: 90, fill: 'white' }, // Second white rectangle
      { type: 'Rect', x: 20, y: 191, width: 120, height: 90, fill: 'white' }, // Bottom-left square
      { type: 'Rect', x: 146, y: 191, width: 120, height: 90, fill: 'white' }  // Bottom-right squa
    ],
    connectors: [
      {  id: 'connector1',x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: template.themeColor , type: 'right', connections: [] },
      { id: 'connector2', x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: template.themeColor , type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill:template.themeColor , type: 'left', connections: [] },
      {  id: 'connector4',x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill:template.themeColor , type: 'top', connections: [] },
    ],
  };
 

  
}


export function createTemplate10(template, x, y) {
  const connectorSize = 10;
  const connectorOffset = connectorSize / 2;
  return {
    ...template,
    x: x,
    y: y,
    width: 200,
    height: 100,
    shapes: [
      { type: 'Rect', x: 10, y: 10, width: 270, height: 286, fill: template.themeColor  ,cornerRadius : 10 }, // Blue background
      { type: 'Rect', x: 20, y: 20, width: 246, height: 61, fill: 'white' }, // Top white rectangle
      { type: 'Rect', x: 20, y: 91, width: 120, height: 195, fill: 'white' }, // Left vertical rectangle
      { type: 'Rect', x: 150, y: 91, width: 115, height: 95, fill: 'white' }, // Upper right square
      { type: 'Rect', x: 150, y: 196, width: 115, height: 90, fill: 'white' }  // Lower right square
    ],
    connectors: [
      { id: 'connector1', x: 286 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: template.themeColor, type: 'right', connections: [] },
      {  id: 'connector2',x: 150 - connectorOffset, y: 301 - connectorOffset, radius: connectorSize, fill: template.themeColor, type: 'bottom', connections: [] },
      { id: 'connector3', x: 15 - connectorOffset, y: 160 - connectorOffset, radius: connectorSize, fill: template.themeColor, type: 'left' , connections: []},
      { id: 'connector4', x: 150 - connectorOffset, y: 15 - connectorOffset, radius: connectorSize, fill: template.themeColor, type: 'top', connections: [] },
    ],
    
  };
}