import axios from "axios";
import { clearAddingTemplate, clearConnectorLines ,clearRightSideBarVariables,dropTemplateID} from "../../../Reducers/CanvasReducer";
import { v4 as uuidv4 } from 'uuid';

//for undoing 
export const handleUndoClick = (isUndoActive, setIsUndoActive, undo) => {
    setIsUndoActive(true);
    undo();
    setTimeout(() => setIsUndoActive(false), 500);
  };

  const deletesAllWidget = async (params) => {
    try {
      const [response1, response2] = await Promise.all([
        axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/delete-template-widget/${params}`),
        axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/remove-all-widgets/${params}`)
      ]);
      
      // Handle responses if needed
  
    } catch (error) {
      console.error(error);
      // Handle errors if needed
    }
  }; 
  
// for clearing whole  canvas at once 
export const handleClearClick = (setTemplates, setLines, updateHistory, setIsClearActive,dispatch,params) => {
  setIsClearActive(true);
  setTemplates([]);
  setLines([]);
  localStorage.removeItem('simulationResult');
  dispatch(clearRightSideBarVariables())
  updateHistory([], []); 
  setTimeout(() => setIsClearActive(false), 500);
  dispatch(clearAddingTemplate());
  deletesAllWidget(params)
  dispatch(clearConnectorLines())
  
};
  
//it is used to delete specific templates
export const handleDeleteClick = async (selectedTemplateIndex, setIsDeleteActive, templates, setTemplates, updateHistory, setSelectedTemplateIndex,projectId) => {
  // if (selectedTemplateIndex !== null) {
  //   const newTemplates = templates.filter((_, index) => index !== selectedTemplateIndex);
  //   setIsDeleteActive(true); 
  //   setTemplates(newTemplates);
  //   updateHistory(newTemplates);
  //   setSelectedTemplateIndex(null);
  //   setTimeout(() => setIsDeleteActive(false), 500); 
  // }
  // setIsDeleteActive(true);
 
  
  // setTimeout(() => setIsDeleteActive(false), 500);
  try {
    if (selectedTemplateIndex !== null) {
      const templateToDelete = templates[selectedTemplateIndex];
      const uniqueWidgetId = templateToDelete.unique_widget_id;

      await axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/delete-canvas-widget-projectId`, { data: { projectId, uniqueWidgetId } });

      const newTemplates = templates.filter((_, index) => index !== selectedTemplateIndex);
      setIsDeleteActive(true);
      setTemplates(newTemplates);
      updateHistory(newTemplates);
      setSelectedTemplateIndex(null);
      setTimeout(() => setIsDeleteActive(false), 500);
    }
  } catch (error) {
    console.error('Error deleting template:', error);
  }
};


    //for redoing anything

export const handleRedoClick = (setIsRedoActive, redo) => {
  setIsRedoActive(true);
  redo();
  setTimeout(() => setIsRedoActive(false), 500);
};

const saveTemplateToDatabase = async (template,projectId) => {

  try {

  

    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-canvas-template`, 
     {
      templateWithProjectId : template,
      projectId : projectId.id
     },
    );

    console.log('Template saved to database:', response.data);
  } catch (error) {
    console.error('Error saving template:', error.message);
  }
}; 
//for copying or cloning after slecting
export const handleCopyClick =async (selectedTemplateIndex, templates, setTemplates, updateHistory, setIsCopyActive,selectMode,params,dispatch) => {
  const uniqueId = uuidv4();
    if (selectedTemplateIndex !== null) {
      const templateToCopy = templates[selectedTemplateIndex];
      if (templateToCopy) {
        const copiedTemplate = { ...templateToCopy, unique_widget_id : uniqueId, x: templateToCopy.x + 10, y: templateToCopy.y + 10 };
        console.log(copiedTemplate,"copiedTemplate");
        setTemplates(currentTemplates => {
          const newTemplates = [...currentTemplates, copiedTemplate];
          updateHistory(newTemplates); 
          return newTemplates;
        });
        //dispatch an action
        console.log("templatess",templates)
        // console.log(templateToCopy,"templateToCopy")
        if(selectMode){
 const dispatchs = await dispatch(dropTemplateID({ newTemplate: copiedTemplate  }));  
       await saveTemplateToDatabase(copiedTemplate ,params );
        }
      }
    }
    setIsCopyActive(true);
    setTimeout(() => setIsCopyActive(false), 500);
  };
  

  // zoom out and zoom in

export const handleZoomOutClick = (setIsZoomOutActive, handleZoomOut) => {
    setIsZoomOutActive(true);
    handleZoomOut();
    setTimeout(() => setIsZoomOutActive(false), 500);
  };
  
  export const handleZoomInClick = (setIsZoomInActive, handleZoomIn) => {
    setIsZoomInActive(true);
    handleZoomIn();
    setTimeout(() => setIsZoomInActive(false), 500);
  };

//for mouse down ,move and deleting any specific template

export const handleMouseDown = (e, selectMode, setStartPoint, setSelectionRect) => {
  // console.log("handleMouseDown",e.target.getStage())
    if (!selectMode) return;
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();
 
    setStartPoint({ x, y });
    setSelectionRect({ x, y, width: 0, height: 0, visible: true });
  };
  
  export const handleMouseMove = (e, selectMode, startPoint, setSelectionRect) => {
    if (!selectMode || !startPoint) return;
    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();
    
    const width = x - startPoint.x;
    const height = y - startPoint.y;
// console.log("width: " + width + ", height: " + height)
    setSelectionRect({ x: startPoint.x, y: startPoint.y, width, height, visible: true });
  };
  export const handleMouseMove1 = (event)=>{

    console.log(event.target.position)
  }
    // Handle mouse up to finalize selection
    export const handleMouseUp = (e, selectMode, startPoint, setSelectionRect, selectionRect, setStartPoint, handleSelectTemplate, templates) => {
    // console.log("handleMouseUp",selectMode)
    
      if (!selectMode || !selectionRect || !templates) return; 
    
      // Find a template within the selectionRect
      const selectedIdx = templates.findIndex((t) => isTemplateWithinRect(t, selectionRect));
      if (selectedIdx !== -1) {
        handleSelectTemplate(selectedIdx);
      }
    
      // Clear selection rect
      setSelectionRect(null);
      setStartPoint(null);
    };


  
// Function to check if a template is within the selection rectangle
 export  const isTemplateWithinRect = (template, rect) => {
    let rectLeft = rect.x;
    let rectRight = rect.x + rect.width;
    let rectTop = rect.y;
    let rectBottom = rect.y + rect.height;
  
    if (rect.width < 0) {
      rectLeft = rect.x + rect.width;
      rectRight = rect.x;
    }
  
    if (rect.height < 0) {
      rectTop = rect.y + rect.height;
      rectBottom = rect.y;
    }
  
    return template.shapes.some(shape => {
      if (shape.type === 'Rect') {
        const shapeLeft = template.x + shape.x;
        const shapeRight = shapeLeft + shape.width;
        const shapeTop = template.y + shape.y;
        const shapeBottom = shapeTop + shape.height;
  
        return !(rectLeft > shapeRight || rectRight < shapeLeft || rectTop > shapeBottom || rectBottom < shapeTop);
      } else if (shape.type === 'Circle') {
        const shapeCenterX = template.x + shape.x;
        const shapeCenterY = template.y + shape.y;
  
        // Check if the circle's bounds intersect with the rectangle
        const closestX = Math.max(rectLeft, Math.min(shapeCenterX, rectRight));
        const closestY = Math.max(rectTop, Math.min(shapeCenterY, rectBottom));
  
        const distanceX = shapeCenterX - closestX;
        const distanceY = shapeCenterY - closestY;
  
        const distanceSquared = (distanceX * distanceX) + (distanceY * distanceY);
        return distanceSquared < (shape.radius * shape.radius);
      }
  
      return false;
    });
  };
    
  
  
  