import AdminSidebar from "../../components/AdminPages/AdminSidebar";
import ClientPlan from "../../components/AdminPages/ClientPlan";




function AdminClientPlan() {
    return(
        <AdminSidebar Component={<ClientPlan/>} />
    );
}

export default AdminClientPlan;