import { Avatar, Grid, Link, Typography } from "@mui/material";
import imgSrc from "../../Images/Rectangle 148.jpg";
import imgSrc2 from "../../Images/avatar.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Button } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GridViewIcon from "@mui/icons-material/GridView";
import { useEffect, useState } from "react";
import imgSrc1 from '../../Images/avatar.jpg'

import { AvatarGroup } from '@mui/material';
import { Pagination as MuiPagination } from "@mui/material";
import axios from "axios";
// import { Link } from 'react-router-dom';

function HomeGrid() {
  const itemsPerPage = 5; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [projects, setProjects] = useState([]);     
  const accessToken = JSON.parse(localStorage.getItem("access-token"));


  
  const fetchData = async () => {
  
    const users = JSON.parse(localStorage.getItem('users'));
    const isDisabled = users?.designation === 'teamMember' || users?.designation === 'teamManager';
  
    try {
      let response;
      if (isDisabled) {
        response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/projects`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
     
      } else {
        response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }); 
        
      }
      console.log(response.data);
      setProjects(response.data?.projectData || response?.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
  
    }
  };

  useEffect(() => {
  
    fetchData();
  
}, []);
  
  // const teamData = [
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },
  //     { name: 'Lorem Ispum', timeAgo: '2 weeks ago' },

  //     // Add more data as needed
  //   ];

  // Calculate the total number of pages
  
  const pageCount = Math.ceil(projects.length / itemsPerPage);

  // Get the current page data
  const currentData = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
const fetchMemberDetails =async (memberId) => {
try{
  // const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL} `)
}catch(error){
  console.error(error);
}
}
  // Determine if the button should be disabled based on user designation
  const users = JSON.parse(localStorage.getItem("users"));
  const isDisabled =
    users?.designation === "teamMember" || users?.designation === "teamManager";




    const AvatarAsync = ({ memberId }) => {
      const [memberDetails, setMemberDetails] = useState(null);
  
      useEffect(() => {
          const fetchMemberDetails = async () => {
              try {
                  // Fetch member details using memberId
                  const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/teamMembers/${memberId}`);
                  setMemberDetails(response.data);
              } catch (error) {
                  console.error('Error fetching member details:', error);
              }
          };
  
          fetchMemberDetails();
  
          // Cleanup function
          return () => {
              // Cancel any pending requests or cleanup if needed
          };
      }, [memberId]);
  
      if (memberDetails) {
          return <Avatar alt={memberDetails.name} src={memberDetails.image} />;
      } else {
          return null;
      }
  };
  
  
  return (
    <div>
      <Grid
        sx={{
          border: "1px solid #FFF",
          marginTop: {
            xs: "25%",
            sm: "15%",
            md: "12%",
            lg: "7%",
          },
    

        //   paddingTop: "2%",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent={{ xs: "center", md: "end" }}
        >
          <Grid
            item
            xs={12}
            md={12}
            justifyContent={{ xs: "center", md: "end" }}
            textAlign={{ xs: "center", md: "end" }}
            sx={{ paddingBottom: "2%" }}
          >
            {/* <div style={{marginTop: '10%', display: 'flex', flexDirection: 'row', gap: '25px', position: 'relative', justifyContent:'end', left: "90px"}}> */}
            {/* <a href="/#/home-page">
              {" "}
              <GridOnIcon
                sx={{
                  border: "1px solid #50A4DB ",
                  boxShadow: " 2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "50%",
                  width: "38px",
                  height: "38px",
                  padding: "9px",
                  backgroundColor: "#50A4DB ",
                  color: "#FFF",
                  marginRight: "2%",
                }}
              />
            </a> */}
            {/* <Button sx={{textTransform: 'none', border: '1px solid #FFF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px', color: '#000', marginRight: '2%', fontSize: '10px'}} endIcon={<KeyboardArrowDownIcon />}>Filters</Button> */}

            {isDisabled ? (
             <Link
             href="/#/home-page"
             style={{
               textTransform: "none",
               textDecoration: "none",
               border: "1px solid #FFF",
               boxShadow: " 2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
               borderRadius: "35px",
               color: "#000",
               padding: "7px 10px 7px 0px",
               marginRight: "3%",
               fontSize: "14px",
             }}
             disabled={isDisabled}
           >
                <GridViewIcon
                  sx={{
                    border: "1px solid #50A4DB ",
                    backgroundColor: "#50A4DB ",
                    boxShadow: " 2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50%",
                    width: "30px",
                    height: "28px",
                    color: "#FFF",
                    padding: "7px",
                    marginRight: "20px",
                    marginLeft: "5px",
                  }}
                />
                Projects
              </Link>
            ) : (
              <Link
                href="/#/home-page"
                style={{
                  textTransform: "none",
                  textDecoration: "none",
                  border: "1px solid #FFF",
                  boxShadow: " 2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: "35px",
                  color: "#000",
                  padding: "7px 10px 7px 0px",
                  marginRight: "3%",
                  fontSize: "14px",
                }}
                disabled={isDisabled}
              >
                <GridViewIcon
                  sx={{
                    border: "1px solid #50A4DB ",
                    backgroundColor: "#50A4DB ",
                    boxShadow: " 2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                    borderRadius: "50%",
                    width: "30px",
                    height: "28px",
                    color: "#FFF",
                    padding: "7px",
                    marginRight: "20px",
                    marginLeft: "5px",
                  }}
                />
                Projects
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', marginTop: '2%'}}> */}
      {/* Header */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          fontSize: { md: "20px", xs: "10px" },
          paddingTop: "1%",
          color: "#FFF",
          backgroundColor: "#50A4DB ",
          borderRadius: "8px",
        }}
      >
        <Grid item xs={3} md={3} justifyContent="center" container>
          <p>Canvas</p>
        </Grid>
        <Grid item xs={2} md={2} justifyContent="center" container>
          <p> Project Name </p>
        </Grid>
        <Grid item xs={2} md={2} justifyContent="center" container>
          <p> Last edited </p>
        </Grid>
        <Grid item xs={2} md={2} justifyContent="center" container>
          <p>Teams</p>
        </Grid>
        <Grid item xs={2} md={2} justifyContent="center" container>
          <p>Action</p>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        <hr
          style={{
            border: "1px solid rgba(175, 175, 175, 0.9)",
            width: "100%",
            height: "1px",
            position: "relative",
            bottom: "7px",
          }}
        />
      </Grid>

      {/* Data of Rows */}
      {currentData.map((item, index) => (
        <div key={index}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor:
                index % 2 === 0
                  ? "rgba(80, 164, 219, 0.20)"
                  : "rgba(80, 164, 219, 0.05)",
              borderRadius: "8px",
              paddingTop: "1%",
            }}
          >
            <Grid
              item
              xs={3}
              md={3}
              justifyContent="center"
              alignItems="center"
              container
            >
              <img
                style={{
                  position: "relative",
                  // left: '10px',
                  bottom: "5px",
                  width: "90px",
                  height: "50px",
                }}
                src={imgSrc}
                alt=""
              ></img>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              justifyContent="center"
              container
              sx={{
                fontSize: { md: "20px", xs: "10px" },
                color: "#000",
                fontWeight: "400",
              }}
            >
              <p>{item?.projectName}</p>
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              justifyContent="center"
              container
              sx={{
                fontSize: { md: "14px", xs: "6px" },
                color: "#000",
                fontWeight: "400",
              }}
            >
            <p>
    Date: {new Date(item?.lastEdited).toLocaleDateString()} <br />
    Time: {new Date(item?.lastEdited).toLocaleTimeString()}
  </p>
            </Grid>
            <Grid item xs={2} md={2} container justifyContent="center" alignItems="center">
                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <div style={{ position: 'relative' }}>
                                    <AvatarGroup max={3} 
                                     sx={{
                                        '& .MuiAvatar-root': { width: 33, height: 33, fontSize: 15 },
                                      }}
                                    >
              {/* {item.teamMember.map((memberId, index) => (
                <div style={{ position: 'relative', marginLeft: '-12px' }}>
                <Avatar key={index} alt="Member" src={imgSrc1} style={{ position: 'relative', zIndex: 2, width: 32, height: 32 }} />
                </div>
              ))} */}
               {item.teamMember.map((memberId, index) => (
                    <AvatarAsync key={index} memberId={memberId} />
                ))}

            
            </AvatarGroup>
                                        {/* <Avatar alt="Remy Sharp" src={imgSrc} style={{ position: 'relative', zIndex: 1, width: 24, height: 24 }} /> */}
                                    </div>

                                    {/* <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Travis Howard" src={imgSrc} style={{ position: 'relative', zIndex: 2, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 3, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 4, width: 24, height: 24 }} />
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '-15px' }}>
                                        <Avatar alt="Cindy Baker" src={imgSrc} style={{ position: 'relative', zIndex: 5, width: 24, height: 24 }} />
                                    </div> */}
                                </div>

                            </Grid>
            <Grid item xs={2} md={2} justifyContent="center" container>
              <Link href={`/#/project-det/${item._id}`}>
                <MdOutlineRemoveRedEye size={25} />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <hr
              style={{
                border: "1px solid rgba(175, 175, 175, 0.9)",
                width: "100%",
                height: "1px",
                position: "relative",
                bottom: "7px",
              }}
            />
          </Grid>
        </div>
      ))}

      {/* Pagination component */}
      <Grid
        item
        xs={12}
        md={12}
        container
        justifyContent="end"
        alignItems="end"
        sx={{ marginBottom: "1%", paddingRight: "5%" }}
      >
        <MuiPagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
          //   shape="rounded"
          variant="outlined"
        />
      </Grid>
      {/* </div> */}
    </div>
  );
}

export default HomeGrid;
