import Sidebar from "../../components/HomePage/Sidebar";
import TeamView from "../../components/HomePage/TeamView";




function TeamViews() {
    return(
        <Sidebar Component={<TeamView/>} />
    )
}

export default TeamViews;