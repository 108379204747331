
import AddMember from "../../components/HomePage/AddMember";

import Sidebar from "../../components/HomePage/Sidebar";

function Member() {
    return(
        <div>
        <Sidebar Component={<AddMember/>}/>
        
        </div>
    );
}

export default Member;