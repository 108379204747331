import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { all } from "axios";
import { createTemplate1, createTemplate2, createTemplate4, createTemplate8, createTemplate9 } from "../pages/canvas/components/TemplateCreaters";




export const submitFormData = createAsyncThunk(
    'data/submitFormData',
    async (formData, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/customization`, formData);
        return response.data?.savedData;
      } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrow the error to propagate it to the caller
      }
    }
  );
  
  export const addTemplate = createAsyncThunk(
'canvas/add-template',
async (template) =>{
  try{
const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/add-template`,{
  templateId : template
})
console.log(response.data.templateId,"template iddd")
return response.data.templateId
  }catch(error){
    console.error(error)
  }
}

  )
 
  ///This api is used to gets the uploaded file columns data rows data
export const getUploadedFiles = createAsyncThunk('canvas/files', async (filename ,{dispatch}) => {

 
  try {
// dispatch(setLoading1(true))
    const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/files/${filename}`);
    // console.log(response,"canvas reducer")
    // dispatch(setLoading1(false))
    return response.data?.data;
   
  } catch (error) {
    console.error(error);
    throw error;
  }
})

//firstlly runs this api and gets all the files 
export const getUploadedFiles2 = createAsyncThunk('canvas/getfileInfo', async () => {
  
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/getfileInfo`)
    return response.data
  }
  catch (error) {
    console.log(error)
  }
})

export const saveSingleSelectedVariable = createAsyncThunk(
  'canvas/saveSingleSelectedVariable',
  async ({ variableName, templateId }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/save-single-variable-info`, { variableName, templateId });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

export const saveGroupSelectedVariable = createAsyncThunk(
  'canvas/saveGroupSelectedVariable',
  async ({ variableName0,variableName1,variableName2,variableName3, templateId }) => {
    try{
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/save-group-variable-info`, { variableName0,variableName1,variableName2,variableName3, templateId });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);

//Presets variable saves

export const saveSinglePresetSelectedVariable = createAsyncThunk(
  'canvas/saveSingleSelectedPresetVariable',
  async ({ variableName, templateId }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/save-single-preset-variable-info`, { variableName, templateId });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);
export const saveGroupSelectedPresetVariable = createAsyncThunk(
  'canvas/saveGroupSelectedVariable',
  async ({ variableName0,variableName1,variableName2,variableName3, templateId }) => {
    try{
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/save-group-preset-variable-info`, { variableName0,variableName1,variableName2,variableName3, templateId });
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
);


export const getSingleSelectedPresetVariable = createAsyncThunk(
  'canvas/getSingleSelectedPresetVariable',
  async (templateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-single-preset-variable-info/${templateId}`)
      return response.data
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  }
)
export const getSingleSelectedVariable = createAsyncThunk(
  'canvas/getSingleSelectedVariable',
  async (templateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-single-variable-info/${templateId}`)
      return response.data
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  }
)
export const getGroupSelectedVariable = createAsyncThunk(
  'canvas/getGroupSelectedVariable',
  async (templateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-group-variable-info/${templateId}`)
      return response.data
    }
    catch (error) {
      console.error(error);
      throw error;
    }
  }
)

export const getAllSingleVariableWidgets = createAsyncThunk(
  'canvas/getAllSingleVariableWidgets',
  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-all-single-variable-info`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getAllSinglePresetVariableWidgets = createAsyncThunk(
  'canvas/getAllSinglePresetVariableWidgets',
  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-all-single-preset-variable-info`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getAllGroupVariableWidgets = createAsyncThunk(
  'canvas/getAllGroupVariableWidgets',
  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-all-group-variable-info`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getAllGroupPresetVariableWidgets = createAsyncThunk(
  'canvas/getAllGroupPresetVariableWidgets',
  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/canvas/get-all-group-preset-variable-info`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getRightsideVariables = createAsyncThunk(
  'canvas/getRightsideVariables',
  async(projectId, { rejectWithValue })=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-rightsidebar-variables/${projectId}`);
      // console.log(response.data,"response data")
      const template = response.data?.templates;
    
      if (!template) {
        // Handle case where template is missing or incomplete
        return rejectWithValue('Template not found');
      }

      return template;
    } catch (error) {
      console.error('Error fetching saved variables:', error);
    }
  }
)

const canvas = createSlice({
    name: "canvas",
  
    initialState: {
    addCustomization : {}, 
    // status : "",
    error: null,
    loading : false,
    connectorLines : [],
    addingTemplate : [],
    getProjectData : {},
    fileInfoLoading:false,
    fileInfo:null,
    filesLoading:false,
    files:null,
    selectedVariable: null,

    singleSelectedVariable:null,
    singleSelectedVariableLoading:false,
    singleSelectedPresitVariable : null,
    singleSelectedPresitVariableLoading:false,

    allsingleVariableWidgets:null,
    allsingleVariableWidgetsLoading:false,

    allSinglePresitVariableWidgets:null,
    allSinglePresitVariableWidgetsLoaded:false,

    allGroupVariableWidgets:null,
    allGroupVariableWidgetsLoading:false,

 selectedOption : false,

    allGroupPresitVariableWidgets : null,
    allGroupPresitVariableWidgetsLoading : false,

    groupSelectedVariable:null,
    groupSelectedVariableLoading:false,
    rightSideVariables: [],
    rightSideVariablesLoading:false,
    isLoading : false,
    dropDownSaveVariables : [],
    lastEditedCanvas : new Date(),
    openRightSidebar : false,
    screenshot : null,
    mutateData : {},
    formulaWidget : {},
    setModelGraphData : []
    }, 

   reducers:{
    setModelGraph : (state,action) => {
      state.setModelGraphData  = action.payload
    },
    //set screenshot of canvas when clicked on create model button
    setScreenShot : (state,action) =>{
state.screenshot = action.payload
    },
    setMutateData : (state,action) =>{
state.mutateData = action.payload
    },
    setFormulaWidget : (state,action) =>{
state.formulaWidget = action.payload
    },
    setSelectedOption : (state,action) =>{//his is used to freezes the canvas section
    
      state.selectedOption = action.payload
    },

    setOpenRightBar : (state,action) =>{//his is used to freezes the canvas section
    
      state.openRightSidebar = action.payload
    },
    setLoading1 : (state,action)=>{
      state.isLoading = action.payload
      // state.isLoading = false
    },
///get project Data as per id 
    projectData : (state,action)=>{
    state.getProjectData =  action.payload
    },

    updateFileInfo: (state,action)=>{
      state.fileInfo = action.payload
    },
    // updateSelectedVariable:(state,action)=>{
    //   state.singleSelectedVariable.variableName = action.payload
    // },
    

    clearConnectorLines: (state) => {
      state.connectorLines = [];
    },
    updateConnectorLines: (state, action) => {
      state.connectorLines = action.payload;
    },

savedDatabaseTemplate  : (state,action) =>{//gets the api based on the projectID
 
state.addingTemplate = action.payload
},
 
dropTemplateID : (state,action)=>{//post api
  const { newTemplate } = action.payload;

  state.addingTemplate = [...state.addingTemplate , newTemplate]  //Inside there all templates are show id and template
},
deleteConnectorLineAction: (state, action) => {
  state.connectorLines = state.connectorLines.filter((_, index) => index !== action.payload);
},

savedCurrentConnector : (state,action) => {///post api saves lines
  // console.log(action.payload,"moann") 
  if(Array.isArray(action.payload))
  {
    // console.log(action.payload)
    state.connectorLines = action.payload
  }
  else{

  const {connectorLines}  = action.payload;   
 
  state.connectorLines = [...state.connectorLines , connectorLines]
}
},

lastEditedCanvas : (state,action)=>{
 state.addingTemplate = action.payload
state.lastEditedCanvas = action.payload;
},
getConnectorLine : (state, action) => {//get api to get lines
state.connectorLines = action.payload

},
clearRightSideBarVariables : (state, action) => {
state.rightSideVariables = []
},
saveRightSideVariables: (state, action) => {
  const newData = action.payload;
  // console.log(newData, "newData");

  // Initialize rightSideVariables to an empty array if it's undefined
  if (!state.rightSideVariables) {
    state.rightSideVariables = [];
  }

  const existingIndex = state.rightSideVariables.findIndex(
    (item) => item.type === newData.type && item.unique_widget_id === newData.unique_widget_id
  );
  if (existingIndex !== -1) {
    // If type exists, update data
    state.rightSideVariables[existingIndex] = newData;
  } else {
    // console.log("IN array");
    // If type doesn't exist, push new data
    state.rightSideVariables.push(newData);
  }
},

 
saveUpdatedConnectorLines : (state,action)=>{
 console.log(action.payload)
},

clearAddingTemplate: (state) => {//clearing the template in
  state.addingTemplate = [];

},
updatesTemplate : (state,action) => {//puts api to update the template dimensions when we move anywhere

  const { newTemplate } = action.payload;
  state.addingTemplate = [...state.addingTemplate , newTemplate] 

},

getRightSidebarVariablesSuccess : (state,action)=>{
  state.dropDownSaveVariables = action.payload;
},


updateDropTemplateId: (state, action) => {
  const { selectedIndex, currentId,newid ,selectedColor,template} = action.payload;

//if the selectedIndex is not comes it means the user has not selcted any template then not goes in switch case

// console.log(template)

  let updatedTemplate;
  switch(selectedIndex){
    case 'template9':
      updatedTemplate = createTemplate9({type:selectedIndex},752.7291,  263,selectedColor);
       break;
    case 'template2':
      updatedTemplate = createTemplate2({type:selectedIndex},752.7291,  263,selectedColor);
      break;
    case 'template4':
      updatedTemplate = createTemplate4({type:selectedIndex}, 752.7291,  263,selectedColor);
       break;
    
    default : 
    console.log("None of the template name is matched")
    
  }

  // console.log(updatedTemplate)

  if(!selectedIndex) {
    const oldTemplateIndex = state?.addingTemplate?.findIndex(template => template?.newTemplate.id === currentId);
    console.log("Old  Index:", oldTemplateIndex);
    if (oldTemplateIndex !== -1) {
      
      const newAddingTemplate = [...state.addingTemplate];
  
      newAddingTemplate[oldTemplateIndex] = {newTemplate : template.newTemplate , id : newid};
      
   
      state.addingTemplate = newAddingTemplate;
    } 
    return;
    }

  const oldTemplateIndex = state?.addingTemplate?.findIndex(template => template?.newTemplate.id === currentId);
  console.log("Old Template Index:", oldTemplateIndex);

  
  if (oldTemplateIndex !== -1) {
    // Create a new addingTemplate array with the updated template ID
    const newAddingTemplate = [...state.addingTemplate];

    newAddingTemplate[oldTemplateIndex] = {newTemplate : updatedTemplate , id : newid};
    
    // Update the state immutably
    state.addingTemplate = newAddingTemplate;
  } else {
    console.warn('Template not found in state for update:', action.payload.oldId);
  }
  state.loading = false;
}

   },
   extraReducers: (builder) => {
    builder
      .addCase(submitFormData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

     
      .addCase(submitFormData.fulfilled, (state, action) => {
        state.addCustomization = action.payload;
        state.loading = false;
      })
      .addCase(submitFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUploadedFiles.pending, (state, action) => {
        state.fileInfoLoading = true
      })
     .addCase(getUploadedFiles.fulfilled, (state, action) => {
   
      state.fileInfoLoading = false
      state.fileInfo = action.payload
    
    })
     .addCase(getUploadedFiles.rejected, (state, action) => {
      state.fileInfoLoading = false; // Reset loading state on error
      state.fileInfo = null;
   
    })
      .addCase(getUploadedFiles2.pending, (state, action) => {
        state.filesLoading = true
      })
     .addCase(getUploadedFiles2.fulfilled, (state, action) => {
      state.filesLoading = false
      state.files = action.payload
    
    })
     .addCase(getUploadedFiles2.rejected, (state, action) => {
      state.files = null
      state.filesLoading = false
    })
      .addCase(getSingleSelectedVariable.pending, (state, action) => {
        state.singleSelectedVariableLoading = true
      })
     .addCase(getSingleSelectedVariable.fulfilled, (state, action) => {
      state.singleSelectedVariable = action.payload
      state.singleSelectedVariableLoading = false
    })
     .addCase(getSingleSelectedVariable.rejected, (state, action) => {
      state.singleSelectedVariable = null
      state.singleSelectedVariableLoading = false
    })
      .addCase(getSingleSelectedPresetVariable.pending, (state, action) => {
        state.singleSelectedPresitVariableLoading = true
      })
     .addCase(getSingleSelectedPresetVariable.fulfilled, (state, action) => {
      state.singleSelectedPresitVariable = action.payload
      state.singleSelectedPresitVariableLoading = false
    })
     .addCase(getSingleSelectedPresetVariable.rejected, (state, action) => {
      state.singleSelectedPresitVariable = null
      state.singleSelectedPresitVariableLoading = false
    })
    .addCase(getAllSinglePresetVariableWidgets.fulfilled, (state, action) => {
      state.allSinglePresitVariableWidgets = action.payload
      state.allSinglePresitVariableWidgetsLoaded = false
    })
    .addCase(getAllGroupPresetVariableWidgets.fulfilled, (state, action) => {
      state.allGroupPresitVariableWidgets = action.payload
      state.allGroupPresitVariableWidgetsLoading = false
    })

      .addCase(getAllSingleVariableWidgets.pending, (state, action) => {
        state.allsingleVariableWidgetsLoading = true
      })
     .addCase(getAllSingleVariableWidgets.fulfilled, (state, action) => {
      state.allsingleVariableWidgets = action.payload
      state.allsingleVariableWidgetsLoading = false
    })
     .addCase(getAllSingleVariableWidgets.rejected, (state, action) => {
      state.allsingleVariableWidgets = null
      state.allsingleVariableWidgetsLoading = false
    })
      .addCase(getAllGroupVariableWidgets.pending, (state, action) => {
        state.allGroupVariableWidgetsLoading = true
      })
     .addCase(getAllGroupVariableWidgets.fulfilled, (state, action) => {
      state.allGroupVariableWidgets = action.payload
      state.allGroupVariableWidgetsLoading = false
    })
     .addCase(getAllGroupVariableWidgets.rejected, (state, action) => {
      state.allGroupVariableWidgets = null
      state.allGroupVariableWidgetsLoading = false
    })
      .addCase(getGroupSelectedVariable.pending, (state, action) => {
        state.groupSelectedVariableLoading = true
      })
     .addCase(getGroupSelectedVariable.fulfilled, (state, action) => {
      state.groupSelectedVariable = action.payload
      state.groupSelectedVariableLoading = false
    })
     .addCase(getGroupSelectedVariable.rejected, (state, action) => {
      state.groupSelectedVariable = null
      state.groupSelectedVariableLoading = false
    })
      .addCase(getRightsideVariables.pending, (state, action) => {
        state.rightSideVariablesLoading = true
      })
     .addCase(getRightsideVariables.fulfilled, (state, action) => {
      state.rightSideVariables = action.payload
      state.rightSideVariablesLoading = false
    })
     .addCase(getRightsideVariables.rejected, (state, action) => {
      state.rightSideVariables = null
      state.rightSideVariablesLoading = false
    })

      // .addCase(dropTemplateID.pending,(state)=>{
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(dropTemplateID.fulfilled,(state,action)=>{
      //   console.log("addingTemplateaData",action.payload)
      //   state.addingTemplate = action.payload
      //   state.loading = false
      // })
  }
  });



  export const {
    dropTemplateID,updateDropTemplateId,clearAddingTemplate,savedDatabaseTemplate,getConnectorLine,savedCurrentConnector, updateFileInfo,clearConnectorLines, updateConnectorLines
    ,setModelGraph,setMutateData,deleteConnectorLineAction ,setFormulaWidget,setScreenShot,projectData,setLoading1,getRightSidebarVariablesSuccess,saveRightSideVariables,setOpenRightBar,setSelectedOption,lastEditedCanvas,clearRightSideBarVariables
  } = canvas.actions;
  
  export default canvas.reducer;