import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';


export default function SimpleContainer7() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{marginTop: '7%'}}>
        <Box >
          <h4
            style={{
              color: '#000',
              textAlign: 'center',
              fontSize: '36px',
              fontStyle: 'normal',
              fontWeight: '500',
            }}
          >
            Faqs
          </h4>
          <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '5%' }}>
  {[1, 2, 3, 4, 5].map((index) => (
    <Grid item xs={12} sm={12} md={12} key={index}>
      <Accordion>
        <AccordionSummary
          expandIcon={ <AddIcon
            sx={{
              fontSize: '4rem', // Adjust the size as needed
              // backgroundColor: '#50A4DB', // Change the background color
              borderRadius: '50%', // Make the icon round
              color: '#50A4DB', // Change the icon color
            }}
          />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          style={{fontWeight: '500',fontSize: '25px'}}
        >
          Lorem ipsum dolor sit amet consectetur. Senectus ornare.
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <hr
        style={{
          width: '100%',
          backgroundColor: '#000',
        }}
      /> */}
    </Grid>
  ))}
</Grid>

        </Box>
      </Container>
    </React.Fragment>
  );
}
