import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

export const addMemberInProject = createAsyncThunk('/add-member',async ({projectId,formValue})=>{
    // const toastId = toast.loading("Loading...");
console.log({projectId, formValue})
    // try{
    //     const accessToken = JSON.parse(localStorage.getItem('access-token'));

    //     const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/project/add-member/${projectId}`,
    //     formValue,
    //     {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //         },
    //     }
    //     );

    //     // toast.success("Member added successfully", { id: toastId });
    //     console.log(response.data.teamMember.teamMember)
    //     toast.success(response.data?.message)
        
    //     return response.data?.teamMember?.teamMember
    // }catch(error){
    //   toast.error(error?.response?.data?.message)
    //     console.error(error);
    // }
})


export const removeMember = createAsyncThunk('/api/remove-member',async({projectId, memberIdToRemove},{rejectWithValue})=>{

  try {
    const response = await axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/project/remove-member`, {
      data: { projectId, memberIdToRemove },
    });

    console.log(response.data)
    return response.data.project;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
}
);


const project = createSlice({
    name: "project",
  
    initialState: {
    addMember : [],
    status : "",
    loading : false,
    createProjectLoader : false
    },
   reducers:{
createProject : (state,action)=>{
  state.createProjectLoader = action.payload
}
   },
    extraReducers: (builder) => {
      ///this is used to handle asynchronious function like api
      builder.addCase(addMemberInProject.fulfilled, (state, action) => {
        console.log("action.payload",action.payload)
        // state?.addMember?.push(action?.payload);
        state.loading = false;
      });
      builder.addCase(addMemberInProject.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addMemberInProject.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.error.message;
      });

      builder
      .addCase(removeMember.pending, (state) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(removeMember.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addMember = action.payload
        state.loading = false;                   
                        
        // state.status = action.status;
        state.error = null;
      })
      .addCase(removeMember.rejected, (state, action) => {
        state.status = 'failed';
        state.loading = false;
        // state.error = action.payload.message;
      });
      
    },
  });



  export const {
createProject
  } = project.actions;
  
  export default project.reducer;