import React, { useEffect, useState } from 'react';
import "../index.css"
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const CustomDropdown = ({ onClose, onTemplateSelect,position }) => {
 
  const dropdownStyle = {
    position: 'absolute',
    // top: `${position.top}px`,
    top:"30px",
    left: `${position.left}px`,
    zIndex: 1000, 
 
  };
const params = useParams()
const [canvasPresets , setCanvasPresets] = useState(null);

  const getCanvasPerArea = async() =>{
  
    await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects/${params.id}`)
    .then((response)=>{
    
      setCanvasPresets(response?.data)
    })
    .catch((err)=>{
      console.error(err);
    
    })

  }

  useEffect(()=>{
    getCanvasPerArea()
  },[])

  return (
    <div className="custom-dropdown" style={dropdownStyle}>
      <div className="dropdown-body">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
  <Icon icon="iconamoon:arrow-left-2-light" width="19" />
  <h3 style={{ fontWeight: '500', marginBottom: '0', fontSize: '16px', marginLeft: '5px' }}>Canvas</h3>
</div>
<hr />


        {/* First template there are only css and names */}
        
      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start',  marginBottom:"-17px"}}>
      <div draggable onDragStart={(e) => onTemplateSelect({ type: 'basictemplate' }, e)} className="template-item" style={styles.blueCard}>
            <div style={styles.basic}></div>
          </div>
          <p className='pt-2' style= {{fontSize:"12px" }}> Basic Template</p>
        </div> */}
       {/* <hr /> */}




        <h6 style={{ fontWeight: '500', fontSize: '16px', marginBottom: '5px' }}>Uploaded data Variable</h6>
      
        <div style={{ display: 'flex',justifyContent: 'flex-start', padding: '9px 0 0 2px',  marginBottom:"-17px"}}>
  {/* Orders Page Template */}
  <div className="template-container" style={{ marginRight:"18px"}}>
  <div draggable onDragStart={(e) => onTemplateSelect({ type: 'uploadSingleVariable', industry: `${canvasPresets?.industry} 3 `,text : "Uploaded Single Variable Widget" }, e)} className="template-item" style={styles.blueCard}>     
  <div style={styles.basic}></div>
      {/* <div style={styles.temp3rectangle}></div>
      <div style={styles.temp3rectangle}></div> */}
        
    </div>
    {/* <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px"  }}>{canvasPresets?.industry} 3</p> */}
    <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px",fontWeight:'500'  }}>Single Variable Widget </p>
         
  </div>
  
  <div className="template-container">
  <div draggable onDragStart={(e) => onTemplateSelect({ type: 'uploadGroupVariable' , industry: `${canvasPresets?.industry} 4 `,text : "Uploaded Group Variable Widget"}, e)} className="template-item" style={styles.blueCard}>
      <div style={styles.basic}></div>
      <div style={styles.basic}></div>
            <div style={styles.basic}></div>
            <div style={styles.basic}></div>
      {/* <div style={styles.rectangleContainer}>
        <div style={styles.square}></div>
        <div style={styles.square}></div>
      </div>
      <div style={styles.rectangleContainer}>
        <div style={styles.square}></div>
        <div style={styles.square}></div>
      </div> */}
    </div>
    
    {/* <p  className='pt-2' style={{ textAlign: 'center' ,fontSize:"12px" }}>{canvasPresets?.industry} 4</p> */}
    <p  className='pt-2' style={{ textAlign: 'center',fontSize:"11px",fontWeight:'500'  }}>Group Variable Widget </p>
         
  </div>

</div>

      
      <hr />
      
        <h6 style={{ fontWeight: '500', fontSize: '16px', marginBottom: '5px' }}> Presets Variable</h6>
      
        <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '9px 0 0 2px', marginBottom:"-17px" }}>
          {/* Homepage Template */}
          <div className="template-container" style={{ marginRight:"18px"}}>
          <div draggable onDragStart={(e) => onTemplateSelect({ type: 'singlePresetVariable', industry: `${canvasPresets?.industry} 1 `,text : "Single Preset Variable Widget"   }, e)} className="template-item" style={styles.blueCard}>
            <div style={styles.basic}></div>
           
            {/* <div style={styles.rectangleContainer}>
            <div style={styles.verticalRectangle}></div>
            <div style={styles.verticalRectangle}></div>


          </div> */}


          </div>
          {/* <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px"  }}>{canvasPresets?.industry} 1</p> */}
          <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px",fontWeight:'500'  }}>Single Preset  Widget </p>
          </div>
          
          {/* Sales Page Template */}
          <div className="template-container">
          <div draggable onDragStart={(e) => onTemplateSelect({ type: 'groupPresetVariable' , industry: `${canvasPresets?.industry} 2 `,text : "Group Preset Variable Widget"}, e)} className="template-item" style={styles.blueCard}>
            <div style={styles.basic}></div>
            <div style={styles.basic}></div>
            <div style={styles.basic}></div>
            <div style={styles.basic}></div>
            {/* <div style={styles.rectangleContainer}>
      <div style={styles.verticalRectangle}></div>
      <div style={styles.squareContainer}>
        <div style={styles.square}></div>
        <div style={styles.square}></div>
    </div> 
    
    </div> */}
          
        </div>
        {/* <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px"  }}>{canvasPresets?.industry} 2</p> */}
        <p  className='pt-2' style={{ textAlign: 'center',fontSize:"11px",fontWeight:'500'  }}>Group Preset  Widget </p>
         
          </div>
          
        </div>

        <hr />
        <h6 style={{ fontWeight: '500', fontSize: '16px', marginBottom: '5px' }}>Manual Dummy Variable</h6>
      
        <div style={{ display: 'flex',justifyContent: 'flex-start', padding: '9px 0 0 2px',  marginBottom:"-17px"}}>
  {/* Orders Page Template */}
  <div className="template-container" style={{ marginRight:"18px"}}>
  <div draggable onDragStart={(e) => onTemplateSelect({ type: 'mutuateVariable', industry: `${canvasPresets?.industry} 3 `,text : "Mutate Variable Widget" }, e)} className="template-item" style={styles.blueCard}>     
  <div style={styles.basic}></div>
      {/* <div style={styles.temp3rectangle}></div>
      <div style={styles.temp3rectangle}></div> */}
        
    </div>
    {/* <p  className='pt-2' style={{ textAlign: 'center',fontSize:"12px"  }}>{canvasPresets?.industry} 3</p> */}
    <p  className='pt-2' style={{ textAlign: 'center',fontSize:"11px",fontWeight:'500'  }}>Mutate Variable Widget</p>
         
  </div>
  
  <div className="template-container">
  <div draggable onDragStart={(e) => onTemplateSelect({ type: 'formulaVariable' , industry: `${canvasPresets?.industry} 4 `,text : "Formula Variable Widget"}, e)} className="template-item" style={styles.blueCard}>
      <div style={styles.basic}></div>
      {/* <div style={styles.basic}></div>
            <div style={styles.basic}></div>
            <div style={styles.basic}></div> */}
      {/* <div style={styles.rectangleContainer}>
        <div style={styles.square}></div>
        <div style={styles.square}></div>
      </div>
      <div style={styles.rectangleContainer}>
        <div style={styles.square}></div>
        <div style={styles.square}></div>
      </div> */}
    </div>
    
    {/* <p  className='pt-2' style={{ textAlign: 'center' ,fontSize:"12px" }}>{canvasPresets?.industry} 4</p> */}
    <p  className='pt-2' style={{ textAlign: 'center',fontSize:"11px",fontWeight:'500'  }}>Formula Variable Widget </p>
         
  </div>
</div>

      

         </div>
    </div>
  );
};






// Styles for the templates
export const styles = {
  blueCard: {
    width: '80px',
    height: '93px',
    backgroundColor: '#50A4DB ', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '5px',
  },
  basic: {
    width: '64px',
    height: '20px',
    backgroundColor: 'white',
    marginBottom: '5px',
    borderRadius: '5px',
  },
  temp3rectangle: {
    width: '61px',
    height: '26px',
    backgroundColor: 'white',
    marginBottom: '5px',
    
  },
  rectangleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%', 
  },
  verticalRectangle: {
    width: '27px',
    height: '53px', 
    backgroundColor: 'white',
    margin: '2px',
  },
  squareContainer: {
    display: 'flex',
    flexDirection: 'column', 
  },
  square: {
    width: '27px',
    height: '26px',
    backgroundColor: 'white',
    margin: '2px',
  },
  
};

export const template1 = {
  
  type : "template8",
  
  blueCard: {
    width: '80px',
    height: '93px',
    backgroundColor: '#50A4DB ', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '5px',
  },
  basic: {
    width: '61px',
    height: '20px',
    backgroundColor: 'white',
    marginBottom: '5px',
    
  },
  temp3rectangle: {
    width: '61px',
    height: '26px',
    backgroundColor: 'white',
    marginBottom: '5px',
    
  },
  rectangleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%', 
  },
  square: {
    width: '27px',
    height: '26px',
    backgroundColor: 'white',
    margin: '2px',
  },
  
}
export const template2 = {
  type : "template9",
  blueCard: {
    width: '80px',
    height: '93px',
    backgroundColor: '#50A4DB ', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '5px',
  },
  basic: {
    width: '61px',
    height: '20px',
    backgroundColor: 'white',
    marginBottom: '5px',
    
  },
  rectangleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%', 
  },
  square: {
    width: '27px',
    height: '26px',
    backgroundColor: 'white',
    margin: '2px',
  },
  
}
export const template3 = {
  type : "template10",
  blueCard: {
    width: '80px',
    height: '93px',
    backgroundColor: '#50A4DB ', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '5px',
  },
  basic: {
    width: '61px',
    height: '20px',
    backgroundColor: 'white',
    marginBottom: '5px',
    
  },
  rectangleContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%', 
  },
  verticalRectangle: {
    width: '27px',
    height: '53px', 
    backgroundColor: 'white',
    margin: '2px',
  },
  squareContainer: {
    display: 'flex',
    flexDirection: 'column', 
  },
  square: {
    width: '27px',
    height: '26px',
    backgroundColor: 'white',
    margin: '2px',
  },
}
export default CustomDropdown