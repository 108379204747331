import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Grid } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const MultiAxisLineChart = ({ chartData }) => {
  const data = {
    datasets: chartData.datasets.map(dataset => ({
      ...dataset,
      data: dataset.data.map(value => Math.floor(Math.random() * 50)), // Generate random data here
    })),
    labels: chartData.labels,
  };

const options = {
  responsive: true,
  scales: {
    yAxes: chartData.yAxes.map(yAxis => ({
      ...yAxis,
      display: yAxis.display !== 'false', // Convert string 'false' to boolean false
    })),
  },
  plugins: {
    legend: {
      position: 'right', // Position legend to the right
    },
  },
};


  return (
    <Grid item container direction="row"  sx={{ width: '100%', height: 'auto', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)' }}>
      <div style={{
        color: '#000',
        fontSize: '24px',
        fontWeight: '500',
        textAlign: 'center',
        marginLeft: '10px',
        marginTop: '10px',

      }}>
        <p>Simulation</p>
      </div>
      <Grid item xs={12} md={12} sx={{marginLeft : '5%',height: '215px',width:'100%', position: 'relative', bottom: {xs: '15px', md: '10px'}}} >
  
      <Line data={data} options={options} style={{minWidth:'95%' ,height : '215px'}} /> 
     
    </Grid>

    </Grid>
  );
};

export default MultiAxisLineChart;
