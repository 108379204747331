import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function HomeDelete({projectId}) {
  const navigate = useNavigate()


  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose =async () => {
try{

  navigate(`/project-det/${projectId}`)
setOpen(false);
}catch(error){
  console.error(error)
}


  };
  const users = JSON.parse(localStorage.getItem('users'));
  const isDisabled = users?.designation === 'teamMember' 

const removingProject = async (req,res)=>{
  const response = await axios.delete(`${process.env.REACT_APP_PUBLIC_BASE_URL}/project-delete/${projectId}`);
  setOpen(false);
  
  navigate(`/#/project-det/${projectId}`)
}

  return (
    <React.Fragment>
      <Button disabled={isDisabled} variant="contained" onClick={handleClickOpen} sx={{
              // width: '10%',
              // height: '60px',
              backgroundColor: '#50A4DB ',
              borderRadius: '8px',
              color: '#FFF',
              fontSize: '24px',
              fontWeight: '600',
              textTransform: 'none',
              padding: '0px 40px 0px 40px'
              // marginLeft: '5%',
              // marginTop: '1%'
            }}>
        Delete
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth= "md"
      >
        <DialogTitle id="responsive-dialog-title">
       
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                    <DeleteIcon sx={{fontSize: '30px'}}/>
                </Grid>
                <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" sx={{textAlign: 'center', fontSize: '20px', marginTop: '1%'}}>
                    <p >Are you sure you want to delete this file ?</p>
                </Grid>
        
            
          </DialogContentText>
        </DialogContent>
        <Grid  item direction="row" container justifyContent="center" textAlign="center" spacing={2} sx={{paddingBottom: '2%'}}>
        <Grid item  xs={5} md={6} container justifyContent="center" alignItems="center" >
          <Button autoFocus onClick={handleClose} sx={{          
            border: '1px solid #50A4DB ',
            borderRadius: '8px',
            color: '#50A4DB ',
            fontSize: '18px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px'
            
          }}>
            Go Back
          </Button>
        </Grid>
        <Grid item xs={5} md={6} container justifyContent="center" alignItems="center">
          <Button onClick={removingProject} autoFocus sx={{            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '300',
            textTransform: 'none',
            padding: '5px 20px 5px 20px',
            "&:hover": {
              backgroundColor: "#50A4DB ", // Set to "inherit" or any other color you want on hover
            },
          }}>
            Delete
          </Button>
        </Grid>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}
