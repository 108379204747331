import PaymentConfirm from "../../components/Subscription/PaymentConfirm";
import Sidebar from "../../components/HomePage/Sidebar";

function PayConfirmPage() {
    return(
        <div>
        <Sidebar Component={<PaymentConfirm/>} />
        </div>
    )
}
export default PayConfirmPage;