
import AdminSidebar from "../../components/AdminPages/AdminSidebar";
import AdminSubscription from "../../components/AdminPages/AdminSubscription";

function Ad_Subscription() {
    return(
        <AdminSidebar Component={<AdminSubscription/>} />
    );
}

export default Ad_Subscription;