import React from 'react';
import Grid from '@mui/material/Grid';
import { Card, CardContent } from '@mui/material';
import imgSrc from '../../Images/Excludee.jpg';
import imgSrc2 from '../../Images/Excludee2.jpg';
import imgSrc3 from '../../Images/service.png';


export default function SimpleContainer8() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Grid item container justifyContent="center" xs={12} md={12} sx={{
        color: '#000',
        fontSize: '30px',
        fontWeight: '500',
        lineHeight: 'normal',
      }}>
        <p>
          Know about our Services
        </p>
      </Grid>
      <Grid item container justifyContent="center" xs={12} md={8} sx={{
        // color: '#AFAFAF',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: 'normal',
        textAlign: 'center', // Add this property for centering text
      }}>
        <p>
          Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
        </p>
      </Grid>

      <Grid container spacing={5} sx={{marginTop: '1%'}}>
      {/* First Row */}
      <Grid item container xs={12} md={6}>
        {/* Card in the first 6 units */}
        <Grid item xs={12} container justifyContent="end">
          
          <Card sx={{ height: '100%', width: '60%', border: '1px solid #FFF', boxShadow: '10px 5px 5px  #50A4DB', borderRadius: '12px' }}>
            <CardContent>
              <Grid item container xs={12}>
              <img src={imgSrc3} alt='' width="20%"/>
              </Grid>
              <Grid item container xs={12} sx={{color: '#000', fontSize: '32px', fontWeight: '500', paddingLeft: '3%', marginTop: '2%'}}>
              <p>Quantitative Service</p>           
          
              </Grid>
              <Grid item container xs={12} sx={{width: '93px', height: '7px', border: '1px solid #38B27F', backgroundColor: '#38B27F', borderRadius: '4px'}}>

              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979',
                  fontWeight: '400', fontStyle: 'normal', marginTop: '7%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Ut tristique senectus tellus vitae vel est penatibus. Hac sagittis aliquet habitasse malesuada risus sed. Ipsum risus pharetra mi egestas pharetra. Adipiscing quam eget ultricies aliquet. Sagittis nisl consectetur porttitor sed nunc augue hendrerit.</p>
              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979',
           fontWeight: '400', fontStyle: 'normal', marginTop: '5%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo </p>
              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979',
               fontWeight: '400', fontStyle: 'normal', marginTop: '5%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo </p>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
        {/* Two Images below the card */}
        <Grid item container direction="row" xs={12} justifyContent="end" spacing={2} sx={{marginTop: '1%'}}>
          <Grid item xs={1} container justifyContent="end">
            <img src={imgSrc} alt="" width="100%" />
          </Grid>
          <Grid item xs={1} container>
            <img src={imgSrc2} alt="" width="100%" />
          </Grid>
        </Grid>
      </Grid>

      {/* Second Row */}
      <Grid item container xs={12} md={6} sx={{marginTop: '2%'}}>
        {/* Two Images on top */}
        <Grid item container direction="row" xs={12}spacing={2}>
          <Grid item xs={1} container>
            <img src={imgSrc} alt="" width="100%" />
          </Grid>
          <Grid item xs={1} container>
            <img src={imgSrc2} alt="" width="100%" />
          </Grid>
        </Grid>
        {/* Card in the next 6 units */}
        <Grid item xs={12} sx={{marginTop: '4%'}}>
          <Card sx={{ height: '100%', width: '60%', border: '1px solid #FFF', boxShadow: '10px 5px 5px  #50A4DB', borderRadius: '12px' }}>
          <CardContent>
              <Grid item container xs={12}>
              <img src={imgSrc3} alt='' width="20%"/>
              </Grid>
              <Grid item container xs={12} sx={{color: '#000', fontSize: '32px', fontWeight: '500', paddingLeft: '3%', marginTop: '2%'}}>
              <p>Qualitative Service</p>           
          
              </Grid>
              <Grid item container xs={12} sx={{width: '93px', height: '7px', border: '1px solid #38B27F', backgroundColor: '#38B27F', borderRadius: '4px'}}>

              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979', 
                fontStyle: 'normal', fontWeight: '400', marginTop: '7%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Ut tristique senectus tellus vitae vel est penatibus. Hac sagittis aliquet habitasse malesuada risus sed. Ipsum risus pharetra mi egestas pharetra. Adipiscing quam eget ultricies aliquet. Sagittis nisl consectetur porttitor sed nunc augue hendrerit.</p>
              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979', 
                 fontWeight: '400', fontStyle: 'normal', marginTop: '5%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo </p>
              </Grid>
              <Grid item container xs={12} md={10} sx={{
                // color: '#797979', 
              fontWeight: '400', fontStyle: 'normal', marginTop: '5%', lineHeight: 'normal', paddingLeft: '3%'}}>
                <p style={{fontSize: '18px' ,color:'2f2f2f'}}>Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo </p>
              </Grid>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
    </div>
  );
}
