import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { GiConsoleController } from 'react-icons/gi';


ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = () => {
  const [data, setData] = useState({
    labels: ['Completed', 'Ongoing'],
    datasets: [
      {
        data: [0, 1], // Initialize with one ongoing project
        backgroundColor: ['#55d38d', '#D3D3D3'],
      },
    ],
  });

  const [completedCount, setCompletedCount] = useState(0);
  const [ongoingCount, setOngoingCount] = useState(0);

  const accessToken = JSON.parse(localStorage.getItem('access-token'));

  useEffect(() => {
    // Fetch projects when the component mounts
    axios
      .get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => {
        const projects = response.data;

        // Count the number of projects with each status
        const completedProjects = projects.filter(project => project.status === 'Completed');
        const ongoingProjects = projects.filter(project => project.status === 'Ongoing');

        setCompletedCount(completedProjects.length);
        setOngoingCount(ongoingProjects.length);

        setData({
          labels: ['Completed', 'Ongoing'],
          datasets: [
            {
              data: [completedProjects.length, ongoingProjects.length],
              backgroundColor: ['#55d38d', '#268aff'],
            },
          ],
        });
      })
      .catch(error => {
        console.error('Error fetching projects:', error);
      });
  }, [accessToken]); // Make sure to include accessToken in the dependency array

  const options = {
    // Optional chart options
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true, // Modify the border radius here
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0, // Remove the border around the arc
      },
    },
    cutout: '75%', // Adjust this value to control the width of the doughnut
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ height: '215px', marginRight: '32px' }}>
        <Doughnut data={data} options={options} />
      </div>
      
    </div>
  );
};

export default DoughnutChart;
