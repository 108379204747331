import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './Card3.css'

export default function BasicCard({number}) {
  return (
    <div >
        <div className='circle' style={{position: 'relative', top: '240px', right: '200px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="153" height="153" viewBox="0 0 153 153" fill="none">
                <circle cx="76.5" cy="76.5" r="76.5" fill="#38B27F"/>
            </svg>
        </div>
        <h1 className='number' style={{
            color: '#FFF',
            fontSize: '48px',            
            fontWeight: '500',
            position: 'relative',
            top: '130px',
            right: '200px'           
            
        }}>{number}</h1>
    
    <Card sx={{ maxWidth: 483, marginLeft: 10, backgroundColor: '#F5F5F5' }}>
      <CardContent sx={{marginLeft: '80px'}}>
        <Typography sx={{
            // width: '383px',
            color: '#000',
            fontSize: '30px',
            fontStyle: 'normal',
            fontWeight: '500',
            textAlign: 'left'
        }}>
            Lorem ipsum sit Senectus ornare interdum nibh.
        </Typography> 
        <Typography sx={{
            // width: '302px',
            // height: '76px',
            // color: '#797979',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            textAlign: 'left'
        }}>
        Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imp
        </Typography>          
      </CardContent>     
    </Card>
    </div>
  );
}