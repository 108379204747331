
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import SubscriptionCard from "./SubscriptionCard";
import { Grid } from '@mui/material';
import './UpgradeSubscription.css'

function UpgradeSubscription() {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        marginTop: '2%',
        border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'
      };
    
      const cellStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        // padding: '8px',
      };
    
      // Add special styles for the first and last cells in each row
      const firstCellStyle = {
        ...cellStyle,
        borderLeft: 'none', // Remove left border
        
      };
      const firstRowCellStyle = {
        ...cellStyle,
        borderTop: 'none', // Remove left border
        
      };
      const LastRowCellStyle = {
        ...cellStyle,
        borderBottom: 'none', // Remove left border
        borderLeft: 'none',
        
      };
    
      const lastCellStyle = {
        ...cellStyle,
        borderRight: 'none', // Remove right border
      };
    
    
    
    
    return(
        <div>
          
          <Grid item xs={12} md={12} sx={{
                marginTop: {
                    xs: '20%',
                    sm: '20%',
                    md: '10%',
                  },
            }}> 
            <h5 style={{color: '#000', fontSize: '30px', fontWeight: '500'}}>Subscription Plan</h5>
          </Grid>
          <Grid item xs={12} md={12}>
            <p style={{color: '#AFAFAF', width: '100%', fontSize: '18px', fontWeight: '400', lineHeight: 'normal', marginTop: '2%', marginBottom: '5%'}}>Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis ac sollicitudin nibh. In quam vitae nisl diam purus.
            </p>
          </Grid>
          <Grid container direction="row" spacing={10} justifyContent="center" alignItems="center" >
            <Grid item xs={12} sm={6} md={4}>
              <SubscriptionCard/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SubscriptionCard/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SubscriptionCard/>
            </Grid>
          </Grid>
          <Grid item xs={12}  md={12}>              
            <h5 style={{color: '#000', fontSize: '32px', fontWeight: '500', marginTop: '5%'}}>Compare Plans</h5>
          </Grid>
          
          <table style={tableStyle}>
            
            <tbody>
              <tr className='tableStyle' style={{color: '#000', fontSize: '32px', fontWeight: '400', textDecoration: 'none'}}>
                <td style={{ ...firstCellStyle, ...firstRowCellStyle }}>Features</td>
                <td style={{cellStyle, ...firstRowCellStyle, textAlign: 'center'}}>Plans A</td>
                <td style={{ cellStyle,...firstRowCellStyle, textAlign: 'center' }}>Plans B</td>
                <td style={{ ...lastCellStyle,...firstRowCellStyle, textAlign: 'center' }}>Plans C</td>
              </tr>
              <tr style={{height: '80px'}}>
                <td id='tabContent' style={{...firstCellStyle, color: '#000', fontSize: '24px', fontWeight: '400'}}>Lorem ipsum dolor sit amet consectetur</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...lastCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
              </tr>
              <tr style={{height: '80px'}}>
                <td id='tabContent' style={{...firstCellStyle, color: '#000', fontSize: '24px', fontWeight: '400'}}>Lorem ipsum dolor sit amet consectetur</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...lastCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
              </tr>
              <tr style={{height: '80px'}}>
                <td id='tabContent' style={{...firstCellStyle, color: '#000', fontSize: '24px', fontWeight: '400'}}>Lorem ipsum dolor sit amet consectetur</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...cellStyle, textAlign: 'center'}}>{<ClearIcon sx={{border: '1px solid rgba(255, 0, 0, 1)', color: 'rgba(255, 0, 0, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...lastCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
              </tr>
              <tr style={{height: '80px'}}>
                <td id='tabContent' style={{firstCellStyle, ...LastRowCellStyle, color: '#000', fontSize: '24px', fontWeight: '400'}}>Lorem ipsum dolor sit amet consectetur</td>
                <td style={{cellStyle, ...LastRowCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{cellStyle, ...LastRowCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
                <td style={{...lastCellStyle, ...LastRowCellStyle, textAlign: 'center'}}>{<CheckIcon sx={{border: '1px solid rgba(56, 178, 127, 1)', color: 'rgba(56, 178, 127, 1)', borderRadius: '24px', height: '30px', width: '30px'}}/>}</td>
              </tr>
            </tbody>
            
          </table>
         

        </div>
    )
}

export default UpgradeSubscription;