import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ClipLoader } from 'react-spinners';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Checkbox, Grid, Link, MenuItem, TextField, Typography,DialogTitle, FormHelperText } from '@mui/material';
import './CreateProject.css'
import Select from '@mui/material/Select';

import { useState,useEffect } from 'react';
import ReactSelect from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';
import { getUploadedFiles, getUploadedFiles2, setLoading1 } from '../../Reducers/CanvasReducer';
import { create } from '@mui/material/styles/createTransitions';
import { createProject } from '../../Reducers/memberReducer';
import { LocalSee } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const CustomFormHelperText = styled(FormHelperText)({
  fontSize: '14px', // Increase the font size here
  color: 'red', // Optional: Customize the color if needed
});
export default function CreateProject({refreshProjectList}) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const uuid = uuidv4();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedOption, setSelectedOption] = useState(null);
  const [levels,setLevels] = useState([])
  const [levelCount, setLevelCount] = useState(0)
const [loading,setLoading] = useState(false)

const marks = [
  { value: 0, label: '0' },
  { value: 0.1, label: '0.1' },
  { value: 0.2, label: '0.2' },
  { value: 0.3, label: '0.3' },
  { value: 0.4, label: '0.4' },
  { value: 0.5, label: '0.5' },
];

function valuetext(value) {
  
  // Round the displayed value to two decimal places for consistency
  return `${value.toFixed(2)}`;

}


  const handleInputChange1 = (selectedValue) => {
    
    setSelectedOption(selectedValue);
  };

  const handleClickOpen = () => {
    
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const LoggedInAs = 'Team Manager' || '' || 'Team Member';

  const [projectName, setProjectName] = useState('');
  const [goalName, setGoalName] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [goalType, setGoalType] = useState('');
  const [industry, setIndustry] = useState('');
  const [specializedUnit, setSpecializedUnit] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [file, setFile] = useState(null);
  //Goal Metric
  const [goalMetric,setGoalMetric] = useState('')
  const [metricValue,setMetricValue] = useState('')
//threshold metric
  const [thresholdMetric,setThresholdMetric] = useState('')
  // const [thresholdMetricValue,setThresholdMetricValue] = useState('')
//forcast metric

      // const [forcastMetric,setForcastMetric] = useState('')
      // const[forCastMetricValue,setForCastMetricValue] = useState('')

  const [selectedSubOption, setSelectedSubOption] = useState("");
  const [showSubOptions, setShowSubOptions] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [selectCategory,setSelectCategory] = useState('');
  const [threshold, setThreshold] = useState('');
   const [categoricalName,setCategoricalName] = useState('')
    const [categoryCount,setCategoryCount] = useState(0)
    // const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [categories, setCategories] = useState([]);

   
  const [formErrors, setFormErrors] = useState({
    projectName: '',
    projectDetails,
    goalName: '',
    goalType: '',
    industry: '',
    specializedUnit: '',  
    startDate: '',
    endDate: '',
    teamMember : '',
    file : '',

    selectCategory:  '',
    goalMetric : '',
    metricValue : '',

    // forcastMetric :'',
    // forCastMetricValue : '' ,
    // thresholdMetric : null,
    // thresholdMetricValue :'',

    // metric validation

    // ... (other form fields)
  });
 
  const handleInputChange = (field, value) => {
   
  setFormErrors((prevErrors) => ({
    ...prevErrors,
    [field]: '',
  }));
  
 
  // Update the state for the specific form field
  switch (field) {
    case 'projectName':
      setProjectName(value);
      break;
    case 'projectDetails':
      setProjectDetails(value);
      break;
    case 'goalName':
      setGoalName(value);
      break;
    case 'goalType':
    setGoalType(value);
    setShowSubOptions(!!value); // Show the sub-options dropdown
    setSelectCategory(''); // Reset selected option
   
    setName(''); // Clear name state
    setValue(''); // Clear value state
      break;
    case 'industry':
      setIndustry(value);
      break;
    case 'specializedUnit':
      setSpecializedUnit(value);
      break;
    case 'startDate':
      setStartDate(value);
      break;
    case 'endDate':
      setEndDate(value);
      break;
    
      case 'goalMetric' : 
      setGoalMetric(value);
      break;
      // case 'forCastMetric' : 
      // setForcastMetric(value);
      // break
    // ... (other form fields)
    default:
      break;
  }

  setFormValueToLocalStorage({
    projectName,
    projectDetails,
    goalName,
    goalType,
    industry,
    specializedUnit,
    startDate,
    endDate,
    goalMetric,
    // forcastMetric
  })
  };
  const getFormValuesFromLocalStorage = () => {
    const storedFormValues = JSON.parse(localStorage.getItem('formValues')) || {};
    return storedFormValues;
  };

  useEffect(()=>{
const storedFormValues = getFormValuesFromLocalStorage();
 // Set form values from local storage to state
 setProjectName(storedFormValues.projectName || '');
 setGoalName(storedFormValues.goalName || '');
 setProjectDetails(storedFormValues.projectDetails || '');
 setGoalType(storedFormValues.goalType || '');
 setIndustry(storedFormValues.industry || '');
 setSpecializedUnit(storedFormValues.specializedUnit || '');
 setStartDate(storedFormValues.startDate || '');
 setEndDate(storedFormValues.endDate || '');
 setGoalMetric(storedFormValues.goalMetric || '');
//  setForcastMetric(storedFormValues.forcastMetric || '');
 // Set other state values similarly
  },[])

const setFormValueToLocalStorage = (formValues) =>{
localStorage.setItem('formValues', JSON.stringify(formValues))
}
  const handleNameChange = (event) => {
    setName(event.target.value);
  
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  
   
  };


  const handleMenuItemClick = (value) => {
    
    setSelectCategory(value);
    
    setCategoricalName('') 
    setCategoryCount(0)
    setLevelCount(0)
    
   
  };




   // Event handler to update threshold value
   const handleThresholdChange = (event) => {
    const inputValue = event.target.value;
    // Ensure only numeric input is accepted
    if (/^\d*$/.test(inputValue)) {
      setThreshold(inputValue);
    }
  };

   
  const handleAddCategory = () => {
    if (categoryCount < 5) {
      setCategoryCount(prevCatCount => prevCatCount + 1);
      setCategories(prevCategories => [...prevCategories, { categoryName: '', categoryValue: '' }]);
    } else {
      toast.error("Maximum category count reached!");
    }
  };
  

  const handleCategoryChange = (index,field,value)=>{
    const updatedLevels = [...categories];///if anyone updates the value of previous level
    updatedLevels[index][field] = value;
    setCategories(updatedLevels)
  }
  const handleDropzoneChange = (acceptedFiles) => {
    console.log(acceptedFiles[0],"qwerty")
    // Check if acceptedFiles contain only .xlsx or .csv files
    const isValidFiles = acceptedFiles.every(file => {
      const extension = file.path.split('.').pop().toLowerCase();
      return extension === 'xlsx' || extension === 'csv';
    });
  
    if (!isValidFiles) {
      alert('Please upload only .xlsx or .csv files.');
      return;
    }

    const file = acceptedFiles[0];

    setFile(file)
    
 
  };
  const handleAddLevel = () => {
    if (levelCount < 5) {
      setLevelCount(prevCount => prevCount + 1);
    setLevels([...levels,{levelName : '',levelValue : '',levelMinRange : '',levelMaxRange : ''}])

    } else {
      // Optionally, you can display a message or prevent further addition when the limit is reached
     toast.error("Maximum level count reached!");
    }
  };

  const handleFieldChange = (index, field, value) => {
    const updatedLevels = [...levels];///if anyone updates the value of previous level
    updatedLevels[index][field] = value;
    setLevels(updatedLevels);
  };
  
  let fileUploadSuccess = false;
  let gcpUploadSuccess = false;






  const handleSubmit = async (e) => {
    e.preventDefault();
  
  
   
    dispatch(setLoading1(true));
  
    // Validate form fields before submitting
    const errors = validateFormFields();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      alert("Please fills all fields")
      return;
    }
  
    try {
      const accessToken = JSON.parse(localStorage.getItem('access-token'));
      dispatch(createProject(true));
      const createProjectResponse = await createProjectAPI(accessToken);///fulfilled create project
  
      if (createProjectResponse.ok) {

        const data = await createProjectResponse.json();

        const fileUploadSuccess = await handleFileUpload(data._id);

        const gcpUploadSuccess = await handleGCPUUpload();
  
        if (fileUploadSuccess && gcpUploadSuccess) {
          handleClose();
          refreshProjectList(data);
          dispatch(setLoading1(false));
          dispatch(createProject(false));
        } else {
          throw new Error('File or GCP upload failed');
        }
      } else {
        handleAPIError(createProjectResponse);
      }
    } catch (error) {
      console.error('Error during project creation:', error);
      dispatch(createProject(false));
      toast.error('Project creation failed');
    } finally {
      setLoading(false);
      localStorage.removeItem('formValues');
    }
  };
  const validateFormFields = () => {
    const errors = {};
    if (!projectName.trim()) errors.projectName = 'Project Name is required';
    if (!projectDetails.trim()) errors.projectDetails = 'Project Details is required';
    if (!goalName.trim()) errors.goalName = 'Goal Name is required';
    if (!goalType.trim()) errors.goalType = 'Goal Type is required';
    if (!industry.trim()) errors.industry = 'Industry is required';
    if (!specializedUnit.trim()) errors.specializedUnit = 'Specialized Unit is required';
    if (!startDate.trim()) errors.startDate = 'Start Date is required';
    if (!endDate.trim()) errors.endDate = 'End Date is required';
    if (!selectCategory.trim()) errors.selectCategory = 'Goal Category is required';
    if (!goalMetric.trim()) errors.goalMetric = 'Goal Metric is required';
    if (!metricValue || !metricValue.toString().trim()) errors.metricValue = 'Metric Value is required';
    if (!file) {
      errors.file = 'Please upload a CSV or XLSX file';
      alert('Please Upload a Csv or Xlsx file');
    }
    return errors;
  };
  const createProjectAPI = async (accessToken) => {
    return fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/createProject`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        projectName,
        projectDetails,
        goalName,
        goalType: {
          typeofGoal: goalType,
          selectCategory,
          levels,
          name,
          value,
          category: categories,
        },
        goalMetric: {
          goalMetricName: goalMetric,
          goalMetricValue: metricValue,
        },
        thresholdMetric: {
          thresholdMetricValue: thresholdMetric.toString(),
        },
        industry,
        specializedUnit,
        startDate,
        endDate,
        canvasId: uuid,
        teamMember: selectedOption?.value._id,
        selectedSubOption,
      }),
    });
  };

  const handleFileUpload = async (projectId) => {
    if (!file) return true; // Treat file upload as successful if no file is present
  
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
  
      const uploadUrl = `${process.env.REACT_APP_PUBLIC_BASE_URL}/uploadFile?projectId=${projectId}`;
      await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return true;
    } catch (error) {
      console.error('Error uploading file:', error);
      return false;
    }
  };
  
  
const handleGCPUUpload = async () => {
  if (!file) return true; // Treat GCP upload as successful if no file is present

  try {
    const formData = new FormData();
    formData.append('file', file);

    await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/uploadInGCP`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return true;
  } catch (error) {
    console.error('Error uploading to GCP:', error);
    toast.error(error.response?.data?.message || 'Error uploading to GCP');
    return false;
  }
};

const handleAPIError = async (response) => {
  const errorData = await response.json();
  toast.error(
    <div style={{ fontSize: '16px' }}>
      {errorData?.message}
    </div>
  );
  handleClose();
  if (errorData.message === "All fields are required") {
    toast.error("All fields are required");
  }
  if (errorData.message === "Same Project is not Saved again") {
    toast.error("Same Project is not Saved again");
  }
};   
 


//calls the upload file api when the project is submits

  // useEffect(() => {
  //   // This effect is triggered when `fileUploadSuccess` changes
  //   // if (fileUploadSuccess) {
  //     dispatch(getUploadedFiles2());
  //       fileUploadSuccess=false;
  //       gcpUploadSuccess = false;
  //   // }
  // }, [fileUploadSuccess, loading]); // Dependency on `fileUploadSuccess` state

  // useEffect(() => {
  //   // This effect is triggered when `files` state changes
  //   if (files?.length > 0) {
  //     const fileWithMatchingProjectId = files.find(file => file.metadata && file.metadata.projectId === responseData);
  //     if (fileWithMatchingProjectId) {
  //       const filename = fileWithMatchingProjectId.filename;
  //       // Dispatch action to get uploaded files
  //       // Example:
  //        dispatch(getUploadedFiles(filename));
  //       dispatch(setLoading1(false));
  //     }
  //   }
  // }, [files,responseData]);

  const industries = [
    {
      value: 'mining',
      label: 'mining'
    },
    {
      value: 'FMCG',
      label: 'FMCG'
    },
    {
      value: 'Tech',
      label: 'Tech'
    },
    {
      value: 'Pharma',
      label: 'Pharma'
    },
    {
      value: 'Health',
      label: 'Health'
    },
  ];
  
  
  const goalsType = [
    {
      value: "Qualitative",
      subOptions: ["Nominal", "Categorical", "Ordinal"],
      levels : ["level 1","level 2","level 3","level 4"]
    },
    {
      value: "Quantitative",
      subOptions: ["Discrete", "Continous"]
    }
  ];
  const mathValue = [
    {
      value : "Percentage",
label: "Percentage",
    },
    {
      value : "Whole",
label : 'Whole'
    },
    {
      value : "Decimal",
label: "Decimal",
    },
    
  ]

  const specializedUnits = [
    {
      value: 'Marketing',
      label: 'Marketing'
    },
    {
      value: 'Sales',
      label: 'Sales'
    },
    {
      value: 'Finance',
      label: 'Finance'
    },
    {
      value: 'losgistics',
      label: 'losgistics'
    },
    {
      value: 'Procurement',
      label: 'Procurement'
    },
    {
      value: 'market access',
      label: 'market access'
    },
    {
      value: 'Clin ops',
      label: 'Clin ops'
    },
  ];
  

// Determine if the button should be disabled based on user designation  
const users= JSON.parse(localStorage.getItem('users'))
const isDisabled =users?.designation === 'teamMember' || users?.designation === 'teamManager';

const [teamData,setTeamData] = useState([])
const [showDialog,setShowDialog] = useState(false)
useEffect(()=>{
  const showAllTeamData = async ()=>{
    try{
    const accessToken = JSON.parse(localStorage.getItem('access-token'))
  const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members`,{
    headers : {
      Authorization : `Bearer ${accessToken}`
    }
  }
  );
  // console.log(response.data.teamMembers);
  setTeamData(response.data.teamMembers)
  if(response.data.teamMembers.length === 0){
setShowDialog(true)
  }
  }catch(error){
    console.error(error)
  }
  }
  
  showAllTeamData()
    },[])
  //   if (teamData.length === 0) {
  //  // Show dialog if teamData is empty
  //  setIsDialogOpen(true);
  //   }
    
    const specializedUnits1 = teamData.map((teamMember) => ({
 
      value: {
        memberId: teamMember.memberId,
        _id: teamMember._id,
      },
      label: `${teamMember.memberId} - (${teamMember.designation})`,
    }));

  return (
    

<>
{loading ? (
        <p>Loading...</p>
      ) : (
       
       <React.Fragment>
      <Button className='button'
            sx={{
              width: '170px',    
              height: '50px',
              backgroundColor: '#50A4DB',
              borderRadius: '8px',
              color: '#FFF',
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'none',

            }}
           
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleClickOpen}
            
            disabled={LoggedInAs === '' || isDisabled}
            
          >
            Create Project
          </Button>

      <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      PaperProps={{
        sx: {
          
          maxWidth: 'md', // You can use 'sm', 'md', 'lg', 'xl', or a specific pixel value
          position: 'relative',
          // overflowY: 'scroll',
          
        },
      }}
    >
      
      <DialogContent>
        <DialogContentText>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Grid container direction="row" spacing={6}>
              <Grid item xs={2} md={1} >
                
                    <Button sx={{textDecoration:'none', color: '#000'}} autoFocus onClick={handleClose}>
                        <ArrowBackIcon sx={{fontSize: '30px'}}/>
                    </Button>
                
              </Grid>
              <Grid item xs={10} md={4} onClick={()=>handleClose()}>
                    <h1 style={{color: '#000', fontSize: '32px', fontWeight: '500'}}>
                      Create Project
                      </h1>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} sx={{ marginTop: '2%' }}>
<h5 style={{ color: '#000', fontSize: '22px', fontWeight: '400' }}>Project Name</h5>
<TextField
  id="outlined-helperText"
  value={projectName}
  onChange={(e) => { handleInputChange('projectName', e.target.value) }}
  // error={!!formErrors.projectName}
  // helperText={formErrors.projectName}

  placeholder='Project Name'
  // multiline // Set to true to enable multiline input
  // rows={1}  
  sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
/>
{formErrors.projectName && (
        <CustomFormHelperText>{formErrors.projectName}</CustomFormHelperText>
      )}
            </Grid>


            <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
            <h5 style={{color: '#000',  fontSize: '22px', fontWeight: '400' }} >Project Details</h5>
            <TextField
                id="outlined-helperText"
                value={projectDetails}
                onChange={(e) => {handleInputChange('projectDetails', e.target.value)}}
                // error={!!formErrors.projectDetails}
                // helperText={formErrors.projectDetails}
                placeholder='Project Details'
                sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
            />
            {formErrors.projectDetails && (
        <CustomFormHelperText>{formErrors.projectDetails}</CustomFormHelperText>
      )}
            </Grid>
{/* that is Indusrty and specializedUnit */}



<Grid container direction="row" spacing={2} style = {{marginTop  : '0.5%'}}>
              <Grid item xs={12} md={6}>  
              <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Industry</h5>

                          <ReactSelect
                            value={{value : industry  , label : industry }}
                          id="outlined-helperText"  
                          options={industries}
                          placeholder='Select Indusrty'
                          // value={{label : industries[0].label}}
                          onChange={(selectedOption) => handleInputChange('industry', selectedOption?.value)}
                          // error={!!formErrors.industry}
                          // helperText={formErrors.industry}                 
                          // label='Industry'
                          sx={{width: '100%'}}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              fontSize: '18px', // Set font size for the input field
                              // borderWidth: '2px', // Increase border size
                              padding: '4px 0px 6px 0px' // Add more padding
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              fontSize: '18px' // Set font size for the selected value
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: '18px' // Set font size for the options in the dropdown
                            })
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              neutral10: 'grey',
                              primary: '#50a4db',
                            },
                          })}
                       />
                       {formErrors.industry && (
        <CustomFormHelperText>{formErrors.industry}</CustomFormHelperText>
      )}        
      
              </Grid>
              
              <Grid item xs={12} md={6}>    
              <h5 style={{color: '#000', fontSize: '21px', fontWeight: '400' }}>Specialized Unit</h5>
              {/* <Typography   style={{color: '#000', fontSize: '20px', fontWeight: '400px'}}variant="h4">Specialized Unit</Typography>                   */}
                    <ReactSelect
                      value={{value :specializedUnit  , label :specializedUnit }}
                        defaultValue="Specialized" 
                      placeholder='Select Unit'
                      id="filled-size-normal" 
                      options = {specializedUnits}
                      onChange={(selectedOption) => handleInputChange('specializedUnit',selectedOption?.value)}
                      // error={!!formErrors.specializedUnit}
                      // helperText={formErrors.specializedUnit}                     
                      // label="Specialized Unit"
                      sx={{width: '100%'}}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          fontSize: '18px', // Set font size for the input field
                          // borderWidth: '2px', // Increase border size
                          padding: '4px 0px 6px 0px' // Add more padding
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: '18px' // Set font size for the selected value
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: '18px' // Set font size for the options in the dropdown
                        })
                      }}

                  />
                  {formErrors.specializedUnit && (
        <CustomFormHelperText>{formErrors.specializedUnit}</CustomFormHelperText>
      )}  

              </Grid>
          

            </Grid>    
          
{/*              
    <Grid item xs={12} md={12} style={{marginTop : '2%'}}>
    <h5 style={{color: '#000',  fontSize: '22px', fontWeight: '400' }} >Threshold Value</h5>
      <TextField
      placeholder='Threshold Value'
        type="number"
        value={threshold}
        onChange={handleThresholdChange}
        variant="outlined"
        fullWidth
        inputProps={{ style: { fontSize: '15px' } }}
        style={{ marginBottom: '16px' }}
      />
    </Grid> */}
  <Grid container direction="row" sx={{marginTop: '2%'}} >
              <Grid item xs={5} sm={4} md={2}>
                <h5 style={{color: '#000', fontSize: '22px', fontWeight: '430' }}>Define Goals</h5>
              </Grid>
              {/* <Grid item xs={1} md={1} container justifyContent="center" alignItems="center">
                <AddCircleOutlineIcon sx={{ fontSize: '22px' }} />
              </Grid> */}
            </Grid>
          
            <Grid container direction="row" spacing={2} >
              
            <Grid item xs={12} md={6}>                
<h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Goal Name</h5>
<TextField
  id="outlined-helperText"
  value={goalName}
  onChange={(e) => handleInputChange('goalName', e.target.value)}
  // error={!!formErrors.goalName}
  // helperText={formErrors.goalName}
  placeholder='Goal Name'
  sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
/>   
{formErrors.goalName && (
        <CustomFormHelperText>{formErrors.goalName}</CustomFormHelperText>
      )}             
</Grid>



                <Grid item xs={12} md={6} >
                <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Type of Goals</h5>
              
                <ReactSelect 
              //  options={goalsType.map(option => ({
              //   label: option.value,
              //   options: option.subOptions.map(subOption => ({ label: subOption, value: subOption })),
              //   groups: option.levels ? option.levels.map(level => ({ label: level, value: level })) : null
              // }))}
              value={{value :goalType  , label :goalType }}
              options = {
                goalsType.map(option => ({
                    label: option.value,
                    value : option.value
              }))
              }
                  id="outlined-helperText"
                  // error={!!formErrors.goalType}
                  // helperText={formErrors.goalType}
                  onChange={(selectedOption) => { handleInputChange('goalType', selectedOption.value) }}
                  placeholder='Select Goal'
                  sx={{width: '100%'}}
                 
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      fontSize: '18px', // Set font size for the input field
                      // borderWidth: '2px', // Increase border size
                      padding: '4px 0px 6px 0px' // Add more padding
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the selected value
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set font size for the options in the dropdown
                    })
                  }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              neutral10: 'grey',
                              primary: '#50a4db',
                            },
                          })}

                />
                 
                
                 {formErrors.goalType && (
        <CustomFormHelperText>{formErrors.goalType}</CustomFormHelperText>
      )}  
           
                </Grid>


            </Grid>  

<Grid container direction="row" spacing={2} style={{marginTop:  '1%'}}>

<Grid item xs={12} md={4} >
                <h5  style={{color: '#000',  fontSize: '22px', fontWeight: '400' }}>{goalType}</h5>
 {
goalType && (
  <ReactSelect
  options={(goalType === 'Qualitative'
    ? goalsType.find((option) => option.value === goalType)?.subOptions
    : ['Discrete', 'Continuous'])?.map((option) => ({ value: option, label: option }))
  }
  value={{ value: selectCategory, label: selectCategory }}
  onChange={(selectedOption) => handleMenuItemClick(selectedOption?.value)}
  placeholder='Select Subtype'
  isClearable={true}
  // error={!!formErrors.selectCategory}
  // helperText={formErrors.selectCategory}
  sx={{ width: '100%' }}
  inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
  styles={{
    control: (provided) => ({
      ...provided,
      fontSize: '18px', // Set font size for the input field
      // borderWidth: '2px', // Increase border size
      padding: '4px 0px 6px 0px' // Add more padding
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set font size for the selected value
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '18px' // Set font size for the options in the dropdown
    })
  }}
  theme={(theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      neutral10: 'grey',
      primary: '#50a4db',
    },
  })}
/>

)

}
{formErrors.selectCategory && (
        <CustomFormHelperText>{formErrors.selectCategory}</CustomFormHelperText>
      )}  



</Grid>    

{selectCategory === 'Nominal' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{selectCategory}</h5>
    <TextField
      placeholder="Name"
      value={name}
      onChange={handleNameChange}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '18px', height: '18px' } }} // Adjust height here
    />
    <TextField
      placeholder="Value"
      value={value}
      sx={{ width: '100%', height: '64%' }}
      onChange={handleValueChange}
      variant="outlined"
      type="number"
      fullWidth
      style={{ marginBottom: '16px' }}
      inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)}

{selectCategory === 'Discrete' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{selectCategory}</h5>
    <TextField
      label="Value"
      type="number" // Only allow integer inputs
      value={value}
      onChange={handleValueChange}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
      inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)}

{/* {selectCategory === 'Continuous' && (
  <Grid item xs={12} md={4}>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>{selectCategory}</h5>
    <TextField
      label="Value"
      type="number" // Allow float inputs as well
      value={value}
      onChange={handleValueChange}
      variant="outlined"
      fullWidth
      style={{ marginBottom: '16px' }}
      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
  inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
    />
  </Grid>
)} */}



{
selectCategory === 'Categorical' && 

<>

<Grid xs={12} md={4} item>
<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400' }}>{`Categorical Name`}</h5>
            <Input
              type="text"
              id='categorical name'
             value={categoricalName}
             style={{ fontSize: '18px',height:'48px' }}
              onChange={(e) => setCategoricalName(e.target.value)}
            />

         </Grid>

<Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
  {
    categoricalName &&
    <Button
      style={{
        backgroundColor: '#50A4DB',
        width: '100%', // Full width on extra small screens (xs)
        maxWidth: '54%', // Maximum half width on medium screens (md)
        height: '39px',
        fontSize: '14px',
        color : '#FFFFFF',
        marginTop : '10%'
      }}
      onClick={handleAddCategory}
    >
      Add Category <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
    </Button>

    
  }
    
  </Grid>

 
 


</>
}

{
selectCategory === 'Ordinal' && 
<>

<Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <Button
      style={{
        backgroundColor: '#50A4DB',
        width: '100%', // Full width on extra small screens (xs)
        maxWidth: '50%', // Maximum half width on medium screens (md)
        height: '38px',
        marginTop : '7%',
        fontSize: '16px',
        color : 'white'
      }}
      onClick={handleAddLevel}
    >
      Add Level <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
    </Button>
  </Grid>

</>
}
<Grid container spacing={4} style={{ marginTop: '1%' }}>
    {goalType=== "Qualitative" && [...Array(levelCount)].map((_, index) => (
      <Grid item xs={12} md={6} key={index} style={{ paddingLeft: '6%' }}>
        <Label htmlFor={`levelName${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Name of level ${index + 1}`}</Label>
        <Input
          type="text"
          id={`levelName${index}`}
          placeholder={`level ${index + 1}`}
          // value={levelNames[index]}
          style={{ fontSize: '18px',height: '50px'}}
          onChange={(e) => handleFieldChange(index, 'levelName', e.target.value)}
         
        />
        <div style={{ marginTop: '3%' }}>
          <Label htmlFor={`levelValue${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Value of level ${index + 1}`}</Label>
          <Input
            type="text"
            id={`levelValue${index}`}
            placeholder={`level ${index + 1}`}
            // value={levelValues[index]}
            style={{ fontSize: '18px',height: '50px'}}
            onChange={(e) => handleFieldChange(index, 'levelValue', e.target.value)}
          />
        </div>

        <Grid container spacing={4} style={{ marginTop: '0.5%' }}>
          <Grid item xs={6} md={6}>
            <Label htmlFor={`rangeLevel${index}`} style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Select Range of Level ${index + 1}`}</Label>
            <Input
              type="number"
              id={`rangeLevel${index}`}
              placeholder={`level ${index + 1}`}
              // value={levelValMinRanges[index]}
              style={{ fontSize: '18px',height: '50px'}}
              onChange={(e) => handleFieldChange(index, 'levelMinRange', e.target.value)}
            />
          </Grid>
          <Grid item xs={6} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <div>
              <Input
                type="number"
                id={`levelRange${index}`}
                placeholder={`Level ${index + 1}`}
                // value={levelValMaxRanges[index]}
                style={{ fontSize: '18px',height: '50px'}}
                onChange={(e) => handleFieldChange(index, 'levelMaxRange', e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    ))}

</Grid>



</Grid>


<Grid container spacing={4} style={{marginTop : '2%'}}>

{goalType === 'Qualitative' && [...Array(categoryCount)].map((_, index) => (

<Grid item xs={12} md={6} key={index} >
<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400' }}>{`Category Name ${index + 1}`}</h5>

<Input
 type="text"
 id={`categoryName${index}`}
 placeholder={`Category Name ${index + 1}`}
//  value={categoryName[index]}
 style={{ fontSize: '18px',height:'50px' }}
 onChange={(e) => handleCategoryChange(index,'categoryName', e.target.value)}
 
/>




<h5  style={{color: '#000',  fontSize: '20px', fontWeight: '400', marginTop: '3%'}}>{`Category Value ${index + 1}`}</h5>

<Input
 type="text"
 id={`categoryValue${index}`}
 placeholder={`Category value ${index + 1}`}
//  value={categoryValue[index]}
 style={{ fontSize: '18px',height:'54px' }}
 onChange={(e) => handleCategoryChange(index,'categoryValue', e.target.value)}
/>


</Grid>

))}
</Grid>

                        


                                              {/* ///Metrics */}


              <Grid container direction="row" spacing={2} style = {{marginTop  : '0.5%'}}>           
             <Grid item xs={12} md={6}>
             <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Goal Metric</h5>
              
          <ReactSelect 

value={{value :goalMetric  , label :goalMetric }}
            id="outlined-helperText"
            options={mathValue}
            // onChange={(selectedOption) => {setGoalMetric(selectedOption?.value) }}
            onChange={(selectedOption) => handleInputChange('goalMetric',selectedOption?.value)}
            placeholder='Select Value'
            sx={{ width: '100%' ,height: '50px'}}
            // error={!!formErrors.goalMetric}
            // helperText={formErrors.goalMetric}
           
            styles={{
              control: (provided) => ({
                ...provided,
                fontSize: '18px', // Set font size for the input field
                // borderWidth: '2px', // Increase border size
                padding: '4px 0px 6px 0px' // Add more padding
              }),
              singleValue: (provided) => ({
                ...provided,
                fontSize: '18px' // Set font size for the selected value
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '18px' // Set font size for the options in the dropdown
              })
            }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              neutral10: 'grey',
                              primary: '#50a4db',
                            },
                          })}
         
         />
         {formErrors.goalMetric && (
        <CustomFormHelperText>{formErrors.goalMetric}</CustomFormHelperText>
      )}  
                 </Grid>

          {goalMetric === 'Percentage' && (
<Grid item xs={12} md={6}>
  <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Enter Percentage</h5>
  <TextField
    type="number"
    // error={!!formErrors.metricValue}
    //               helperText={formErrors.metricValue}
    id="percentage"
    placeholder="Enter Percentage"
    value={metricValue}
    // style={{ fontSize: '25px', width:'100%' }}
    inputProps={{ style: { fontSize: '15px', height: '16px' } }} 
    onChange={(e) => {
      // Extract numeric part (removes decimals)
      const number = e.target.value.replace(/\D/g, '');
      // Parse as integer, clamp between 0 and 100
      const percentage = Math.max(0, Math.min(100, parseInt(number, 10)));
      // Update the input value (controlled component approach)
      setMetricValue(percentage);
    }}
  />
   {formErrors.metricValue && (
        <CustomFormHelperText>{formErrors.metricValue}</CustomFormHelperText>
      )}  
</Grid>
)}


    {goalMetric === 'Whole' && (
         <Grid item xs={12} md={6}>

         <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Whole value</h5>
             
    <TextField
      type="number"
      id="whole"
      // error={!!formErrors.metricValue}
      // helperText={formErrors.metricValue}
      placeholder="Enter Whole Number"
      value={metricValue} 
      inputProps={{ style: { fontSize: '15px', height: '16px' } }} // Adjust height
    //  style={{ fontSize: '18px',width:'100%' }}
     onChange={(e) => {
      // Extract the numeric part (removes decimals)
      const wholeNumber = e.target.value.replace(/\D/g, '');
      // Update the input value (controlled component approach)
      setMetricValue(wholeNumber);
    }}
 
    />
  {formErrors.metricValue && (
        <CustomFormHelperText>{formErrors.metricValue}</CustomFormHelperText>
      )}  
     </Grid>
    )}

    {goalMetric === 'Decimal' && (
          <Grid item xs={12} md={6}>
          <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Decimal Value</h5>
              
     <TextField
       type="number"
      //  error={!!formErrors.metricValue}
      //  helperText={formErrors.metricValue}
       id="decimal"
       value={metricValue}
       inputProps={{ style: { fontSize: '15px', height: '16px' } }}
       placeholder="Enter Decimal Value"
      
      //  value={Percentage} 
      // style={{ fontSize: '18px',width:'100%' }}

      //  onChange={(e) => setWidgetName(e.target.val
       onChange={(e) => setMetricValue(e.target.value)}
     />
   {formErrors.metricValue && (
        <CustomFormHelperText>{formErrors.metricValue}</CustomFormHelperText>
      )}  
      </Grid>
    )}

{/* //In row 2nd Dropdown */}
<Grid item xs={12} md={6}>
      <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Threshold Metric</h5>
      {/* <TextField
      select
        value={thresholdMetric}
        onChange={(e) => setThresholdMetric(e.target.value)}
        placeholder='Select Value'
        sx={{ width: '100%', height: '50px' }}
        error={!!formErrors.thresholdMetric}
            helperText={formErrors.thresholdMetric}
        inputProps={{ style: { fontSize: '15px' } }}
      >
        {mathValue.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <p style={{ fontSize: '18px' }}>{option.value}</p>
          </MenuItem>
        ))}
      </TextField> */}
  <Box sx={{ width: 300, ml: 5, mt: 2 }}>
  <Slider
    aria-label="Discrete values"
    sx={{
      '& .MuiSlider-valueLabel': { fontSize: '24px' },
      '& .MuiSlider-markLabel': { fontSize: '16px' } // Adjust font size for marks
    }}
    defaultValue={0.0}
    step={0.1}
    valueLabelDisplay="auto"
    onChange={(e, value) => setThresholdMetric(value)} // Ensure value is passed to the handler
    min={0.0}
    max={1.0}
    marks={[
      { value: 0.0, label: '0.0' },
      // { value: 0.1, label: '0.1' },
      { value: 0.2, label: '0.2' },
      // { value: 0.3, label: '0.3' },
      { value: 0.4, label: '0.4' },
      // { value: 0.5, label: '0.5' },
      { value: 0.6, label: '0.6' },
      // { value: 0.7, label: '0.7' },
      { value: 0.8, label: '0.8' },
      // { value: 0.9, label: '0.9' },
      { value: 1.0, label: '1.0' },
    ]}
  />
</Box>


    </Grid>


    {/* { thresholdMetric=== 'Percentage' && (
<Grid item xs={12} md={6}>
  <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Enter Percentage</h5>
  <TextField
    type="number"
    id="percentage"
    error={!!formErrors.thresholdMetricValue}
    helperText={formErrors.thresholdMetricValue}
    placeholder="Enter Percentage"
    value={thresholdMetricValue}
    style={{ fontSize: '18px',width:'100%' }}
    onChange={(e) => {
      // Extract numeric part (removes decimals)
      const number = e.target.value.replace(/\D/g, '');
      // Parse as integer, clamp between 0 and 100
      const percentage = Math.max(0, Math.min(100, parseInt(number, 10)));
      // Update the input value (controlled component approach)
      setThresholdMetricValue(percentage);
    }}
  />
</Grid>
)}


    {thresholdMetric === 'Whole' && (
         <Grid item xs={12} md={6}>
         <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Whole value</h5>
             
    <TextField
      type="number"
      id="whole"
      error={!!formErrors.thresholdMetricValue}
      helperText={formErrors.thresholdMetricValue}
      placeholder="Enter Whole Number"
      value={thresholdMetricValue} 
     style={{ fontSize: '18px',width:'100%'}}
     onChange={(e) => {
      // Extract the numeric part (removes decimals)
      const wholeNumber = e.target.value.replace(/\D/g, '');
      // Update the input value (controlled component approach)
      setThresholdMetricValue(wholeNumber);
    }}
    />
 
     </Grid>
    )}

    {thresholdMetric === 'Decimal' && (
          <Grid item xs={12} md={6}>
          <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Decimal Value</h5>
              
     <TextField
       type="number"
       id="decimal"
       error={!!formErrors.thresholdMetricValue}
       helperText={formErrors.thresholdMetricValue}
       value={thresholdMetricValue}
       placeholder="Enter Decimal Value"
      //  value={Percentage} 
      style={{ fontSize: '18px',width: '100%' }}
      //  onChange={(e) => setWidgetName(e.target.val
       onChange={(e) => setThresholdMetricValue(e.target.value)}
     />
  
      </Grid>
    )} */}



             </Grid>

{/* //3rd dropdown */}
             {/* <Grid container direction="row" spacing={2} style = {{marginTop  : '0.5%'}}>
          
             <Grid item xs={12} md={6}>
             <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Forcast Metric</h5>
      <ReactSelect
      options={mathValue}
value = {{value : forcastMetric , label : forcastMetric}}
        // value={forcastMetric}
        // onChange={(selectedOption) => setForcastMetric(selectedOption?.value)}
        onChange={(selectedOption) => handleInputChange('forCastMetric',selectedOption?.value)}
        placeholder='Select Value'
        error={!!formErrors.forcastMetric}
        helperText={formErrors.forcastMetric}
        sx={{ width: '100%' }}
       
        styles={{
          control: (provided) => ({
            ...provided,
            fontSize: '18px', // Set font size for the input field
            // borderWidth: '2px', // Increase border size
            padding: '4px 0px 6px 0px' // Add more padding
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: '18px' // Set font size for the selected value
          }),
          option: (provided) => ({
            ...provided,
            fontSize: '18px' // Set font size for the options in the dropdown
          })
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            neutral10: 'grey',
            primary: '#50a4db',
          },
        })}

      />
   
          </Grid>
            

          { forcastMetric=== 'Percentage' && (
<Grid item xs={12} md={6}>
  <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Enter Percentage</h5>
  <TextField
    type="number"
    
    id="percentage"
    error={!!formErrors.forCastMetricValue}
    helperText={formErrors.forCastMetricValue}
    placeholder="Enter Percentage"
    inputProps={{ style: { fontSize: '15px', height: '16px' } }} 
    value={forCastMetricValue}
    // InputProps={{ style: { fontSize: '25px' } }} 
    onChange={(e) => {
      // Extract numeric part (removes decimals)
      const number = e.target.value.replace(/\D/g, '');
      // Parse as integer, clamp between 0 and 100
      const percentage = Math.max(0, Math.min(100, parseInt(number, 10)));
      // Update the input value (controlled component approach)
      setForCastMetricValue(percentage);
    }}
  />
</Grid>
)}


    {forcastMetric === 'Whole' && (
         <Grid item xs={12} md={6}>
         <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Whole value</h5>
             
    <TextField
      type="number"
      id="whole"
      error={!!formErrors.forCastMetricValue}
    helperText={formErrors.forCastMetricValue}
      placeholder="Enter Whole Number"
      value={forCastMetricValue} 
    //  style={{ fontSize: '18px' }}
    inputProps={{ style: { fontSize: '15px', height: '16px' } }}
     onChange={(e) => {
      // Extract the numeric part (removes decimals)
      const wholeNumber = e.target.value.replace(/\D/g, '');
      // Update the input value (controlled component approach)
      setForCastMetricValue(wholeNumber);
    }}
    />
 
     </Grid>
    )}

    {forcastMetric === 'Decimal' && (
          <Grid item xs={12} md={6}>
          <h5 style={{color: '#000', fontSize: '20px', fontWeight: '400' }}>Write Decimal Value</h5>
              
     <TextField
       type="number"
       id="decimal"
       value={forCastMetricValue}
       error={!!formErrors.forCastMetricValue}
    helperText={formErrors.forCastMetricValue}
       placeholder="Enter Decimal Value"
      //  value={Percentage} 
      // style={{ fontSize: '18px',width:'100%' }}
      inputProps={{ style: { fontSize: '15px', height: '16px' } }}
      //  onChange={(e) => setWidgetName(e.target.val
       onChange={(e) => setForCastMetricValue(e.target.value)}
     />
  
      </Grid>
    )}


            </Grid> */}


                                                {/* ///Metrics ends */}

            <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
              <h5 style={{color: '#000', fontSize: '22px', fontWeight: '430px'}}>Timeline</h5>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{marginTop: '1%'}}>
              <Grid item xs={12} md={6}>                      
                    <TextField
                     value={startDate || new Date().toISOString().substring(0, 10)} 
                      id="outlined-helperText"  
                      type='date' 
                      onChange={(e) => handleInputChange('startDate', e.target.value)}
                      // error={!!formErrors.startDate}
                      // helperText={formErrors.startDate}                   
                      // label="Start Date"
                      sx={{ width: '100%' }} // Set TextField width to 100%
                      inputProps={{
                          style: {
                              width: '100%', // Set input width to 100%
                              height: 'auto', // Set input height to auto
                              fontSize: '15px' // Set input font size
                          }
                      }}
                      // InputLabelProps={{ shrink: false }} 
                          
                    />      
                    {formErrors.startDate && (
        <CustomFormHelperText>{formErrors.startDate}</CustomFormHelperText>
      )}                  
              </Grid>
              <Grid item xs={12} md={6}>                      
                    <TextField
                    value={endDate || new Date().toISOString().substring(0, 10)} 
                      id="outlined-helperText" 
                      type='date'
                      onChange={(e) => handleInputChange('endDate', e.target.value)}
                      // error={!!formErrors.endDate}
                      // helperText={formErrors.endDate}                         
                      placeholder="End Date"
                      sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                      inputProps={{ style: { fontSize: '15px', height: '16px' } }} // Adjust height here
                    />   
                    {formErrors.endDate && (
        <CustomFormHelperText>{formErrors.endDate}</CustomFormHelperText>
      )}                    
              </Grid>


              {/* <Grid item xs={12} md={6}>                      
                    <TextField
                      id="outlined-helperText" 
                      onChange={(e) => handleInputChange('status', e.target.value)}
                      error={!!formErrors.status}
                      helperText={formErrors.status}                  
                      placeholder="Status"
                      sx={{width: '100%'}}
                    />                     
              </Grid> */}
              {/* <Grid item xs={12} md={6}>                      
                    <TextField
                      id="outlined-helperText" 
                      onChange={(e) => handleInputChange('company', e.target.value)}
                      error={!!formErrors.company}
                      helperText={formErrors.company}                    
                      placeholder="Company"
                      sx={{width: '100%'}}
                    />                     
              </Grid> */}
            </Grid>
             
            <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
              <h5 style={{ fontSize: '24px', fontWeight: '400px', color: '#000'}}>Add /Selects Members</h5>
            </Grid>

            <Grid container sx={{ marginTop: '1%', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={6}>
  <ReactSelect
id="outlined-helperText"
options={specializedUnits1}
isSearchable
isClearable
onChange={(selectedOption) => handleInputChange1(selectedOption)}
value={selectedOption}
placeholder="Search or select a Member"
sx={{ width: '100%' }}
// error={!!formErrors.teamMember}
// helperText={formErrors.teamMember}

styles={{
  control: (provided) => ({
    ...provided,
    fontSize: '18px', // Set font size for the input field
    // borderWidth: '2px', // Increase border size
    padding: '4px 0px 6px 0px' // Add more padding
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '18px' // Set font size for the selected value
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '18px' // Set font size for the options in the dropdown
  })
}}
theme={(theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    neutral10: 'grey',
    primary: '#50a4db',
  },
})}

/>

{formErrors.teamMember && (
        <CustomFormHelperText>{formErrors.teamMember}</CustomFormHelperText>
      )}  
  </Grid>
<Grid item xs={12} md={6} >
              <Link href="/#/add-member" style={{marginLeft : '3%'}}>
              <AddIcon sx={{border: '1px dashed #000',color: '#000', width: '20%', height: '60%'}}/>
              </Link>
            </Grid>
{/* //show adialog box if teamData is empty */}
{showDialog && (
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}
          fullWidth={true} // Makes the Dialog box take up the full width of its container
          maxWidth="xs" // Sets the maximum width of the Dialog box to "md"
          PaperProps={{
              style: {
                  minHeight: '100px', // Sets the minimum height of the Dialog box content
                  maxHeight: '40vh' // Sets the maximum height of the Dialog box content (80% of the viewport height)
              }
          }}
          >
              <DialogTitle>No Team Members Presents</DialogTitle>
              <DialogContent>
                  <DialogContentText style={{ fontSize: '16px' }}>
                     :- There are no team members. Please Clicks on Add Members first.
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button style={{ fontSize: '16px' }} onClick={() => setShowDialog(false)} color="primary" autoFocus>
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
      )}
  
  </Grid>

  <Grid item xs={12} md={12} container alignItems='center' justifyContent='center' style={{ marginTop: '3%' }}>
<div>

  <Dropzone onDrop={handleDropzoneChange} style={{ cursor: 'pointer', width: '50%' }}>
    {({ getRootProps, getInputProps }) => (
      <section {...getRootProps()}>
        <input {...getInputProps()}  />
        <p style={{ fontSize: '20px', fontWeight: '500' ,color :'#000',cursor: 'pointer',borderStyle : 'dotted',padding : '3px', 
        // color: formErrors.file ? 'red' : '#000'
       
        }}>Drag & drop CSV file or click to select</p>
      </section>
    )}
  </Dropzone>
  
    { file && 
<div style={{ marginTop: '10px', fontSize: '15px', color: '#50A4DB' }}>
  (File "{file.name}" uploaded successfully!)
</div>

}

  {/* <button onClick={handleUpload}>Upload</button> */}
</div>
</Grid>

 
  

          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleClose}>
          Disagree
        </Button> */}
        <Button type="submit" className='button_size' variant='contained' onClick={(e) => {
  
  handleSubmit(e); // Call handleSubmit function
}} autoFocus sx={{
          width: '20%',
         
          backgroundColor: '#50A4DB ',
          borderRadius: '8px',
          color: '#FFF',
          fontSize: '20px',
          fontWeight: '600',                                               
          textTransform: 'none',           
          marginRight: '5%'
          
        }}
    
        >
          Create Project
        </Button>
      </DialogActions>
    </Dialog>
 
     
      </React.Fragment>
      
    ) } 

</>
  );
}
