import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, Button } from 'reactstrap';
import { Progress } from 'reactstrap';
import { Pie } from 'react-chartjs-2';
import CreatableSelect from 'react-select/creatable';
import {  Box, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Slider, Typography } from '@mui/material';

import "../index.css"
import html2canvas from 'html2canvas';

import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getRightSidebarVariablesSuccess, getRightsideVariables, getUploadedFiles, getUploadedFiles2, saveRightSideVariables, setAucGraph, setModelGraph, setScreenShot, setSelectedOption, updateFileInfo } from '../../../Reducers/CanvasReducer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactSelect from 'react-select';
import toast from "react-hot-toast";
import { handleClearClick } from './HandlerFunctions';
import { Alert, AlertTitle } from '@mui/material';
import ChatBot from './ChatBot';
import { SettingsInputHdmiRounded } from '@mui/icons-material';



function RightSidebar({showSimulationOptions}) {
  const [showPopup, setShowPopup] = useState(false);
  const [showSum, setShowSum] = useState(false);
  const [open, setOpen] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [mixedModelPopUp,setMixedModelPopUp] = useState(false)
  const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
  const [expressionDropdownOpen, setExpressionDropdownOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedExpression, setSelectedExpression] = useState('');
  const [showCoefficient,setShowCoefficient] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const captureRef = useRef(null);
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [showAucValue,setShowAucValue] = useState({})
  const freeze = JSON.parse(localStorage.getItem('freeze'));
  const [intercept, setIntercept] = useState(null);

  const params=useParams();
  const dispatch = useDispatch();
  const toggleTypeDropdown = () => setTypeDropdownOpen(prevState => !prevState);
  const toggleExpressionDropdown = () => setExpressionDropdownOpen(prevState => !prevState);
  const [showCreateModel,setShowCreateModel] = useState(true)
  const [showMessage1,setShowMessage1] = useState(false)//creating model message
  const [showPopup1,setShowPopup1] = useState(false)//selects variable in dropdown 
  const [showPopup3,setShowPopup3] = useState(false)//this popup is used to connector line if not connected
  const [showMessage,setShowMessage] = useState(false)//this is for disabled the react select
  const [multipliedValues, setMultipliedValues] = useState({});
  const [showAllModels,setShowAllModels] = useState([])
  const [showAiResponse,setShowAiResponse] = useState({})
  const [multipliedValuesSum, setMultipliedValuesSum] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(0);
  const {getProjectData} = useSelector(state => state.canvas)//get specific project data
const {fileInfo,fileInfoLoading,files,filesLoading,rightSideVariables,rightSideVariablesLoading,dropDownSaveVariables,mutateData,formulaWidget} =useSelector(state=>state.canvas);
    const allTemplates  = useSelector(state=>state?.canvas?.addingTemplate);
  const file_Id = files && files.find(file => file.metadata && file.metadata.projectId === params.id);
//as per inside all teamplates
const [filteredTemplates, setFilteredTemplates] = useState([]);

useEffect(() => {
  const filtered = allTemplates.filter(
    template => template.text !== "Mutate Variable Widget" && template.text !== "Formula Variable Widget"
  );
  setFilteredTemplates(filtered);
}, [allTemplates]);
  // console.log(allTemplates,"right")
  const {connectorLines}  = useSelector(state=>state?.canvas);
const [targetColumn,setTargetColumn] = useState("")
const placeholders = [
  'Fixed Slope',
  'Fixed Coefficient',
  'Random Slope',
  'Random Coefficient' 
  ];

  useEffect(()=>{
if(getProjectData?.thresholdMetric?.thresholdMetricValue !== undefined){
setThresholdValue(getProjectData?.thresholdMetric?.thresholdMetricValue)
}
  },[getProjectData])

  useEffect(() => {
    console.log('Threshold Value:', thresholdValue);
  }, [thresholdValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-rightsidebar-variables/${params.id}`);
        const templates = result?.data?.templates;

        if (templates && templates.length > 0) {
          // Find the template with the maximum x and y coordinates
        // Assuming `templates` is an array of objects, each having `templateCordinates` with `x` and `y` properties
const maxCoordinateTemplate = templates.reduce((max, template) => {
  const { x } = template.templateCordinates;
  const { x: maxX } = max.templateCordinates;
  return x > maxX ? template : max; 
}, templates[0]);

// Set the target column with the data of the template having the maximum x coordinate
setTargetColumn(maxCoordinateTemplate?.data);
        } else {
          console.log("No templates found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [allTemplates, params.id,selectedTexts]);

  // useEffect(() => {
  //   // Filter templates based on projectId
  //   const filteredTemplates = allTemplates.filter(template => template?.projectId === params?.id);
  
  //   setTemplates(filteredTemplates);
  
  // }, [dispatch,allTemplates,params.id]);




  // const options = [ // Replace this array with your actual dropdown options
  // { value: 'Variable 1', label: 'Variable 1' },
  // { value: 'Variable 2', label: 'Variable 2' },
  // { value: 'Variable 3', label: 'Variable 3' }
  // ]
// console.log(dropDownSaveVariables,"dropDownSaveVariables")


  const [additionalTextFieldVisible, setAdditionalTextFieldVisible] = useState(false);


  const saveDataToSelectedRightSidebar =async (data) =>{
    try {
     
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-right-variable`, data);
      // console.log("Data saved successfully:", response.data);
      // dispatch(saveRightSideVariables(data))
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

const handleClearClicks =async ()=>{
  setShowMessage(false)
  // localStorage.setItem('freeze', JSON.stringify(false),
  localStorage.setItem('freeze', JSON.stringify({
    freeze: false, // Your current freeze state
   projectId: null // Extract the project ID from params
   })

);
  const getItemValue = JSON.parse(localStorage.getItem('freeze'))
  dispatch(setSelectedOption(getItemValue))

 
    setShowMessage1(false)
    setShowPopup(false)
    setShowPopup1(false)
    setShowCreateModel(true)
    setShowCoefficient(false)

  const response = await axios.delete(`https://ai.aidprofit.com/Removed?project_id=${params.id}`);
    

}

const handleTextChange = (index, selectedOption, templateType, template) => {
  console.log(selectedOption)
  let updatedSelectedTexts = [...selectedTexts];
// console.log("updatedSelectedTexts",updatedSelectedTexts )
  if (Array.isArray(selectedOption)) {
    if (selectedOption.length > 4) {
      alert("You can select only up to 4 options.");
      return; // Exit the function
    }
    updatedSelectedTexts[index] = selectedOption.map(option => option?.label);
  } else {
    updatedSelectedTexts[index] = selectedOption ? selectedOption : null;
  }

  const data = {
    projectId: params?.id,
    type: templateType,
    data: selectedOption,
    file_id: file_Id?._id,
    templateCordinates: {
      x: template.x,
      y: template.y
    },

    unique_widget_id: template?.unique_widget_id // Add this property

  };

  dispatch(saveRightSideVariables(data));
  saveDataToSelectedRightSidebar(data);

  setAdditionalTextFieldVisible(true);
  setSelectedTexts(updatedSelectedTexts);
//  
};

  useEffect(() => {
    const projectId = params.id;
    axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-rightsidebar-variables/${projectId}` )
      .then(res => {
        dispatch(getRightSidebarVariablesSuccess(res.data.templates)); // Assuming you have an action creator for this
      })
      .catch(error => {
        console.error('Error fetching right sidebar variables:', error);
        // Dispatch an action to handle the error if needed
      });
  }, []);
  
  // useEffect(() => {
  //   if (files && params.id) {
  //     const fileWithMatchingProjectId = files.find(file => file.metadata && file.metadata.projectId === params.id);

  //     if (fileWithMatchingProjectId) {
  //       const filename = fileWithMatchingProjectId.filename;
  //       // console.log("message")
  //       dispatch(getUploadedFiles(filename));
     
  //     }
  //   }
  // }, [files]);

  // useEffect(()=>{
    
  //   dispatch(getUploadedFiles2())
  // },[])
  // useEffect(()=>{
  //   dispatch(updateFileInfo())
  // },[params.id])

  const firstFileInfo =fileInfo && fileInfo?.length > 0 ? fileInfo[0] : null;


  let options = [];
  
  if (firstFileInfo) {
    options = Object.keys(firstFileInfo).map(key => ({
      value: firstFileInfo[key],
      label: key
    }));
  }
  // } else {
  //   options = [
  //     { value: 'Variable 1', label: 'Variable 1' },
  //     { value: 'Variable 2', label: 'Variable 2' },
  //     { value: 'Variable 3', label: 'Variable 3' }
  //   ];
  // }
  // const [selectedTexts, setSelectedTexts] = useState(Array(options.length).fill(''));
  // const [selectedTexts, setSelectedTexts] = useState([options[0]?.value || '']);

  const typeMapping = {
    'Uploaded Single Variable Widget': 'singleVariable',
    'Uploaded Group Variable Widget': 'groupVariable',
    'Single Preset Variable Widget': 'singlePresetVariable', 
    'Group Preset Variable Widget': 'groupPresetVariable',
    'Mutate Variable Widget': 'mutuateVariable',
    'Formula Variable Widget': 'formulaVariable',
  };

//that is only returns label value only
const getSelectedValueForType = (selectedTexts, type) => {
  const index = allTemplates.findIndex(template => typeMapping[template.text] === type);
  const selectedValue = selectedTexts[index];
  if (selectedValue) {
      if (Array.isArray(selectedValue)) {
          // If it's an array, return the array itself
  // If it's an array, extract labels
        const labels = selectedValue.map(value => value.label);
        return labels;
      } else {
          const option = options.find(option => option?.label === selectedValue);
          return option ? option?.label : null;
      }
  }
  return null;
};



// Function to handle the multiplication and store the result
const handleCoefficientMultiplication = (key, value) => {
  console.log(key, value)

  const newValue = parseFloat(value);
  const multipliedValue = isNaN(newValue) ? '' : newValue * showAucValue[bestModelName].Coefficients[key];
  setMultipliedValues(prevState => ({
    ...prevState,
    [key]: multipliedValue
  }));
};
 // Load saved multipliedValues from localStorage on component mount
 useEffect(() => {
  const savedValues = localStorage.getItem('simulationResult');
  
 
  if (savedValues) {
    setMultipliedValuesSum(JSON.parse(savedValues));
  }

}, []);

// // Save multipliedValues to localStorage whenever it changes
useEffect(() => {
  localStorage.setItem('simulationResult', JSON.stringify(multipliedValuesSum));
}, [showSum,multipliedValuesSum]);

const allFieldsFilled = (fields) => {
  return fields.every(field => multipliedValues[field] !== undefined && multipliedValues[field] !== '');
};

                const handleSimulation = () => {
                  // setShowSum()
                  if(allFieldsFilled(Object.keys(showAucValue[bestModelName].Coefficients))){
                  const sum = Object.values(multipliedValues).reduce((acc, val) => acc + (isNaN(val) ? 0 : val), 0);
            console.log(sum,"summ")
                  setMultipliedValuesSum(sum);
                  setShowSum(Object.keys(multipliedValues).every(key => multipliedValues[key] !== ''));
                  const interceptValue = showAucValue[bestModelName]?.Intercept[0];
                    setIntercept(interceptValue !== undefined ? interceptValue : null);
                  }
                }

              
            
              // const allFieldsFilled = (fields) => {
              //   // Check if all values in 'fields' array are not empty strings AND exist in multipliedValues
              //   return fields.every(field => multipliedValues.hasOwnProperty(field) && multipliedValues[field] !== '');
              // };


const handleClosePopUp = ()=>{
  setShowPopup3(false); 
}

const handleSaveVariables = () => {

  //untill all connectors is not connects to all template then show popup
  if (allTemplates?.length !== connectorLines.length + 1) {

    setShowPopup3(true); 
    return;
  }
   // Check if the rightSideVariables length is less than or equal to 3
   if (rightSideVariables?.length < 3) {
    setShowPopup(true); // Show popup if less than or equal to 3 elements
    return;
  }
  // if ( selectedTexts.length === 0 || selectedTexts.some(value => !value || (Array.isArray(value) && value.length === 0))) {
  //   setShowPopup(true); // Show popup if any dropdown is empty
  //   return;
  // }
  setShowPopup2(true);
  // localStorage.setItem('freeze', JSON.stringify(true));

  // setShowMessage1(true);
  setShowCreateModel(false);
};

// const handleScreenshot = () => {
//   html2canvas(document.body).then((canvas) => {
//     const imgData = canvas.toDataURL('image/png');
//    dispatch(setScreenShot(imgData))
//     setScreenshot(imgData);
//   }).catch(error => {
//     console.error("Error capturing screenshot: ", error);
//   });
// };
// const [isVisible,setIsVisible] =useState(false)
// const handleChatBot = ()=>{
//   setIsVisible(true)
// }
const [bestModelName,setBestModelName] = useState("")
const [mixedModelArray,setMixedModelArray] = useState([])
const [selectedDropdownValues, setSelectedDropdownValues] = useState({});
//Mixed model popup dropDown value saved in states
const handleDropdownChange = (value, placeholder) => {
  setSelectedDropdownValues(prevValues => ({
    ...prevValues,
    [placeholder]: value
  }));
};

const handleMixModelCancelClick = () => {
  setSelectedDropdownValues({});
  setMixedModelPopUp(false);
};

const handleRunButtonClick = async () => {
//freeze the screen when clicks in Run 
  localStorage.setItem('freeze', JSON.stringify({
    freeze: true, // Your current freeze state
   projectId: params.id // Extract the project ID from params
   }));

  const getItemValue = JSON.parse(localStorage.getItem('freeze'));
  dispatch(setSelectedOption(getItemValue));


  const projectId = params.id;
  // handleScreenshot()
  setShowMessage(true)

  setShowPopup2(false)
  setShowMessage1(true)
  try {
    const data = {
      projectId,
      singleVariable: getSelectedValueForType(selectedTexts, 'singleVariable') || null,
      groupVariable: getSelectedValueForType(selectedTexts, 'groupVariable') || null,
      singlePresetVariable: getSelectedValueForType(selectedTexts, 'singlePresetVariable') || null,
      groupPresetVariable: getSelectedValueForType(selectedTexts, 'groupPresetVariable') || null,
      mutuateVariable: getSelectedValueForType(selectedTexts, 'mutuateVariable') || null,
      formulaVariable: getSelectedValueForType(selectedTexts, 'formulaVariable') || null,
    };

    // Chain the POST and GET requests using Promise.all
    const [saveResponse, getResponse] = await Promise.all([
      axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-rightsidebar-variables`, data),
      axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-rightsidebar-variables/${projectId}`),
    ]);

    if (getResponse.status === 200) {
      if (getResponse.data?.templates?.length < 3) {
        setShowPopup(true); // Show popup if less than 3 elements
        return;
      }

      dispatch(getRightSidebarVariablesSuccess(getResponse?.data?.templates));
 // Conditionally include mutateData if it has content
 const dataArray = getResponse?.data?.templates ? [...getResponse.data.templates] : [];
const aiPayloadData = dataArray.filter(getsData => getsData.type !== "Mutate Variable Widget" && getsData.type !== "Formula Variable Widget")
 
if (Object.keys(mutateData).length > 0) {
  aiPayloadData.push({...mutateData,type : "Mutate Variable Widget"});
 }
 if (Object.keys(formulaWidget).length > 0) {
  aiPayloadData.push({...formulaWidget,type : "Formula Variable Widget"});
 }



//When we clicks on Run button then , identify is it mixed model or not

    const aiResponse = await axios.post(
      'https://ai.aidprofit.com/mixed_model_identiying',
      { data: aiPayloadData },
      { headers: { 'Content-Type': 'application/json' } }
    );

//then logistic regression and comes a array of object
    if (aiResponse?.data?.result === false) {
      setMixedModelArray([])
      try{

        const runModelApi = await axios.post(
          'https://ai.aidprofit.com/test',
          { data: dataArray },
          { headers: { 'Content-Type': 'application/json' } }
        );
         
        if(runModelApi?.data?.result == "create model again" || runModelApi?.data?.result === "" ){
            toast.error("Please Create a model again");
            return;
        }
        const bestModelCoefficients = runModelApi.data.result;
       
        const bestModelNameObject = bestModelCoefficients.find(item => item.hasOwnProperty("Best Model Name"));
       const bestModelNamee = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;
      
      //  console.log(`Best Model Name: ${bestModelNamee}`)//that is object of best Model
      
      // Step 2: Find the object that matches the best model name
      const bestModel = bestModelCoefficients.find(obj => obj[bestModelNamee]);
      
      console.log(bestModel)
      console.log(bestModel[bestModelNamee]?.Coefficients)
      
      
      
      
      setBestModelName(bestModelNamee)
        setShowAiResponse(bestModel.Coefficients);
        setShowAucValue(bestModel);
        setShowCoefficient(true);
        setShowAllModels(runModelApi?.data?.result);
      
      // Ensure state updates before checking conditions
      if (bestModelCoefficients[bestModelName]?.['Mean AUC'] < getProjectData?.thresholdMetric?.thresholdMetricValue) {
        setOpen(true);
      }
      }catch(e){
        console.log(e);
      }
 
      
    }
    
    else if (aiResponse.data.result === true) {
      setMixedModelArray(dataArray)
      setMixedModelPopUp(true);
    }
     


      toast.success("Successfully saved variables");
    }
  } catch (error) {
    setShowAllModels([])
    console.error('Error saving variables:', error);
  } finally {
   
    setShowPopup2(false)
    setShowCreateModel(false);
    setShowMessage1(false);
  }
};

//Inside te model of mixed model we can show this 
const handleRunButtonClick1 = async () =>{
  //freeze the screen when clicks in RUn 
  localStorage.setItem('freeze', JSON.stringify({
    freeze: true, // Your current freeze state
   projectId: params.id // Extract the project ID from params
   }));

  const getItemValue = JSON.parse(localStorage.getItem('freeze'));
  dispatch(setSelectedOption(getItemValue));


  // handleScreenshot()
  setShowMessage(true)

  setShowPopup2(false)
  setShowMessage1(true)

  if (rightSideVariables.length < 3) {
    setShowPopup(true); // Show popup if less than or equal to 3 elements
    return;
  }
const modelName ={
  model : "mixed model"
}
  try
  {

    const mixedModelData = [
      ...mixedModelArray,  // that objects is widget dropdown variable
      selectedDropdownValues , // Add selectedDropdownValues directly if it's an object
      modelName  //that is also object
    ];
    setMixedModelPopUp(false)
    const runModelApi = await axios.post(
      'https://ai.aidprofit.com/test',
      { data: mixedModelData },
      { headers: { 'Content-Type': 'application/json' } }
    );

    if (runModelApi?.data?.result === "create model again") {
      toast.error("Please Create a model again");
    }

    if (runModelApi.data?.templates?.length < 3) {
      setShowPopup(true); // Show popup if less than 3 elements
      return;
    }
  
    setSelectedDropdownValues({})
    setMixedModelPopUp(false)

// console.log("runModelApi",runModelApi)
  const bestModelCoefficients = runModelApi.data.result;
  // console.log("bestModelCoefficients",bestModelCoefficients)

  const bestModelNameObject = bestModelCoefficients.find(item => item.hasOwnProperty("Best Model Name"));
const bestModelNamee = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;

 console.log(`Best Model Name: ${bestModelNamee}`)//that is object of best Model

// Step 2: Find the object that matches the best model name
const bestModel = bestModelCoefficients.find(obj => obj[bestModelNamee]);

// console.log(bestModel)
// console.log(bestModel[bestModelNamee]?.Coefficients)




setBestModelName(bestModelNamee)
  setShowAiResponse(bestModel.Coefficients);
  setShowAucValue(bestModel);
  setShowCoefficient(true);
  setShowAllModels(runModelApi?.data?.result);

  // Ensure state updates before checking conditions
  if (bestModelCoefficients[bestModelName]?.['Mean AUC'] < getProjectData?.thresholdMetric?.thresholdMetricValue) {
    setOpen(true);
  }
  }catch(e){
    setSelectedDropdownValues({})
    setMixedModelPopUp(false)
    console.log(e);
  } finally {
   
    setShowPopup2(false)
    setShowCreateModel(false);
    setShowMessage1(false);
  }
}


const handleClose = () => setOpen(false);



  // console.log(rightSideVariables,"var")
  // console.log(selectedTexts,"selectedText")
  
  // const initializeSelectedTexts = () => {
  //   const initialSelectedTexts = allTemplates.map(template => {
  //     const type = typeMapping[template.text];
  //     return rightSideVariables[type] || (options[0]?.value || '');
  //   });
  //   setSelectedTexts(initialSelectedTexts);
  // };


  ///Initially call this function 
  // const initializeSelectedTexts = () => {

    // const initialSelectedTexts = allTemplates.map(template => {
    //   const type = typeMapping[template?.text];

    //   const defaultValue = rightSideVariables[type] || (options[0]?.value || '');
    //   console.log(`Initializing selectedTexts for ${template.text}: ${defaultValue}`);
    //   return defaultValue;
    // });

    //that is calls the api which is get the saved right sidebar variables
    useEffect(() => {
      dispatch(getRightsideVariables(params.id))
    }, [params.id,dispatch]);
    
    const initializeSelectedTexts = () => {
     
    const initialSelectedTexts = rightSideVariables.map(variable => {
      let defaultValue = '';

   
              // Handle different types accordingly
              switch (variable.type) {

                  case "Uploaded Single Variable Widget":
                  case "Single Preset Variable Widget":
                      defaultValue = variable?.data || '';
                      break;
                  case "Uploaded Group Variable Widget":
                  case "Group Preset Variable Widget":
                      if (Array.isArray(variable.data)) {
                          // Handle array of objects
                          defaultValue = variable.data.map(obj => Object.values(obj)[0]);
                      } else if (typeof variable.data === 'object') {
                          // Handle single object
                          defaultValue = Object.values(variable?.data).map(value => value?.variableName || '');
                      }
                      break;
                  default:
                      defaultValue = '';
                      break;
              }
              console.log(`Initializing selectedTexts  ${defaultValue}`);
  
          // console.log(`Initializing selectedTexts for ${template?.text}:`, defaultValue);
          return defaultValue;
      });
  
      setSelectedTexts(initialSelectedTexts);
  };

  const getAiResponse =async () =>{
    try{
      const getAiResponse1 = await axios.get(`https://ai.aidprofit.com/data?project_id=${params.id}`);

      const bestModelCoefficients = getAiResponse1.data.res;
      // console.log("bestModelCoefficients",bestModelCoefficients)
      dispatch(setModelGraph(bestModelCoefficients))
      const bestModelNameObject = bestModelCoefficients.find(item => item.hasOwnProperty("Best Model Name"));
 const bestModelNamee = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;

 // Step 2: Find the object that matches the best model name
const bestModel = bestModelCoefficients.find(obj => obj[bestModelNamee]);

//sets the graph data and send to another component dashbaord


     setBestModelName(bestModelNamee)
      setShowAiResponse(bestModel.Coefficients);
      setShowAucValue(bestModel);
      setShowCoefficient(true);
      setShowAllModels(getAiResponse1?.data?.res);

       // Check if Best Model is undefined
  // const bestModel = showAllModels?.['Best Model Name'];
  // if (showAucValue?.['Mean AUC'] < getProjectData?.thresholdMetric?.thresholdMetricValue) {
  //   setOpen(true);
  // }
  if(getAiResponse1.data){
localStorage.setItem("freeze" ,JSON.stringify({
  freeze : true,
  projectId : params.id
}))
setShowMessage(true)
  }  

  }catch(error){
    
      localStorage.setItem("freeze" ,JSON.stringify({
        freeze : false,
        projectId : null
  }))
    console.error(error)
  }
  }

useEffect(()=>{

  getAiResponse()

},[params.id])

  useEffect(() => {
      if (!rightSideVariablesLoading && rightSideVariables) {
          initializeSelectedTexts();
      }
  }, [rightSideVariables, rightSideVariablesLoading]);


const [selectedOption1 , setSelectedOption1] = useState([])

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    option: (provided) => ({

      ...provided,
      fontSize: '16px',

    }),
  };
  const modelKeys = showAllModels.reduce((keys, obj) => {
    const modelKey = Object.keys(obj).find(key => key !== "freeze" && key !== "Best Model Name");
    if (modelKey) keys.push(modelKey);
    return keys;
}, []);

const data = modelKeys.map(key => ({
    label: key,
    value: '1'
}));

const chartData = {
  labels: modelKeys,
  datasets: [
      {
          label: 'Model Accuracy',
          data: data.map(item => item.value),
          backgroundColor: [
              '#f1c40f',
              '#2ecc71',
              '#3498db',
              '#9b59b6',
              '#e74c3c',
              '#34495e'
          ],
          borderColor: '#fff',
          borderWidth: 1
      }
  ]
};
  const hasCreateModelAgain = Object.keys(showAllModels).includes('create model again');



  return (
   
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div style={{ flex: 1 }}>
        {showSimulationOptions ? (
          
          <>
  
              {
              selectedOption1.map((option, index) => (
                 <div className='pb-3'>
                <Label style={{fontSize: '16px'}} className='labels'>{`Simulation value for ${option.label}` }</Label>

                <TextField
                    key={index}
                    inputProps={{ style: { fontSize: '18px', height: '16px' } }} 
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: '7px' }}
                    // Add any additional props or event handlers as needed
                />
               </div>
            ))
        
         
          }
            
                     {
              showAucValue && (
                <>
                  <h2 style={{ textAlign: 'center', marginTop: '13%' }}>{`Coefficient of ${bestModelName}`}</h2>
                  <div style={{ border: '1px solid #000', borderRadius: '2px', marginTop: '2%', marginLeft: '3%' }}>
                    {showAucValue[bestModelName]?.Coefficients && Object.entries(showAucValue[bestModelName].Coefficients).map(([key, value]) => (
                      <div key={key} style={{ marginTop: '2%', marginLeft: '3%' }}>
                        <h5 style={{ marginBottom: '10px', fontSize: '16px' }}>
                          Coefficient of {key}: {typeof value === 'number' ? value.toFixed(4) : Number(value).toFixed(4)}
                        </h5>
                      </div>
                    ))}
                  </div>
                </>
              )
            }

   
                      {
                  showAucValue && (
                    <>

                <h2 style={{ textAlign: 'center', marginTop: '3%' }}>{`Value of Independent Variable`}</h2>

                    <div style={{ border: '1px solid #000',borderRadius : '2px', marginTop: '2%', marginLeft: '3%' }}>
                      {showAucValue[bestModelName] && Object.entries(showAucValue[bestModelName]?.Coefficients).map(([key, value]) => (
                        <div key={key} style={{marginTop: '2%', marginLeft: '3%'}}>
                          <h5 style={{ marginBottom: '10px', fontSize: '16px' }}>
                          Value of {key}
                          </h5>
                          <TextField
                                  onChange={(event) => handleCoefficientMultiplication(key, parseFloat(event.target.value))}
                                  variant="outlined"
                                  // fullWidth
                                  required
                                  sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                                  inputProps={{ style: { fontSize: '18px', height: '16px' } }} // Adjust height here
                                />
                                {multipliedValues[key] !== '' && (
                                  <p style={{fontSize : '14px'}}>Result of {key}: {multipliedValues[key]}</p>
                                )}
                        </div>
                      ))}
                    </div>
                    </>
                  )
                }

{/* //Chatbot and simulation button */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '3%' }}>

                    <Button
                      onClick={handleSimulation}
                      style={{
                        backgroundColor: '#50A4DB',
                        borderRadius: '8px',
                        color: '#FFF',
                        fontSize: '20px',
                        fontWeight: '400',
                        textTransform: 'none',
                        marginLeft: 'auto',  // This pushes the button to the right
                        marginBottom: '7%',
                       
                      }}
                    >
                      Simulate
                    </Button>

                 
                    </div>

                    <hr />

{showCoefficient && (
<>
{showSum ? (

<p style={{fontSize : '15px'}}>Results :  ({(intercept + multipliedValuesSum)})</p>
) : (
<p style={{ color: 'red' }}>* Please fill in all fields</p>
)
}
</>
)}
          </>
        ) : (
          <>
       <div className='pt-2 pb-4' style={{marginTop : '4%'}}>
      {allTemplates && allTemplates.length > 0 && (
    
     <Input
          type="text"
          id="name"
          fullWidth
          name="name"
          style={{ fontSize: '16px', fontWeight: '500', color: '#50a4db' }}
          value={allTemplates[allTemplates.length - 1]?.text.slice(0,-7)}
        />

      )} 
    </div>
        <div style={{marginTop : '2%'}}>

  {getProjectData && ( // Use parentheses for clarity
  <>
 
    <p style={{ fontWeight: '500', fontSize: '18px'}}>
     Type : {getProjectData?.goalType?.typeofGoal}
    </p>
    <p style={{ fontWeight: '500', fontSize: '18px'}}>
     Goal : {getProjectData?.goalName}
    </p>
    <h5 style={{ color: '#000', fontSize: '20px', fontWeight: '400' }}>Threshold Metric</h5>
    <Box sx={{ width: 170, ml: 0.9, mt: 0.6 }}>
            <Slider
              aria-label="Discrete values"
              sx={{
                '& .MuiSlider-valueLabel': { fontSize: '24px' },
                '& .MuiSlider-markLabel': { fontSize: '16px' },
                '& .Mui-disabled': {
                  color: 'blue',
                },
                '& .MuiSlider-track': {
                  color: '#50a4db',
                },
              }}
              value={thresholdValue} // Use value prop instead of defaultValue
             
              step={0.1}
             
              valueLabelDisplay="auto"
              disabled // Disable the slider
              min={0.0}
    max={1.0}
    marks={[
      { value: 0.0, label: '0.0' },
      // { value: 0.1, label: '0.1' },
      { value: 0.2, label: '0.2' },
      // { value: 0.3, label: '0.3' },
      { value: 0.4, label: '0.4' },
      // { value: 0.5, label: '0.5' },
      { value: 0.6, label: '0.6' },
      // { value: 0.7, label: '0.7' },
      { value: 0.8, label: '0.8' },
      // { value: 0.9, label: '0.9' },
      { value: 1.0, label: '1.0' },
    ]}
            />
          </Box>

   
    <p style={{ fontWeight: '500', fontSize: '18px', marginTop: '6%'}}>
      Goal Metric
    </p>
    <div style={{ display: 'flex',flexDirection:'column'}}>
  {/* Input for metric name */}
  <div style={{display : 'flex'}}>
  <Label htmlFor="type of variable" style={{  fontSize: "17px", whiteSpace: 'nowrap' }}> Name = </Label>
     
  <p
    style={{
      fontWeight: '400',
      fontSize: '16px',
      width: '80px',
      textAlign: 'center', 
      border: 'none',
      outline: 'none',
    }}>
   {getProjectData?.goalMetric?.goalMetricName}
   </p>
</div>
  
<div style={{display : 'flex'}}>
  <Label htmlFor="type of variable" style={{  fontSize: "17px", whiteSpace: 'nowrap' }}> Value = </Label>
     
  <p
    style={{
      fontWeight: '400',
      fontSize: '16px',
      width: '80px',
      textAlign: 'center', 
      border: 'none',
      outline: 'none',
    }}>
   {getProjectData?.goalMetric?.goalMetricValue}
   </p>
</div>
    
   
</div>

    </>
  )}

</div>

<div>
  {filteredTemplates && filteredTemplates?.map((template, index) => (
    <div style={{ marginTop: '4%' }} key={index}>
      {!(template.text.includes("Mutate Variable Widget") || template.text.includes("Formula Variable Widget")) && (
        <p style={{ fontWeight: '400', fontSize: '15px' }}>{template?.text}</p>
      )}

      <div key={`select-${index}`}>
        {template.text === "Uploaded Group Variable Widget"  ? (
          <ReactSelect
            key={`group-select-${index}-${template.unique_widget_id}`} // Combine index and unique_widget_id
            isDisabled={showMessage}
            isMulti={template.text.includes("Group")} // Set isMulti based on template text
            value={
              template.text.includes("Group") && Array.isArray(selectedTexts[index])
                ? selectedTexts[index].map(value => ({ label: value, value: value }))
                : null // Clear value when switching to multi-select
            }
            onChange={(selectedOption) => handleTextChange(index, selectedOption, template.text, template)}
            options={options.map(option => ({ label: option.label, value: option.value }))}
            placeholder="Select an option"
            styles={customStyles}
          />
        ) : template.text === "Uploaded Single Variable Widget" ?
        (
          <ReactSelect
          key={`single-select-${index}-${template.unique_widget_id}`} // Combine index and unique_widget_id
          isDisabled={showMessage}
          isMulti={false} // Explicitly set to false for single-select
          value={selectedTexts[index] ? { label: selectedTexts[index], value: selectedTexts[index] } : null}
          onChange={(selectedOption) => handleTextChange(index, selectedOption.label, template.text, template)}
          options={options.map(option => ({ label: option.label, value: option.value }))}
          placeholder="Select an option"
          styles={customStyles}
        />
        )

        : template.text === "Single Preset Variable Widget" ? (
          <TextField
          isDisabled={showMessage}
            key={`text-field-${index}-${template.unique_widget_id}`} // Combine index and unique_widget_id
            disabled={showMessage}
            value={selectedTexts[index] || ''}
            onChange={(e) => handleTextChange(index, e.target.value, template.text, template)}
            placeholder="Enter text"
            variant="outlined"
            fullWidth
         
            inputProps={{ maxLength: 17}} // Enforce max length at the input level
            InputProps={{
              style: { fontSize: '15px', backgroundColor: 'white',height:'40px' } // Set the font size and background color here
            }}
            />
        )
        
        :
        template.text === "Group Preset Variable Widget" ? (
          <CreatableSelect 
          isMulti
 
          key={`group-select-${index}-${template.unique_widget_id}`} // Combine index and unique_widget_id
          disabled={showMessage}
          // isMulti={template.text.includes("Group")} // Set isMulti based on template text
          value={
            template.text.includes("Group") && Array.isArray(selectedTexts[index])
              ? selectedTexts[index].map(value => ({ label: value, value: value }))
              : null // Clear value when switching to multi-select
          }
          onChange={(selectedOption) => handleTextChange(index, selectedOption, template.text, template)}
          options={options.map(option => ({ label: option.label, value: option.value }))}
          placeholder="Creates an option"
          styles={customStyles}
          />
      )
      :

      (
          template.text === "Mutate Variable Widget" || template.text === "Formula Variable Widget" ? (
            null
          )
          :

          null
        )}
      </div>  
    </div>
  ))}
</div>



       {showPopup && (
  <Alert severity="error" sx={{ width: '100%' , marginTop: '2%'}}>
    <AlertTitle style={{ fontSize: '14px' }}>Minimum 3 Widgets Required or Variable Required</AlertTitle>
  </Alert>
)}

       {showPopup1 && ( 
  <Alert style={{marginTop:'2%'}}>

    <AlertTitle style={{fontSize:'16px',width:'100%'}}>Please Selects Variable in dropdown</AlertTitle>
   
  </Alert>
)}

       <div style={{ display: 'flex' }}>
       
          {
              !freeze?.freeze  &&  
            <Button
            onClick={
              handleSaveVariables  
             }
            style={{
              backgroundColor: '#50A4DB',
              borderRadius: '8px',
              color: '#FFF',
              fontSize: '20px',
              fontWeight: '400',
              textTransform: 'none',
              // marginRight: '7%',
              marginTop : '7%',
              // marginBottom : '34%',
      
            }}>
              Create Model
            </Button>
          }

<Dialog
        open={showPopup2}
        onClose={() => setShowPopup2(false)}
        PaperProps={{
          // style: {
          //   width: '400px', // Adjust the width as needed
          //   height: '160px', // Adjust the height as needed
          // },
        }}
      >
        <DialogTitle>
         <h3> Train the Model </h3>
          </DialogTitle>
        <DialogContent>
          <p style={{fontSize : '18px'}}> Do you want to train the Model with Goal Metric {getProjectData?.goalMetric?.goalMetricName} = {getProjectData?.goalMetric?.goalMetricValue} 
          <br/>   
          and last outcome variable = {targetColumn} ?
           </p>
           {/* <p style={{fontSize : '18px'}}>The last Outcome Widget variable is {selectedTexts && selectedTexts[selectedTexts.length-1]} ?</p> */}
        </DialogContent>
        <DialogActions>

          <Button style={{fontSize : '16px'}} onClick={handleRunButtonClick} color="primary" variant="contained">Run</Button>
          <Button style={{fontSize : '16px'}} onClick={() => {
            setShowPopup2(false)
            setShowMessage(false) }
          }>Cancel</Button>
        </DialogActions>
      </Dialog>
    
  </div>
  

  {/* ///when connector lines is not connect to all template then show popup */}
  <Dialog
        open={showPopup3}
        onClose={() => setShowPopup3(false)}
        PaperProps={{
          style: {
            width: '400px', // Adjust the width as needed
            height: '160px', // Adjust the height as needed
          },
        }}
      >
        <DialogTitle> 
         <h3>Connection Error</h3>
          </DialogTitle>
        <DialogContent>
          <p style={{fontSize : '18px'}}>Please connects all connectors lines to widget and then create model</p>
          
                 </DialogContent>
        <DialogActions>
          <Button style={{fontSize : '16px'}} onClick={() => setShowPopup3(false)}>Cancel</Button>
             </DialogActions>
      </Dialog>


      <Dialog
        open={mixedModelPopUp}
        onClose={() => setMixedModelPopUp(false)}
        PaperProps={{
          style: {
            width: '500px', // Adjust the width as needed
            height: '420px', // Adjust the height as needed,

          },
        }}
      >
        <DialogTitle> 
         <h3>Mixed Models </h3>
          </DialogTitle>
        <DialogContent>
          {/* <p style={{fontSize : '18px'}}>Please connects all connectors lines to widget and then create model</p> */}
          
          <>
          {placeholders.map((placeholder, index) => (
   <div style={{marginTop:'4%'}}>
    <ReactSelect
      key={index}
      isClearable
      options={selectedTexts.filter(option=> option !== targetColumn).map(option => ({ label: option, value: option}))}
      placeholder={placeholders[index]} // Dynamic placeholder from the array
      onChange={(selectedOption) => handleDropdownChange(selectedOption?.label, placeholders[index])}
      sx={{ width: '100%' }}
      inputProps={{ style: { fontSize: '16px', height: '14px' } }} // Adjust height here
      styles={{
        control: (provided) => ({
          ...provided,
          fontSize: '16px', // Set font size for the input field
          // borderWidth: '2px', // Increase border size
          padding: '4px 0px 6px 0px' // Add more padding
        }),
        singleValue: (provided) => ({
          ...provided,
          fontSize: '18px' // Set font size for the selected value
        }),
        option: (provided) => ({
          ...provided,
          fontSize: '16px' // Set font size for the options in the dropdown
        })
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          neutral10: 'grey',
          primary: '#50a4db',
        },
      })}
    />
    </div>
  ))
}

        
  
          </>
                 </DialogContent>
        <DialogActions>
          
          <Button style={{fontSize : '16px'}} onClick={() => handleRunButtonClick1()}>Run</Button>
          <Button style={{fontSize : '16px'}} onClick={() => handleMixModelCancelClick()}>Cancel</Button>
             </DialogActions>
      </Dialog>

  <div>
  {
        showMessage1 && 
        <p style={{fontSize :  '20px',marginTop:'5%'}}>Creating Model....</p>

      }
  {
     freeze && freeze.freeze  && freeze.projectId === params.id   && 
        <Button onClick = {handleClearClicks}  style={{
          backgroundColor: '#50A4DB',
          borderRadius: '8px',
          color: '#FFF',
          fontSize: '20px',
          fontWeight: '400',
          textTransform: 'none',
          // marginRight: '7%',
          marginTop : '7%',
          marginBottom : '7%',
  
        }}>Reset All...</Button>
        
      }
     
     {
  showCoefficient && showAllModels && (
    <div style={{marginTop : '2%'}}>
      <h2 style={{ textAlign: 'center' }}>All Models</h2>

      <div style={{ border: '1px solid #000', borderRadius: '10px', marginTop: '2%', marginLeft: '3%', position: 'relative' }}>
        <div>
        { bestModelName ? (
            <Pie
            data={chartData}
            options={{
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'right'
              }
            }}
          />
        )
          :
          <p style={{ fontSize: '16px', textAlign: 'center', marginTop: '3%' }}>No data available to display the chart</p>
          }
        
        </div>

        {showAllModels['Best Model Name'] && (
          <p style={{ fontSize: '14px', textAlign: 'center', marginTop: '3%' }}>
            {`Best Model is ${showAllModels['Best Model Name']}`}
          </p>
        ) }
      </div>
    </div>
  )
}
{/* //Please create a model again */}

<Dialog
      open={open}
      onClose={handleClose}
        PaperProps={{
          style: {
            width: '400px', // Adjust the width as needed
            height: '160px', // Adjust the height as needed
          },
        }}
      >
        <DialogTitle>
         <h3>Alert! </h3>
          </DialogTitle>
        <DialogContent>
          <p style={{fontSize : '18px'}}> Please create a model again.</p>
        </DialogContent>
        <DialogActions>
          <Button style={{fontSize : '16px'}} onClick={handleClose}>Cancel</Button>
          {/* <Button style={{fontSize : '16px'}} onClick={handleRunButtonClick} color="primary" variant="contained">Run</Button> */}
        </DialogActions>
      </Dialog>

      {
  showCoefficient && (
    <>
      <h2 style={{ textAlign: 'center', marginTop: '3%' }}>
        {`Coefficient of ${bestModelName}`}
      </h2>

      <div style={{ border: '1px solid #000', borderRadius: '10px', marginTop: '2%', marginLeft: '3%' }}>
        {Object.entries(showAucValue[bestModelName]?.Coefficients).map(([key, value]) => (
          <div key={key} style={{ marginTop: '2%', marginLeft: '3%' }}>
            <h5 style={{ marginBottom: '5px', fontSize: '16px' }}>
              Coefficient of {key}: {typeof value === 'number' ? value.toFixed(4) : 'N/A'}
            </h5>
          </div>
        ))}
      </div>
    </>
  )
}

{

showCoefficient && showAucValue && getProjectData?.thresholdMetric && (
  <>
  <h2  style={{ textAlign: 'center',marginTop : '3%' }}>{`Result Values of ${bestModelName}`}</h2>
   
  <div style={{ border: '1px solid #000',borderRadius : '10px', marginTop: '2%', marginLeft: '3%' }}>
  <h5 style={{ marginTop: '2%', marginLeft: '3%', fontSize: '16px' }}>
        Threshold Value : {getProjectData?.thresholdMetric?.thresholdMetricValue}
            </h5>  
            {Object.entries(showAucValue[bestModelName])
  .filter(([key]) => key !== 'Coefficients' && key !== 'Intercept' && key !== 'fpr' && key !== 'tpr') // Filter out the "Coefficients" and "Intercept" keys
  .map(([key, value]) => (
    <div key={key} style={{ marginTop: '2%', marginLeft: '3%' }}>
      <h5 style={{ marginBottom: '5px', fontSize: '16px' }}>
        {key}: {typeof value === 'number' ? value.toFixed(4) : Number(value).toFixed(4)}  
      </h5>
    </div>
  ))}

  </div>
  </>
) 
}

{
  
showCoefficient && showAucValue && getProjectData?.thresholdMetric && (
  <>
  <h2  style={{ textAlign: 'center',marginTop : '3%' }}>{`Best Model Intercept`}</h2>
   
  <div style={{ border: '1px solid #000',borderRadius : '10px', marginTop: '2%', marginLeft: '3%' }}>
  <h5 style={{ marginTop: '2%', marginLeft: '3%', fontSize: '16px' }}>
       Intercept : {showAucValue[bestModelName]?.Intercept[0]}
            </h5>  

</div>
            </>


            )
}


 
  </div>

        </>
        )}
        
       {
  showCoefficient && (
    <>
    <h2  style={{ textAlign: 'center',marginTop : '3%' }}>Thermometer</h2>
   
    <Progress multi style={{ marginTop: '4%' ,  height: '30px'}}>
  
  <Progress bar color="success" value="34" className="w-100">
  <span style={{ fontSize: '16px' }}>
  AUC: {typeof showAucValue[bestModelName]?.['Mean AUC'] === 'number' ? showAucValue[bestModelName]['Mean AUC'].toFixed(4) : 'N/A'}
</span>

  </Progress>
  <Progress bar color="danger" value="33" className="w-100" >
    <span style={{ fontSize: '16px' }}>
    Threshold: {getProjectData?.thresholdMetric?.thresholdMetricValue}
    </span>
  </Progress>
</Progress>

{showAucValue && getProjectData && (
  <div style={{ marginTop: '2%', marginLeft: '3%', display: 'flex', alignItems: 'center' }}>
    
    {showAucValue[bestModelName]?.['Mean AUC'] > getProjectData?.thresholdMetric?.thresholdMetricValue ? (
 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
 <span style={{ fontSize: '16px' }}>
   {`AUC > Threshold :`}
 </span>
 <span style={{ color: '#198754', fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' ,justifyContent: 'center'}}>
   Best Fitted Model
   <CheckCircleIcon style={{ color: '#198754', marginLeft: '5px' }} />
 </span>
</div>

): (
      <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
       <span style={{ fontSize: '16px' }}>
   {`Threshold > AUC :`}
 </span>
        <span style={{ color: '#198754', fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' ,justifyContent: 'center'}}>Bad Model
        <CancelIcon style={{ color: '#dc3545', marginLeft: '5px' }} /> {/* Example: Use a cross icon */}
        </span>
        </div>
      </>
    )}
  </div>
)}

{bestModelName ? (
          <p style={{ fontSize: '14px', textAlign: 'center', marginTop: '3%' }}>
            {`Best Model is ${bestModelName}`}
          </p>
        ) : (
          <p style={{ fontSize: '14px', textAlign: 'center', marginTop: '3%', color: 'red' }}>
            Please create a model again
          </p>
        )}

    </>
  )
}

       </div>

   
  
      </div>
  );
}
  
export default RightSidebar;