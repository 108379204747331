import { Button, Container, Grid, Link,Pagination as MuiPagination } from "@mui/material";
import CancelPlan from "./CancelPlan";
import { useEffect, useState } from "react";
import './Subscription.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';
function Subscription() {

    const [paymentData1, setPaymentData1] = useState(null);
    const [pageCount, setPageCount] = useState(0);
  const [currentData, setCurrentData] = useState([]);
    const data = JSON.parse(localStorage.getItem('user-profile'));
  
  function convertToLocaleDateString(dateString) {
    const dateObject = new Date(dateString);
  
    // Check if dateObject is valid before proceeding
    if (!isNaN(dateObject.getTime())) {
      const localDateString = dateObject.toLocaleDateString();
      return localDateString;
    } else {
      console.error('Invalid date string:', dateString);
      return 'Invalid Date';
    }
  }
    const fetchPaymentData = async () => {
        try {
          // Ensure data.billingId exists before making the API call
          if (!data || !data.billingId) {
            console.error('Invalid data or billingId is missing.');
            return;
          }
      
          const billingId = data.billingId;
    
          // Define the API endpoint with the billingId parameter
          const apiUrl = `${process.env.REACT_APP_PUBLIC_BASE_URL}/balance-transactions/${billingId}`;
      
          // Make the API call using Axios
          const response = await axios.get(apiUrl);
      
          // Update the state with the API response
          setPaymentData1(response.data);
          
        } catch (error) {
          console.error('Error fetching payment data:', error);
          // Handle the error, if needed
        }
      };
      
    
    useEffect(() => {
  
        fetchPaymentData()
    }, []);
  
  
    const itemsPerPage = 5; // Set the number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);

const navigate = useNavigate()
const [paymentData,setPaymentData] = useState([])

useEffect(() => {
  const fetchInvoiceData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/invoiceDetails/${data.billingId}`);
      const fetchedPaymentData = response?.data?.invoiceDetails || [];

      setPaymentData(fetchedPaymentData);

      // Calculate pagination
      const calculatedPageCount = Math.ceil(fetchedPaymentData.length / itemsPerPage);
      setPageCount(calculatedPageCount);

      // Get the current page data
      const calculatedCurrentData = fetchedPaymentData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );
      console.log(calculatedCurrentData)
      setCurrentData(calculatedCurrentData);
    } catch (error) {
      console.error(error);
    }
  };

  fetchInvoiceData();
}, [data.billingId, currentPage]);



      const handleClickOpen = async () => {
        try {
    
          const response = await axios.post(
            `${process.env.REACT_APP_PUBLIC_BASE_URL}/billing-portal-link`,
            { customerId: data.billingId }
          );
         
          window.location.href = response?.data?.url
    
         await navigate('/#/home-page')
          // Handle the response here
        } catch (error) {
          console.error("AxiosError:", error);
          // Handle the error here
        }
      };


        // Calculate the total number of pages
  
    // Handle page change
    const handlePageChange = (event, value) => {
      setCurrentPage(value);
    };
  const planData = JSON.parse(localStorage.getItem('users')) 
  
  const getPriceName = (priceId) =>{
const priceName = {
  1200 : "Simple",
  2000 :  "Moderate",
  3600 : "Advance"
}
return priceName[priceId] || "Plan"
  }


      return(
          <div >
            <Grid container direction="row" spacing={2} sx={{
    marginTop: {
        xs: '15%',
        sm: '10%',
        md: '7%',
    },
    paddingBottom: '10px'
}}>
    <Grid item xs={6} md={6} sx={{ color: '#000', fontSize: { md: '32px', md: '24px', xs: '18px' }, fontWeight: '500' }}>
        <p>Subscription Plan</p>
    </Grid>

    <Grid item xs={6} md={6} container justifyContent="flex-end" alignItems="end">
        {/* Upgrade Plan */}
        <Grid item sx={{ position: 'relative', bottom: { md: '23px', sm: '10px', xs: '5px' }, fontSize: { md: '20px', xs: '14px' } }} style={{marginRight : '4%'}}>
            <Link onClick={handleClickOpen} sx={{ padding: '8px 8px', borderRadius: '4px', backgroundColor: '#50A4DB', border: '1px solid #50A4DB', color: '#FFF', textDecoration: 'none', fontWeight: '600', cursor: 'pointer' }}>Upgrade Plan</Link>
        </Grid>

        {/* Renew Plan or Cancel Plan */}
        {data && data.plan === "none" && (
            <Grid item sx={{ position: 'relative', bottom: { md: '15px', sm: '10px', xs: '5px' }, fontSize: { md: '20px', sm: '16px', xs: '10px' } }}>
                <Link onClick={handleClickOpen} sx={{ borderRadius: '4px', padding: '8px 8px', backgroundColor: '#50A4DB', border: '1px solid #50A4DB', color: '#FFF', textDecoration: 'none', fontWeight: '600', textAlign: 'center', display: 'grid', alignItems: 'center' }}>
                    Renew Plan
                </Link>
            </Grid>
        )}

        {data && data.plan !== "none" && (
            <Grid item sx={{ position: 'relative', bottom: { md: '15px', sm: '10px', xs: '5px' }, fontSize: { md: '20px', sm: '16px', xs: '10px' } }}>
                <CancelPlan customerId={data?.billingId} />
            </Grid>
        )}
    </Grid>
</Grid>


              {/* //Card */}
         
              <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', width: '100%', height: 'auto', borderRadius: '8px'}}>
                
              <Grid container direction="row" spacing={2}
  justifyContent="space-between"
  alignItems="flex-start" sx={{ paddingLeft: '10px', justifyContent: 'space-between' }}>
  {/* <Grid item xs={4} md={6} sx={{ color: '#000', fontSize: { md: '24px', sm: '18px', xs: '14px' }, fontWeight: '500', position: 'relative', top: '10px' }}>
    <p>Plan Name : </p>
    <p>{data && data.plan !== null && data.plan !== "none" ? data.plan[0].toUpperCase() +  data.plan.slice(1) : "Plan Name"} </p>
  </Grid> */}
<Grid item xs={4} md={6} sx={{ color: '#000', fontSize: { md: '24px', sm: '18px', xs: '14px' }, fontWeight: '500', position: 'relative', top: '10px' }}>
    <p>Plan Name : {data && data.plan !== null && data.plan !== "none" ? data.plan[0].toUpperCase() + data.plan.slice(1) : "Plan Name"}</p>
</Grid>

  <Grid item xs={8} md={6} sx={{ paddingLeft: '10px', color: '#AFAFAF', fontSize: { md: '18px', sm: '12px', xs: '10px' }, fontWeight: '400' }}>
  
    <p style={{display:'flex',justifyContent:'flex-end',marginRight:'3px'}}>
      {data && data.planEndDate !== null && data.planEndDate !== "none" ?
        `Next Billing Date is: ${convertToLocaleDateString(data?.planEndDate)}`
        :
        "Please Renew the plan"
      }
    </p>
  </Grid>
</Grid>
     
                                                
                  
              
              </Grid>



              <Grid item xs={12} md={12} sx={{color: '#000', fontSize:{ md: '26px', sm: '20px', xs: '16px'}, fontWeight: '400', marginTop: '3%', marginBottom: '3%'}}>
                  <p>Payment Details</p>
              </Grid>
              <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}>

                  {/* Header */}
                  <Grid container direction="row" justifyContent="center" alignItems="center" sx={{fontSize: {md: '22px', xs: '12px'}, fontWeight: '600', paddingTop: '1%', backgroundColor: '#e0e0e0'}}>
                      <Grid item xs={2} md={2}>
                          <p>Date</p>
                      </Grid>
                      <Grid item xs={2} md={2}>
                          <p>Amount</p>
                      </Grid>
                      <Grid item xs={2} md={2}>
                          <p>Status</p>
                      </Grid>
                      <Grid item xs={2} md={2}>
                          <p>Type</p>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        
                      </Grid>
                    
                  </Grid>

                  <Grid item xs={12} md={12}>
                      <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', position: 'relative', bottom: '10px'}} />
                  </Grid>

                  {/* Data rows */}

                  {currentData && currentData?.slice()?.reverse().map((item, index) => (
            <div key={index} >
                  <Grid container direction="row"  justifyContent="center" alignItems="center" sx={{fontSize: {md: '20px', xs: '10px'}, fontWeight: '400', paddingTop: '1%', paddingBottom: '1%'}}>
                      <Grid item xs={2} md={2}>

                      <p>{new Date(item?.startDate).toLocaleDateString()}</p>

                      </Grid>
                      <Grid item xs={2} md={2}>
                          <p >{item.amount}$</p>
                      </Grid>
                     
                      {
                          item?.status == 'paid' ? 
                          (
                              <Grid item xs={2} md={2}>
                         <p style={{ color: '#38B27F', border: '1px solid #F5FFEB', borderRadius: '4px', backgroundColor: '#F5FFEB', width: '50%', textAlign: 'center' }}>{item.status}</p>

                      </Grid>
                          )
  :
  (
    <Grid item xs={2} md={2}>
      <p style={{ color: '#CF6F64',
           backgroundColor: '#FFE3E3', border: '1px solid #FFE3E3', width: '50%', textAlign: 'center'}}>{item.status }</p>

  </Grid>
  )                    }
                      
                      <Grid item xs={2} md={2}>
                          <p>{item.priceId.length === 0 ? getPriceName(item.type) : `${item?.type} feature added`}</p>
                      </Grid>

                  <Grid item xs={2} md={2}>
{
                  item.status == 'paid' ?  
                   <Button className="download_size" sx={{color: '#FFF', width: '100%',backgroundColor: '#50A4DB ', borderRadius: '4px', textTransform: 'none', fontSize: '16px'}} variant="contained"
                  onClick={()=>{
                    window.location.href = item.invoiceLink
                  }} >
                    Download 
                    </Button>
                    :
                     <Button className="download_size" sx={{color: '#FFF', width: '100%',backgroundColor: '#50A4DB', borderRadius: '4px', textTransform: 'none', fontSize: '16px'}} variant="contained" 
                     onClick={()=>{
                       window.location.href = item.invoiceLink
                     }}
                     >Pay Invoice
                     </Button>
}
                      </Grid>

                  </Grid>
                  <Grid item xs={12} md={12}>
                      <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)'}} />
                  </Grid>
                  </div>
          ))}

          {/* Pagination component */}
        <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
        <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
          //   shape="rounded"
          variant="outlined"
          />
     
      </Grid>

                {/* <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{fontSize: '20px', fontWeight: '400', paddingTop: '1%', paddingBottom: '1%'}}>
                    <Grid item xs={2} md={2}>
                        <p>21/05/23</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>$ 00.00</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{color: '#38B27F', boder: '1px solid #F5FFEB', borderRadius: '4px', backgroundColor: '#F5FFEB', width: '50%', textAlign: 'center'}}>Paid</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>Plan Name</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Button sx={{color: '#FFF', width: '100%',backgroundColor: '#50A4DB ', borderRadius: '4px', textTransform: 'none', fontSize: '16px'}} variant="contained">Download Invoice</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)'}} />
                </Grid>

                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{fontSize: '20px', fontWeight: '400', paddingTop: '1%', paddingBottom: '1%'}}>
                    <Grid item xs={2} md={2}>
                        <p>21/05/23</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>$ 00.00</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{color: '#F00', boder: '1px solid rgba(255, 227, 227, 1)', borderRadius: '4px', backgroundColor: 'rgba(255, 227, 227, 1)', width: '60%', textAlign: 'center'}}>Cancelled</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>Plan Name</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Button sx={{color: '#FFF', width: '100%',backgroundColor: '#50A4DB ', borderRadius: '4px', textTransform: 'none', fontSize: '16px'}} variant="contained">Download Invoice</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)'}} />
                </Grid>
                
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{fontSize: '20px', fontWeight: '400', paddingTop: '1%', paddingBottom: '1%'}}>
                    <Grid item xs={2} md={2}>
                        <p>21/05/23</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>$ 00.00</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{color: '#38B27F', boder: '1px solid #F5FFEB', borderRadius: '4px', backgroundColor: '#F5FFEB', width: '50%', textAlign: 'center'}}>Paid</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <p style={{}}>Plan Name</p>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <Button sx={{color: '#FFF', width: '100%',backgroundColor: '#50A4DB ', borderRadius: '4px', textTransform: 'none', fontSize: '16px'}} variant="contained">Download Invoice</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)'}} />
                </Grid> */}
            </div>
        </div>
    )
}

export default Subscription;