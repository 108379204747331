import * as React from 'react';

import { AvatarGroup, Avatar,Button, Grid, Typography } from '@mui/material';
import imgSrc from '../../Images/Rectangle 148.jpg'
import { formatDistanceToNow, isValid } from 'date-fns';
import { Link } from 'react-router-dom';
import avatarImage1 from '../../Images/avatar.jpg';
import avatarImage2 from '../../Images/avatar2.jpg';
import avatarImage3 from '../../Images/avatar3.jpg';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { projectData } from '../../Reducers/CanvasReducer';
export default function HomeCard({projectName, lastEdited, to,teamMembers,canvasId,projectId,teamMemberIds}) {

const [avatars,setAvatars] = React.useState([])

React.useEffect(() => {
  const fetchProjectData = async () => {
      try {
          const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members-images/${projectId}`);
        
          setAvatars(response.data);
      } catch (error) {
          console.error('Fetching project data error:', error);
      }
  };

  if (projectId) {
      fetchProjectData();
  }
}, [projectId]);

if (!projectData) {
  // Render loading state while data is being fetched
  return <div>Loading...</div>;
}

// console.log(projectId,"projectId")

   // State to hold team member details including image URLs
  //  const [teamMembers, setTeamMembers] = useState([]);

  //  React.useEffect(() => {
  //    const fetchTeamMembers = async () => {
  //      try {
        
 
  //        // Map over team member IDs and make individual API requests to fetch image URLs
  //        const teamMemberDetails = await Promise.all(teamMemberIds.map(async (memberId) => {
  //          const memberResponse = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/${memberId}`);
  //          return memberResponse.data; // Assuming the response contains necessary details including image URL
  //        }));
 
  //        // Update state with fetched team member details
  //        setTeamMembers(teamMemberDetails);
  //      } catch (error) {
  //        console.error('Error fetching team members:', error);
  //      }
  //    };
 
  //    // Fetch team members when component mounts
  //    fetchTeamMembers();
  //  }, [teamMemberIds]);
 
  // const calculateTimeDifference = (lastEdited) => {
  //   console.log('lastEdited:', lastEdited);
  //   if (!lastEdited) {
  //     return 'Not available'; // or any default message
  //   }
  
  //   const dateObject = new Date(lastEdited);
  
  //   if (!isValid(dateObject)) {
  //     return 'Invalid date'; // Handle invalid date
  //   }
  
  //   const difference = formatDistanceToNow(dateObject, { addSuffix: true });
  //   return difference;
  // };

  // const timeDifference = calculateTimeDifference();
  
  // React.useEffect(() => {
  //   const fetchAvatars = async () => {
  //     try {
  //       // Fetch image URLs for each team member ID
  //       const avatarPromises = teamMembers.map(async memberId => {
  //         const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members-images/${memberId}`);
  //         return response.data.imageUrl; // Assuming the response contains the image URL
  //       });

  //       // Wait for all promises to resolve
  //       const avatarUrls = await Promise.all(avatarPromises);
  //       setAvatars(avatarUrls);
  //     } catch (error) {
  //       console.error('Error fetching avatars:', error);
  //     }
  //   };

  //   fetchAvatars();
  // }, [teamMembers])
  const users = JSON.parse(localStorage.getItem('users'));
    const isDisabled = users?.designation === 'teamMember' || users?.designation === 'teamManager';
  
  
    // // Array to hold Avatar components
    // const avatars = [
    //   <Avatar alt="Avatar 1" src={avatarImage1} />,
    //   <Avatar alt="Avatar 2" src={avatarImage2} />,
    //   <Avatar alt="Avatar 3" src={avatarImage3} />,
    //   <Avatar alt="Avatar 3" src={avatarImage3} />,
    // ];
    // const dispatch = useDispatch()


  return (
    <div >    
    <Grid item xs={12} md={12} container>
    <Link to={`/canvas/${projectId}`}>

    <img src={imgSrc} alt='' style={{width: '100%', height: 'auto'}} />
    </Link>
    </Grid>
    <Grid item container direction="row">
    {/* <div style={{display: 'flex', flexDirection: 'row', gap: '155px'}}> */}
    <Grid item xs={8} md={8} sx={{paddingLeft: '7px'}}>
        {/* <div style={{position: 'relative', left: '7px'}}> */}
        <Link to={`/project-det/${projectId}`}>
        <h6 style={{color: '#000', fontSize: '20px', fontWeight: '500'}}  >{projectName}</h6>
        </Link>
        <p style={{color: '#000', fontSize: '14px', fontWeight: '400'}}>last edited {lastEdited}</p>
        {/* </div> */}
        </Grid>

        <Grid item container xs={4} md={4} justifyContent="end" alignItems="end">

            <Button  component={Link} to={`/project-det/${projectId}`} sx={{width: '100%', height: 'auto', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',fontSize: '12px', fontWeight: '400', color: 'white', textTransform: 'none', position: 'relative', bottom: '25%','&:hover': {
      backgroundColor: '#1565C0',
      color : 'white' // Maintain the same background color on hover
    }, marginRight: '10%',backgroundColor:'#50A4DB'}}>Details
    </Button>
       
        </Grid>

    {/* </div> */}
    </Grid>

    {/* //Avatars */}

    <Grid item container direction="row">
    <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 34, height: 34, fontSize: 15 } }}>
    {avatars && avatars.imageUrls && avatars.imageUrls.slice(0, 3).map((url, index) => (
        <Avatar key={index} alt={`Avatar ${index + 1}`} src={url} sx={{ width: 30, height: 30 }} />
    ))}
    {avatars && avatars.imageUrls && avatars.imageUrls.length > 3 && (
        <Typography variant="caption" sx={{ color: '#ccc', fontSize: '12px' }}>
            +{avatars.imageUrls.length - 3}
        </Typography>
    )}
</AvatarGroup>


    </Grid>

    </div>
    
  );
}