// ** Third Party Components

import Chart from "react-apexcharts";
import { Circle } from "react-feather";


// ** Reactstrap Imports
import { Card, CardTitle, CardText, CardBody, Row, Col } from "reactstrap";
import { Icon } from '@iconify/react';


const  SimulationCard= () => {


  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    animations: {
        enabled: true,
        easing: 'easeinout', 
        speed: 800, 
        animateGradually: {
          enabled: true,
          delay: 150 
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
    
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    comparedResult: [2, -3, 8],
    labels: [  "Passed Threshold ","Unpassed Threshold"],
    stroke: { width: 1 },
    colors: ["#7254EA", "#268AFF"],
    grid: {
      padding: {
        right: -20,
        bottom: -8,
        left: -20,
      },
    },
   
    plotOptions: {
      pie: {
        startAngle: -10,
        donut: {
          expandOnClick: false, 
        size: '70%',
          labels: {
            show: false,
            name: {
              offsetY: 15,
            },
            value: {
              offsetY: -15,
              formatter(val) {
                return `${parseInt(val)} %`;
              },
            },
            // total: {
            //   show: true,
            //   offsetY: 15,
            //   label: "Guide",
            //   formatter() {
            //     return "20%";
            //   },
            // },
          },
          
        },
      },
    },
    
    // responsive: [
    //   {
    //     breakpoint: 1325,
    //     options: {
    //       chart: {
    //         height: 120,
    //       },
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     options: {
    //       chart: {
    //         height: 120,
    //       },
    //     },
    //   },
    //   {
    //     breakpoint: 1065,
    //     options: {
    //       chart: {
    //         height: 100,
    //       },
    //     },
    //   },
    //   {
    //     breakpoint: 992,
    //     options: {
    //       chart: {
    //         height: 120,
    //       },
    //     },
    //   },
    // ],
  };

  return (
    <Card style={{ boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)',marginTop : '4%' }}>
      <CardBody>
        <Row className='pb-40'>
          <Col xs='12' sm='12' md='12'>
            <CardTitle className="mb-1 text-muted" style={{ fontSize: "22px", fontWeight: "600" }}>Simulation</CardTitle>
            <CardText className="text-muted font-small-2"></CardText>
          </Col>
        </Row>
        <Row>
          <Col sm='12' md='7' className='mb-2'>
            <Chart
              options={options}
              series={[70, 30]}
              type="donut"
              height={200}
            />
          </Col>
          <Col xs='12' sm='12' md='5' className='mb-0 mt-5'>
            <div className='d-flex justify-content-between mb-1 mt-1'>
              <div className='d-flex align-items-center mb-3'>
                <Icon icon="clarity:circle-solid" color="#7254EA" width="23" />
                <span style={{ textShadow: "0px 0px 1px rgba(0,0,0,0.5)", marginLeft: "10px" ,width:"100%"}}>Passed Threshold</span>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex align-items-center'>
                <Icon icon="clarity:circle-solid" color="#268AFF" width="23" />
                <span style={{ textShadow: "0px 0px 1px rgba(0,0,0,0.5)", marginLeft: "10px" , width:"100%"}}>Unpassed Threshold</span>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SimulationCard;