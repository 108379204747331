import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactSelect from 'react-select'

import { Checkbox, FormControlLabel, Grid, TextField ,Select, MenuItem} from '@mui/material';

import './AddClient.css'
import axios from 'axios';
import toast from 'react-hot-toast';





export default function AddClient() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [startDate, setStartDate] = React.useState(new Date().toISOString().split('T')[0]); // Today's date
  const [endDate, setEndDate] = React.useState('');


const [formData, setFormData] = React.useState({
  name: '',
  email: '',
  plan: 'Simple',
  period: '1',
  priceId : ''
});

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({
      ...formData,
      [name]: value
  });

};

const handlePeriodChange = (selectedOption) => {
  const months = parseInt(selectedOption);
  // console.log("months",months)
  if (!isNaN(months)) {
    const today = new Date();
    const futureDate = new Date(today.setMonth(today.getMonth() + months));
    console.log(futureDate)
    setFormData({
      ...formData,
      period: selectedOption,
      endDate: futureDate.toISOString().split('T')[0]
    });
    setEndDate(futureDate.toISOString().split('T')[0]); // Update endDate state also
  } else {
    setFormData({
      ...formData,
      period: '',
      endDate: ''
    });
    setEndDate(''); // Update endDate state
  }
};


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 const LoggedInAs = 'Team Manager' || '' || 'Team Member';

 
//all products   pricing
const allProducts = {
 
 1 : [
  {
    planName : "Simple" , priceId : "price_1OlRPTSGW1EyGI68j0Q8wSOS"
  },
  {
    planName : "Moderate" , priceId : "price_1OlRTxSGW1EyGI6845fez4k6"
  },
  {
    planName : "Advance" , priceId : "price_1OlRVLSGW1EyGI68yuqpDxWm"
  },
],
  
 6 : [ 
  {
  planName : "Simple" , priceId : "price_1OlWMYSGW1EyGI68LfTzCJr5"
},
{
  planName : "Moderate" , priceId : "price_1OlWNJSGW1EyGI68N0S5gyL1"
},
{
  planName : "Advance" , priceId : "price_1OlWOWSGW1EyGI68DTzolkQa"
},],

12 : [ {
  planName : "Simple" , priceId : "price_1OlWP9SGW1EyGI68qop9JBMT"
},
{
  planName : "Moderate" , priceId : "price_1OlWQ7SGW1EyGI68lVQHTFDP"
},
{
  planName : "Advance" , priceId : "price_1OlWQfSGW1EyGI68Bkd73ybk"
},]
}




const handlePlanChange = (event) => {
  const selectedPlan = event.target.value;
  const selectedPeriodProducts = allProducts[formData.period];
  const selectedProduct = selectedPeriodProducts.find(product => product.planName === selectedPlan);

  setFormData((prevFormData) => ({
    ...prevFormData,
    plan: selectedPlan,
    priceId: selectedProduct ? selectedProduct.priceId : ""
  }));
};

  const handleAddClient = async (e)=>{
  e.preventDefault()
    try{

      setOpen(false);
      // toast.success("Mail Sent Successfully")
  //creates new customer inside stripe
  const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/admin/add-client`,{
    formData
  })
   // Reset formData state to empty values
   setFormData({
    name: '',
    email: '',
    plan: '',
    period: '1',
    priceId: ''
});
toast.success(response.data.message)

    }catch(error)
    {
  console.error(error)
  setOpen(false);
  toast.error(error.response.data.message)
    } 
  }

  return (
    <React.Fragment>
      <Button className='detail-button'
            sx={{border: '1px solid #50A4DB ', backgroundColor: '#50A4DB', color: '#50A4DB ', textTransform: 'none', textDecoration: 'none', padding: '10px', height: '90%',color : 'white', fontSize: '16px', marginRight: '10%'}}
            variant="contained" 
            endIcon={<AddIcon />}
            onClick={handleClickOpen}
            disabled={LoggedInAs === ''}
          >
            Add Client
          </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        PaperProps={{
          sx: {
            
            maxWidth: 'md', // You can use 'sm', 'md', 'lg', 'xl', or a specific pixel value
            position: 'relative',
            // overflowY: 'scroll',
            
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              
                <Grid item xs={12} md={12} >                  
                      <Button sx={{textDecoration:'none', color: '#000'}} autoFocus onClick={handleClose}>
                          <ArrowBackIcon sx={{fontSize: '30px'}}/>
                      </Button>                  
                </Grid>                
           
                <Grid item container direction="row" spacing={4} sx={{marginTop: '1%'}} >
                    
                    <Grid item xs={12} md={6} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Subscription holder name</p>
                        <TextField
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                    inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Subscription holder E-mail Id</p>
                        <TextField
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                    inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ color: '#000', fontSize: { md: '20px', xs: '14px' }, fontWeight: '500' }}>
      <p>Time Period for Subscription</p>
      <ReactSelect
      options={[  
        { value: 1, label: '1 Month' },
        { value: 6, label: '6 Months' },
        { value: 12, label: '1 Year' }
      ]}
      fullWidth
      onChange={selectedOption => handlePeriodChange(selectedOption)}
     
styles={{
  control: (provided) => ({
    ...provided,
    fontSize: '18px', // Set font size for the input field
    // borderWidth: '2px', // Increase border size
    padding: '4px 0px 6px 0px' // Add more padding
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '18px' // Set font size for the selected value
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '18px' // Set font size for the options in the dropdown
  })
}}
theme={(theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    neutral10: 'grey',
    primary: '#50a4db',
  },
})}
    />
      
    </Grid>
    
                    <Grid item xs={12} md={3} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >Start Date</p>
                        <TextField
                    id="outlined-basic"
                    type="date"
                    sx={{ width: '100%' }} // Set TextField width to 100%
                      inputProps={{
                          style: {
                              width: '100%', // Set input width to 100%
                              height: 'auto', // Set input height to auto
                              fontSize: '15px' // Set input font size
                          }
                      }}
                    // fullWidth
                    variant="outlined"
                    value={startDate}
                    readOnly
                />                 
                    </Grid>

                    <Grid item xs={12} md={3} sx={{color: '#000', fontSize: {md: '20px', xs: '14px'}, fontWeight: '500'}}>
                        <p >End Date</p>
                        <TextField
                    id="outlined-basic"
                    type="date"
                    sx={{ width: '100%', height: 'auto' }} // Change height to 'auto' or specific value
                    inputProps={{ style: { fontSize: '16px', height: '16px' } }} // Adjust height here
                    fullWidth
                    variant="outlined"
                    value={endDate}
                    readOnly
                />
                    </Grid>

                    
                </Grid>

                <Grid item container direction="row" sx={{ marginTop: '3%' }}>
  <Grid item xs={5} md={2} sx={{ color: '#000', fontSize: { md: '20px', xs: '14px' }, fontWeight: '500' }}>
    <p>Pricing</p>
  </Grid>

  <Grid item xs={12} md={4}>
    <Select
      value={formData?.plan}
      onChange={handlePlanChange}
      fullWidth
      style={{ width: '200px', marginRight: '10%' }}
    >
      {allProducts && allProducts[formData.period]?.map((product) => (
        <MenuItem key={product.planName} value={product.planName}>
          {product.planName}
        </MenuItem>
      ))}
    </Select>
  </Grid>
</Grid>

              
                
               
                <Grid item container direction="row">
                {/* <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid> */}
                
                {/* <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid>
                <Grid item xs={12} md={12} sx={{ marginTop: '2%', paddingLeft: '8%' }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} />}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '16px' }}>Lorem ipsum dolor sit amet consectetur</span>}
                        labelPlacement="end"
                        
                    />
                </Grid> */}

                </Grid>
                
                       
                          
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
          <Button className='button_size' variant='contained'  autoFocus sx={{
            width: '20%',
            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '600',
            textTransform: 'none',     
            marginRight: '5%'
            
          }}
          onClick = {(e)=>handleAddClient(e)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
