import { Box, Button, Grid, Typography } from "@mui/material";
import imgSrc from '../../Images/loginImage.png'
import InputWithIcon from "../../components/InputField/InputField";




import imgSrc2 from '../../Images/LoginPoly.png';
import imgSrc3 from '../../Images/LoginPoly3.png';
import imgSrc4 from '../../Images/LoginPoly4.png';
import imgSrc5 from '../../Images/LoginPoly2.png'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { NavLink } from "react-router-dom";
import './AdminForgotPassword.css'




function AdminForgotPassword(){  


    return(
        
        <Box >
            
            <Grid container direction="row" spacing={2}>

                <Grid item xs={4} md={4}>
                    <div style={{position: 'relative', top : '25px',left: '8%'}}>                    
                        <img src="/assets/image4.jpg" alt="" />                    
                    </div>
                </Grid>

                <Grid item xs={4} md={4}>
                    <div>
                        <img style={{width: '60%'}} src={imgSrc3} alt="" />
                    </div>            
                </Grid>  

                <Grid item xs={4} md={4} container alignItems="end" justifyContent="end">
                    <img style={{width: '40%'}} src={imgSrc4} alt="" />
                </Grid>   

            </Grid>

            <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center" sx={{position: 'relative', bottom: {md: '100px'}}}>

                <Grid item xs={12} md={6} justifyContent="center" container alignItems="center">
                    <img  className="hide-on-sm"  style={{height: '500px', width: '500px'}} src={imgSrc} alt="" />
                </Grid>

                <Grid item container xs={12} md={6} justifyContent="center" textAlign="center">              
                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{marginTop: {xs: '5%'},color: '#000',fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '700',}}>
                        <p> Forgot Password </p>
                    </Grid>
                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center">
                        <Typography sx={{
                            color: '#000',
                            fontSize: '20px',
                            fontWeight: '500',
                            paddingLeft: '15%',
                            paddingRight: '15%',
                            marginTop: '2%',
                            lineHeight: 'normal'
                        }}>
                            Please enter thr E-mail address you’d your reset password information to be sent
                        </Typography>
                    </Grid>
                    <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%'}}>
                        <InputWithIcon label='Email Id' icon={<MailOutlineIcon sx={{fontSize: '30px'}}/>}/>
                    </Grid>
                    <Grid item container xs={8} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%'}}>
                        <Button className="request-link-size" sx={{ fontSize: '30px', fontWeight: '500', borderRadius: '24px', textTransform: 'none', backgroundColor: '#50A4DB ', padding: '10px 0px 10px 0px', width: {md: '60%', xs: '90%'},}} variant="contained">Request Reset Link</Button>
                    </Grid>
                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center" sx={{marginTop: '3%',fontSize:{md: '24px', xs: '18px'}, fontWeight: '400',  textDecoration: 'underline', color: '#50A4DB '}}>
                    
                        <a style={{color: '#50A4DB '}} href="/#/login">Back to Login</a>
                    
                    </Grid> 
                </Grid>

            </Grid>

            <Grid container direction="row">

                <Grid item xs={6} md={4}>
                    <img style={{width: '30%'}} src={imgSrc2} alt="" />

                </Grid>

                <Grid item xs={6} md={4} container justifyContent="end">
                    <img style={{width: '60%'}} src={imgSrc5} alt="" />
                    
                </Grid>

            </Grid>                
            
        </Box>       
          
    );
}

export default AdminForgotPassword;