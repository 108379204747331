
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import Select from 'react-select';

const ApexChartComponent = ({graphData}) => {
  const bestModelNameObject = graphData.find(item => item.hasOwnProperty("Best Model Name"));
  const bestModelName = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;
  const bestModel = graphData.find(obj => obj[bestModelName]);

  const generateOptions = () => {
    const options = [];
    for (let i = 0; i <= 10; i++) {
      options.push({
        value: `${i * 10}-${(i + 1) * 10}`,
        label: `${i * 10}-${(i + 1) * 10}`,
      });
    }
    return options;
  };

    
  
  const initialOption = bestModel[bestModelName]?.X_test[0];
  const selectOptions = generateOptions();

  const initialSeries = [
    {
      name: 'Website Blog',
      type: 'column',
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    },
    {
      name: 'Social Media',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }
  ];
  
  const [series, setSeries] = useState(initialSeries);
  const [xAxis, setXAxis] = useState(null);
  const [yAxisOptions, setYAxisOptions] = useState([]);
  const [yAxis, setYAxis] = useState({ value: '0-10', label: '0-10' }); // Set default y-axis range
  const [chartType, setChartType] = useState(null);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2, // Specify the width of the lines
    },
    xaxis: {
      categories: initialOption ? Object.keys(initialOption) : [],
      title: {
        text: '',
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      title: {
        text: '$ (thousands)',
      },
    },
    fill: {
      opacity: 1,
    },
  });
  




  
  useEffect(() => {
    const defaultXAxis = '0-10';
    if (xAxis) {
      const selectedOption = xAxis?.value || defaultXAxis;
      if(selectedOption){
      const selectedData = bestModel[bestModelName].X_test.map(item => item[selectedOption]);
      
      const minData = Math.min(...selectedData);
      const maxData = Math.max(...selectedData);
      
      const newYAxisOptions = Array.from({ length: Math.ceil(maxData / 10) }, (_, i) => ({
        value: `${i * 10}-${(i + 1) * 10}`,
        label: `${i * 10}-${(i + 1) * 10}`,
      }));

      setYAxisOptions(newYAxisOptions);
      // Reset yAxis to default when xAxis changes
      setYAxis({ value: '0-10', label: '0-10' });
    }
    }
  }, [xAxis]);
  useEffect(() => {
    if (xAxis) {
      const selectedOption = xAxis.value;
      const selectedData = bestModel[bestModelName].X_test.map(item => item[selectedOption]);
  
      const [minRange, maxRange] = yAxis.value.split('-').map(Number);
  
      const updatedSeries = [
        {
          name:   `In Bar`,
          type: 'column',
          data: selectedData,
        },
        {
          name:  `In line `,
          type: 'line',
          data: selectedData,
        }
      ];
  
      setSeries(updatedSeries);
  
      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          title: {
            text: xAxis.label,
          },
        },
        yaxis: {
          min: minRange,
          max: maxRange,
          title: {
            text: yAxis.label,
          },
        },
      }));
    }
  }, [xAxis, yAxis, chartType]);
  
  return (
    <div>

<div style={{ display: 'flex' }}>
        <div style={{ width: '150px' }}>
          <Select
            placeholder="X-Axis"
            options={Object.keys(initialOption).map(key => ({ value: key, label: key }))}
            isClearable
            onChange={(selectedOption) => setXAxis(selectedOption)}
          />
        </div>
        <div style={{ width: '150px', marginLeft: '2%' }}>
          <Select
            placeholder="Y-Axis"
            options={selectOptions}
            isClearable
            value={yAxis}
            onChange={(selectedOption) => setYAxis(selectedOption)}
          />
        </div>
        <div style={{ width: '150px', marginLeft: '2%' }}>
          <Select
            placeholder="Type"
            options={[
              { label: "Actual", value: "Actual" },
              { label: "Predicted", value: "Predicted" },
            ]}
            isClearable
            onChange={(selectedOption) => setChartType(selectedOption)}
          />
        </div>
  
    </div>
    <div id="chart">
      
      <Chart options={options} series={series}  type="line" height={350} />
    </div>
    </div>
  );
};

export default ApexChartComponent;

// import React from 'react';
// import Chart from 'react-apexcharts';

// const CustomerCard1 = () => {
//   const options = {
//     chart: {
//       height: 350, 
//       type: 'line',
//     },
//     stroke: {
//       width: [0, 4]
//     },
//     title: {
//       text: 'Traffic Sources'
//     },
//     dataLabels: {
//       enabled: true,
//       enabledOnSeries: [1]
//     },
//     labels: [
//       '01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', 
//       '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'
//     ],
//     xaxis: {
//       type: 'datetime'
//     },
//     yaxis: [{
//       title: {
//         text: 'Website Blog',
//       },
//     }, {
//       opposite: true,
//       title: {
//         text: 'Social Media'
//       }
//     }]
//   };

//   const series = [
//     {
//       name: 'Website Blog',
//       type: 'column',
//       data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
//     },
//     {
//       name: 'Social Media',
//       type: 'line',
//       data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
//     }
//   ];

//   return (
//     <div id="chart">
//       <Chart options={options} series={series} type="line" height={350} />
//     </div>
//   );
// };

// export default CustomerCard1;