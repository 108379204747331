
import axios from "axios";
import {
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  loginRequest,
  loginSuccess,
  loginFailure,
  loadUserRequest,
  loadUserSuccess,
  loadUserFailure,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFailure,
  updateToken,
} from "../Reducers/userReducers";
import toast from "react-hot-toast";





  //gets useer profile information
  //that info fetch only when user is logged in and have a token....

  export const loadUser = () => async (dispatch) => {

      try {

        dispatch(loadUserRequest());

      // if(!localStorage.getItem('access-token')){
        const accessToken = JSON.parse(localStorage.getItem('access-token'));
         // Simulate team-login scenario by checking the role
    const user = JSON.parse(localStorage.getItem('users'));
    if(user?.designation === 'teamMember' || user?.designation === 'teamManager'){
// For team-login scenario, directly dispatch loadUserSuccess
dispatch(loadUserSuccess(user));
return; // Exit early

    }
        const { data } = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/user/profile`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        
        });
       
          localStorage.setItem("users", JSON.stringify(data?.user));
     
          // }
// else 
// {
  // localStorage.setItem("access-token", null);
  // console.log("datadatadata",data)
  // localStorage.setItem("users", JSON.stringify(data?.user));
// }
        
        // dispatch(loadUserSuccess(data?.user));
    // }
    
    // else {
      // if (error.response && error.response.status === 401) {
      //   localStorage.removeItem('access-token');
      //   localStorage.removeItem('users');
      //   window.location.href = '/login'; 
      // }
      
      // console.log("Inside else try block",JSON.parse(localStorage.getItem('users')))
      // dispatch(loadUserSuccess(JSON.parse(localStorage.getItem("users")))).then((response)=>{

      // })

      // }
  
    if(data.user.role === "admin" && data.user.plan === null){
      dispatch(loadUserSuccess(data.user))
      } else if(data.user.plan === null ){
        localStorage.clear()
        window.location.reload()
      }else if(data.user.designation === "teamMember" || data.user.designation === "teamManager"){
        dispatch(loadUserSuccess(data.user))
      }
      else{
        dispatch(loadUserSuccess(data.user))
      
      }
      }
      
    
      catch (error) {
        
    
          // Handle other errors
        console.log("inside catch block")
        console.log(error)
            dispatch(loadUserFailure(error.response?.data?.message));
       
          
      
      }
    };
    

//Logout user


  export const logoutUser = () => async (dispatch) => {
    
    try {
      dispatch(logoutUserRequest())
  
      const role = JSON.parse(localStorage.getItem('users')).role;

      localStorage.clear();
      window.location.href = '/#/login';
      dispatch(logoutUserSuccess());
      if (role === 'admin') {
        window.location.href = '/#/admin-login';
      }
     
    } catch (error) {
      console.log("ook")
      dispatch(logoutUserFailure(error?.response?.data?.message));
    }
  };