import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { IoMdLogOut } from 'react-icons/io';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function ActionAreaCard2() {
  const [recentProjects, setRecentProjects] = useState([]);

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('access-token')); // Replace 'yourTokenKey' with the actual key you use to store the token

    // Set the token in the headers
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    // Fetch recent projects when the component mounts
    axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/recentProjects`,{headers})
      .then(response => {
        setRecentProjects(response.data);
      })
      .catch(error => {
        console.error('Error fetching recent projects:', error);
      });
  }, []);

  return (
    <Card sx={{ width: '100%', height: '270px', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)' }}>
      <CardActionArea>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div style={{
                color: '#000',
                fontSize: '24px',
                fontWeight: '500',
                // textAlign: 'center',
            
                // marginTop: '6px'
              }}>
                Recent Projects
              </div>
            </Grid>
            {recentProjects.length > 0 ?  recentProjects.map(project => (
              <Grid key={project._id} item xs={12} md={12}>
                <ProjectDetails project={project} />
              </Grid>
              
            ))
          :
          <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
          <h3 style={{ marginRight: '3%' }}>No Projects present now!</h3>
      </Grid>
      
          }
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function ProjectDetails({ project }) {
  const projectDetailLink = `/project-det/${project._id}`; // Assuming your project detail page path is '/project/:projectId'
  return (
    <Grid item container sx={{
      width: '100%', border: '1px solid #FFF', borderRadius: '8px',
      background: 'linear-gradient(180deg, #50A4DB  0%, rgba(80, 164, 219, 0.39) 99.99%, rgba(80, 164, 219, 0.00) 100%)',
      padding: '10px 0px 10px 5px', direction: 'row'
    }}>
      <Grid item container xs={4} md={4} sx={{ fontSize: '12px', fontWeight: '400', color: '#FFF' }}>
       <p style={{fontSize : '15px'}}> {project?.projectName} </p>
      </Grid>
      <Grid item container xs={4} md={4} sx={{ fontSize: '10px', fontWeight: '400', color: '#FFF' }}>
      <p style={{fontSize : '15px'}}>   Status: {project?.status} </p>
      </Grid>
      <Grid item container xs={4} md={4} justifyContent="center" alignContent="center" alignItems="center" sx={{ display: 'flex' }}>
  <Link to={projectDetailLink} style={{ textDecoration: 'none' }}>
    <IoMdLogOut size={30} style={{ color: '#FFF', cursor: 'pointer' }} />
  </Link>
</Grid>

    </Grid>
  );
}
