import HomeCard from "./HomeCard";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText,  Grid, Link } from "@mui/material";
import GridOnIcon from '@mui/icons-material/GridOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewIcon from '@mui/icons-material/GridView';

import * as React from 'react';





function TeManagerHome() {

 
    
    return(
        <div>
          <Grid sx={{border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', marginTop: {
                    xs: '20%',
                    sm: '12%',
                    md: '10%',
                    lg: '5%'
                },
                marginBottom: '3%',
                
                paddingTop: '2%'}}>
            <Grid container direction="row" spacing={2} justifyContent={{ xs: 'center', md: 'end' }}>
                <Grid item xs={12} md={12} justifyContent={{ xs: 'center', md: 'end' }} textAlign={{ xs: 'center', md: 'end' }} sx={{paddingBottom: '2%'}}>
                    {/* <div style={{marginTop: '10%', display: 'flex', flexDirection: 'row', gap: '25px', position: 'relative', justifyContent:'end', left: "90px"}}> */}
                   
                        <Button sx={{textTransform: 'none', border: '1px solid #FFF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px',  color: '#000', marginRight: '2%', fontSize: '10px'}} endIcon={<KeyboardArrowDownIcon />}>Filters</Button>
                        <Link href="/#/home-dashboard" style={{textTransform: 'none', textDecoration: 'none', border: '1px solid #FFF', boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px', color: '#AFAFAF', padding: '7px 10px 7px 0px', marginRight: '3%', fontSize: '14px'}}  > 
                    <GridViewIcon sx={{border: '1px solid #AFAFAF',backgroundColor: '#AFAFAF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '50%', width: '30px', height: '28px', color: '#FFF', padding: '7px',marginRight: "20px", marginLeft: '5px' }}/> Home</Link>
        
                </Grid>
            </Grid>
            </Grid>

            <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)', marginTop: '2%'}}>
                <Grid container direction="row" spacing={2} sx={{marginTop: '1%'}}>
                  <Grid item xs={12} sm={6} md={4} container justifyContent="center">
                  {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '5%' }}> */}
                        <HomeCard />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} container justifyContent="center">
                        <HomeCard />
                  </Grid>
               
                
                    
                
                 
                  </Grid>

            </div>           
           
       
        </div>
        
    )
}

export default TeManagerHome;