// ChartComponent.js
import { Grid } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';


class DoughnutCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55],
      options: {
        chart: {
          
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
          },
        },
        
        responsive: [
          {
            
            options: {
              chart: {
                width: 100,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div id="chart" style={{border: '1px solid #D9D9D9', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)', width: '100%'}}>
        <Grid item container xs={12} md={10}  sx={{color: '#000', fontSize: '24px', fontWeight: '600', paddingLeft: '3%'}}>
            <p>Total No. of Visitors</p>
        </Grid>
        <Grid item container xs={12} md={6}  sx={{color: '#000', fontSize: '24px', fontWeight: '600', paddingLeft: '3%'}}>
            <p>14534</p>
        </Grid>
        <Grid item container xs={12} md={12}>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          
        />
        </Grid>
      </div>
    );
  }
}

export default DoughnutCard;
