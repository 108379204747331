import React, { useEffect, useState } from 'react';
import SimpleCard from './cards/SimpleCard';
import { CardTitle, Col, Row } from 'reactstrap';
import SimulationCard from './cards/Simulation';
import CampaignCard from './cards/CampaignCard';
import CustomerCard from './cards/CustomerCard';
import ForthCard from './cards/ForthCard';
import { Icon } from '@iconify/react';
import "../../index.css"
import { useParams } from 'react-router-dom';
import { getUploadedFiles, getUploadedFiles2, savedDatabaseTemplate } from '../../../../Reducers/CanvasReducer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CustomerCard1 from './cards/CustomerCard1';
import ForthCard1 from './cards/ForthCard1';
import CustomerCard2 from './cards/CustomerCard2';
import ForthCard2 from './cards/ForthCard2';

function Dashboard({ toggleLeftSidebar }) {
  const [templateDataLength, setTemplateDataLength] = useState(0);
  const [connectorLinesLength, setConnectorLinesLength] = useState(0);
  const dispatch = useDispatch();
  const {fileInfo,files,setModelGraphData} =useSelector(state=>state.canvas)
  const params = useParams();
  
  useEffect(() => {
    if (files && params.id) {
      const fileWithMatchingProjectId = files.find(file => file.metadata && file.metadata.projectId === params.id);
  
      if (fileWithMatchingProjectId) {
        const filename = fileWithMatchingProjectId.filename;
        dispatch(getUploadedFiles(filename));
      }
    }
  }, [files,params.id]);

  useEffect(()=>{
    dispatch(getUploadedFiles2())
  },[])
  const firstFileInfo =fileInfo && fileInfo.length > 0 ? fileInfo[0] : null;

  useEffect(() => {
    const getSavedCanvas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-template-widget/${params.id}`);
      
        const getTemplateData = response.data?.canvasTemplates?.templateData
        // Extracting 'templateData' objects from each item and dispatching the action
        const templateDataArray = getTemplateData.map(item => item.templateData);
        setTemplateDataLength(templateDataArray.length);
        dispatch(savedDatabaseTemplate(templateDataArray));
      } catch (error) {
        console.error(error);
      }
    };

    getSavedCanvas(); // Call the function to fetch templates when component mounts
  }, [dispatch, params.id]);
  useEffect(() => {
    const getConnectorLines = async () => {
      try {
        if (!params.id) return; // Ensure params.id is available

        // Make API call to fetch connector lines data
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/get-connector-lines/${params.id}`);
        const connectorLines = response.data?.connectorLines;
        setConnectorLinesLength(connectorLines.length)
        console.log('Fetched connector lines:', connectorLines);
      } catch (error) {
        console.error('Error fetching connector lines:', error);
      }
    };

    getConnectorLines();
  }, [params.id]);
  const dashboardStyle = {
    
    height: '100vh',
    marginBottom:"30px",
    // backgroundColor: '#f5f5f5', 
    boxSizing: 'border-box',
    width:"100%"
    

  };

  const handleToggleSidebar = () => {
    // toggleLeftSidebar();
  };
  return (
    <div  style={dashboardStyle}>
      <Row  >
      <Col className="d-flex align-items-center">
  <span onClick={handleToggleSidebar} className="toggle-sidebar-icon me-2" style={{cursor: "pointer"}}>
  <Icon icon="ant-design:bars-outlined"  width="24"/>
  </span>
  <CardTitle className="mb-3 mt-3 text-muted" style={{fontSize: "24px", fontWeight: "600"}}>
    Dashboard
  </CardTitle>
</Col>

      </Row>
      <Row >
      <Col lg="4" sm="6" className="mb-3">
  <SimpleCard 
    title="No. of variables" 
    number={firstFileInfo ? Object.keys(firstFileInfo).length : 0} 
  />
</Col>

        <Col lg="4" sm="6" className=" mb-3">
          <SimpleCard title="No. of Widgets" number={templateDataLength || 0} />
        </Col>
        <Col lg="4" sm="12" className=" mb-3">
          <SimpleCard title="No. of connections" number={connectorLinesLength || 0} />
        </Col>
      </Row>
     <Row style={{marginTop : '3%'}}>
       <Col lg="7" xs="12"  className="mb-3">
        <CampaignCard graphData ={setModelGraphData} />
      </Col> 
      <Col lg="5" xs="12" className="mb-3" style={{marginTop : '3%'}}>
        <SimulationCard />
      </Col>
    </Row>
   <Row >
      <Col lg="7" xs="12" className=" mb-3">
        <CustomerCard graphData ={setModelGraphData}/>
      </Col>
      <Col lg="5" xs="12">
        <ForthCard/>
      </Col>
    </Row>
   <Row >
      <Col lg="7" xs="12" className=" mb-3">
        <CustomerCard1 graphData ={setModelGraphData} /> 
      </Col>
      <Col lg="5" xs="12">
        <ForthCard1 />
      </Col>
    </Row>
   <Row >
      <Col lg="7" xs="12" className=" mb-3">
        <CustomerCard2 aucGraphData ={setModelGraphData}/>
      </Col>
      <Col lg="5" xs="12">
        <ForthCard2 />
      </Col>
    </Row>
    </div>
  );
}

export default Dashboard;
