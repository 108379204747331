import * as React from 'react';
import { styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Grid,Link, List} from '@mui/material';
import { MdOutlineSubscriptions } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { GoHome } from "react-icons/go";
import { useLocation } from 'react-router-dom';
import { RiTeamLine } from "react-icons/ri";
import { logoutUser } from '../../Actions/authAction';
import { useDispatch } from 'react-redux';



const drawerWidth = 217;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    ...(open && {
      marginLeft: drawerWidth,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    width: `calc(100% px)`,
    // marginLeft: `${drawerWidth}px`,
  }),
}));

export default function AdminSidebar({Component}) {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(window.innerWidth >= 960); // Initially open for screens wider than or equal to 960px

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    setOpen(window.innerWidth >= 960);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const [isDesktopView, setIsDesktopView] = useState(true);

  const location = useLocation();
const dispatch = useDispatch()
  const LoggedInAs = 'Team Manager' || '' || 'Team Member';
  const handleLogout = () => {
   
    dispatch(logoutUser());
};
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: '#FFF', boxShadow: 'none', borderBottom: '1px solid #000', height: 'auto',  paddingBottom: '10px' }}>
        <Toolbar>
        <Grid container direction="row">
            <Grid item container xs={2} md={1}>
            <IconButton
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, color: 'black' }}
            >
                {open ?  <img src="/assets/image4.jpg" alt="" height= "100%" width= "80%" /> :  <img src="/assets/image4.jpg" alt="" height= "100%" width= "80%" />}
            </IconButton>
            </Grid>
           
        </Grid>
        
        </Toolbar>
      </AppBar>
      
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={2} >
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)',
                top: '10%',
                borderTop: 'none'
              },
              
              

            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <List sx={{marginTop: '15%'}}>
               
                
                <ListItem>
                  <Grid container spacing={1}>
                  <Grid item xs={11} md={11}>
                      <LinkButton
                        pathname="/admin-dashboard"
                        label="Dashboard"
                        active={location.pathname === '/admin-dashboard'}
                        
                        disabled={LoggedInAs === ''}
                      />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <LinkButton
                        pathname="/admin-client"
                        label="Clients"
                        active={location.pathname === '/admin-client' }
                        
                        disabled={LoggedInAs === ''}
                      />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <LinkButton
                        pathname="/admin-variable"
                        label="Variable"
                        active={
                          location.pathname === '/admin-variable' 
                         
                        }
                        
                        disabled={LoggedInAs === ''}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={1}>
                    <Grid item xs={11} md={11}>
                      <LinkButton
                        pathname="/admin-subscription"
                        label="Subscription"
                        active={location.pathname === '/admin-subscription' }
                        
                      />
                    </Grid>
                    <Grid item xs={11} md={11} justifyContent="center" container alignItems="center"
                     onClick={() => {
                        
                      handleLogout()
                 
                           }}
                    >
                  
                    <LinkButton
                        pathname="/"
                        label="Logout"
                        
                        // icon={<IoMdLogOut  size={25}/>}
                        
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
          </Drawer>
        </Grid>
        <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
          <Main open={open}>
            <div>
              {Component}           
            </div>
          </Main>
        </Grid>
      </Grid>
    </Box>
  );
}

function LinkButton({ pathname, label, active, icon, disabled }) {
  
  return (
    <ListItem
      disabled={disabled}
      sx={{
        backgroundColor: active ? 'rgba(80, 164, 219, 1)' : 'none',
        border: active ? '1px solid rgba(80, 164, 219, 1)' : 'none',
        
        // width: active ? '190px' : 'none',
        
        textAlign: 'left',
        position: 'relative',
        // left: active ? '20px' : '0'
        opacity: disabled ? 0.5 : 1, // Adjust the opacity when disabled
      }}
    > 
    
      {icon}
 
      <ListItemText>
        <Link href={`/#${pathname}`} sx={{
          textDecoration: 'none',    
          fontSize: '20px',
          color: active ? '#FFF' : 'rgb(0, 0, 0, 1)',
          position: 'relative',
          fontWeight: '500',
          pointerEvents: disabled ? 'none' : 'auto', 
          paddingLeft: '20px'
          // right: active ? '0px' : '20px'
        }}>
          {label}
        </Link>
      </ListItemText>
    </ListItem>
  );
}


