import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';

export default function ActionAreaCard({ chart, text1, topText, projectCount }) {
  
  return (
    <Card sx={{ width: '100%', height: '267px', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: 'transparent', // Remove hover background color
    },
    }}>
      <CardActionArea>
        <CardContent>
          <Grid container spacing={2}>
            {/* Column for Text and Num */}
            <Grid item xs={12} md={6}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{
                  color: '#000',
                  fontSize: '20px',
                  fontWeight: '500',
                  // marginTop: '10px'
                }}>
                  {text1}
                </Typography>

                <Typography sx={{
                  color: '#000',
                  fontSize: '48px',
                  fontWeight: '400'
                }}>
                  {projectCount}
                </Typography>
              </div>
            </Grid>

            {/* Column for Chart */}
            <Grid item xs={12} md={6} container justifyContent="center">
              <div>
                {chart}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
