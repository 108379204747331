import { Button, Card, CardContent, Grid, TextField } from '@mui/material';
import imgSrc from '../../Images/profilePic.jpg';
import imgSrc2 from '../../Images/visa.jpg';
import AddIcon from '@mui/icons-material/Add';
import './HomeProfile.css'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamProfile, userProfile } from '../../Reducers/userReducers';
import axios from 'axios';
import HomeCard from './HomeCard';
import { useNavigate } from 'react-router-dom';

function HomeProfile() {
    const data = JSON.parse(localStorage.getItem('user-profile'));
const dispatch = useDispatch()
const navigate = useNavigate()
 const {userData} = useSelector(state => state.user)

 const users = JSON.parse(localStorage.getItem('users'))
 const isTeamMember = users?.designation === 'teamMember' || users?.designation === 'teamManager';
    useEffect(()=>{

const users = JSON.parse(localStorage.getItem('users'))

if (users && (users?.designation === 'teamMember' || users?.designation === 'teamManager')) {
       dispatch(teamProfile())
    }else{
        dispatch(userProfile())
    }

    },[dispatch])


const [projects,setProjects] = useState([])


   
    // Fetch team projects when the component mounts
    const fetchTeamProjects = async () => {
        const accessToken = JSON.parse(localStorage.getItem('access-token'))

      try {

        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/projects`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
 
        setProjects(response.data?.projectData);
      } catch (error) {
        console.error('Error fetching team projects:', error);
      }
    };

useEffect(() => {
 if(isTeamMember){
   // Call the function to fetch team projects
    fetchTeamProjects();
}
  }, []); // Empty dependency array ensures the effect runs once on mount
  
    
  function calculateTimeDifference(timestamp) {
    const currentTimestamp = new Date();
    const editedTimestamp = new Date(timestamp);
  
    const timeDifferenceInMillis = currentTimestamp - editedTimestamp;
  
    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifferenceInMillis / 1000);
  
    // Calculate minutes, hours, days, etc.
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Less than a minute ago';
    }
  }

    

    const LoggedInAs = 'Team Manager' || '';
    const handleUpgrade = async () => {
        try {
    
          const response = await axios.post(
            `${process.env.REACT_APP_PUBLIC_BASE_URL}/billing-portal-link`,
            { customerId: users.billingId }
          );
         
          window.location.href = response?.data?.url
    
         await navigate('/#/home-page')
          // Handle the response here
        } catch (error) {
          console.error("AxiosError:", error);
          // Handle the error here
        }
      };
useEffect(()=>{

},[userData])
    return(
        <div>
            <Grid container direction="row" spacing={2} sx={{marginBottom: '2%', marginTop: {
                xs: '22%',
                sm: '12%',
                md: '7%',
                }}}>

                <Grid item xs={8} md={6}>
                    <h5 style={{color: '#000', fontSize: '32px', fontWeight: '400', position: 'relative'}}>Your Profile</h5>
                </Grid>
{isTeamMember
     ? (
        
        <Grid item xs={4} md={6} justifyContent="end" textAlign="end">
        <a href="/#/home-profile-edit"  style={{fontSize: '20px', color: '#000'}}>Edit</a>
    </Grid>
    ) : (

        <Grid item xs={4} md={6} justifyContent="end" textAlign="end">
        <a href='/#/home-profile-edit' style={{fontSize: '20px', color: '#000'}}>Edit</a>
    </Grid>
    )
 }
               

            </Grid> 

            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={3}>
                    <img style={{
                        width: '100%',
                        height: '100%',
                        backgroundSize: "auto",
                        // backgroundPosition: "center", // Center the background image
                        
                    }} src={userData?.image || userData?.image?.url} alt="" />                
                </Grid>
                <Grid item xs={12} md={4}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', paddingRight: '10px'}}>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >User Name</h3>
                        <TextField
                            id="outlined-helperText"
                            value = {userData?.userName || '' || userData?.memberName}
                        // defaultValue="Lorem ispum"
                            sx={{width: '100%', '& #outlined-helperText': {
                                fontSize: '20px',
                                // Add other styles as needed
                            },}}
                        />
                        </div>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF', marginTop: '1%'}} >E-mail</h3>
                        <TextField
                            id="outlined-helperText"
                            
                            value = {userData?.email || ''}
                            sx={{width: '100%', '& #outlined-helperText': {
                                fontSize: '20px',
                                // Add other styles as needed
                            },}}
                        />
                        </div>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF', marginTop: '1%'}} >Company Name</h3>
                        <TextField
                            id="outlined-helperText"
                            
                            value = {userData?.companyName || '' || userData?.company}
                            sx={{width: '100%', '& #outlined-helperText': {
                                fontSize: '20px',
                                // Add other styles as needed
                            },}}
                        />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={5}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div>
                        <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF'}} >Address</h3>
                        <TextField
  id="outlined-multiline-static"
  multiline
  rows={6}
  value={userData?.address || ''}
  sx={{
    width: '100%',
    height: 'auto', // Set height to auto
    '& #outlined-multiline-static': {
      fontSize: '20px',
      lineHeight: '2.2rem'
      // Add other styles as needed
    },
  }}
/>

                        </div>
                    
                        <div>
                            <h3 style={{fontSize: '20px', fontWeight: '400', color: '#AFAFAF', marginTop: '1%'}} >Country</h3>
                            <TextField
                                id="outlined-helperText"
                                
                                value = {userData?.country || ''}
                                sx={{width: '100%', '& #outlined-helperText': {
                                    fontSize: '20px',
                                    // Add other styles as needed
                                },}}
                            />
                        </div>                    
                    </div>
                </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
                <h4 style={{color: "#AFAFAF", fontSize: '20px', fontWeight: '400', marginTop: '3%'}}>
                    Company Description
                </h4>
            </Grid>

            <Grid item xs={12} md={12}>
                <p style={{color: '#000', fontSize: '18px', fontWeight: '500', lineHeight: 'normal', marginTop: '1%'}}>
                {userData?.description ? userData?.description : "..."}
                </p>
            </Grid>

{ 
isTeamMember ? 
    
    (
      
        <div style={{ marginTop:  "2%"}}>
             <Grid item xs={12} md={12}>
                <h4 style={{color: "#AFAFAF", fontSize: '20px', fontWeight: '400', marginTop: '3%'}}>
                   Projects
                </h4>
            </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '1%' }}>

{projects.map(project => {

return (
    <>

<Grid key={project._id} item xs={12} sm={6} md={4} container justifyContent="center" >
    <HomeCard projectId={project?._id} projectName={project.projectName} lastEdited={calculateTimeDifference(project.lastEdited)}/>
  </Grid>

  </>
);
})}
</Grid>

        </div>  
    
    )
    :
    (
        <>

          <Grid item xs={12} md={12}>
                <p style={{color: '#AFAFAF', fontSize: '18px', fontWeight: '400', marginTop: '2%'}}>Subscription Details</p>
            </Grid>

            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} md={2}>
                    <p style={{color: "#AFAFAF", fontSize: '16px'}}>Plan Name</p>
                    <p style={{fontSize: '18px', fontWeight: '500'}}>{data && data?.plan[0].toUpperCase() +  data.plan.slice(1)} </p>
                </Grid>
                <Grid item xs={4} md={2}>
                    <p style={{color: "#AFAFAF", fontSize: '16px'}}>Type</p>
                    <p style={{fontSize: '18px', fontWeight: '500'}}>{data?.role[0].toUpperCase() +  data.role.slice(1)}</p>
                </Grid>
                <Grid item xs={4} md={2}>
                    <p style={{color: "#AFAFAF", fontSize: '16px'}}>Next billing date</p>
                    <p style={{ fontSize: '18px', fontWeight: '500' }}>
  {data?.planEndDate && new Date(data.planEndDate).toLocaleDateString()}
</p>
                </Grid>
                <Grid item xs={4} md={2}>
                    <p style={{color: "#AFAFAF", fontSize: '16px'}}>Billing Period</p>
                    <p style={{fontSize: '18px', fontWeight: '500'}}>Monthly</p>
                </Grid>
                <Grid item xs={6} md={4} justifyContent="center" alignItems="center" container>
                    <Button className='upgrade_button' sx={{
                        // width: '193px',
                        // height: '46px',
                        borderRadius: '4px',
                        background: '#50A4DB',
                        color: '#FFF',
                        textTransform: 'none',
                        // top: '20px'
                        fontSize: '20px',
                        fontWeight: '600',
                        padding: '5px 35px 5px 35px'
                    }} variant='contained'
                    onClick = {handleUpgrade}
                    >Upgrade Plan</Button>
                </Grid>
            </Grid>
{/* 
            <Grid item xs={12} md={12}>
                <p style={{color: "#AFAFAF", fontSize: '18px', marginTop: '2%'}}>Payment Methods</p>
            </Grid>
            
            <Grid container direction="row" spacing={2} sx={{marginTop: '2%'}}>
                <Grid item xs={12} md={6}>
                    <Card sx={{width: '100%', height: 'auto',  borderRadius: '4px', background: 'linear-gradient(180deg, #50A4DB  0%, #38B27F 100%)', fontSize: {md: '20px', sm: '18px', xs: '12px'}, fontWeight: '400'}}>
                        <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
                            
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <p >Card Info</p>
                                </Grid>
                                <Grid item xs={6} md={6} container justifyContent="end" alignItems="end" sx={{paddingRight: '20px'}}>
                                    <p>Edit</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={4} md={6}>
                                    <p  >Card Number</p>
                                </Grid>
                                <Grid item xs={8} md={6} container justifyContent="center" alignItems="center">
                                    <p>xxxx xxxx xxxx 1234</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <p >Card Holder Name</p>
                                </Grid>
                                <Grid item xs={6} md={6} container>
                                    <p style={{paddingLeft:"55px"}}>Lorem</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={6} md={6}>
                                    <p >Expiry Date</p>
                                </Grid>
                                <Grid item xs={6} md={6} container>
                                    <p style={{paddingLeft:"55px"}}>04/24</p>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={6} md={6} container>
                                    <p>Card type</p>
                                </Grid>
                                <Grid item xs={3} md={3} container>
                                    <p style={{paddingLeft:"55px"}}>Visa</p>
                                </Grid>
                            
                                <Grid item xs={3} md={3} container>
                                <img src={imgSrc2} alt=''/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Card sx={{width: '100%', height: '100%', border: '1px dashed #50A4DB ', borderRadius: '4px', display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
                        <CardContent sx={{color: "#50A4DB ",alignItems: 'center', textAlign: 'center', }}>
                            <AddIcon/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid> */}
        </>
    )
}
          

        </div>
    )
}

export default HomeProfile;