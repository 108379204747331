import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';

export default function ActionAreaCard3({ image, text }) {
  return (
    <Grid item container justifyContent="center" alignItems="center">
    
      <Grid item xs={12} sm={12} md={12} lg={12}>
        
          <div style={{
            color: '#38B27F',
            // fontSize: '96px',
            fontWeight: '500',
            position: 'relative',
            // marginLeft: '70px',
          }}>
            <h5 style={{fontSize: '96px'}}>
              {text}
            </h5>
          </div>
      </Grid>
    

          <Card sx={{ width: '100%', backgroundColor: '#FFF', boxShadow: '2px 2px 8px 0px rgba(0, 0, 0, 0.25)' }}>
            <CardActionArea>
              <CardContent>
                {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}> */}
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CardMedia
                      component="img"
                      // width='261px'
                      // height='228px'
                      image={image}
                      alt=""
                      
                    ></CardMedia>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography sx={{
                      color: '#000',
                      fontSize: '32px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      // textAlign: 'left'
                      paddingLeft: '30px'
                    }}>
                      Title
                    </Typography>

                    <Typography variant="body2" sx={{
                      // width: '270px',
                      color: '#D9D9D9',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      // textAlign: 'left',
                      lineHeight: 'normal',
                      paddingLeft: '30px'
                    }}>
                      Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo
                    </Typography>
                    <Typography variant="body2" sx={{
                      // width: '270px',
                      color: '#D9D9D9',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      // textAlign: 'left',
                      lineHeight: 'normal',
                      paddingLeft: '30px'
                    }}>
                      Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo
                    </Typography>
                  
                </Grid>
                </Grid>

              </CardContent>
            </CardActionArea>
          </Card>
        
      
    </Grid>
  );
}
