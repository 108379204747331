import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Button } from '@mui/material';

ChartJS.register(...registerables);

// Utils for generating data (replace these with actual utility functions or implementations)
const Utils = {
  numbers: ({ count, min, max }) => Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1)) + min),
  namedColor: (index) => `hsl(${index * 30}, 100%, 50%)`,
  transparentize: (color, opacity) => {
    const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');
    return `${color}${alpha}`;
  },
  months: ({ count }) => Array.from({ length: count }, (_, i) => `Month ${i + 1}`),
  rand: (min, max) => Math.floor(Math.random() * (max - min + 1)) + min,
  CHART_COLORS: {
    blue: 'rgba(54, 162, 235, 1)', // Blue color
    yellow : '#ffe6aa'
  },
};

const initialData = {
  labels: Utils.months({ count: 7 }),
  datasets: [
    {
      label: 'Dataset 1',
      backgroundColor: Utils.transparentize(Utils.namedColor(0), 0.5),
      borderColor: Utils.namedColor(0),
      borderWidth: 1,
      data: Utils.numbers({ count: 7, min: -100, max: 100 }),
    },
    {
      label: 'Dataset 2',
      backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
      borderColor: Utils.CHART_COLORS.blue,
      borderWidth: 1,
      data: Utils.numbers({ count: 7, min: -100, max: 100 }),
    },
    {
      label: 'Dataset 3',
      backgroundColor: Utils.transparentize(Utils.CHART_COLORS.yellow, 0.5),
      borderColor: Utils.CHART_COLORS.yellow,
      borderWidth: 1,
      data: Utils.numbers({ count: 7, min: -100, max: 100 }),
    },
  ],
};

const ForthCard = () => {
  const [data, setData] = useState(initialData);

  const actions = [
    {
      name: 'Randomize',
      handler: () => {
        setData((prevData) => ({
          ...prevData,
          datasets: prevData.datasets.map((dataset) => ({
            ...dataset,
            data: Utils.numbers({ count: prevData.labels.length, min: -100, max: 100 }),
          })),
        }));
      },
    },
    {
      name: 'Add Dataset',
      handler: () => {
        setData((prevData) => {
          const newDatasetIndex = prevData.datasets.length;
          const newDataset = {
            label: `Dataset ${newDatasetIndex + 1}`,
            backgroundColor: Utils.transparentize(Utils.namedColor(newDatasetIndex), 0.5),
            borderColor: Utils.namedColor(newDatasetIndex),
            borderWidth: 1,
            data: Utils.numbers({ count: prevData.labels.length, min: -100, max: 100 }),
          };
          return {
            ...prevData,
            datasets: [...prevData.datasets, newDataset],
          };
        });
      },
    },
    {
      name: 'Add Data',
      handler: () => {
        setData((prevData) => {
          const newLabel = `Month ${prevData.labels.length + 1}`;
          return {
            ...prevData,
            labels: [...prevData.labels, newLabel],
            datasets: prevData.datasets.map((dataset) => ({
              ...dataset,
              data: [...dataset.data, Utils.rand(-100, 100)],
            })),
          };
        });
      },
    },
    {
      name: 'Remove Dataset',
      handler: () => {
        setData((prevData) => ({
          ...prevData,
          datasets: prevData.datasets.slice(0, -1),
        }));
      },
    },
    {
      name: 'Remove Data',
      handler: () => {
        setData((prevData) => ({
          ...prevData,
          labels: prevData.labels.slice(0, -1),
          datasets: prevData.datasets.map((dataset) => ({
            ...dataset,
            data: dataset.data.slice(0, -1),
          })),
        }));
      },
    },
  ];

  const config = {
    type: 'bar',
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          // text: 'Chart.js Bar Chart',
        },
      },
    },
  };

  return (
    <div>
      <Bar data={config.data} options={config.options} />
      <div>
        {actions.map((action, index) => (
          <Button style={{marginLeft : '2%',marginTop : '2%',}} variant="outlined" key={index} onClick={action.handler}>
            {action.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ForthCard;
