import Sidebar from "../../components/HomePage/Sidebar";
import PaymentCancel from "../../components/Subscription/PaymentCancel";

function PayCancelPage() {
    return(
        <div>
            <Sidebar Component={<PaymentCancel/>} />
        </div>
    )
}

export default PayCancelPage;