import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Button } from 'reactstrap';
import { styles ,template1,template2,template3} from './Templates';
import VariableModal from './VariablesModal';
import ReactSelect from 'react-select';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { saveRightSideVariables, setFormulaWidget, setSelectedOption, submitFormData, updateDropTemplateId } from '../../../Reducers/CanvasReducer';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem, Select, TextField } from '@mui/material';

function FormulaModal({ isOpen, toggle ,currentId,unique_widget_id,type}) {
    const params = useParams();
  const [activeTab, setActiveTab] = useState('customization');
  const allTemplates  = useSelector(state=>state?.canvas?.addingTemplate);
  const [selectedColor, setSelectedColor] = useState(null); 
  const dispatch = useDispatch()
  const [variableName, setVariableName] = useState('')
  const [selectTemplate,setSelectTemplate] = useState({})
  const [changeBorder,setChangeBorder] = useState()
  const [selectedIndex,setSelectedIndex] = useState("")
  const [currentTemplate,setCurrentTemplate] = useState({})
  const [levelName, setLevelName] = useState('')
  const [levelValue, setLevelValue] = useState('')
const [levelValMinRange, setLevelValMinRange] = useState('')
const [levelValMaxRange, setLevelValMaxRange] = useState('')
const [goalType,setGoalType] = useState('')
const [subTypes,setSubTypes] = useState('')
const [openLevel,setOpenLevel] = useState('')
const [levelCount, setLevelCount] = useState(0); // State to track the number of text fields
const [formula,setFormula] = useState('')
 const [variables, setVariables] = useState([]);
 const [selectedValues, setSelectedValues] = useState({});
const {fileInfo} =useSelector(state=>state.canvas);

 
 const firstFileInfo =fileInfo && fileInfo?.length > 0 ? fileInfo[0] : null;


 let options = [];
 
 if (firstFileInfo) {
   options = Object.keys(firstFileInfo).map(key => ({
     value: firstFileInfo[key],
     label: key
   }));
 }

  
const handleVariableSelect = (index) => (selectedOption) => {
  const variableName = variables[index];
  const updatedSelectedValues = { ...selectedValues, [variableName]: selectedOption.label };
  setSelectedValues(updatedSelectedValues);
  
};
  // Function to parse the formula and extract variables

  // Function to parse the formula and extract variables
  const parseFormula = (formula) => {
    const variableRegex = /[A-Za-z]+/g; // Regular expression to match alphabetic characters
    const matches = formula.match(variableRegex);
    if (matches) {
      const uniqueVariables = [...new Set(matches)]; // Remove duplicate variables
      setVariables(uniqueVariables);
    } else {
      setVariables([]); // Reset variables if no matches found
    }
  };

  // Event handler for formula input change
  const handleFormulaChange = (e) => {
    const newFormula = e.target.value;
    setFormula(newFormula);
    parseFormula(newFormula); // Parse the formula whenever it changes
  };


//All the canvas widgets which is drag and drops
const addingTemplate = useSelector(state => state.canvas.addingTemplate)

//find the object in the addingTemplate array that matches with the currentID


useEffect(() => {
  
  if (isOpen) {
    // Find the matching template based on currentId
    const templateObject = addingTemplate.find(
      (template) => template.newTemplate?.id === currentId
    );

    // Update currentTemplate only if a matching template is found
    if (templateObject) {
      setCurrentTemplate(templateObject?.newTemplate);
       // Update widgetName only when a match is found
    } else {
      // Handle case where matching template is not found (optional)
      console.warn("Template with ID", currentId, "not found in addingTemplate");
    }
  } else {
    // Reset currentTemplate state only if it's currently set (prevents unnecessary updates)
    if (currentTemplate?.id) { // Check if currentTemplate has an id to avoid errors
      setCurrentTemplate({});
    }
  }
}, [isOpen, currentId]);



  const databaseVariable =[
    {
      value : 'variable 1'
    },
    {
      value : 'variable 2'
    },{
      value : 'variable 3'
    },{
      value : 'variable 4'
    },
  ]
  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedWidget = allTemplates.find(template => template?.unique_widget_id === unique_widget_id)
  
    try {
      const accessToken = JSON.parse(localStorage.getItem('access-token'));
  
     
     if (selectedWidget) {
      // Extract the x and y coordinates
      const templateCoordinates = {
        templateCoordinates: { 
        x: selectedWidget.x,
        y: selectedWidget.y
      }
      };
//saves this in redux state because we directly passed pushed this in ai model api
      const formulaData = {
        projectId : params.id,
        unique_widget_id,
        formula,
        formulaVariables :  selectedValues,
        ...templateCoordinates,
       type : type,
        data: variableName,
      }

     await saveDataToSelectedRightSidebar({
      projectId : params.id,
      unique_widget_id,
     
      templateCordinates: {
        x: selectedWidget.x,
        y: selectedWidget.y
      },
     type,
      data: variableName,
     })
    
  

    dispatch(setFormulaWidget(formulaData))
    dispatch(saveRightSideVariables(formulaData));//it is important to show the selected variable inside drag template
  
      
      toggle(); // Close the modal
  setVariableName('')
  setSelectedValues({})
  setFormula('')
  setVariables([])
    } }catch (error) {
      toggle(); // Close the modal
      console.error(error);
      setVariableName('')
      setFormula('')
      setSelectedValues({})
      setVariables([])
    }
  };
  

  //save data to database RightSidebar because it is used many places
const saveDataToSelectedRightSidebar =async (data) =>{
  try {
  //  console.log(data)
    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-right-variable`, data);
   
    // console.log("Data saved successfully:", response.data);
    // dispatch(saveRightSideVariables(data))
  } catch (error) {
    toggle();
    
    console.error("Error saving data:", error);
    return;
  }
}





const handleAddLevel = () => {
  setLevelCount(prevCount => prevCount + 1); // Increment the count of text fields
};
  const handleInputChange = (field,value)=>{

switch(field){
    case 'goalType':
    setGoalType(value)

    case 'subTypes':
      setSubTypes(value)

    
}
  }
  const renderCustomization = () => (
  
      <Grid container spacing={2}>
     <Grid item xs={12} md={12}>
        <Label htmlFor="variable name" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>Variable Name</Label>
        
          <Input
            type="text"
            id="variable-name"
            placeholder="Enter variable name"
            value={variableName} // Pre-fill with industry if available
            style={{ fontSize: '18px' }}
            onChange={(e) => setVariableName(e.target.value)}
            maxLength={18} // Limit input to 19 characters
          />


      </Grid>
 
    
      <Grid item xs={12} md={6}>
      <Label htmlFor="variable name" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>Enter Formula</Label>
        
        <Input
          type="text"
          id="formulaInput"
          placeholder="Enter Formula"
          value={formula}
          style={{ fontSize: '18px' ,paddingLeft: '18px'}}
          onChange={handleFormulaChange}
        />
      </Grid>
      {/* Render input fields for variables */}
        {variables && variables.map((variable, index) => (
          <Grid item xs={12} md={6} key={index}>
          
            <Label htmlFor="variable name" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Select Variable ${variable}`}</Label>
            <ReactSelect
 inputProps={{ style: { fontSize: '16px', height: '10px' } }} // Adjust height here
            // value={selectedVariable}
            onChange={handleVariableSelect(index)}
            id="variableSelect"
            fullWidth
            options={options.map(option => ({ label: option.label, value: option.value }))}
            styles={{
              control: (provided) => ({
                ...provided,
                fontSize: '14px', // Set font size for the input field
                // borderWidth: '2px', // Increase border size
                padding: '3px 0px 5px 0px' // Add more padding
              }),
              singleValue: (provided) => ({
                ...provided,
                fontSize: '18px' // Set font size for the selected value
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '16px' // Set font size for the options in the dropdown
              })
            }}
          />
     
          </Grid>
        ))}
    
 
 <Grid item xs={12}>
    <Button color="primary" style={{ float: 'right', height: "40px", width: "130px", fontSize: "16px" }} onClick={(event) => handleSubmit(event)}>Next</Button>
  </Grid>
  
      </Grid>
    
  );



  return (
    <Modal isOpen={isOpen} toggle={toggle} centered style={{maxWidth:'100%',width:'50%',margin : 'auto' ,overflow : 'auto'}}>
   <ModalHeader toggle={toggle} style={{ fontSize: "20px", textAlign: 'center' }}>
 <p style={{ fontSize: "25px",fontWeight:'600'}}> Create Widget</p> 
</ModalHeader>

      <ModalBody style={{padding:"25px"}}>
       
        {renderCustomization()}
      </ModalBody>
    </Modal>
  );
}

export default FormulaModal;
