import * as React from "react";

import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";

import imgSrc from '../../Images/Rectangle 148.jpg'
import { Button, Grid, Link } from "@mui/material";

import HomeEdit from "./HomeEdit";

import HomeDelete from "./HomeDelete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MemberCard from "./MemberCard";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function ProjectDetail({ match }) {
  // const theme = useTheme();
  const [project, setProject] = useState(null);
  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const projectId = parts[parts.length - 1];
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.project);

  const query = useLocation(window.location.search);
const navigate = useNavigate()
  const users = JSON.parse(localStorage.getItem("users"));
  const isDisabled = users?.designation === "teamMember";
  const params = useParams();
  // const accessToken = JSON.parse(localStorage.getItem('access_token'))
  const handleNavigate = ()=>{
    navigate(`/canvas/${params.id}`)
  }
  useEffect(() => {
    // Create a cancel token source
    const cancelTokenSource = axios.CancelToken.source();

    // Fetch project details using the project ID
    const fetchProjectDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_PUBLIC_BASE_URL}/projects/${projectId}`,
          {
            cancelToken: cancelTokenSource.token, // Pass the cancel token to the reques
          }
        );

        console.log('Project details:', response.data);
        setProject(response.data);
      } catch (error) {
        // Check if the error is due to the request being canceled
        if (axios.isCancel(error)) {
          console.log("Request canceled:", error.message);
        } else {
          console.error("Error fetching project details:", error);
        }
      }
    };

    // Call the function to fetch project details
    fetchProjectDetails();

    // Cleanup function
    return () => {
      // Cancel the request when the component is unmounted
      cancelTokenSource.cancel("Component unmounted");
    };
  }, [dispatch, loading, query?.search]); // Empty dependency array ensures the effect runs once on mount

  return (
    <div style= {{marginTop : '7%'}}>
    <Box className="hello" >
      <CssBaseline />
      {loading ?
        <p>Loading...</p>

        :

        <div>

          {/* //arrow */}

          <Grid className="d-flex justify-content-between align-items-center" style={{ marginTop: '76px' }}>

            <div className="flex-grow-1 d-flex align-items-center">

              <Grid xs={2} md={1}>
                {isDisabled ?
                  <a href="/#/home-page" style={{ textDecoration: "none", color: "#000" }}>
                    <ArrowBackIcon sx={{ fontSize: "30px" }} />
                  </a>
                  :
                  <a href="/#/home-page" style={{ textDecoration: "none", color: "#000" }} >
                    <ArrowBackIcon sx={{ fontSize: "30px" }} />
                  </a>
                }
              </Grid>

              <Grid xs={10} md={4} container alignItems="center" >
                <h1 style={{ color: "#000", fontSize: "32px", fontWeight: "400" }}>
                  Project Details
                </h1>
              </Grid>

            </div>

            <div className="d-flex">

              <HomeEdit project={project} />

              <div className="" style={{ marginLeft: "10px" }}>
                <HomeDelete projectId={params.id} />
              </div>

            </div>

          </Grid>




          <Grid container direction="row" spacing={2} sx={{marginTop : '1.5%' }}>
       
     <Grid item xs={12} md={8}>
  <Grid container style={{ alignItems: 'center' }}>
    <Grid item xs={12} md={2}>
      <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' ,marginRight : '1%'}}>Project Name</p>
    </Grid>

    <Grid item xs={12} md={10} >
      {project && (
        <textarea 
          style={{
            background : '#a358580a',
            padding : '2%',
            color: '#000',

            border : '1px solid #a358580a',
            fontSize: '18px',
            fontWeight: '400',
            resize: 'vertical', // Allow vertical resizing
            width: '100%',      // Take up full width
            minHeight: '100px',  // Set minimum height
            outline: 'none', 
            cursor : 'default'
          }}
         
          value={project.projectName}
          readOnly
          // onChange={(e) => handleProjectNameChange(e.target.value)} // Replace handleProjectNameChange with your onChange handler
        />
      )}
    </Grid>
  </Grid>
</Grid>

 <Grid item xs={12} md={4}>
        <Grid container >
          




          <Grid item xs={4}>
            <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Canvas</p>
          </Grid>
          <Grid item xs={8}>
          {/* <Link to={`/canvas/${params.id}`}> */}
        <img src={imgSrc} alt='canvas' style={{ width: '100%', height: 'auto', cursor: 'pointer' }} onClick={handleNavigate} />
      {/* </Link> */}
</Grid>
        </Grid>
      </Grid>

    

</Grid>


{/* <img src={}/> */}
<Grid container direction="row" spacing={2} sx={{marginTop : '1.5%' }}>
       
       <Grid item xs={12} md={8}>
    <Grid container style={{ alignItems: 'center' }}>
      <Grid item xs={12} md={2}>
        <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Project Description</p>
      </Grid>
  
      <Grid item xs={12} md={10}>
        {project && (
          <textarea 
            style={{
              background : '#a358580a',
              padding : '2%',
              color: '#000',
              cursor : 'default',
              border : '1px solid #a358580a',
              fontSize: '18px',
              fontWeight: '400',
              resize: 'vertical', // Allow vertical resizing
              width: '100%',      // Take up full width
              minHeight: '50px',  // Set minimum height
              outline: 'none', 
            }}
           
            value={project?.projectDetails}
            readOnly
            // onChange={(e) => handleProjectNameChange(e.target.value)} // Replace handleProjectNameChange with your onChange handler
          />
        )}
      </Grid>
    </Grid>
  </Grid>
  
  
        <Grid item xs={12} md={4}>
          <Grid container >
          <Grid item xs={6}>
  <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Industry</p>
</Grid>
<Grid item xs={6}>
  {project ? (
    <span
      style={{
        color: '#000',
        fontSize: '18px',
        fontWeight: '400',
        padding: '2%',
        width: '100%',
        cursor : 'default',
        boxSizing: 'border-box',
      }}
    >
      {project.industry}
    </span>
  ) : (
    <span style={{ color: '#ccc' }}>No industry information</span>
  )}
</Grid>

          </Grid>
        </Grid>
  
  </Grid>



<Grid container direction="row" spacing={2} sx={{marginTop : '1.5%' }}>
       
       <Grid item xs={12} md={8}>
    <Grid container style={{ alignItems: 'center' }}>
      <Grid item xs={12} md={2}>
        <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Goals</p>
      </Grid>
  
      <Grid item xs={12} md={10}>
        {project && (
          <textarea 
            style={{
              background : '#a358580a',
              padding : '2%',
              color: '#000',
              cursor : 'default',
              border : '1px solid #a358580a',
              fontSize: '18px',
              fontWeight: '400',
              resize: 'vertical', // Allow vertical resizing
              width: '100%',      // Take up full width
              minHeight: '50px',  // Set minimum height
              outline: 'none', 
            }}
           
            value={project?.goalName}
            readOnly
            // onChange={(e) => handleProjectNameChange(e.target.value)} // Replace handleProjectNameChange with your onChange handler
          />
        )}
      </Grid>
    </Grid>
  </Grid>
  
  {/* //TimeLines */}
  <Grid item xs={12} md={4}>
  <Grid container alignItems="center">
    <Grid item xs={12}>
      <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400', marginBottom: '8px' }}>Timeline</p>
    </Grid>
    <Grid item xs={12} container spacing={2}>
      {project && (
        <>
          <Grid item xs={6}>
            <input
              type="date"
              style={{
                padding: '8px',
                fontSize: '18px',
                fontWeight: '400',
                width: '100%',
                boxSizing: 'border-box',
              }}
              value={project.startDate}
              readOnly // Make the input read-only
            />
          </Grid>
          <Grid item xs={6}>
            <input
              type="date"
              style={{
                padding: '8px',
                fontSize: '18px',
                fontWeight: '400',
                width: '100%',
                boxSizing: 'border-box',
              }}
              value={project.endDate}
              readOnly // Make the input read-only
            />
          </Grid>
        </>
      )}
    </Grid>
  </Grid>
</Grid>


  </Grid>

   
  <Grid container direction="row" spacing={2} sx={{marginTop : '1.5%' }}>
   
   <Grid item xs={12} md={4}>
         <Grid container >
           
           <Grid item xs={6}>
             <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Status</p>
           </Grid>
           
           <Grid item xs={6}>
   {project ? (
     <span
       style={{
         color: '#000',
         fontSize: '18px',
         fontWeight: '400',
         marginTop : '2%',
         padding: '2%',
         width: '100%',
         boxSizing: 'border-box',
         cursor : 'default'
       }}
     >
       {project.status}
     </span>
   ) : (
     <span style={{ color: '#ccc' }}>No Status</span>
   )}
 </Grid>
         </Grid>
       </Grid>
 
       </Grid>
 

      {/* Additional details for Company and Industry */}
      {/* <Grid item xs={12} md={12} >
      <Grid container>
          <Grid item xs={6}>
            <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Company</p>
          </Grid>
          <Grid item xs={6}>
            {project && <p style={{  wordWrap: 'break-word',color: '#000', fontSize: '18px', fontWeight: '400' }}>{project?.company}</p>}
          </Grid>

        

      
      </Grid>

      <Grid item xs={12} md={6} >
      <Grid container>
      <Grid item xs={6}>
            <p style={{ color: '#50A4DB', fontSize: '20px', fontWeight: '400' }}>Industry</p>
          </Grid>
          <Grid item xs={6}>
            {project && <p style={{ color: '#000', fontSize: '18px', fontWeight: '400' }}>{project?.industry}</p>}
          </Grid>
          </Grid>

          </Grid>

    </Grid> */}

        




       


          <Grid item xs={12} md={12} sx={{ marginTop: "2%", marginBottom: '20px' }}>
            <h5 style={{ color: "#000", fontSize: "24px", fontWeight: "400" }}>
              Team Members
            </h5>
          </Grid>

          <Grid container direction="row" spacing={1}>

            {
              project &&
              project?.teamMember.map(
                (item, index) =>
                  item && ( // Check if item is not null or undefined
                  <Grid
                  key={item._id}
                  item
                  xs={12}
                  md={2}

                  sx={{
                    display: {xs  : 'flex' , md : 'initial'},
                    justifyContent: { xs: 'center', md: 'initial' }, // Center on extra small screens, and default alignment on medium screens
                    alignItems: { xs: 'center', md: 'initial' }, // Center on extra small screens, and default alignment on medium screens
                  }}
                >

                  <MemberCard teamMember={item} />
                  
                </Grid>
                

                
                  )
              )}


            {/* //Add member */}
            {project && (
              <Grid 
              item
              xs={12}
              md={2}
              sx={{
                display: {xs  : 'flex' , md : 'initial'},
                justifyContent: { xs: 'center', md: 'initial' }, // Center on extra small screens, and default alignment on medium screens
                alignItems: { xs: 'center', md: 'initial' }
              }}  
              >
                <Button
                  disabled={isDisabled}
                  href={`/#/project-member/${project?._id}?name=${project.projectName}&company=${project?.company}`}
                  sx={{
                    textDecoration: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                    // padding: "10px",
                    gap: "10px",
                    color: "#50A4DB ",
                
                    height: "100%",
                    minHeight: "100px",
                    border: "1px dashed #50A4DB ",
                    fontSize: "20px",
                  }}
                >
                  <AddCircleOutline sx={{ fontSize: "30px" }} />
                  Add Member
                </Button>
              </Grid>
            )}


          </Grid>

        </div>

      }
    </Box>
    </div>
  );
}
