import DefaultHome from "../../components/HomePage/DefaultHome";
import HomeDashboard from "../../components/HomePage/HomeDashboard";
import Sidebar from "../../components/HomePage/Sidebar";

function Dashboard() {
    return(
        <div>
        <Sidebar Component={<HomeDashboard/>}/>
        
        </div>
    );
}

export default Dashboard;