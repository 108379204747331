import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './Container3.css'
import ActionAreaCard from '../Card1/Card1';
import image from '../../Images/Rectangle_6.jpg';
import image1 from '../../Images/Rectangle_8.jpg';
import image2 from '../../Images/Rectangle_10.jpg';
import { Grid } from '@mui/material';

export default function SimpleContainer3() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" sx={{marginTop: '5%'}}>
        <Box>
          <Grid item xs={12} md={12}>
            <h4 id='heading4'>Advantage of choosing us</h4>
          </Grid>
          <Grid item direction="column" container spacing={2} sx={{marginTop: '1.5%'}}>
            <Grid item container xs={12} md={10} justifyContent="center" alignItems="center">
            {/* <div style={{display: 'flex', flexDirection: 'column', gap: '50px', marginTop: '5%'}}> */}
            <ActionAreaCard rightImage={false} image={image}/>
            </Grid>
            <Grid item container xs={12} md={10} justifyContent="center" alignItems="center">
            <ActionAreaCard rightImage={true} image={image1}/>
            </Grid>
            <Grid item container xs={12} md={10} justifyContent="center" alignItems="center">
            <ActionAreaCard rightImage={false} image={image2}/>
            {/* </div> */}
            </Grid>
            </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}