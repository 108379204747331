import { Box, Grid, Typography } from "@mui/material";
import imgSrc from '../../Images/TeamMemberLogin.png'
import InputWithIcon from "../../components/InputField/InputField"; 
import { FaRegUser } from "react-icons/fa";
import imgSrc2 from '../../Images/LoginPoly.png';
import imgSrc3 from '../../Images/LoginPoly3.png';

import imgSrc5 from '../../Images/PolyLogin.png'
import imgSrc6 from '../../Images/Exclude.jpg'
import { NavLink } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import './TeamManagerLogin.css';



function TeamManagerLogin2(){  


    return(
        
        <Box sx={{height: 'auto'}} >
           
            
            <Grid container direction="row" spacing={2}>
                <Grid item xs={4} md={4}>
                    <div style={{position: 'relative', top : '25px', left: '8%'}}>
                    <NavLink to="/">
                        <img src="/assets/image4.jpg" alt="" />
                    </NavLink>
                    </div>
                </Grid>

                <Grid item xs={4} md={4}>
               
                        <img style={{width: '60%'}} src={imgSrc3} alt="" />
                        
                </Grid>  

                <Grid item xs={4} md={4} container alignItems="end" justifyContent="end">
                    <img style={{width: '30%', height: '70%'}} src={imgSrc6} alt="" />

                </Grid>         
            </Grid>

            <Grid container direction="row" sx={{position: 'relative', bottom: {md: '150px'}}}>
                <Grid  item xs={12} md={6} justifyContent="center" textAlign="center" container >
                    <img className="hide-on-sm"  style={{height: '400px', width: '400px'}} src={imgSrc} alt="" />
                </Grid>

                <Grid item container xs={12} md={6} justifyContent="center" textAlign="center">                
                    <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{marginTop: {xs: '12%'}, color: '#000', fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '500',}}>
                       <p>
                        Welcome!
                        </p> 
                    </Grid>
                    <Grid item container xs={10} md={12} justifyContent="center" textAlign="center" sx={{ color: '#000', fontSize:{md: '40px', sm: '30px', xs: '25px'},fontWeight: '500',  cursor: "pointer"}}>
                        <p>
                            Login to your team
                        </p>
                    </Grid>
                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '2%'}}>
                        
                        <InputWithIcon label='User Id' icon={<FaRegUser size={25}/>} />
                    </Grid>
                    <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" sx={{marginTop: '2%'}}>
                        <InputWithIcon label='Password' icon={<RiLockPasswordLine size={30}/>}/>
                    </Grid>
                   
                    <Grid item container xs={12} md={12} justifyContent="center" textAlign="center">   
                        <a href="#" style={{fontSize: '32px', fontWeight: '500', borderRadius: '24px', border: '1px solid #FFF', background: 'rgba(80, 164, 219, 1)', color: "#FFF", textAlign: 'center',padding: '10px 0px 10px 0px', marginTop: '4%', width: '60%'}} variant="contained">Login</a>
                    </Grid>
                   
                </Grid>

                

            </Grid>

            <Grid container direction="row" >
                <Grid item xs={6} md={4}>
                    <img style={{width: '30%'}} src={imgSrc2} alt="" />

                </Grid>
                <Grid item xs={6} md={4} container justifyContent="end" alignItems="end">
                    <img style={{width: '40%', height: '50%'}} src={imgSrc5} alt="" />
                    
                </Grid>
            </Grid>
                
            

            
        </Box>
        
    
    );
}

export default TeamManagerLogin2;