import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Button } from 'reactstrap';
import { styles ,template1,template2,template3} from './Templates';
import VariableModal from './VariablesModal';
import axios from 'axios';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { saveRightSideVariables, setMutateData, submitFormData, updateDropTemplateId } from '../../../Reducers/CanvasReducer';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';


function TemplateModal({ isOpen, toggle ,currentId,type,unique_widget_id,resetMutateId}) {
console.log("uniqueWidgetId",unique_widget_id)
  const {fileInfo,fileInfoLoading,files,filesLoading} =useSelector(state=>state.canvas)
  const [activeTab, setActiveTab] = useState('customization');
  const [selectedColor, setSelectedColor] = useState(null); 
  const dispatch = useDispatch()
  const [widgetName, setWidgetName] = useState('')
  const [goalType,setGoalType] = useState('')
  const [subTypes,setSubTypes] = useState('')
  const [nominalName,setNominalName] = useState('')
  const [nominalValue,setNominalValue] = useState('')
  const [selectTemplate,setSelectTemplate] = useState({})
  const [discreteValue,setDiscreteValue] = useState('')
  const [selectedIndex,setSelectedIndex] = useState("")
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [currentTemplate,setCurrentTemplate] = useState({})
  const [errors, setErrors] = useState({ linkVariable: false, widgetName: false });

    const [levelCount, setLevelCount] = useState(0); // State to track the number of text fields
    // const [levelNames, setLevelNames] = useState(Array(levelCount).fill(''));
    // const [levelValues, setLevelValues] = useState(Array(levelCount).fill(''));
    // const [levelValMinRanges, setLevelValMinRanges] = useState(Array(levelCount).fill(''));
    // const [levelValMaxRanges, setLevelValMaxRanges] = useState(Array(levelCount).fill(''));
   const [levels,setLevels] = useState([])
    const [categoricalName,setCategoricalName] = useState('')
    const [categoryCount,setCategoryCount] = useState(0)
    const [categories,setCategories] = useState([]);//this is an array of all category value
  
const [linkVariable,setLinkVariable] = useState('')
const [typeoflikedVariable, setTypeofLikedVariable] = useState('');
  const [showAdditionalField, setShowAdditionalField] = useState(false);
                    const allTemplates  = useSelector(state=>state?.canvas?.addingTemplate);
  console.log(allTemplates)
  
  // Function to handle changes in typeoflikedVariable
  const handleTypeofLikedVariableChange = (e) => {
    setTypeofLikedVariable(e.target.value);

    // Check if the value is 'Hierarchical' to decide whether to show the additional field
    if (e.target.value === 'Hierarchical') {
      setShowAdditionalField(true);
    } else {
      setShowAdditionalField(false);
    }
  };
 // Initialize an array of state values for each Slider


  // Define marks for the Slider
  const marks = [
    { value: 10000, label: '10k' },
    { value: 50000, label: '50k' },
    { value: 100000, label: '100k' },
    { value: 150000, label: '150k' },
    { value: 200000, label: '200k' },
    { value: 250000, label: '250k' },
    { value: 300000, label: '300k' }
  ];
  const initialRange = selectedLevel.map (() => [10000, 200000])
  
  const [levelRanges, setLevelRanges] = useState(initialRange);

function valuetext(value) {
  
  // Round the displayed value to two decimal places for consistency
  return `${value.toFixed(2)}`;

}
const handleSliderChange = (index) => (event, newValue) => {
  // Create a copy of the current state
  const newLevelRanges = [...levelRanges];
  // Update the value at the specified index
  newLevelRanges[index] = newValue;
  // Update the state with the new array
  setLevelRanges(newLevelRanges);
};




const firstFileInfo =fileInfo && fileInfo?.length > 0 ? fileInfo[0] : null;
  
let options = [];
  
  if (firstFileInfo) {
    options = Object.keys(firstFileInfo).map(key => ({
      value: firstFileInfo[key],
      label: key
    }));
  } else {
    options = [
      { value: 'Variable 1', label: 'Variable 1' },
      { value: 'Variable 2', label: 'Variable 2' },
      { value: 'Variable 3', label: 'Variable 3' }
    ];
  }

  const goalsType = [
    {
      value: "Qualitative",label : "Qualitative",
      subOptions: ["Nominal", "Categorical", "Hierarchical"],
      levels : ["level 1","level 2","level 3","level 4"]
    },
    {
      value: "Quantitative",label : "Quantitative",
      subOptions: ["Discrete", "Continous"]
    }
  ];
  const handleAddLevel = () => {
    if (levelCount < 5) {
      setLevelCount(prevCount => prevCount + 1);
setLevels([...levels,{levelName : '',levelValue : '',levelMinRange : '',levelMaxRange : ''}])

    } else {
      // Optionally, you can display a message or prevent further addition when the limit is reached
     toast.error("Maximum level count reached!");
    }
  };
  const handleFieldChange = (index, field, value) => {
    const updatedLevels = [...levels];///if anyone updates the value of previous level
    updatedLevels[index][field] = value;
    setLevels(updatedLevels);
  };

  const handleAddCategory = () => {
    if (categoryCount < 5) {
      setCategoryCount(prevCatCount => prevCatCount + 1);
      setCategories(prevCategories => [...prevCategories , {categoryName : '' , categoryValue : ''}]);
    } else {
      toast.error("Maximum category count reached!");
    }
  };
  const [nominalCount,setNoimalCount] = useState(0);
  const [nomCategories,setNomCategories] = useState([]);
  
  const handleCategoryValue = (index,field,value)=>{
    const updateCategoryValue = [...categories];
    updateCategoryValue[index][field] = value
    setCategories(updateCategoryValue)
      }
      const handleNominalValue = (index, field, value) => {
        // Create a copy of the nomCategories array
        const updatedCategories = [...nomCategories];
      
        // Create a copy of the object at the specified index
        const updatedCategory = { ...updatedCategories[index] };
      
        // Update the specified field of the copied object
        updatedCategory[field] = value;
      
        // Update the array with the copied object
        updatedCategories[index] = updatedCategory;
      
        // Update the state with the new array
        setNomCategories(updatedCategories);
      };
      
    
  
  const handleAddNominal = () => {
    if(nominalCount< 5){
      setNoimalCount(prevNomCount => prevNomCount + 1 )
      setNomCategories(prevCategories => [...prevCategories , 
       {
         nominalName : '',
        nominalValue : ''
      }
      ] )
    }
  }
  


const addingTemplate = useSelector(state => state.canvas.addingTemplate)

//find the object in the addingTemplate array that matches with the currentID





// State to manage visibility of additional input field
const [levelField, setLevelField] = useState(false);

const typeOfLevels =  [
  { value: 'level 1', label: 'level 1' },
  { value: 'level 2', label: 'level 2' },

]


const handleLevelChange = (selectedOption)=>{
const selectedValue = selectedOption;
setSelectedLevel(selectedValue);

 // Check if any levels are selected
 setShowAdditionalField(selectedLevel && selectedLevel?.length > 0);
}

// const handleLevelChange = (selectedOption) => {
//   // Handle the selection change here
//   console.log('Selected level:', selectedOption); // Log the selected option object
//   // You can perform additional actions based on the selected option
// };
useEffect(() => {
  
  if (isOpen) {
    // Find the matching template based on currentId
    const templateObject = addingTemplate.find(
      (template) => template.newTemplate?.id === currentId
    );

    // Update currentTemplate only if a matching template is found
    if (templateObject) {
      setCurrentTemplate(templateObject.newTemplate);
     
    } else {
      // Handle case where matching template is not found (optional)
      console.warn("Template with ID", currentId, "not found in addingTemplate");
    }
  } else {
    // Reset currentTemplate state only if it's currently set (prevents unnecessary updates)
    if (currentTemplate?.id) { // Check if currentTemplate has an id to avoid errors
      setCurrentTemplate({});
    }
  }
}, [isOpen, currentId]);

const params = useParams();
const handleLinkVariableChange =async (selectedOption) => {
if(selectedOption){
  setLinkVariable(selectedOption);
  setErrors((prevErrors) => ({ ...prevErrors, linkVariable: false }));
  try{
// const response  =await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/ai/chat`,
// {
//   message : `is ${linkVariable} is Nominal, Hierarchiacal ,ordinal, discrete or continuous ? Give me in one word`
// }
// )
// new Code start
const fileData = await files && files.find(file => file.metadata && file.metadata.projectId === params.id);
const payload = {
  "data": [
    {
      "file_id": fileData._id
    }
  ]
}
const response = await axios.post(`http://34.135.250.199:5000/type of columns`, payload)
const filtertype = await response.data.result[selectedOption.label]

// new Code end

setTypeofLikedVariable(filtertype)
  }catch(error){
    console.error(error);
  }
}else{
  //clear the state of goaltype
  setLinkVariable('')
  setGoalType('')
}
}
//save data to database RightSidebar because it is used many places
const saveDataToSelectedRightSidebar =async (data) =>{
  try {
  //save inside this and then further gets there data which is associated with  the projectId and pass to the Ai model

    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/save-right-variable`, data);
   
    // console.log("Data saved successfully:", response.data);
    // dispatch(saveRightSideVariables(data))
  } catch (error) {
    toggle();
    
    console.error("Error saving data:", error);
    return;
  }
}

const handleSubmit = async (event) => {
  event.preventDefault();
  const newErrors = {
    linkVariable: !linkVariable,
    widgetName: !widgetName.trim(),
  };
  setErrors(newErrors);

  const hasErrors = Object.values(newErrors).some(error => error);

  if (!hasErrors) {
    // Your submission logic here
    console.log('Form submitted successfully with:', { linkVariable, widgetName });
  }

  toggle();
  const selectedWidget = allTemplates.find(template => template?.unique_widget_id === unique_widget_id)
  
  try {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));

    const category = {
      categoryCount: categoryCount,
      categoryName: categoricalName,
      categoricalValue: categories
    };

    const nominals = {
      nominalCount: nominalCount,
      nominalsValue: nomCategories,
    };

    const stateData = {
      levelCount,
      levels, // array of objects containing level
      category,
      type:  type,
      unique_widget_id,
      widgetName,   
      goalType,
      subTypes,
      nominals,
      data: linkVariable.label
    };

    if (selectedWidget) {
      // Extract the x and y coordinates
      const templateCoordinates = {
        templateCoordinates: { 
        x: selectedWidget.x,
        y: selectedWidget.y
      }
      };
      const stateData1 = {
        levelCount,
        levels, // array of objects containing level
        category,
        type:  type,
        unique_widget_id,
        widgetName,
        goalType,
        subTypes,
        nominals,
        data: linkVariable.label,
        ...templateCoordinates
      };
     ///save the data in database
     dispatch(setMutateData(stateData1))//because sending in payload

     //insde this important because of in template and save in db only
     await saveDataToSelectedRightSidebar({
      projectId : params.id,
      unique_widget_id,
      templateCordinates: {
        x: selectedWidget.x,
        y: selectedWidget.y
      },
      type,
      data: linkVariable.label,
     })

    
     dispatch(saveRightSideVariables(stateData1));  
     toggle(); // Close the modal
     //Reset the satates for next time
    //  setCategoryCount('');
    //  setCategoricalName('');
    //  setCategories([]);
    //  setNoimalCount('');
    //  setNomCategories([]);
    //  setLevelCount('');
    //  setLevels([]);
    //  setWidgetName('');
    //  setGoalType('');
    //  setSubTypes([]);
    //  setLinkVariable({ label: '' });

    }   
    else {
        toggle(); // Close the modal
       // Handle error response
       // Reset state variables after successful API call
       setCategoryCount('');
       setCategoricalName('');
       setCategories([]);
       setNoimalCount('');
       setNomCategories([]);
       setLevelCount('');
       setLevels([]);
       setWidgetName('');
       setGoalType('');
       setSubTypes([]);
       setLinkVariable({ label: '' });
    
    }

    // Dispatch the consolidated state object
  

    // const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/create-manual-widget/${params.id}`, stateData, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${accessToken}`,
    //   },
    // });

    // dispatch(setMutateData()); // Reset or update state after the API call if necessary

 
   
    resetMutateId()//that is empty the state of previous mutate id
  
   
  } catch (error) {
    toggle(); // Close the modal
     // Reset state variables after successful API call
     setCategoryCount('');
     setCategoricalName('');
     setCategories([]);
     setNoimalCount('');
     setNomCategories([]);
     setLevelCount('');
     setLevels([]);
     setWidgetName('');
     setGoalType('');
     setSubTypes([]);
     setLinkVariable({ label: '' });
    console.error(error);
  }
};





  const handleInputChange = (field,value)=>{
console.log(field,value);
switch(field){
    case 'goalType':
    setGoalType(value.value)

    case 'subTypes':
      setSubTypes(value)

    
}
// setSubTypes('')
  }
  const renderTextFields = () => {
    if (!selectedLevel?.length) {
      return null; // No levels selected, render nothing
    }

    // Render text fields based on the number of selected levels
    return selectedLevel.map((level, index) => (
      <Grid item xs={12} md={6} key={index}>

<div style={{ display: 'flex', flexDirection: 'column' }}>
      <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: '20px', whiteSpace: 'nowrap' }}>
        Select Value
      </Label>
      <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: '16px', whiteSpace: 'nowrap' }}>
        {`Level ${index + 1}`}
      </Label>
    </div>
         <Box sx={{ width: 300, ml:5,mt : 2 }}  >
          <Slider
            aria-label="Discrete values"
            // defaultValue={levelRanges} // Default value set to 0.1
            value={levelRanges[index]} // Use specific state value for this Slider
            getAriaValueText={valuetext} // Function to display the value text
            valueLabelDisplay="auto"
            onChange={(event, newValue) => handleSliderChange(index)(event, newValue)} // Handle change event with index
            min={10000} // Minimum value
            max={300000} // Maximum value
            marks={marks} // Marks for the Slider
          />
            </Box>
      </Grid>
    ));
  };
  const renderCustomization = () => (
  
      <Grid container spacing={2}>
     <Grid item xs={12} md={12}>
        <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>Widget Name</Label>
       
          <Input
            type="text"
            id="widgetName"
            placeholder="Enter widget name"
            value={widgetName} // Pre-fill with industry if available
            style={{ fontSize: '18px' }}
            onChange={(e) => setWidgetName(e.target.value)}
          />
       {errors.linkVariable && <p style={{ color: 'red' }}>This field is required.</p>}
      



      </Grid>
 
      <Grid item xs={12} md={6}>
      <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>
          Select Link Variable
          </Label>
       

        <ReactSelect
          isClearable
    value={linkVariable}
    // onChange={(selectedOption) => setLinkVariable(selectedOption)}
    onChange={handleLinkVariableChange}
    options={options}
    
    menuPlacement="auto" // Adjust menu placement based on available space
    placeholder="Select Value"
  styles={{
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '38px',
      fontSize: '18px'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for options
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for the selected value
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value labels
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value label text
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value remove button
    })
  }}
  />
    {errors.widgetName && <p style={{ color: 'red' }}>This field is required.</p>}
      </Grid>
 
 {
  linkVariable && 
  <>
    <Grid item xs={12} md={6}>
  <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: '17px', whiteSpace: 'nowrap' }}>
    Type of linked variable
  </Label>

  <Input
    type="text"
    id="typeoflikedVariable"
    value={typeoflikedVariable}
    onChange={handleTypeofLikedVariableChange}
    style={{ fontSize: '18px' }}
    readOnly
  />


</Grid>

      <Grid item xs={12} md={6}>

      <Label htmlFor="type of variable" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>Turn to new Variable type</Label>
     
                  <ReactSelect
                  // isClearable
                    options={goalsType}
                    id="outlined-helperText"
                    onChange={(selectedOption) => { handleInputChange('goalType',selectedOption)
                    
                  setSubTypes('') 
                  setLevelCount(0)
                  setCategoryCount(0)
              
                  }}

                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      height: '38px',
                      fontSize: '18px'
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set the font size for options
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set the font size for the selected value
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set the font size for multi-value labels
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set the font size for multi-value label text
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      fontSize: '18px' // Set the font size for multi-value remove button
                    })
                  }}

                    placeholder='Select new variable...'
                   
                  />
                    {/* {
                    goalsType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <p style={{ fontSize: '18px' }}> {option.value} </p>
                      </MenuItem>
                    ))
                    } */}
                
 </Grid>
 </>

  }
 
 { goalType && (
  <>
    <Grid item xs={12} md={6}>
      <Label htmlFor="type of variable" style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>Select Subtypes</Label>
      <Select
        select
        id="outlined-helperText"
        value={subTypes}
        onChange={(e) => { handleInputChange('subTypes', e.target.value) 
      setLevelCount(0)
    setCategoricalName('')
      setCategoryCount(0)
      setNoimalCount(0)
      }}
        placeholder='Select Value'
        sx={{ width: '100%', height: '38px' }}
        inputProps={{ style: { fontSize: '15px' } }}
      >
        {(goalType === 'Qualitative' ?
          goalsType.find((option) => option.value === goalType)?.subOptions 
          :
          ['Discrete', 'Continuous'].includes(goalType) ? [] : ['Discrete', 'Continuous']
        )?.map((option) => (
          <MenuItem key={option} value={option}>
            <p style={{ fontSize: '18px' }}> {option} </p>
          </MenuItem>
          ))}
      </Select>
    </Grid>
 {
  
   subTypes === 'Hierarchical' &&

<Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column',marginTop : '3%' }}>
      <Button
        style={{
          backgroundColor: '#50A4DB',
          width: '100%', // Full width on extra small screens (xs)
          maxWidth: '50%', // Maximum half width on medium screens (md)
          height: '38px',
          fontSize: '16px'
        }}
        onClick={handleAddLevel}
      >
        Add Level <span style={{ fontSize: '15px', paddingLeft: '1px' }}>+</span>
      </Button>
    </Grid>
 
  }

<Grid container spacing={4} style={{ marginTop: '1%' }}>
      {[...Array(levelCount)].map((_, index) => (
        <Grid item xs={12} md={6} key={index} style={{ paddingLeft: '6%' }}>
          <Label htmlFor={`levelName${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Name of level ${index + 1}`}</Label>
          <Input
            type="text"
            id={`levelName${index}`}
            placeholder={`Purchase level ${index + 1}`}
            // placeholder={`level ${index + 1}`}
            // value={levelNames[index]}
            style={{ fontSize: '18px' }}
            onChange={(e) => handleFieldChange(index, 'levelName', e.target.value)}
           
          />
          <div style={{ marginTop: '3%' }}>
            <Label htmlFor={`levelValue${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Value of level ${index + 1}`}</Label>
            <Input
              type="text"
              id={`levelValue${index}`}
              placeholder={`level ${index + 1}`}
              // value={levelValues[index]}
              style={{ fontSize: '18px' }}
              onChange={(e) => handleFieldChange(index, 'levelValue', e.target.value)}
            />
          </div>

          <Grid container spacing={4} style={{ marginTop: '0.5%' }}>
            <Grid item xs={6} md={6}>
              <Label htmlFor={`rangeLevel${index}`} style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Select Range of Level ${index + 1}`}</Label>
              <Input
                type="number"
                id={`rangeLevel${index}`}
                placeholder="250k"
                // value={levelValMinRanges[index]}
                style={{ fontSize: '18px' }}
                onChange={(e) => handleFieldChange(index, 'levelMinRange', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <div>
                <Input
                  type="number"
                  id={`levelRange${index}`}
                  // placeholder={`Level ${index + 1}`}
                  placeholder="500k"
                  // value={levelValMaxRanges[index]}
                  style={{ fontSize: '18px' }}
                  onChange={(e) => handleFieldChange(index, 'levelMaxRange', e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}

 </Grid>

{
  subTypes === 'Nominal' && 
  <>
  <Button
        style={{
          backgroundColor: '#50A4DB',
          width: '100%', // Full width on extra small screens (xs)
          maxWidth: '50%', // Maximum half width on medium screens (md)
          height: '38px',
          fontSize: '16px'
        }}
        onClick={handleAddNominal}
      >
        Add Nominals <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
      </Button>

  {/* <Grid container spacing={4} style={{ marginTop: '0.5%' }}>
            <Grid item xs={12} md={6}  style={{ paddingLeft: '6%' }}>
              <Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Nominal Name `}</Label>
              <Input
                type="text"
                id='nominal name'
                placeholder='Write Name'
               value={nominalName}
                style={{ fontSize: '18px' }}
                onChange={(e) => setNominalName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            <Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Nominal Value `}</Label>
                <Input
                  type="text"
                  id='nominal value'
                  placeholder='Write Value'
                  value={nominalValue}
                  style={{ fontSize: '18px' }}
                  onChange={(e) => setNominalValue(e.target.value)}
                />
           
            </Grid>
          </Grid> */}
           {[...Array(nominalCount)].map((_, index) => (
        <Grid item xs={12} md={6} key={index} >
          <Label htmlFor={`Nominal Name${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Nominal Name ${index + 1}`}</Label>
          <Input
            type="text"
            id={`NominalName${index}`}
            placeholder={`Nominal Name ${index + 1}`}
            // value={categories[index]}
            style={{ fontSize: '18px' }}
            onChange={(e) => handleNominalValue(index, 'nominalName',e.target.value)}
          />
          <Label htmlFor={`NominalValue${index}`} style={{ marginRight: '36px',marginTop : '3%', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Nominal Value ${index + 1}`}</Label>
          <Input
          type="number"
            id={`categoryName${index}`}
            placeholder={`Nominal value ${index + 1}`}
            // value={categories[index]}
            style={{ fontSize: '18px' }}
            onChange={(e) => handleNominalValue(index,'nominalValue', e.target.value)}
          />
      


        </Grid>
      ))}
          </>
}



{
  subTypes === 'Categorical' && 

  <>
 <Grid xs={12} md={6} item>
   <Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>{`Categoriacal Name`}</Label>
              <Input
                type="text"
                id='categorical name'
               value={categoricalName}
                style={{ fontSize: '18px' }}
                onChange={(e) => setCategoricalName(e.target.value)}
              />

 </Grid>
 
<Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
    {
      categoricalName &&
      <Button
        style={{
          backgroundColor: '#50A4DB',
          width: '100%', // Full width on extra small screens (xs)
          maxWidth: '50%', // Maximum half width on medium screens (md)
          height: '38px',
          fontSize: '16px'
        }}
        onClick={handleAddCategory}
      >
        Add Category <span style={{ fontSize: '15px', paddingLeft: '4px' }}>+</span>
      </Button>

      
    }
      
    </Grid>
  
   
    {[...Array(categoryCount)].map((_, index) => (
        <Grid item xs={12} md={6} key={index} >
          <Label htmlFor={`CategoryName${index}`} style={{ marginRight: '36px', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Category Name ${index + 1}`}</Label>
          <Input
            type="text"
            id={`categoryName${index}`}
            placeholder={`Category Name ${index + 1}`}
            // value={categories[index]}
            style={{ fontSize: '18px' }}
            onChange={(e) => handleCategoryValue(index, 'categoryName',e.target.value)}
          />
          <Label htmlFor={`CategoryValue${index}`} style={{ marginRight: '36px',marginTop : '3%', fontSize: "17px", whiteSpace: 'nowrap' }}>{`Category Value ${index + 1}`}</Label>
          <Input
            type="text"
            id={`categoryName${index}`}
            placeholder={`Category value ${index + 1}`}
            // value={categories[index]}
            style={{ fontSize: '18px' }}
            onChange={(e) => handleCategoryValue(index,'categoryValue', e.target.value)}
          />
      


        </Grid>
      ))}


 
 </>
}

{
  subTypes ==='Discrete' || subTypes ==='Continuous' &&  
  <Grid item xs={12} md={6}>
{/* <Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>Discrete Value</Label> */}
<Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>Select Levels</Label>
              {/* /when we converts them in discrete value then this select is comes to Original vairable */}
              <ReactSelect 
              isMulti
              options={[
                {value : "Level 1", label : "Level 1"},
                {value : "Level 2", label : "Level 2"},

              ]}
              onChange={handleLevelChange}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  height: '38px',
                  fontSize: '18px'
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: '18px' // Set the font size for options
                }),
                singleValue: (provided) => ({
                  ...provided,
                  fontSize: '18px' // Set the font size for the selected value
                }),
                multiValue: (provided) => ({
                  ...provided,
                  fontSize: '18px' // Set the font size for multi-value labels
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  fontSize: '18px' // Set the font size for multi-value label text
                }),
                multiValueRemove: (provided) => ({
                  ...provided,
                  fontSize: '18px' // Set the font size for multi-value remove button
                })
              }}

              />
              {/* <Input
                type="number"
                // id='nominal name
                value={discreteValue}
              
                style={{ fontSize: '18px' }}
                  onChange={(e) => {
        // Extract the numeric part (removes decimals)
        const wholeNumber = e.target.value.replace(/\D/g, '');
        // Update the input value (controlled component approach)
        setDiscreteValue(wholeNumber);

      }}
              /> */}
</Grid>

}
< >
        { 
          ( subTypes ==='Discrete' ||  subTypes ==='Continuous') && 
          (typeoflikedVariable === 'Nominal' || typeoflikedVariable === 'Categorical' || typeoflikedVariable === 'Hierarchical') && 

        renderTextFields()
        }
      </>

{/* {
  subTypes === 'Continuous' &&
  <Grid item xs={12} md={6}>
 <Label  style={{ fontSize: "17px", whiteSpace: 'nowrap' }}>Continous Value</Label>
              <Input
                type="number"
                // id='nominal name
                step="any"
                style={{ fontSize: '18px' }}
                // onChange={(e) => setNominalName(e.target.value)}
              />
</Grid>
} */}



  </>
)}

{/* {linkVariable && (
  <Grid item xs={12} md={12}>
  <Label htmlFor="widgetName" style={{ marginRight: '36px', fontSize: '17px', whiteSpace: 'nowrap' }}>
    Select Levels
  </Label>

  <ReactSelect
  isClearable
  id="typeoflikedVariable"
  options={typeOfLevels.map((option) => ({ value: option.value, label: option.value }))}
  isMulti
  onChange={handleLevelChange}
  placeholder="Select Value"
  styles={{
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '38px',
      fontSize: '18px'
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for options
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for the selected value
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value labels
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value label text
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      fontSize: '18px' // Set the font size for multi-value remove button
    })
  }}
/>


</Grid>

  )} */}

 
      


 <Grid item xs={12}>
    <Button  style={{backgroundColor:'#50A4DB', float: 'right', height: "40px", width: "130px", fontSize: "16px" }} onClick={handleSubmit} >Next</Button>
  
  </Grid>
  
      </Grid>
    
  );

  const renderVariables = () => (
    <VariableModal />
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered style={{maxWidth:'100%',width:'70%' ,margin : 'auto' ,marginTop:'15px',marginBottom:'15px',overflow : 'auto'}}>
    <ModalHeader toggle={toggle} style={{ fontSize: "20px", textAlign: 'center' }}>
 <p style={{ fontSize: "25px",fontWeight:'600'}}> Create Widget</p> 
</ModalHeader>

      <ModalBody style={{padding:"25px"}}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
          {/* <div style={tabStyle('customization')} onClick={() => handleTabChange('customization')}>
            Customization
            <span style={underlineStyle('customization')}></span>
          </div> */}
          {/* <div style={tabStyle('variables')} onClick={() => handleTabChange('variables')}>
            Variables
            <span style={underlineStyle('variables')}></span>
          </div> */}
        </div>
        {(activeTab === 'customization' ? renderCustomization() : renderVariables())}
      </ModalBody>
    </Modal>
  );
}

export default TemplateModal;
