import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";

export const userProfile = createAsyncThunk("get/user/profile", async () => {

  try {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));


    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/user/profile`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    // toast.success("fetch User details");
// console.log("User details",response.data)
    return response.data.user
  } catch (error) {

    console.log("error in fetching project", error.message);
    // toast.error("Please Login ");

    return null;
  }
});


////edit user data
export const editUserDetails = createAsyncThunk('user/editUserDetails', async ({ formValue, userId,data}) => {

  const updateData = formValue;

  // console.log(updateData);

  try {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));


    const response = await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/user/edit-profile/${userId}`,
      {
        imageData : data,///Image data
        updateData : updateData
      },
      
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // 'Content-Type': 'multipart/form-data' 
        },
      }
    );

  
    return response?.data.updatedUser

  }

  catch (error) {

    console.log("Error in fetching channel analytics : ", error);
    return null;
  }

})


export const teamProfile = createAsyncThunk("get/team/profile", async () => {

  try {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));

    // console.log("accessToken,", accessToken)

    const response = await axios.get(
      `${process.env.REACT_APP_PUBLIC_BASE_URL}/team/profile`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    // toast.success("fetch User details");

    return response.data.user
  } catch (error) {
    console.log("error in fetching project", error.message);
    // toast.error("Please Login ");

    return null;
  }
});

////edit Team details
export const editTeamDetails = createAsyncThunk('user/editTeamDetails', async ({ formValue, userId,data }) => {
  // console.log(userId)

  const updateData = formValue;
  // console.log(updateData);

  try {
    const accessToken = JSON.parse(localStorage.getItem('access-token'));



    const response = await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/edit-profile/${userId}`,
      {
        imageData:data,
        updateData:updateData
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // console.log(response.data)
    return response?.data.updatedUser

  }

  catch (error) {

    console.log("Error in fetching channel analytics : ", error);
    return null;
  }

})

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    error: null,
    // isAuthenticated: false,
    token: null,
    userData: null,

  },
  reducers: {
    createUserRequest: (state) => {
      state.loading = true;
    },

    createUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    createUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loginTeamRequest: (state, action) => {
      state.loading = true;
    },
    loginTeamSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    loginTeamFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    loadUserRequest: (state) => {
      state.loading = true;
    },
    loadUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
      state.name = action.payload.name;
    },
    loadUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;////so
    },
    logoutUserRequest: (state) => {
      state.loading = true;
    },
    logoutUserSuccess: (state) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
      state.error = null;
      state.token = null;
    },
    logoutUserFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearAllErrors: (state) => {
      state.error = null;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    ///this is used to handle asynchronious function like api
    builder.addCase(userProfile.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.loading = false;
    });
    builder.addCase(userProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(teamProfile.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.loading = false;
    })
    builder.addCase(teamProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(teamProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(editTeamDetails.fulfilled, (state, action) => {

      state.userData = action.payload;
      state.loading = false;
    })
    builder.addCase(editUserDetails.fulfilled, (state, action) => {

      state.userData = action.payload;
      state.loading = false;
    })
    builder.addCase(editUserDetails.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(editUserDetails.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  loginRequest,
  loginSuccess,
  loginTeamSuccess,
  loginTeamRequest,
  loginTeamFailure,
  loginFailure,
  loadUserRequest,
  loadUserSuccess,
  loadUserFailure,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFailure,
  clearAllErrors,
  updateToken,
} = userSlice.actions;

export default userSlice.reducer;
