import { Box, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import imgSrc from "../../Images/TeamMemberLogin.png";
import InputWithIcon from "../../components/InputField/InputField";
import { FaRegUser } from "react-icons/fa";
import imgSrc2 from "../../Images/LoginPoly.png";
import imgSrc3 from "../../Images/LoginPoly3.png";
import imgSrc4 from "../../Images/LoginPoly4.png";
import imgSrc5 from "../../Images/PolygonLogin.png";
import { NavLink, useNavigate } from "react-router-dom";
import { RiLockPasswordLine } from "react-icons/ri";
import "./TeamManagerLogin.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginTeamSuccess } from "../../Reducers/userReducers";
import toast from "react-hot-toast";

function TeamManagerLogin() {
  const [memberId, setMemberId] = useState("");
  const [password, setPassword] = useState("");
  const [projectName, setProjectName] = useState("");
  const [designation, setDesignation] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationClicked, setConfirmationClicked] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
const [teamName,setTeamName] = useState([]);

const [selectedDesignation, setSelectedDesignation] = useState("");

const handleDesignationChange = (event) => {

  setSelectedDesignation(event.target.value);
};
  const handleConfirm = async () => {
    try {
    
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BASE_URL}/team/login`,
        {
          memberId: memberId,
          password: password,
        }
      );

      const data = response.data; // Assuming your response contains data property
    //   localStorage.setItem("users",JSON.stringify(data))
    
    setTeamName(response.data.teamData)
      const {teamData} = data;
    
      // if (teamData.some(member => member.designation === 'teamManager')) {
      //   // At least one member in the teamData array has the designation 'teamManager'
      //   console.log('At least one team member is a team manager.');
      // } else {
      //   // No member in the teamData array has the designation 'teamManager'
      //   console.log('No team member is a team manager.');
      // }
     
      setShowConfirmation(true);
      // setToken(memberToken);
      setProjectName(teamData.projectName);
      setDesignation(teamData.designation);
      setConfirmationClicked(true);
    } catch (error) {
      toast.error(error.response.data?.message)
      console.error("Login error:", error);
    }
  };



const dispatch = useDispatch()


const handleLogin = async () => {
  if (selectedDesignation) {
      const specificTeamMember = teamName.find(teamMember => teamMember.designation === selectedDesignation);

      if (specificTeamMember) {///login for specific team member
          try {
            const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/login1`, {
                  memberId: specificTeamMember._id
              });
              
              localStorage.setItem("users",JSON.stringify(response?.data?.teamData))
              localStorage.setItem("access-token", JSON.stringify(response.data.memberToken));
              dispatch(loginTeamSuccess());
          } catch (error) {
              console.error("Error during login:", error);
              // Handle error as needed (e.g., show error message to user)
          }
      } else {
          console.warn("Team member not found for the selected designation:", selectedDesignation);
          // Handle case where team member is not found
      }
  }
};

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={4} md={4}>
          <div style={{ position: "relative", top: "25px", left: "8%" }}>
            <NavLink to="/">
              <img src="/assets/image4.jpg" alt="" />
            </NavLink>
          </div>
        </Grid>

        <Grid item xs={4} md={4}>
          <img style={{ width: "60%" }} src={imgSrc3} alt="" />
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          container
          alignItems="end"
          justifyContent="end"
        >
          <img style={{ width: "40%" }} src={imgSrc4} alt="" />
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        sx={{ position: "relative", bottom: { md: "150px" } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          justifyContent="center"
          textAlign="center"
          container
        >
          <img
            className="hide-on-sm"
            style={{ height: "400px", width: "400px" }}
            src={imgSrc}
            alt=""
          />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={6}
          justifyContent="center"
          textAlign="center"
        >
          <Grid
            item
            container
            xs={10}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{
              marginTop: { xs: "12%" },
              color: "#000",
              fontSize: { md: "40px", sm: "30px", xs: "25px" },
              fontWeight: "500",
            }}
          >
     
            
            <p>Welcome!</p>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{
              color: "#000",
              fontSize: { md: "40px", sm: "30px", xs: "25px" },
              fontWeight: "500",
            }}
          >
            <p>Login to your team</p>
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={10}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="User Id"
              icon={<FaRegUser size={25} />}
              value={memberId}
              onChange={(e) => setMemberId(e.target.value)}
            />
          </Grid>
          <Grid
            item
            container
            xs={10}
            md={10}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="Password"
              icon={<RiLockPasswordLine size={30} />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>

          {!confirmationClicked && (
            <Grid
              item
              container
              xs={12}
              md={12}
              justifyContent="center"
              textAlign="center"
            >
              <a
                style={{
                  fontSize: "32px",
                  fontWeight: "500",
                  borderRadius: "24px",
                  border: "1px solid #FFF",
                  background: "rgba(80, 164, 219, 1)",
                  color: "#FFF",
                  textAlign: "center",
                  padding: "10px 0px 10px 0px",
                  marginTop: "4%",
                  width: "60%",
                  cursor: "pointer"
                }}
                variant="contained"
                onClick={handleConfirm}
              >
                Confirm
              </a>
            </Grid>
          )}

          {showConfirmation && (
            <>
              {/* <Grid
                item
                container
                xs={10}
                md={10}
                justifyContent="center"
                textAlign="center"
                sx={{ marginTop: "2%" }}
              >
                <InputWithIcon
                  label="ProjectName"
                  icon={<FaRegUser size={30} />}
                  disabled={true} // Set to true if you want to disable the input
                  defaultValue={projectName} // Set the default value
                />
              </Grid> */}

<Grid
      item
      container
      xs={10}
      md={10}
      justifyContent="center"
      textAlign="center"
      sx={{ marginTop: "3%" }}
    >
     <TextField
  select
  label="Select designation"
  value={selectedDesignation}
  onChange={handleDesignationChange}
  id="outlined-helperText"
  placeholder="Select a designation"
  sx={{
   
    width: 500,
      maxWidth: '100%',
   
  }}
  InputProps={{
    startAdornment: (
      <RiLockPasswordLine size={30} />
    ),
    inputProps: {
      style: {
        fontSize: '20px', // Adjust the font size for desktop and phone views
      },
    },
     sx: {
      '& fieldset': {
        borderColor: '#000', // Set your desired border color
        borderRadius: 24, // Set your desired border radius
        // paddingRight:  isDesktop ? '300px' : '100px',
      },
      
    
    },
  }}
  variant="outlined"
>
{teamName.map((teamMember) => (
  teamMember.projects.length > 0 && (
    <MenuItem key={teamMember.designation} value={teamMember.designation}>
      {teamMember.designation}
    </MenuItem>
  )
))}

</TextField>

    </Grid>
            </>
          )}

      {/* //Login Button */}
      {confirmationClicked && showConfirmation && (
        <Grid
          item
              container
              xs={12}
              md={12}
              justifyContent="center"
              textAlign="center"
        >
          <a
           style={{
            fontSize: "32px",
            fontWeight: "500",
            borderRadius: "24px",
            border: "1px solid #FFF",
            background: "rgba(80, 164, 219, 1)",
            color: "#FFF",
            textAlign: "center",
            padding: "10px 0px 10px 0px",
            marginTop: "4%",
            width: "60%",
            cursor : 'pointer'
          }}
            variant="contained"
            onClick={handleLogin}
          >
            Login
          </a>
        </Grid>
      )}
        </Grid>


    



      </Grid>

 
      {/* <Grid container direction="row">
        <Grid item xs={6} md={4}>
          <img style={{ width: "30%" }} src={imgSrc2} alt="" />
        </Grid>
      
        <Grid
          item
          xs={6}
          md={4}
          container
          justifyContent="end"
          alignItems="end"
        >
          <img style={{ width: "50%", height: "40%" }} src={imgSrc5} alt="" />
        </Grid>

      </Grid> */}

   
    </Box>
  );
}

export default TeamManagerLogin;
