import AdminSidebar from "../../components/AdminPages/AdminSidebar";
import Variable from "../../components/AdminPages/Variable";




function AdminVariable() {
    return(
        <AdminSidebar Component={<Variable/>} />
    );
}

export default AdminVariable;