import { Typography, Grid } from "@mui/material";
import ActionAreaCard3 from "../../components/WorkCard/WorkCard";
import imgSrc from '../../Images/Rectangle 72.jpg';
import imgSrc2 from '../../Images/Rectangle 77.jpg';
import imgSrc3 from '../../Images/Rectangle 81.jpg';
import imgSrc4 from '../../Images/Rectangle 75.jpg';
import imgSrc5 from '../../Images/Rectangle 79.jpg';
import imgSrc6 from '../../Images/Work(1).png';
import imgSrc7 from '../../Images/workLast.png';
import Footer from "../../components/Footer/Footer";
import './Work.css';

function Work() {
    return (
        <div>
            {/* <ResponsiveAppBar/> */}
            <Typography sx={{
                color:'#000',
                fontSize: '40px',
                fontWeight: '500',
                textAlign: 'center',
                // marginTop: '3%'
            }}>
                m
            </Typography>
            <Grid item xs={12} mt={4}  sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                marginTop : '5%'
            }}>
<p>How we work</p>


                </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={4} >
                <Grid item xs={12} sm={12} md={5} container alignItems="center" justifyContent="center" sx={{position: 'relative', bottom: {md: '100px'}}}>
                    <ActionAreaCard3 image={imgSrc} text='Step 1' />
                </Grid>
                
                <Grid item xs={12} sm={6} md={1} container alignItems="center" justifyContent="center">
                    <img src={imgSrc6} alt="" className="hide-on-sm" style={{ height: 'auto', position: 'relative', top: '200px'}} />
                </Grid>

                <Grid item xs={12} sm={12} md={5} sx={{marginTop: { md: '200px' }}}>
                    <ActionAreaCard3 image={imgSrc4} text='Step 2'  />
                </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} sm={12} md={5} sx={{position: 'relative', bottom: { md: '100px'}}}>
                    <ActionAreaCard3 image={imgSrc2} text='Step 3'/>
                </Grid>

                <Grid item xs={12} sm={6} md={1} container alignItems="center" justifyContent="center">
                    <img src={imgSrc6} alt="" className="hide-on-sm" style={{ height: 'auto', position: 'relative', bottom: '100px'}} />
                </Grid>

                <Grid item xs={12} sm={12} md={5} sx={{marginTop: { md: '200px' }}}>
                    <ActionAreaCard3 image={imgSrc5} text='Step 4' />
                </Grid>
            </Grid>


            <Grid container spacing={1} justifyContent="center" >
                <Grid item xs={12} sm={12} md={5} sx={{position: 'relative', bottom: {md: '150px'}}}>
                    <ActionAreaCard3 image={imgSrc3} text='Step 5'/>
                </Grid>
                <Grid item xs={12} sm={6} md={1} container alignItems="center" justifyContent="center">
                    <img src={imgSrc7} alt="" className="hide-on-sm" style={{ height: 'auto', position: 'relative', bottom: '280px' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={5} className="hide-on-sm">

                </Grid>
            </Grid>
            

           

            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default Work;
