// Navbar.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarText } from 'reactstrap';
import { teamProfile, userProfile } from '../../../Reducers/userReducers';

function CustomNavbar() {
  const navbarStyle = {
    borderBottom: '2px solid #000000',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10000, // ensures the navbar stays on top of other elements
    backgroundColor: '#fff' // optional, for solid background
  };
  const dispatch = useDispatch()
  const callsProfileImage = ()=>{
    dispatch(userProfile())
  }
  useEffect(()=>{
    callsProfileImage()
  },[])
  const {userData} = useSelector(state => state.user)
  const {lastEditedCanvas} = useSelector(state => state.canvas)
  
  function calculateTimeDifference(timestamp) {
    const currentTimestamp = new Date();
    const editedTimestamp = new Date(timestamp);
  
    const timeDifferenceInMillis = currentTimestamp - editedTimestamp;
  
    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifferenceInMillis / 1000);
  
    // Calculate minutes, hours, days, etc.
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Less than a minute ago';
    }
  }
  
  return (
    <Navbar expand="md" style={navbarStyle}>
      {/* Logo */}
      <NavbarBrand href="/#/home-page">
        <img src="/assets/image4.jpg" alt="Logo" style={{ height: '40px' }} />
      </NavbarBrand>

      {/*  heading */}
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink className="heading">Canvas</NavLink>
        </NavItem>
      </Nav>

      {/* Profile picture  */}
      <NavbarText style={{fontSize:"12px"}} className="heading2">
       
        Edited {calculateTimeDifference(lastEditedCanvas)}
        <img src={userData?.image} alt="Profile" style={{ height: '37px', borderRadius: '10%', marginLeft: '10px' }} />
        
      </NavbarText>
    </Navbar>
  );
}

export default CustomNavbar;
