import axios from "axios";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const styles = {
    button: {
      position: 'fixed',
      bottom: '0px',
      right: '20px',
      padding: '10px 20px',
      backgroundColor: '#50a4db',
      color: '#fff',
      border: 'none',
      borderRadius: '50px',
      cursor: 'pointer',
      fontSize: '16px',
      zIndex: 11119,
    },
    chatbotContainer: {
      position: 'fixed',
      bottom: '70px', // Adjusted for spacing above button
      right: '20px',
      width: '300px',
      height: '400px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      display: isOpen ? 'flex' : 'none', // Maintain conditional display
      flexDirection: 'column',
      zIndex: 11119,
      transition: 'all 0.3s ease',
    },
    chatHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '10px',
      borderRadius: '10px 10px 0 0',
      fontSize: '18px',
    },
    closeButton: {
      color: '#fff',
      cursor: 'pointer'
    },
    chatMessages: {
      flex: 1,
      padding: '10px',
      overflowY: 'auto',
      fontSize: '16px',
    },
    userMessage: {
      alignSelf: 'flex-end',
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '10px',
      borderRadius: '10px',
      margin: '5px 0',
      maxWidth: '80%',
    },
    botMessage: {
      alignSelf: 'flex-start',
      backgroundColor: '#e0e0e0',
      padding: '10px',
      borderRadius: '10px',
      margin: '5px 0',
      maxWidth: '80%',
    },
    inputContainer: {
      display: 'flex',
      padding: '10px',
      borderTop: '1px solid #e0e0e0',
    },
    input: {
      flex: 1,
      padding: '10px',
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      fontSize: '16px',
    },
    sendButton: {
      padding: '10px 20px',
      backgroundColor: '#007bff',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      marginLeft: '10px',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };

  const toggleChatbot = () => {
    if (!isOpen) {
      setMessages([{ sender: 'bot', text: 'Hi my name is AIDA, how can I help you today?' }]);
    } else {
      setMessages([]);
    }
    setIsOpen(!isOpen);
    window.scrollTo({ bottom: 0, behavior: 'smooth' });
  };

  const toggleChatbotClose = () => {
    setIsOpen(false);
    setMessages([]);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === '') return;
    const userMessage = { sender: 'user', text: input };
    setMessages([...messages, userMessage]);

    try {
      const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/ai/chat`, { message: input });
      const botMessage = { sender: 'bot', text: response.data };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error fetching from OpenAI API:', error);
    }
    setInput('');
  };

  return (
    <div>
      <button onClick={toggleChatbot} style={styles.button}>
        {isOpen ? 'Close Chat' : 'AIDA Chat'}
      </button>

      {isOpen && (
        <div style={styles.chatbotContainer}>
          <div style={styles.chatHeader}>
            <p>Chat</p>
            <div onClick={toggleChatbotClose} style={styles.closeButton}>
              <CloseIcon />
            </div>
          </div>
          <div style={styles.chatMessages}>
            {messages.map((message, index) => (
              <div
                key={index}
                style={
                  message.sender === 'user'
                    ? styles.userMessage
                    : styles.botMessage
                }
              >
                {message.text}
              </div>
            ))}
          </div>
          <div style={styles.inputContainer}>
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              style={styles.input}
              placeholder="Type your message..."
            />
            <button onClick={handleSendMessage} style={styles.sendButton}>
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
