import { Button, Grid, IconButton, Link, Pagination as MuiPagination, TextField } from "@mui/material";
import TeamData from "./TeamData";
import { useEffect, useRef, useState } from "react";
import './Team.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import ReactPaginate from 'react-paginate';
import { ClipLoader } from 'react-spinners';
import { FaSearch } from 'react-icons/fa';
import InputAdornment from '@mui/material/InputAdornment';

function Team() {   

   const [projectCounts,setProjectCounts] = useState({})
   const [loading,setLoading] = useState(false)
        const itemsPerPage = 10; // Set the number of items to display per page
        const [currentPage, setCurrentPage] = useState(1);
         const navigate =useNavigate()
        const [teamData,setTeamData] =useState([]) 
      
        const searchTimerRef = useRef(null);

        const fetchAllTeamMembers = async () => {
          try {
            setLoading(true);
            const accessToken = JSON.parse(localStorage.getItem('access-token'));
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team-members`, {
              headers: {
                Authorization: `Bearer ${accessToken}`
              }
            });
            setTeamData(response?.data?.teamMembers);
            console.log(response?.data?.projectInfo)
            setProjectCounts(response?.data?.projectInfo)
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        };
      
        useEffect(() => {
          fetchAllTeamMembers();
        }, []);
      
        const handleSearch = async (e) => {
          const searchResult = e.target.value.trim();
          clearTimeout(searchTimerRef.current); // Clear previous timer
      
          if (searchResult) {
            // Set new timer for debouncing
            searchTimerRef.current = setTimeout(async () => {
              try {
                setLoading(true);
                const accessToken = JSON.parse(localStorage.getItem('access-token'));
                const response = await axios.post(
                  `${process.env.REACT_APP_PUBLIC_BASE_URL}/member-search/${searchResult}`,
                  {}, // No data to send in the body
                  {
                    headers: {
                      Authorization: `Bearer ${accessToken}`
                    }
                  }
                );
                setTeamData(response?.data?.teamMembers);
              } catch (error) {
                console.error(error);
              } finally {
                setLoading(false);
              }
            }, 1500); // 1.5 seconds delay
          } else {
            fetchAllTeamMembers(); // If search input is empty, fetch all team members
            console.log("No Search this Team Member");
          }
        };
    
      // Calculate the total number of pages
  const pageCount = Math.ceil(teamData.length / itemsPerPage);

  // Get the current page data
  const currentData = teamData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleAddMembers = (e)=>{
    e.preventDefault();
    navigate('/add-member')
  }     
    return(
       <div >
        <Grid item xs={12} md={12} sx={{
           display: 'flex',
           justifyContent: 'space-between',
                marginTop: {
                    xs: '22%',
                    sm: '13%',
                    md: '13%',
                    lg: '9%'
                  },
                  marginBottom: '2%'
            }}>
            <h4 style={{color: '#000', fontWeight: '400', fontSize: '32px'}}>
                Team Members
            </h4>
            <div>
            <TextField
  onChange={(e) => handleSearch(e)}
  placeholder="Search Team Members"

  InputProps={{
    style :  {
      fontSize: '16px', // Adjust the font size as needed
    },
    endAdornment: (
      <InputAdornment position="end">
        <IconButton sx={{ fontSize: 16 }}>
          <FaSearch />
        </IconButton>
      </InputAdornment>
    ),
  }}
/>

            <Button className='button'
            sx={{
             marginLeft : 2,
              backgroundColor: '#50A4DB',
              borderRadius: '8px',
              color: '#FFF',
              fontSize: '18px',
              fontWeight: '600',
              textTransform: 'none',

            }}
           
            variant="contained"
           
         onClick = {(e)=>handleAddMembers(e)}
            
            
          >
          Add Members
          </Button>
          </div>
        </Grid>
        
        {/* <div style={{ border: '1px solid #FFF', boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.25)'}}> */}

        {/* <Grid item xs={12} md={12} direction="row" container justifyContent={{ xs: 'center', md: 'end' }} alignItems={{ xs: 'center', md: 'end' }} sx={{ paddingBottom: '1%', paddingTop: '1%' }}>
            <TextField
              id="outlined-helperText"                            
              defaultValue="Search"
              sx={{width: '30%', '& #outlined-helperText': {
                  fontSize: {md: '20px', xs: '10px'},  
                  color: 'rgba(47, 43, 61, 0.78)',
                                             
                },}}
            />
            <Link
              href="/#/project-member"
              sx={{border: '1px solid #38B27F', borderRadius: '8px', textDecoration: 'none', fontSize:{md: '22px', xs: '12px'}, padding: '12px', color: '#FFF', backgroundColor: '#38B27F', marginLeft: '2%', marginRight: '2%'}}
            >Add Member</Link>
        </Grid> */}

       

      {/* Header */}
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ fontSize: {md: '24px', xs: '12px'}, fontWeight: '500', color: '#FFF', background: 'linear-gradient(to right, #50A4DB, #50A4DB) !important', paddingTop: '1%', borderRadius: '8px' }}>
        <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
          <p >Name</p>
        </Grid>
        <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
          <p>Designation</p>
        </Grid>
        <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
          <p>Projects Assigned</p>
        </Grid>
        <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
          <p>Action</p>
        </Grid>
        
      </Grid>
      <Grid item xs={12} md={12} >
            <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', width: '100%', height: '1px', position: 'relative', bottom: '10px' }} />
        </Grid>

      {/* Data rows */}
      
      {   
  loading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <ClipLoader color="#1565C0" loading={loading} size={50} />
  </div>
  ) : (
    currentData.length > 0 ? (
      currentData.map((item, index) => (
        <div key={index}>
          <Grid container justifyContent="center" alignItems="center" direction="row" sx={{ fontSize: { md: '20px', xs: '10px' }, fontWeight: '400', color: '#000', cursor: 'pointer', transition: 'background-color 0.3s', backgroundColor: index % 2 === 0 ? 'rgba(80, 164, 219, 0.20)' : 'rgba(80, 164, 219, 0.05)', borderRadius: '8px' }}>
            <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
              <p>{item.userName}</p>
            </Grid>
            <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
              <p>{item.designation}</p>
            </Grid>
            <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
              {projectCounts[item._id] && (
                <p>
                  {projectCounts[item._id].lastProjectName} {projectCounts[item._id]?.projectCount - 1 > 0 ? `+ ${projectCounts[item._id].projectCount - 1} more` : ""}
                </p>
              )}
            </Grid>
            <Grid item xs={3} md={3} container justifyContent="center" alignItems="center">
              <TeamData memberId={item._id} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', width: '100%', height: '1px' }} />
          </Grid>
        </div>
      ))
    ) : (
      <Grid item xs={12} md={12}>
        <p style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', fontWeight: 600, fontSize: '20px' }}>No Team Member Present now...</p>
      </Grid>
    )
  )
}


      {/* Pagination component */}
      <Grid item xs={12} md={12} container justifyContent="end" alignItems="end" sx={{marginBottom: '1%', paddingRight: '5%'}}>
      <MuiPagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          size="large"
        //   shape="rounded"
        variant="outlined"
        />
     
      </Grid>
    {/* </div> */}
        
       </div>
    )
}

export default Team;