import HomeCard from "./HomeCard";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, TextField, Grid, Link } from "@mui/material";
import GridOnIcon from '@mui/icons-material/GridOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewIcon from '@mui/icons-material/GridView';
import { ClipLoader } from 'react-spinners';
import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Pagination as MuiPagination } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import { useState } from "react";
import { ScaleLoader, Text as SpinnerText} from 'react-spinners';
import { useEffect } from "react";
import axios from 'axios';
import './customDatePicker.css'; // Import custom styles
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { useSelector } from "react-redux";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import the icon

import toast from "react-hot-toast";
import { formatDistanceToNow, isValid, parseISO } from 'date-fns';

function DefaultHome({userList}) {
  const {createProjectLoader} = useSelector(state => state.project)
  const message = JSON.parse(localStorage.getItem('message'));
  const itemsPerPage = 6; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = React.useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [projects, setProjects] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const pageCount = Math.ceil(projects.length / itemsPerPage);

  // Get the current page data
  const currentData = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);

    // ... (other state variables)
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    localStorage.removeItem('message')
  };

  const handleSecond = () => {
    setOpen(false);
    setOpen2(true);
  };

  const handleThird = () => {
    setOpen2(false);
    setOpen3(true);
    localStorage.removeItem('message')
  };
  const filterProjectsByDate = (projects, startDate, endDate) => {
    if (!startDate && !endDate) return projects;

    return projects.filter(project => {
      const lastEditedDate = new Date(project.lastEdited);
      const start = startDate ? new Date(startDate) : new Date('1970-01-01');
      const end = endDate ? new Date(endDate) : new Date();

      return lastEditedDate >= start && lastEditedDate <= end;
    });
  };


  // const [formattedLastEdited, setFormattedLastEdited] = useState('');
  // const [timeDifference, setTimeDifference] = useState('');
  
  // ... (other state variables)

const userName = useSelector((state) => state?.user?.name)

 let accessToken;
//  console.log(localStorage.getItem('access-token'))

if(localStorage.getItem('access-token') != null ) {


 accessToken = JSON.parse(localStorage.getItem('access-token'))

}
const [loading,setLoading] = useState(false)
useEffect(() => {
  fetchData();
}, []);

useEffect(() => {
  if(userList){
  fetchData();
  }
}, [userList]);


const fetchData = async () => {
  setLoading(true)
  const users = JSON.parse(localStorage.getItem('users'));
  const isDisabled = users?.designation === 'teamMember' || users?.designation === 'teamManager';

  if (isDisabled) {
    try {
     
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/team/projects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    // console.log(response.data);
      setProjects(response.data?.projectData);

    } catch (error) { 
      console.error('Error fetching team projects:', error);
    }finally{
      setLoading(false);
    }
  } else {
    try {
      const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/projects`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,

        },
      });
     
      setProjects(response.data);
    
    } catch (error) {
      console.error('Error fetching projects:', error);
    }finally {
      setLoading(false); // Finish loading
    }
  }
};



  function calculateTimeDifference(timestamp) {
    const currentTimestamp = new Date();
    const editedTimestamp = new Date(timestamp);
  
    const timeDifferenceInMillis = currentTimestamp - editedTimestamp;
  
    // Convert milliseconds to seconds
    const seconds = Math.floor(timeDifferenceInMillis / 1000);
  
    // Calculate minutes, hours, days, etc.
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Less than a minute ago';
    }
  }
  
 
  
  // Determine if the button should be disabled based on user designation  
  const users= JSON.parse(localStorage.getItem('users'))
  const isDisabled =users?.designation === 'teamMember' || users?.designation === 'teamManager';
  
  const loaderContainer = {
 
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.25)' /* Optional: Add a semi-transparent background */
  }
    return(
        <div>
 
          <Grid sx={{border: '1px solid #FFF', marginTop: {
                    xs: '20%',
                    sm: '12%',
                    md: '10%',
                    lg: '5%'
                },
                // marginBottom: '1%',
                
                paddingTop: '2%'}}>
                  
            <Grid container direction="row" spacing={2} justifyContent={{ xs: 'center', md: 'end' }}>
            <Grid item xs={6} md={6}>
          <div style={{display:'flex'}}>
          <p style={{ marginTop: '2.2%', fontSize: '18px', fontWeight: '400', whiteSpace: 'nowrap' }}>Filter by date</p>

            <div style={{marginLeft : '5%'}}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                // customInput={<TextField fullWidth />}
                customInput={
                  <TextField
                    fullWidth
                    style={{fontSize: '10px', fontWeight: 'bold'}}
                    InputProps={{ // Add InputProps prop to TextField
                      endAdornment: ( // Define endAdornment with the icon
                        <CalendarTodayIcon />
                      ),
                    }}
                    inputProps={{
                      style: { fontSize: '16px',cursor : 'pointer' }, // Increase font size here
                    }}
                    // calendarClassName="custom-date-picker"
                  />
                }
              />
            </div>
            <div style={{marginLeft : '3%'}}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="End Date"
                // customInput={<TextField fullWidth />}
                customInput={
                  <TextField
                    fullWidth
                    InputProps={{ // Add InputProps prop to TextField
                      endAdornment: ( // Define endAdornment with the icon
                        <CalendarTodayIcon />
                      ),
                    }}
                    inputProps={{
                      style: { fontSize: '16px',cursor : 'pointer' }, // Increase font size here
                    }}
                  />
                  
                }
  
              />
            </div>
            </div>
        </Grid>



                <Grid item xs={6} md={6} justifyContent={{ xs: 'center', md: 'end' }} textAlign={{ xs: 'center', md: 'end' }} >
                    {/* <div style={{marginTop: '10%', display: 'flex', flexDirection: 'row', gap: '25px', position: 'relative', justifyContent:'end', left: "90px"}}> */}

    <a href="/#/home-grid" > <GridOnIcon sx={{border: '1px solid #FFF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '50%', width: '38px', height: '38px', padding: '9px', color: '#000', marginRight: '2%'}} /></a>

  {/* <Button sx={{ textTransform: 'none', border: '1px solid #FFF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px', color: '#000', marginRight: '3%', fontSize: '14px', }} endIcon={<KeyboardArrowDownIcon />}>
  Filters
</Button> */}
  {/* <Button href="/#/home-dashboard" style={{ textTransform: 'none', textDecoration: 'none', border: '1px solid #FFF', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '35px', color: '#000', padding: '7px 10px 4px 0px', marginRight: '3%', fontSize: '14px' }} disabled={isDisabled}>
    <GridViewIcon sx={{ border: '1px solid #50A4DB ', backgroundColor: '#50A4DB ', boxShadow: ' 2px 2px 8px 0px rgba(0, 0, 0, 0.25)', borderRadius: '50%', width: '30px', height: '28px', color: '#FFF', padding: '7px', marginRight: '20px', marginLeft: '5px' }} />
    Home
  </Button> */}

                   
                </Grid>
            </Grid>
            </Grid>


            <div style={{ border: '1px solid #FFF', marginTop: isDisabled ? '7%' : '2%' }}>
      <Grid container direction="row" spacing={2}>
        {createProjectLoader ? (
          <Grid item container>
            <Grid xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={loaderContainer}>
      <ScaleLoader color="#50A4DB" size={50} loading={true} />
      <span style={{ marginLeft: '10px', fontSize: '16px' }}>Creating Project Please wait...</span>
    </div>
            </Grid>
          </Grid>
        ) : (
          loading ? (
            <Grid item container>
              <Grid xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader color="#1565C0" loading={loading} size={50} />
              </Grid>
            </Grid>
          ) : (
            currentData.length > 0 ? (
              filterProjectsByDate(currentData, startDate, endDate).map(project => (
                <Grid key={project._id} item xs={12} sm={6} md={4} container justifyContent="center">
                  <Card>
                    <CardContent>
                      <HomeCard
                        projectName={project?.projectName}
                        lastEdited={calculateTimeDifference(project?.lastEdited)}
                        to={`/project-det/${project?._id}`}
                        teamMembers={project?.teamMember}
                        canvasId={project?.canvasId}
                        projectId={project?._id}
                        teamMemberIds={project?.teamMember}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid container justifyContent="center" alignItems="center" item xs={12} sm={6} md={4}>
                <h4 style={{ textAlign: 'center' }}>No Projects Present Now. Please Create a Project!</h4>
              </Grid>
            )
          )
        )}
      </Grid>
    </div>


    {

!createProjectLoader && 
<Grid
container
direction="row"
justifyContent="flex-end" // Aligns items to the end of the row
alignItems="flex-end" // Aligns items to the bottom of the column
sx={{ marginTop: '2%', paddingRight: '5%' }}
>
<Grid item>
  <MuiPagination
    count={pageCount}
    page={currentPage}
    onChange={handlePageChange}
    color="primary"
    size="large"
    variant="outlined"
  />
</Grid>
</Grid>
      
    }

           
           {/* WalkThrough */}
                { message === "Registered Successfully"   ?
               <>
               <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  fullWidth    
                  sx={{
                    '& .MuiDialog-paper': {
                      width: '80%',
                      position: 'relative',
                      margin: 'auto', // Center the dialog horizontally
                      right: { lg: '7%' }, // Adjust top position based on screen sizes
                      bottom: {lg: '3%'}
                    },
                  }}              
                >        
                  <DialogContent>
                    <DialogContentText>                   
                          <Grid item container direction="row" sx={{width: '100%'}}>
                            <Grid item xs={1} md={1} >
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                            <Grid item xs={10} md={10} container justifyContent="csenter" textAlign='center' sx={{color: '#000', fontSize:{md: '20px', xs: '14px'}, fontWeight: '400', paddingTop: {xs: '10px'}}}>
                              <p>Get started with a with quick tour and get a better understanding</p>
                            </Grid>
                            <Grid item xs={1} md={1} container justifyContent="end">
                              <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M19.1617 1.20796C20.4787 0.235341 22.3426 1.1709 22.3497 2.80817L22.4454 24.9044C22.4518 26.3963 20.8816 27.3698 19.5482 26.7005L1.67743 17.7305C0.344068 17.0612 0.186409 15.2204 1.38655 14.3342L19.1617 1.20796Z" fill="#50A4DB "/>
                              </svg>
                              <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M3.54219 26.04C2.16818 26.9304 0.364882 25.8827 0.457769 24.2481L1.71135 2.18726C1.79599 0.697758 3.42277 -0.178053 4.71278 0.571371L22.0025 10.6157C23.2926 11.3651 23.3375 13.2121 22.0855 14.0235L3.54219 26.04Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                          </Grid>            
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Grid item container direction="row" sx={{paddingBottom: '5px'}}>
                    <Grid item xs={5} md={5} container justifyContent="end" alignItems="center">
                    <Button autoFocus onClick={handleSecond} sx={{
                      width: '120px',
                      height: '50px',
                      backgroundColor: '#50A4DB ',
                      border: '1px solid #50A4DB ',
                      borderRadius: '8px',
                      // color: '#50A4DB ',
                      fontSize: '20px',
                      fontWeight: '300',
                      textTransform: 'none',
                      color: '#FFF',
                      '&:hover': {
                        backgroundColor: '#50A4DB ',  // Set the same background color as the default state
                      },
                      
                    }}>
                      Start
                    </Button>
                    </Grid>
                    <Grid item xs={5} md={5} container justifyContent="center" alignItems="center">
                    <Link onClick={handleClose} autoFocus sx={{
                    
                      fontSize: '20px',
                      fontWeight: '300',
                      // textTransform: 'none',
                      color: '#000',
                      position: 'relative',
                      left: '20px',
                      textDecorationColor: '#000'
                    }}>
                      Skip
                    </Link>  
                    </Grid>    
                    <Grid item container xs={2} md={2} justifyContent='end' alignItems="center">
                    <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                      <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                    </svg>
                    <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M24.9526 23.3364C25.4612 24.8926 23.9865 26.3673 22.4303 25.8587L1.42704 18.9945C0.00894441 18.5311 -0.420817 16.7342 0.634119 15.6792L14.7732 1.54021C15.8281 0.485276 17.625 0.915041 18.0884 2.33313L24.9526 23.3364Z" fill="#50A4DB "/>
                    </svg>
                    </Grid>              
                  </Grid>
                  </DialogActions>
                  
                </Dialog>
                                                                               
                <Dialog
                  fullScreen={fullScreen}
                  open={open2}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  fullWidth    
                  sx={{
                    '& .MuiDialog-paper': {
                      width: '80%',
                      position: 'relative',
                      margin: 'auto', // Center the dialog horizontally
                      left: {lg: '20%', md: '10%'},
                      bottom: {lg: '10%', md: '5%'}
                    },
                  }}              
                >        
                  <DialogContent>
                    <DialogContentText>                   
                          <Grid item container direction="row" sx={{width: '100%'}}>
                            <Grid item xs={1} md={1} >
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                            <Grid item xs={10} md={10} container justifyContent="csenter" textAlign='center' sx={{color: '#000', fontSize:{md: '20px', xs: '14px'}, fontWeight: '400', paddingTop: {xs: '10px'}}}>
                              <p>Get started with a with quick tour and get a better understanding</p>
                            </Grid>
                            <Grid item xs={1} md={1} container justifyContent="end">
                              <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M19.1617 1.20796C20.4787 0.235341 22.3426 1.1709 22.3497 2.80817L22.4454 24.9044C22.4518 26.3963 20.8816 27.3698 19.5482 26.7005L1.67743 17.7305C0.344068 17.0612 0.186409 15.2204 1.38655 14.3342L19.1617 1.20796Z" fill="#50A4DB "/>
                              </svg>
                              <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M3.54219 26.04C2.16818 26.9304 0.364882 25.8827 0.457769 24.2481L1.71135 2.18726C1.79599 0.697758 3.42277 -0.178053 4.71278 0.571371L22.0025 10.6157C23.2926 11.3651 23.3375 13.2121 22.0855 14.0235L3.54219 26.04Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                          </Grid>            
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Grid item container direction="row" sx={{paddingBottom: '5px'}}>
                    <Grid item xs={5} md={5} container justifyContent="end" alignItems="center">
                    <Button autoFocus onClick={handleThird} sx={{
                      width: '120px',
                      height: '50px',
                      backgroundColor: '#50A4DB ',
                      border: '1px solid #50A4DB ',
                      borderRadius: '8px',
                      // color: '#50A4DB ',
                      fontSize: '20px',
                      fontWeight: '300',
                      textTransform: 'none',
                      color: '#FFF',
                      '&:hover': {
                        backgroundColor: '#50A4DB ',  // Set the same background color as the default state
                      },
                      
                    }}>
                      Next
                    </Button>
                    </Grid>
                    <Grid item xs={5} md={5} container justifyContent="center" alignItems="center">
                    <Link onClick={handleClose} autoFocus sx={{
                    
                      fontSize: '20px',
                      fontWeight: '300',
                      // textTransform: 'none',
                      color: '#000',
                      position: 'relative',
                      left: '20px',
                      textDecorationColor: '#000'
                    }}>
                      Skip
                    </Link>  
                    </Grid>    
                    <Grid item container xs={2} md={2} justifyContent='end' alignItems="center">
                    <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                      <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                    </svg>
                    <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M24.9526 23.3364C25.4612 24.8926 23.9865 26.3673 22.4303 25.8587L1.42704 18.9945C0.00894441 18.5311 -0.420817 16.7342 0.634119 15.6792L14.7732 1.54021C15.8281 0.485276 17.625 0.915041 18.0884 2.33313L24.9526 23.3364Z" fill="#50A4DB "/>
                    </svg>
                    </Grid>              
                  </Grid>
                  </DialogActions>
                  
                </Dialog>

                <Dialog
                  fullScreen={fullScreen}
                  open={open3}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                  fullWidth    
                  sx={{
                    '& .MuiDialog-paper': {
                      width: '80%',
                      position: 'relative',
                      margin: 'auto', // Center the dialog horizontally
                     
                    },
                  }}              
                >        
                  <DialogContent>
                    <DialogContentText>                   
                          <Grid item container direction="row" sx={{width: '100%'}}>
                            <Grid item xs={1} md={1} >
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                                <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                            <Grid item xs={10} md={10} container justifyContent="csenter" textAlign='center' sx={{color: '#000', fontSize:{md: '20px', xs: '14px'}, fontWeight: '400', paddingTop: {xs: '10px'}}}>
                              <p>Get started with a with quick tour and get a better understanding</p>
                            </Grid>
                            <Grid item xs={1} md={1} container justifyContent="end">
                              <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M19.1617 1.20796C20.4787 0.235341 22.3426 1.1709 22.3497 2.80817L22.4454 24.9044C22.4518 26.3963 20.8816 27.3698 19.5482 26.7005L1.67743 17.7305C0.344068 17.0612 0.186409 15.2204 1.38655 14.3342L19.1617 1.20796Z" fill="#50A4DB "/>
                              </svg>
                              <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="23" height="27" viewBox="0 0 23 27" fill="none">
                                <path d="M3.54219 26.04C2.16818 26.9304 0.364882 25.8827 0.457769 24.2481L1.71135 2.18726C1.79599 0.697758 3.42277 -0.178053 4.71278 0.571371L22.0025 10.6157C23.2926 11.3651 23.3375 13.2121 22.0855 14.0235L3.54219 26.04Z" fill="#38B27F"/>
                              </svg>
                            </Grid>
                          </Grid>            
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                  <Grid item container direction="row" sx={{paddingBottom: '5px'}}>
                    <Grid item xs={5} md={5} container justifyContent="end" alignItems="center">
                    <Button autoFocus onClick={handleClose} sx={{
                      width: '120px',
                      height: '50px',
                      backgroundColor: '#50A4DB ',
                      border: '1px solid #50A4DB ',
                      borderRadius: '8px',
                      // color: '#50A4DB ',
                      fontSize: '20px',
                      fontWeight: '300',
                      textTransform: 'none',
                      color: '#FFF',
                      '&:hover': {
                        backgroundColor: '#50A4DB ',  // Set the same background color as the default state
                      },
                      
                    }}>
                      Next
                    </Button>
                    </Grid>
                    <Grid item xs={5} md={5} container justifyContent="center" alignItems="center">
                    <Link onClick={handleClose} autoFocus sx={{
                    
                      fontSize: '20px',
                      fontWeight: '300',
                      // textTransform: 'none',
                      color: '#000',
                      position: 'relative',
                      left: '20px',
                      textDecorationColor: '#000'
                    }}>
                      Skip
                    </Link>  
                    </Grid>    
                    <Grid item container xs={2} md={2} justifyContent='end' alignItems="center">
                    <svg style={{}} xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                      <path d="M0.592914 3.12857C-0.00976862 1.60625 1.37213 0.044285 2.95656 0.456936L24.3397 6.026C25.7834 6.40201 26.3221 8.16929 25.3335 9.28666L12.0841 24.2626C11.0955 25.3799 9.27578 25.0607 8.72661 23.6735L0.592914 3.12857Z" fill="#38B27F"/>
                    </svg>
                    <svg style={{position: 'relative', top: '10px'}} xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <path d="M24.9526 23.3364C25.4612 24.8926 23.9865 26.3673 22.4303 25.8587L1.42704 18.9945C0.00894441 18.5311 -0.420817 16.7342 0.634119 15.6792L14.7732 1.54021C15.8281 0.485276 17.625 0.915041 18.0884 2.33313L24.9526 23.3364Z" fill="#50A4DB "/>
                    </svg>
                    </Grid>              
                  </Grid>
                  </DialogActions>
                  
                </Dialog>
                </>
:
" "
}

        </div>
        
    )
}

export default DefaultHome;