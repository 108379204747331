import React, { useState, useRef } from 'react';
import { Chart, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Button } from '@mui/material';



ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend, TimeScale);

const Utils = {
    numbers: ({ count, min, max }) => Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1)) + min),
    newDate: (days) => {
      const date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    },
    CHART_COLORS: {
      red: 'rgb(255, 99, 132)',
      blue: 'rgb(54, 162, 235)',
      green: 'rgb(75, 192, 192)',
    },
    transparentize: (color, opacity) => {
      const alpha = Math.round(opacity * 255).toString(16).padStart(2, '0');
      return `${color}${alpha}`;
    },
    CHART_COLORS: {
        blue: 'rgba(54, 162, 235, 1)', // Blue color
        yellow : '#ffe6aa'
      },
  };
const ForthCard2 = () => {
  const chartRef = useRef(null);
  const DATA_COUNT = 7;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

  const labels = Array.from({ length: DATA_COUNT }, (_, i) => Utils.newDate(i));

  const [chartData, setChartData] = useState({
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: 'Dataset 1',
        backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
        borderColor: Utils.CHART_COLORS.blue,
        data: Utils.numbers(NUMBER_CFG),
      },
      {
        type: 'bar',
        label: 'Dataset 2',
        backgroundColor: Utils.transparentize(Utils.CHART_COLORS.yellow, 0.5),
        borderColor: Utils.CHART_COLORS.yellow,
        data: Utils.numbers(NUMBER_CFG),
      },
      {
        type: 'line',
        label: 'Dataset 3',
        backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
        borderColor: Utils.CHART_COLORS.blue,
        fill: false,
        data: Utils.numbers(NUMBER_CFG),
      },
    ],
  });

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Combo Time Scale',
      },
    },
    scales: {
      x: {
        type: 'time',
        display: true,
        offset: true,
        ticks: {
          source: 'data',
        },
        time: {
          unit: 'day',
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  const actions = [
    {
      name: 'Randomize',
      handler: () => {
        const newData = chartData.datasets.map((dataset) => ({
          ...dataset,
          data: Utils.numbers({ count: chartData.labels.length, min: 0, max: 100 }),
        }));
        setChartData({ ...chartData, datasets: newData });
      },
    },
  ];

  return (
    <div>
      <h2>Combo Time Scale Chart</h2>
      <Line ref={chartRef} data={chartData} options={options} />
      <div>
        {actions.map((action, index) => (
          <Button style={{marginTop : '2%',marginLeft:'3%'}} variant='outlined' key={index} onClick={() => action.handler(chartRef.current)}>
            {action.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ForthCard2;
