import imgSrc from "../../Images/loginImage.png";
import InputWithIcon from "../../components/InputField/InputField";
import { FaRegUser } from "react-icons/fa";

import imgSrc2 from "../../Images/LoginPoly.png";
import imgSrc3 from "../../Images/LoginPoly3.png";
import { AiOutlineMail } from "react-icons/ai";
import Select ,{components} from 'react-select';
import CountrySelect from "../../components/InputField/InputCountry";
import BusinessIcon from "@mui/icons-material/Business";
import image from  "../../Images/profilePic.jpg"
import { Box, Button, Grid, InputLabel } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import imgSrc4 from "../../Images/Polygon 15.png";
import "./SignUp.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputIndustry from "../../components/InputField/InputIndustry";
import {
  createUserFailure,
  createUserRequest,
  createUserSuccess,
  userProfile,
} from "../../Reducers/userReducers";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

function SignUp() {
  const navigate = useNavigate();
  const [signupError, setSignupError] = useState("");
  const [emailError, setEmailError] = useState("");
  const params = useParams();

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    address: "",
    companyName: "",
    country: "",
    industry: "",
    password: "",
    confirmPassword: "",
    id: params.id, // product/priceId,
    image : image
  });
  const dispatch = useDispatch();
  const [inputErrors, setInputErrors] = useState({
    userName: false,
    email: false,
    address: false,
    companyName: false,
    country: false,
    industry: false,
    password: false,
    confirmPassword: false,
  });
  const options = [
    { value: 'IT', label: 'IT' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'FMCG', label: 'FMCG' },
    { value: 'Education', label: 'Education' },
    { value: 'Fashion', label: 'Fashion' },
    { value: 'Retail', label: 'Retail' },
    { value: 'E-commerce', label: 'E-commerce' },
    { value: 'Digital', label: 'Digital' },
    { value: 'Consulting', label: 'Consulting' },
    { value: 'Insurance & Financial', label: 'Insurance & Financial' },
    { value: 'Fitness & Sports', label: 'Fitness & Sports' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Marketing & Advertising', label: 'Marketing & Advertising' },
    { value: 'Mining', label: 'Mining' },
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Wholesale', label: 'Wholesale' },
    { value: 'Trade', label: 'Trade' }
  ];
  const options1 = [
    { value: 'India', label: 'India' },
    { value: 'US', label: 'US' },
  ];
  
  const customStyles = {
    control: (provided, state) => ({
      ...provided, 
      borderRadius: "5px",
      paddingTop: "3px",
      paddingBottom: "3px",
      width: "20vw", 
      maxWidth: "275px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: 'none',
      borderLeft: "none",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ECFFFF' : 'white', // Change hover color here
      color: state.isFocused ? '#3498DB' : 'black', // Change hover color here
      '&:hover': {
        backgroundColor: '#ECFFFF', // Change hover color here
        color: '#3498DB',
      }
    }),
  }

  const handleChange = (e, name) => {
   
    const { value } = e.target;
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);

      setFormData({ ...formData, [name]: value });
      setInputErrors({
        ...inputErrors,
        [name]: value.trim() === "",
        email: !isValidEmail,
      });

      setEmailError(!isValidEmail ? "Invalid email format." : ""); // Set the email error message
    } else {
      console.log(name,value)
      setFormData({ ...formData, [name]: value });
      setInputErrors({ ...inputErrors, [name]: value.trim() === "" });
    }
  };
  const CustomAdornment = () => {
    return <RiLockPasswordLine />; // Use the imported MUI Icon component
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    // Check for empty fields
    const newInputErrors = {};
    for (const key in formData) {
      // Check if the property exists and is a string before calling trim()
      newInputErrors[key] =
        typeof formData[key] === "string" && formData[key].trim() === "";
    }
    setInputErrors(newInputErrors);

    // Check if any fields are empty
    if (Object.values(newInputErrors).some((value) => value)) {
      return;
    }
    console.log(image)
console.log("formData",formData)
    try {
    
      if (
        params.planName === "Moderate" ||
        params.planName === "Advanced" ||
        params.planName === "Simple"
      ) {
        dispatch(createUserRequest());

        const response = await fetch(
          `${process.env.REACT_APP_PUBLIC_BASE_URL}/signUp`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        if (response.ok) {
          const data = await response.json();
// console.log(data,"front")
          // localStorage.setItem("access-token", JSON.stringify(data.token));
          // localStorage.setItem("message", JSON.stringify(data.message));
          // localStorage.setItem("users", JSON.stringify(data.newUser));

          dispatch(createUserSuccess(data.newUser));

          ///After completed the registration successfully
          ///then create subscription checkout link
          const checkOutLink = await fetch(
 
            `${process.env.REACT_APP_PUBLIC_BASE_URL}/create-checkout-link`,

            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                customerId: data?.newUser?.billingId,
                priceId: data?.id,
                data : data
              }), // Adjust the body as needed
            }
          );
        
          if (checkOutLink.ok) {
            // Additional actions after successful /create/checkout-link API
            const responseData = await checkOutLink.json();
          
          // localStorage.setItem("access-token", JSON.stringify(data.token));
          localStorage.setItem("access-token", JSON.stringify(data.token));
          localStorage.setItem("message", JSON.stringify(data.message));
          localStorage.setItem("users", JSON.stringify(data.newUser));

            const redirectUrl = responseData.url;

        
            // Additional actions after successful /create/checkout-link API
            // Redirect to the desired URL upon successful signup and checkout session link creation
            window.location.href = redirectUrl; // Redirect to the checkout URL
          
        
          await navigate("/home-page"); // Replace '/home-page' with your desired URL
            console.log("responseData",responseData)
          } else {
            localStorage.clear();
            navigate("/pricing");
            console.error("Error creating checkout session link:", checkOutLink.statusText);
          }
        
        
        
        } else {

          const errorData = await response.json();
          console.error("Signup failed:", errorData.message);
          setSignupError(errorData.message); // Set the error message
        }
      } else {
        navigate('/pricing')
        toast.error("Firstly takes subscription and then signup");
      
      }
    } catch (error) {
      console.error("Error during signup:", error);
      dispatch(createUserFailure(error?.response?.data?.message));
      setSignupError("An error occurred during signup. Please try again."); // Set a generic error message
    }
  };

  const handleSelectChange = (selectedOption) => {
    const selectedIndustry = selectedOption ? selectedOption.value : '';
    handleChange({ target: { value: selectedIndustry } }, "industry");
  };

  const handleCountryChange = (selectedOption) => {
    const selectedCountry = selectedOption ? selectedOption.value : ''; // If selectedOption is null, set selectedCountry to an empty string
    
    handleChange({ target: { value: selectedCountry } }, "country");
  };
  return (
    <Box>
      {/* <div style={{display: 'flex', flexDirection: 'row', gap: '20%', textAlign: 'center' }}> */}

    <Grid container direction="row" spacing={2} justifyContent="center" style={{marginTop:'0.1%'}}>
      
        {/* <Grid item xs={2} md={2}>
          <div style={{ position: "relative", top: "25px", left: "8%" }}>
            <NavLink to="/">
              <img src="/assets/image4.jpg" alt="" />
            </NavLink>
          </div>
        </Grid> */}

        <Grid item container xs={10} md={10} justifyContent="center" textAlign="center" style={{marginTop:'4%'}}>
     

        <Grid
          item
          container
          xs={10}
          md={10}
          justifyContent="center"
          textAlign="center"
          marginTop="3%"
        >
          <Grid
            item
            container
            xs={12}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{
              // marginTop: { xs: "8%" },
              color: "#000",
              fontSize:{md: '25px', sm: '27px', xs: '16px'},
              fontWeight: "700",
            }}
          >
            <p>Welcome! <span style={{display:'inline'}}>Create your account</span></p>
          </Grid>
          {/* <Grid
            item
            container
            xs={12}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{
              color: "#000",
              fontSize: { md: "40px", sm: "30px", xs: "25px" },
              fontWeight: "700",
            }}
          >
            <p> Create your account </p>
          </Grid> */}
          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="User Name"
              icon={<FaRegUser size={25} />}
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  userName: formData.userName.trim() === "",
                })
              }
              value={formData.userName}
              onChange={(e) => handleChange(e, "userName")}
              helperText={inputErrors.userName ? "This field is required" : ""}
              error={inputErrors.userName}
            />
          </Grid>


          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="Email"
              icon={<AiOutlineMail size={30} />}
              onBlur={() => {
                setInputErrors((prevErrors) => ({
                  ...prevErrors,
                  email: formData.email.trim() === "",
                }));
                setEmailError((prevEmailError) => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  const isValidEmail = emailRegex.test(formData.email);
                  return isValidEmail ? "" : "Invalid email format.";
                });
              }}
              helperText={
                inputErrors.email ? "This field is required" : emailError
              }
              type="email"
              value={formData.email}
              error={inputErrors.email || !!emailError}
              onChange={(e) => handleChange(e, "email")}
            />
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="Address"
              icon={<IoLocationOutline size={30} />}
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  address: formData.address.trim() === "",
                })
              }
              helperText={inputErrors.address ? "This field is required" : ""}
              value={formData.address}
              onChange={(e) => {
                handleChange(e, "address");
              }}
              error={inputErrors.address}
            />
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
             <Box sx={{
    width: 500,
    maxWidth: '100%',
  }}>
            
            <Select
      placeholder="Select Country"
      isClearable
      onChange={handleCountryChange}
      options={options1}
      onBlur={() =>
        setInputErrors({
          ...inputErrors,
          country: formData.country.trim() === "",
        })
      }
      styles={{
        control: (provided) => ({
          ...provided,
          border: '1px solid #262626',
          borderRadius: 24,
          height: '55px',
          fontSize: '20px',
          paddingLeft: '40px',
          textAlign: 'left',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? '#ECFFFF' : 'white',
          color: state.isFocused ? '#3498DB' : 'black',
          '&:hover': {
            backgroundColor: '#ECFFFF',
            color: '#3498DB',
          },
          fontSize: '18px',
          textAlign: 'left',
        }),
      }}
  
   
             
            />
               </Box>
             
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >

            <InputWithIcon
              label="Company Name"
              icon={<BusinessIcon sx={{ fontSize: "30px" }} />}
              value={formData.companyName}
              onChange={(e) => {
                handleChange(e, "companyName");
              }}
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  companyName: formData.companyName.trim() === "",
                })
              }
              helperText={
                inputErrors.companyName ? "This field is required" : ""
              }
              error={inputErrors.companyName}
            />
          </Grid>

          {/* <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          
          >
            <InputIndustry
              value={formData.industry}
              onChange={(value) =>
                handleChange({ target: { value } }, "industry")
              }
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  industry: formData.industry.trim() === "",
                })
              }
              helperText={inputErrors.industry ? "This field is required" : ""}
              error={inputErrors.industry}
              
            />
          </Grid> */}
    <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          
          >
<Box sx={{
  width: 500,
  maxWidth: '100%',
}}>
 <Select
//  isClearable
  placeholder="Select Industry"
  onChange={handleSelectChange}
  isClearable
  options={options}
  onBlur={() =>
    setInputErrors({
      ...inputErrors,
      industry: formData.industry.trim() === "",
    })
  }
  InputProps={{
    startAdornment: 
      <CustomAdornment /> // Adjust size and margin if necessary
    ,
    style: {
      fontSize: '20px', // Adjust the font size for desktop and phone views
    },
  }}
  styles={{
    control: (provided) => ({
      ...provided,
      border: '1px solid #262626',
      borderRadius: 24,
      height: '55px',
      fontSize: '20px',
      paddingLeft: '40px',
      textAlign: 'left', // Align text to the left
    }),
    
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#ECFFFF' : 'white',
      color: state.isFocused ? '#3498DB' : 'black',
      '&:hover': {
        backgroundColor: '#ECFFFF',
        color: '#3498DB',
      },
      fontSize: '18px', // Adjust font size
      textAlign: 'left', // Align text to the left
    }),
  }}
/>

</Box>

          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="Password"
              icon={<RiLockPasswordLine size={30} />}
              type="password"
              value={formData.password}
              onChange={(e) => {
                handleChange(e, "password");
              }}
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  password: formData.password.trim() === "",
                })
              }
              helperText={inputErrors.password ? "This field is required" : ""}
              error={inputErrors.password}
            />
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ marginTop: "2%" }}
          >
            <InputWithIcon
              label="Confirm Password"
              icon={<RiLockPasswordLine size={30} />}
              type="password"
              value={formData.confirmPassword}
              onChange={(e) => {
                handleChange(e, "confirmPassword");
              }}
              onBlur={() =>
                setInputErrors({
                  ...inputErrors,
                  confirmPassword: formData.confirmPassword.trim() === "",
                })
              }
              helperText={
                inputErrors.confirmPassword ? "This field is required" : ""
              }
              error={inputErrors.confirmPassword}
            />
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
            sx={{ color: "red", marginTop: "2%", fontSize: "20px" }}
          >
            {signupError}
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={12}
            justifyContent="center"
            textAlign="center"
          >
            <Button
              type="submit"
              onClick={handleSubmit}
              style={{
                fontSize: "28px",
                fontWeight: "500",
                borderRadius: "24px",
                border: "1px solid #FFF",
                background: "rgba(80, 164, 219, 1)",
                color: "#FFF",
                textAlign: "center",
                padding: "2px 0px 2px 0px",
                marginTop: "3%",
                width: "60%",
              }}
              variant="contained"
            >
              Sign Up
            </Button>
          </Grid>

          <Grid
  item
  container
  xs={11}
  md={12}
  justifyContent="center"
  textAlign="center"
  sx={{
    fontSize: { md: "20px", xs: "18px" }, // Adjusted font size to match the first grid
    fontWeight: "400",
    marginTop: "2%",
  }}
>
  <p style={{ display: 'inline' }}>Already have an account?</p>
  <a
    href="/#/login"
    style={{ color: "#50A4DB ", textDecoration: "underline", marginLeft: '2%' }} // Added marginLeft to match the styling of the first link
  >
    Login
  </a>
</Grid>

          {/* </div>  */}
        </Grid>
      </Grid>

      </Grid>

      
{/* //Images */}
      {/* <Grid container direction="row">
        <Grid item xs={6} md={6}>
          <img style={{ width: "10%" }} src={imgSrc2} alt="" />
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          container
          justifyContent="end"
          alignItems="end"
        >
          <img style={{ width: "10%" }} src={imgSrc4} alt="" />
        </Grid>
      </Grid> */}
    </Box>
  );
}

export default SignUp;
