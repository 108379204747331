import SimpleContainer1 from "../../components/Container1/Container1";
// import ResponsiveAppBar from "../../components/ResponsiveAppBar/ResponsiveAppBar";
import SimpleContainer2 from "../../components/Container2/Container2";
import SimpleContainer3 from "../../components/Container3/Container3";
import SimpleContainer4 from "../../components/Container4/Container4";
import SimpleContainer5 from "../../components/Container5/Container5";
import SimpleContainer6 from "../../components/Container6/Container6";
import SimpleContainer7 from "../../components/Container7/Container7";
import SimpleContainer8 from "../../components/Container8/Container8";

import Footer from "../../components/Footer/Footer";


function Home() {
    return (
        <div>
            {/* <ResponsiveAppBar/> */}
            <SimpleContainer1/>
            <SimpleContainer2/>
            <SimpleContainer3/>
            <SimpleContainer4/>
            <SimpleContainer5 />
            <SimpleContainer8/>
            <SimpleContainer6/>
            <SimpleContainer7/>
            <Footer/>
        </div>
    );
}

export default Home;