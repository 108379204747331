import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import Select from 'react-select';

const CustomerCard = ({ graphData }) => {
  const bestModelNameObject = graphData.find(item => item.hasOwnProperty("Best Model Name"));
  const bestModelName = bestModelNameObject ? bestModelNameObject["Best Model Name"] : null;
  const bestModel = graphData.find(obj => obj[bestModelName]);

  const generateOptions = () => {
    const options = [];
    for (let i = 0; i <= 10; i++) {
      options.push({
        value: `${i * 10}-${(i + 1) * 10}`,
        label: `${i * 10}-${(i + 1) * 10}`,
      });
    }
    return options;
  };

  const initialOption = bestModel[bestModelName]?.X_test[0];
  const selectOptions = generateOptions();

  const initialSeries = [
    {
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
    {
      name: 'Free Cash Flow',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
    },
  ];

  const [series, setSeries] = useState(initialSeries);
  const [xAxis, setXAxis] = useState(null);
  const [yAxis, setYAxis] = useState({ value: '0-10', label: '0-10' }); // Set default y-axis range
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: initialOption ? Object.keys(initialOption) : [],
      title: {
        text: '',
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      title: {
        text: '$ (thousands)',
      },
    },
    fill: {
      opacity: 1,
    },
  });

  
  useEffect(() => {
    if (xAxis) {
      const selectedOption = xAxis.value;
      const selectedData = bestModel[bestModelName].X_test.map(item => item[selectedOption]);

      const [minRange, maxRange] = yAxis.value.split('-').map(Number);

      const updatedSeries = [
        {
          name: yAxis?.label,
          data: selectedData,
        },
      ];

      setSeries(updatedSeries);

      setOptions(prevOptions => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          title: {
            text: xAxis.label,
          },
        },
        yaxis: {
          min: minRange,
          max: maxRange,
          title: {
            text: yAxis.label,
          },
        },
      }));
    }
  }, [xAxis, yAxis, bestModel, bestModelName]);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '150px' }}>
          <Select
            placeholder="X-Axis"
            options={Object.keys(initialOption).map(key => ({ value: key, label: key }))}
            isClearable
            onChange={(selectedOption) => setXAxis(selectedOption)}
          />
        </div>
        <div style={{ width: '150px', marginLeft: '2%' }}>
          <Select
            placeholder="Y-Axis"
            options={selectOptions}
            isClearable
            value={yAxis}
            onChange={(selectedOption) => setYAxis(selectedOption)}
          />
        </div>
        <div style={{ width: '150px', marginLeft: '2%' }}>
          <Select
            placeholder="Type"
            options={[
              { label: "Actual", value: "Actual" },
              { label: "Predicted", value: "Predicted" },
            ]}
            isClearable
            // onChange={(selectedOption) => setChartType(selectedOption)}
          />
        </div>
      </div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default CustomerCard;
