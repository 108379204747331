import { Grid, Typography } from "@mui/material";
import './Card3.css';

function Card3({icon}) {
    return (    
               
            <Grid item >
            <div className="hexagon">
                <div className="hexagon_inner">
                    <div className="hexagon_icon">{icon}</div>
                    <Grid item >
            <Typography className="hexagon_text">
                Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis .
                </Typography>
                </Grid>
                </div>
            </div>
            </Grid>
                  
        
      
    );
  }
  
  export default Card3;