import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Checkbox, FormControlLabel, Grid, Link, TextField } from '@mui/material';
import './ChangePlan.css'
import axios from 'axios';
import toast from 'react-hot-toast';




export default function ChangePlan({currentCharge,customerId,currentPriceId,currentSubscription}) {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
   const [isChecked,setIsChecked] = React.useState(false)
   const [selectedPriceId,setSelectedPriceId] = React.useState([])

let labelsWithPrices;
//simple moderate and advanced
if(currentPriceId[0] === "price_1OlRPTSGW1EyGI68j0Q8wSOS"){

  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Simple (for Users)', price: 5 ,priceId : "price_1OiDpTSGW1EyGI68mAhLYoHb"},
    { label: 'Lorem ipsum dolor sit amet Simple (for Projects)', price: 5,priceId : "price_1OiDp0SGW1EyGI683THx9Iri" },
    { label: 'Lorem ipsum dolor sit amet Simple (for Simulations)', price: 5 ,priceId : "price_1OiDobSGW1EyGI68bTJttjHo"},
    { label: 'Lorem ipsum dolor sit amet Simple (for Variables)', price: 5 ,priceId : "price_1OiDo4SGW1EyGI68P1y5vHR2"},
  ];
}
else if(currentPriceId[0] === "price_1OlRTxSGW1EyGI6845fez4k6"){
  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Moderate (for Users)', price: 7 ,priceId : "price_1Oiu2uSGW1EyGI68SY92P7Fn"},
    { label: 'Lorem ipsum dolor sit amet Moderate (for Projects)', price: 7,priceId : "price_1Oiu3ESGW1EyGI68Y61Uu3to" },
    { label: 'Lorem ipsum dolor sit amet Moderate (for Simulations)', price: 7 ,priceId : "price_1Oiu3VSGW1EyGI68mdmXRd7Y"},
    { label: 'Lorem ipsum dolor sit amet Moderate (for Variables)', price: 7 ,priceId : "price_1Oiu3tSGW1EyGI68K6amlBow"},
  ];
}else if(currentPriceId[0] === "price_1OlRVLSGW1EyGI68yuqpDxWm"){
  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Advance (for Users)', price: 9 ,priceId : "price_1OivesSGW1EyGI68lwEjcpgZ"},
    { label: 'Lorem ipsum dolor sit amet Advance (for Projects)', price: 9,priceId : "price_1OivfiSGW1EyGI68EdRX8E3r" },
    { label: 'Lorem ipsum dolor sit amet Advance (for Simulations)', price: 9 ,priceId : "price_1Oivg4SGW1EyGI6808fyUZm1"},
    { label: 'Lorem ipsum dolor sit amet Advance (for Variables)', price: 9 ,priceId : "price_1OivgdSGW1EyGI68VINqqBAW"},
  ];
}else{
  console.log("Wrong price id is presents")
}

//For recurring payments
if(currentPriceId[0] === "price_1OlRPTSGW1EyGI68j0Q8wSOS" && isChecked){
  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Simple subs (for Users)', price: 5 ,priceId : "price_1Oiwn9SGW1EyGI68PmguaKcQ"},
    { label: 'Lorem ipsum dolor sit amet Simple subs (for Projects)', price: 5,priceId : "price_1OiwnUSGW1EyGI680ki1SPn7" },
    { label: 'Lorem ipsum dolor sit amet Simple subs (for Simulations)', price: 5 ,priceId : "price_1Oiwo2SGW1EyGI68h6Edac2D"},
    { label: 'Lorem ipsum dolor sit amet Simple subs (for Variables)', price: 5 ,priceId : "price_1OiwoNSGW1EyGI68EJN6vbXq"},
  ];
}else if(currentPriceId[0] === "price_1OlRTxSGW1EyGI6845fez4k6" && isChecked){
  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Moderate subs (for Users)', price: 7 ,priceId : "price_1Oiwp7SGW1EyGI68zboluETF"},
    { label: 'Lorem ipsum dolor sit amet Moderate subs (for Projects)', price: 7,priceId : "price_1OiwpSSGW1EyGI68iaTbHSiy" },
    { label: 'Lorem ipsum dolor sit amet Moderate subs (for Simulations)', price: 7 ,priceId : "price_1OiwpoSGW1EyGI68WksFlnmk"},
    { label: 'Lorem ipsum dolor sit amet Moderate subs (for Variables)', price: 7 ,priceId : "price_1OiwqESGW1EyGI68SNfUQTgt"},
  ];
}else if(currentPriceId[0] === "price_1OlRVLSGW1EyGI68yuqpDxWm" && isChecked){
  labelsWithPrices = [

    { label: 'Lorem ipsum dolor sit amet Advance subs (for Users)', price: 9 ,priceId : "price_1OiwrqSGW1EyGI68le8rNoKF"},
    { label: 'Lorem ipsum dolor sit amet Advance subs (for Projects)', price: 9,priceId : "price_1OiwsCSGW1EyGI68AVeGUU1M" },
    { label: 'Lorem ipsum dolor sit amet Advance subs (for Simulations)', price: 9 ,priceId : "price_1OiwrJSGW1EyGI68RToh8B5K"},
    { label: 'Lorem ipsum dolor sit amet Advance subs (for Variables)', price: 9 ,priceId : "price_1OiwqxSGW1EyGI68QSB2ULYt"},
  ];
}else{
  console.log("Wrong price id is presents")
}



    //for One time label and price
    // const labelsWithPrices = [

    //   { label: 'Lorem ipsum dolor sit amet (for Users)', price: 5 ,priceId : "price_1OiDpTSGW1EyGI68mAhLYoHb"},
    //   { label: 'Lorem ipsum dolor sit amet (for Projects)', price: 5,priceId : "price_1OiDp0SGW1EyGI683THx9Iri" },
    //   { label: 'Lorem ipsum dolor sit amet (for Simulations)', price: 5 ,priceId : "price_1OiDobSGW1EyGI68bTJttjHo"},
    //   { label: 'Lorem ipsum dolor sit amet (for Variables)', price: 5 ,priceId : "price_1OiDo4SGW1EyGI68P1y5vHR2"},
    // ];
  //for subscription label and price


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const [planIds, setPlanIds] = React.useState([]);
    
  React.useEffect(() => {
      const fetchSubscriptionDetails = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/customer/subscription-details?customerId=${customerId}`);
            console.log(response.data.planIds)
            setPlanIds(response.data.planIds);
          } catch (error) {
              console.error('Error fetching subscription details:', error);
          }
      };
      
      fetchSubscriptionDetails();
  }, [customerId]); // Empty dependency array ensures that this effect runs only once when the component mounts
  

  const handleSubmit =async () => {
    
    try{
    
if(isChecked){
  const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/add-multiple-subscriptions`,{
    currentPriceId,
    currentSubscription,
    customerId,
 selectedPriceId: selectedPriceId.filter(id => !planIds.includes(id)) // Filter out planIds from selectedPriceId which is not includes inside planIds array
})
setSelectedPriceId([])
window.location.reload()
}
else{
    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/add-multiple-features`,{
        currentPriceId,
        customerId,
     selectedPriceId: selectedPriceId.filter(id => !planIds.includes(id)) // Filter out planIds from selectedPriceId
    })
    setSelectedPriceId([])
    window.location.reload()
    }
   
}
catch(error){
console.error(error)
toast.error(error.response.data.error)
    }
    setOpen(false);
  
  };

 const LoggedInAs = 'Team Manager' || '' || 'Team Member';
 const [totalPrice , setTotalPrice] = React.useState(0);
  // An array of labels


const handleCheckboxChange = (price,priceId) => (event) => {
  
  if (event.target.checked) {


      setTotalPrice(totalPrice + price);
      setSelectedPriceId([...selectedPriceId,priceId])//adding new selected priceId from this array 
      
  } else {
      setTotalPrice(totalPrice - price);
     //Remove diselects price id from the array using filter method
     setSelectedPriceId(selectedPriceId.filter(id => id !== priceId));
  }
}

const handleCheckboxChange1 =  () =>{
  setIsChecked(!isChecked)

if(isChecked){
//calls another function for setOne time payment only
recurringPayment()

}
}
const recurringPayment = ()=>{
 
}

  return (
    <React.Fragment>
      <Button className='detail-button'
           sx={{
            color: '#AFAFAF',
            fontSize: '20px',
            fontWeight: '400',                 
            textTransform: 'none',
           }}
           
           
            onClick={handleClickOpen}
            disabled={LoggedInAs === ''}
          >
            Change Plan
          </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        PaperProps={{
          sx: {
            
            maxWidth: 'md', // You can use 'sm', 'md', 'lg', 'xl', or a specific pixel value
            position: 'relative',
            // overflowY: 'scroll',
            
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Grid container direction="row" spacing={6}>
                <Grid item xs={2} md={1} >
                  
                      <Button sx={{textDecoration:'none', color: '#000'}} autoFocus onClick={handleClose}>
                          <ArrowBackIcon sx={{fontSize: '30px'}}/>
                      </Button>
                  
                </Grid>
                <Grid item xs={10} md={4}>
                      <h1 style={{color: '#000', fontSize: '32px', fontWeight: '400'}}>Change Plan</h1>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={2} sx={{marginTop: '2%'}} >
                <Grid item xs={12} md={3} >
                    <h3 style={{color: '#000', fontSize: '24px', fontWeight: '400'}} >Change Charges -</h3>              
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        fullWidth
                        id="outlined-helperText"
                        label="Current charges"
                        disabled
                        defaultValue={currentCharge/100 }
                        InputProps={{               
                          style: { fontSize: '16px' },               
                            startAdornment: <span style={{ color: '#000' }}>$</span>,
                            sx: {
                                '& fieldset': {
                                  borderColor: '#000', // Set your desired border color
                                  
                                },
                                
                              
                              },
                            
                          }}
                          InputLabelProps={{
                            style: { color: '#000' },
                          }}
                        
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        id="outlined-number"
                        label="New Charges"
                        fullWidth
                        disabled
                        value = {currentCharge / 100 + totalPrice}
                        style={{fontSize : '20px'}}
                        InputProps={{  
                          style: { fontSize: '16px' },                         
                            startAdornment: <span style={{ color: '#000' }}>$</span>,
                            shrink: true,
                            sx: {
                                '& fieldset': {
                                  borderColor: '#000', // Set your desired border color                                  
                                },                               
                              
                              },
                          }}
                          InputLabelProps={{
                            style: { color: '#000' },
                          }}
                    />
                </Grid>

              </Grid>

              
              <Grid item xs={12} md={12} sx={{marginTop: '2%'}}>
      <h5 style={{color: '#000', fontSize: '24px', fontWeight: '400'}}>Features</h5>
      <label style={{ display: 'inline-flex', alignItems: 'center' ,fontWeight:  '400',fontSize : '18px'}}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={()=>handleCheckboxChange1()}
          style={{ marginRight: '5px' }}
        />
      Click for Recurring Payment
      </label>

    </Grid>
                
                {labelsWithPrices && labelsWithPrices.map((item, index) => (
                <Grid key={index} item xs={12} md={12} sx={{ marginTop: '2%', paddingLeft: '5%' }}>
                    <FormControlLabel
                    key={item.priceId}

                        value="end"
                        control={
                        <Checkbox sx={{ color: 'rgba(80, 164, 219, 1)', '& .MuiSvgIcon-root': { fontSize: 28 } }} 
                        checked={planIds.includes(item.priceId) || selectedPriceId.includes(item.priceId)} // Check if the priceId is in either planIds or selectedPriceId
                        onChange={handleCheckboxChange(item.price,item.priceId)} />}
                        disabled={planIds.includes(item.priceId)}
                        label={<span id='label-size' style={{ color: '#000', fontSize: '20px' }}>{item.label} - ${item.price}
                        </span>}
                        labelPlacement="end"
                        
                    />
                </Grid>
                ))}                         
                          
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
          <Button className='button_size' variant='contained' onClick={handleSubmit} autoFocus sx={{
            width: '20%',
            
            backgroundColor: '#50A4DB ',
            borderRadius: '8px',
            color: '#FFF',
            fontSize: '20px',
            fontWeight: '600',
            textTransform: 'none',     
            marginRight: '5%'
            
          }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
