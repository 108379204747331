import { Grid, Link } from '@mui/material';
import imgSrc from '../../Images/paymentCancel.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function PaymentCancel() {
    return(
        <div>
            <Grid item xs={12} md={12} sx={{
                marginTop: {
                    xs: '20%',
                    sm: '10%',
                    md: '7%',
                  }
            }}>
                <a href='/#/upgrade-subscription' style={{ textDecoration:'none', color: '#000'}}>
                    <ArrowBackIcon sx={{fontSize: '30px'}}/>
                </a>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <img src={imgSrc} alt="" style={{width: '50%', height: '50%'}}/>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center" sx={{color: '#000',textAlign: 'center', fontSize: '32px', fontWeight: '600', marginTop: '3%'}}>
                <p >Your plan has been cancelled</p>
            </Grid>
            <Grid item xs={12} md={12} container justifyContent="center" alignItems="center">
                <Link href="/#/subscription" sx={{border: '1px solid rgba(80, 164, 219, 1)', backgroundColor: 'rgba(80, 164, 219, 1)', color: '#FFF', fontSize: '20px', fontWeight: '600', textDecoration: 'none', padding: '10px 60px 10px 60px', marginTop: '3%'}} >Renew Plan</Link>
            </Grid>
        </div>
    )
}

export default PaymentCancel;