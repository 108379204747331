import { Grid } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './BarCard.css'
import { FaCircle } from 'react-icons/fa';

const BarChart = () => {
  const options = {
    series: [
        {
            name: 'Plan A',
            data: [44, 55, 57, 56, 61],
            color: 'rgba(80, 164, 219, 1), rgba(80, 164, 219, 0.34), rgba(80, 164, 219, 0)', // Specify the color for this series
          },
          {
            name: 'Plan B',
            data: [76, 85, 101, 98, 87],
            color: 'rgba(56, 178, 127, 1), rgba(177, 217, 176, 1)', // Specify the color for this series
          },
          {
            name: 'Plan C',
            data: [35, 41, 36, 26, 45],
            color: 'rgba(151, 71, 255, 1), rgba(194, 179, 214, 1)', // Specify the color for this series
          },
    ],
    chart: {
      type: 'bar',
      height: 350,
      width: '100%'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        
      
        startingShape: 'rounded',
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun'],
    },
    yaxis: {},
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val + ' thousands';
        },
      },
    },
   
  };

  return (
    
    <Grid  container direction="row"  alignItems="stretch" sx={{border: '1px solid #D9D9D9', boxShadow: '5px 5px 5px 5px rgba(0,0,0,0.2)', width: '100%'}}>
    <Grid item xs={12} md={12} sx={{color: '#000', fontSize: '24px', fontWeight: '600', paddingLeft: '5%', paddingTop: '1%'}}>
    <p>Subscription Analysis</p>
    </Grid>
    <Grid item xs={12} md={8}>
     
        <ReactApexChart options={options} series={options.series} type="bar" />
   
    </Grid>
    <Grid item direction="column" container xs={12} md={4} sx={{paddingLeft: '30px'}}>
        <p style={{color: '#000', fontSize: '26px', fontWeight: '400'}}>Users most preferred plan</p>
      
    <div className="series-labels">
          {options.series.map((s, index) => (
            <div key={index} className="series-label" style={{ color: s.color, marginBottom: '40px' }}>
              <FaCircle style={{ marginRight: '20px' }} />
              {s.name}
            </div>
          ))}
        </div>

        {/* <p style={{color: '#000', fontSize: '20px', fontWeight: '400'}}>Lorem ipsum dolor sit amet consectetur. Enim diam nunc ornare porttitor varius. Neque at justo scelerisque at leo habitasse et blandit neque.</p>
      */}
    </Grid>
    
  </Grid>

  );
};

export default BarChart;
