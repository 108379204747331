import React from 'react';
import { Grid, Typography, Container } from '@mui/material';
import Footer from '../../components/Footer/Footer';
import ActionAreaCard2 from '../../components/Tutorial_Card/Tutorial_Card';
import imgSrc from '../../Images/Rectangle 35.jpg';

function Tutorial() {
  return (
    <div >
    <Container maxWidth="lg" >
    
        <Typography
          variant="h4"
          style={{
            color: '#000',
            textAlign: 'center',
            fontSize: '40px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          // marginTop : '0px', 
         
          }}
        >
         m
        </Typography>
        <Grid item xs={12} mt={4}  sx={{
                color: '#000',
                textAlign: 'center',
                fontSize: '40px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                marginTop : '7%'
            }}>

<p> Tutorial</p>
              </Grid>
        <Typography
          variant="body1"
          style={{
            // color: '#AFAFAF',
            textAlign: 'center',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            maxWidth: '862px',
            margin: '0 auto',
            marginTop: '2%'
          }}
        >
          Lorem ipsum dolor sit amet consectetur. Risus et metus purus magna odio vivamus felis sit id. Ornare tempor
          pulvinar justo pellentesque. Dolor sed placerat nunc non risus. Egestas imperdiet mattis egestas eget facilisis
          ac sollicitudin nibh. In quam vitae nisl diam purus.
        </Typography>

      <Grid container item justifyContent="center" spacing={2} mt={5} alignItems="center">
        {[...Array(3)].map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
            <ActionAreaCard2 image={imgSrc} />
          </Grid>
        ))}
      </Grid>

      <Grid container item justifyContent="center" spacing={2} mt={5} alignItems="center">
        {[...Array(3)].map((_, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4} container justifyContent="center" alignItems="center">
            <ActionAreaCard2 image={imgSrc} />
          </Grid>
        ))}
      </Grid>

      
    </Container>
    <div style={{ marginTop: '50px' }}>
    <Footer />
  </div>
  </div>
  );
}

export default Tutorial;
